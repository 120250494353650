import styled from 'styled-components'

export const Wrapper = styled.div`
display: grid;
user-select: none;
overflow-x: clip;
position: relative;
width: 100vw;
height: 60px;
font-size: ${props => props.fontSize ? props.fontSize : '12px'};
align-items: center;
padding: 0 calc(3.22vw - 15px ) 0 3.22vw;
grid-template-columns: ${props => props.gridTemplateColumns ? props.gridTemplateColumns : '22px 22px 2fr 1fr 7rem 5rem 5rem 2rem 30px 30px'};
grid-column-gap: 20px;
text-transform: ${props => props.isHeader ? 'uppercase' : 'undefined'};
background: ${props => props.rowHighlight ? props.rowHighlight.background : 'transparent'};
outline: ${props => props.rowHighlight ? props.rowHighlight.border : '1px solid #000000'};
/* outline-offset: -1px; */
font-weight: ${props => props.rowHighlight ? props.rowHighlight.fontWeight : ''};
color: ${props => props.rowHighlight ? props.rowHighlight.textColor : '#ffffff'};
&:hover{
    background: ${props => props.isHeader || props.noHover ? 'transparent' && props.rowHighlight === undefined : props.rowHighlight ? props.rowHighlight.background : 'rgba(255,255,255,0.15)'};
}
&:hover >div{
    visibility: visible;
}

`;

export const ColumnElement = styled.div`
    display: flex;
    align-items: center;
    visibility: ${props => props.hideOffHover === true && props.showMenu === false ? 'hidden' : 'visible'};
    text-transform: ${props => props.uppercase ? 'uppercase' : ''};
    justify-content: ${props => props.align ? props.align : 'flex-start'};
    text-align: ${props =>
        props.align ?
            props.align === 'flex-start' ? 'left'
                : props.align === 'flex-end' ? 'right'
                    : props.align === 'center' ? 'center' : 'left'
            : 'left'};
        width: ${({ width }) => width};
        height: ${({ height }) => height};


`;

export const Action = styled.div`
width: 20px;
height: 20px;
padding: 3px;
position: relative;
display: flex;
align-items: center;
justify-content: center;
opacity: ${props => props.opacity ? props.opacity : 1};
svg{
    height: 90%;
    width: 90%;
}
`;

export const AlertIcon = styled(Action)`
position: ${props => props.position ? props.position : 'relative'};
padding: 3px;
right: ${props => props.right ? props.right : ''};
`;