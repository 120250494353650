import styled from 'styled-components';

export const DropdownListContainer = styled.div`

`;

export const DropdownContainer = styled.div`
    height: var(--defaultInputHeight);
    width: 100%;
    position: relative;
    background: var(--greyBackground);
    text-transform: uppercase;
font-size: var(--fontSmall);
font-weight: 700;
letter-spacing: 0.1rem;

    
`;

export const DropdownHeader = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 20px;
    user-select: none;
    img{
        width: 12px;
        position: absolute;
        right: 20px;
    }
    cursor: pointer;
`;

export const DropdownList = styled.ul`
margin: 0;
padding: 0;
background: var(--greyBackground);
position: relative;
z-index: 2;
box-shadow: 0 5px 3px -1px rgb(0,0,0);
height: 100%;

`;

export const ListItem = styled.li`
    height: var(--defaultInputHeight);
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding-left: 20px;
    user-select: none;
    cursor: pointer;
    background: ${props => props.isSelected? 'rgba(255,255,255,0.2)' : 'transparent'};
    color: ${props => props.isSelected? 'rgb(255,255,255)' : 'var(--purpleGrey)'};
    
`;

export const DropdownArrow = styled.img`
    transform: ${props => props.shouldRotate? 'rotate(180deg)' : '0'};
`;

export const LabelStyled = styled.label`
    position: relative;
    font-size:  1em;
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;