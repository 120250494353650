import styled from "styled-components";

export const ButtonStyle = styled.button`
    all: unset;
    font-family: Raleway;
    height: ${props => props.height ? props.height : 'var(--buttonHeight)'};
    font-size: ${props => props.fontSize ? props.fontSize : 'var(--buttonFontSize)'};
    color: ${props => props.color ? props.color : props.validContinue ? 'var(--white)' : '#000'};
    width: ${({ width }) => width};
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.background};
    text-transform: ${props => props.transform ? props.transform : 'uppercase'};
    cursor: ${props => props.validContinue ? 'pointer' : 'default'};
    border: ${({ border }) => border};
    border: ${props => props.validContinue ? props.border : '2px solid rgba(255,255,255,0.1)'};
    border-radius: ${({ borderRadius }) => borderRadius};
    box-shadow: ${({ boxShadow }) => boxShadow};
    background: ${props => props.validContinue ? props.background : 'rgba(255,255,255,0.15)'};
    font-weight: ${props => props.validContinue ? '500' : '700'};
    padding: ${props => props.padding ? props.padding : ''};
    letter-spacing: 0.08rem;
    white-space: nowrap;
    opacity: ${props => props.setOpacity ? props.setOpacity: ''};
    transition: ${props => props.transition ? props.transition: ''};
    :hover{
        background: ${props => props.hoverColor ? props.hoverColor : 'rgba(255,255,255,0.15)'} ;

    }
`; 