import { useState, useEffect, useRef } from 'react';
import API from '../API';
import { useAuth } from '../contexts/AuthContext'
import { updateRelTime } from '../utils'
import dayjs from 'dayjs'
import { numToFormattedPriceString, showTrailing, calculatePriceWithFee, calculateCut } from '../helpers'

import agreedIcon from '../images/agreedIcon.svg'
import notAccepted from '../images/close.svg'

import ToolTip from '../components/ToolTip'
import Flexbox from '../components/Flexbox'

import { Span, FlexCell } from '../components/TransactionHistory/TransactionHistory.styles'



export const getCompletionStatus = (payoutComplete = undefined, stemTransfer = [], index = 0, justifyContent = 'center') => {
    if (stemTransfer === undefined)
        return '-'
    if (payoutComplete === undefined && stemTransfer.length > 0)
        return stemTransfer[index].updated_at ?
            <ToolTip justifyContent={justifyContent} boxShadow="0 0 10px rgb(0,0,0)" background="#000000" bottom="20px" border="#000000" relative toolTipPosition="absolute" toolTipText={`Confirmed ${dayjs.unix(stemTransfer[index].updated_at).format('M/D/YY')}`}>
                <img src={agreedIcon} style={{ width: '15px', height: '15px' }} /></ToolTip > : <img src={notAccepted} style={{ height: '15px', width: '15px' }} />
    else if (!stemTransfer[0].updated_at || !stemTransfer[1].updated_at)
        return '-'
    else return payoutComplete === '1' ? <img src={agreedIcon} style={{ height: '15px', width: '15px' }} /> : <img src={notAccepted} style={{ height: '15px', width: '15px' }} />
}

export const tableDeliverablesFormatted = (payoutComplete = undefined, stemTransfer = [], index = 0) => {
    if (stemTransfer === undefined)
        return '-'
    if (payoutComplete === undefined && stemTransfer.length > 0)
        return stemTransfer[index].updated_at ?
            dayjs.unix(stemTransfer[index].updated_at).format('M/D/YY') : '-'
    else if (!stemTransfer[0].updated_at || !stemTransfer[1].updated_at)
        return '-'
    else return '-'
    // else return payoutComplete === '1' ? <img src={agreedIcon} style={{ height: '15px', width: '15px' }} /> : <img src={notAccepted} style={{ height: '15px', width: '15px' }} />
}


export const useTransactionHook = (args) => {
    const { songs } = args
    const initialState = {
        data: [],
        buyerHeader: [],
        songwriterHeader: [
        ],
        headerPadding: '20px 10px 0',
        subHeaderPadding: '5px 10px',
        subHeader: [],
        subHeaderSongwriter: [],
        nbHits: { title: 'Songs Sold', value: 0 },
        totalValue: { title: 'Total Sales', value: 0 },

    }
    const [tableData, setTableData] = useState(initialState)

    const { totalValue, nbHits } = tableData
    const { currentUser } = useAuth()
    updateRelTime()
    const [selected, setSelected] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const onSearchChange = (e) => {
        setSearchValue(e.target.value)
    }
    const filterOptions = useRef({
        status: [{ title: 'Pending Deliverables Transfer', value: 5 }, { title: 'Pending Payout', value: 6 }, { title: 'Past', value: 7 }]
    })
    const totalResults = useRef(0);
    const resultsPerPage = 5
    const pageRef = useRef(1)
    const [page, setPage] = useState(1)
    const [filters, setFilters] = useState({ playlist: 'Sales', status: 7 })
    const { status: statusFilter } = filters
    const [loading, setLoading] = useState(true)
    const secondColumnSize = useRef('2fr min-content')
    const [sideBarDetails, setSideBarDetails] = useState({
        showSideBar: false,
        sideBarType: 1,
        fullSideBar: false,
        data: {},
        title: '',
        songTitle: '',
        buttonText: ''

    })
    const defaultFilters = { searchValue: '', searchTermList: [] }
    const [sideBarType, setSideBarType] = useState(false)
    const [activeFilters, setActiveFilters] = useState(defaultFilters)
    const { searchTermList } = activeFilters
    const selectedSong = useRef(null)
    const handleSelect = (c) => {
        selectedSong.current = c
    }
    const payload = useRef({
        songId: '',
        category: null
    })
    const songDetails = {
        options: {
            'option-1': {
                id: 'songwriter-details',
                title: 'Songwriter Details',
                onClick: async (songId) => { return await API.fetchUserBySongId(currentUser, songId) },
            },
        }
    }

    const getDotMenu = (song) => {
        console.log("song is", song)
        return [
            {
                value: 1, innerText: 'Payout', onClick: () => {
                    // setSideBar(column.id, song.id)
                }
            },
            {
                value: 2, innerText: 'Song Details', onClick: () => {
                    setSideBar('song-details', song.id)
                }
            },
            {
                value: 3, innerText: 'Songwriter Details', onClick: () => {
                    setSideBar('songwriter-details', song.id)
                }
            },
            {
                value: 4, innerText: 'Buyer Details', onClick: () => {
                    setSideBar('buyer-details', song.id)
                }
            },
            {
                value: 5, innerText: 'Message Songwriter', onClick: () => {
                    setSideBar('message-songwriter', song.id)
                }
            },
            {
                value: 6, innerText: 'Message Buyer', onClick: () => {
                    setSideBar('message-buyer', song.id)
                }
            },
        ]
    }

    const mapSongDetails = (_sideBarId, songId) => {
        const typeId = Object.keys(songDetails.options).find(id => {
            const _option = songDetails.options[id]
            return _option.id === _sideBarId
        })
        console.log("type id is", typeId, _sideBarId)
        return songDetails.options[typeId]
    }

    const setSideBar = async (_sideBarId, songId) => {
        payload.current = { ...payload.current, songId: songId }
        setSelected([songId])
        const _detailsType = mapSongDetails(_sideBarId, songId)
        const res = await _detailsType.onClick(songId)
        console.log("songId", songId, songs)
        const _selectedSongDetails = songs.find(it => parseInt(it.song_id) === parseInt(songId)).song_title
        if (res) {
            secondColumnSize.current = '2fr minmax(500px, 1fr)'
            setSideBarDetails(prev => ({ ...prev, sideBarType: _sideBarId, showSideBar: true, fullSideBar: true, title: _detailsType.title, data: { ...res }, songTitle: _selectedSongDetails }))
            // setButton(prev => ({ ...prev, fullSideBar: true }))

        }
        // }
    }
    const columns = [
        { Header: "Song Title", accessor: "Song Title" },
        // { Header: "Contact Email", accessor: "email" },
        // // { Header: "Contact Email", accessor: "email" },
        // { Header: "Paypal Email", accessor: "songwriterPaypal" },
        // { Header: "Split [%]", accessor: "split_percent" },
        // { Header: "Split [$]", accessor: "split" },
    ]

    const getDataValue = (data, accessor) => {
        console.log("data", accessor, data[accessor], data[accessor], typeof (data[accessor]))
        if (typeof (data[accessor]) !== 'object')
            return data[accessor]
        else
            return data[accessor]
    }

    const makeCSV = async (rows = [], filename) => {
        const separator = ','
        const keys = Object.keys(rows[0])
        const csvContent = `${keys.join(separator)}\n${rows.map((row) => keys.map((k) => {
            let cell = row[k] === null || row[k] === undefined ? '' : row[k];

            cell = cell instanceof Date
                ? cell.toLocaleString()
                : cell.toString().replace(/"/g, '""');

            if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`;
            }
            return cell;
        }).join(separator)).join('\n')}`;
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // In case of IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    const getTableDataForExport = (data = [], columns = []) => {
        console.log("data", data, columns)
        let _columns = Object.keys(data[0]['tableDataForExport']).map(it => {
            let header = it
            return ({ Header: header, accessor: it })
        })
        return data.map((record) => {
            console.log("record is", record)
            return _columns.reduce((recordToDownload, column) => ({ ...recordToDownload, [column.Header]: getDataValue(record['tableDataForExport'], column.accessor) }), {})
        })
    }

    const formatSongwriterTableData = (song, index, headerProps) => {
        const { isPrimary, buyerLabel, buyerName, song_title, split, priceWithFee, split_percent, primarySongwriterEmail, paypalDetails, buyerEmail, stemTransfer, price, saleDetails, payoutComplete, payoutDate } = song
        const tableData = {}
        const tableDataForExport = {}

        tableData['Primary Songwriter Contact Email'] = {
            major: 'Song Title',
            title: <FlexCell >
                <span>{song_title}</span>
                <Span>{primarySongwriterEmail}</Span></FlexCell>,
            props: { padding: '20px 10px', }
        }
        tableData['Email'] = {
            major: 'Buyer',
            title: <FlexCell><span>{buyerName}</span><Span >{buyerEmail}</Span></FlexCell>,
            props: { padding: '20px 10px', }
        }
        tableDataForExport['Song Title'] = song_title
        tableDataForExport['Primary Songwriter Contact Email'] = primarySongwriterEmail
        tableDataForExport['Buyer Name'] = buyerName
        tableDataForExport['Buyer Email'] = buyerEmail
        tableDataForExport['Buyer Label'] = buyerLabel
        tableDataForExport['Deliverables Confirmation - Songwriter'] = tableDeliverablesFormatted(undefined, stemTransfer, 0)
        tableDataForExport['Deliverables Confirmation - Buyer'] = tableDeliverablesFormatted(undefined, stemTransfer, 1)
        tableDataForExport['Price [Inc Fee]'] = numToFormattedPriceString(priceWithFee)
        tableDataForExport['Split [%]'] = split_percent.toString()
        tableDataForExport['Split [$]'] = numToFormattedPriceString(split)
        tableDataForExport['Date Sold'] = dayjs.unix(saleDetails.dateSold).format('M/D/YY')
        if (filters.status === 7)
            tableDataForExport['PayPal Transaction Id'] = paypalDetails.paypalTransactionID
        tableData['Buyer Label'] = { title: buyerLabel, props: { padding: '20px 10px', } }
        if (filters.status === 5) {
            // tableData['Contact Email'] = { major: 'Primary Songwriter', title: primarySongwriterEmail, props: { padding: '20px 10px', } }
            tableData['Songwriter'] = { major: 'Deliverables Confirmation', title: getCompletionStatus(undefined, stemTransfer, 0), tableData: tableDeliverablesFormatted(undefined, stemTransfer, 0), props: { width: 'min-content', padding: '0', textAlign: 'center' } }
            tableData['Buyer'] = { major: 'Deliverables Confirmation', title: getCompletionStatus(undefined, stemTransfer, 1), tableData: tableDeliverablesFormatted(undefined, stemTransfer, 1), props: { width: 'min-content', padding: '0', textAlign: 'center' } }
        }

        tableData['Inc. Fee'] = { major: 'Price', title: numToFormattedPriceString(priceWithFee), props: { padding: '20px 10px', textAlign: 'center' } }
        // tableData['Split [%]'] = { title: `${split_percent.toString()}%`, props: { padding: '20px 10px', textAlign: 'left' } }
        tableData['Split [%]'] = {
            major: 'Split [$]',
            title: <FlexCell end><span>{numToFormattedPriceString(split)}</span><Span>{`${split_percent.toString()}%`}</Span></FlexCell>, props: { padding: '20px 10px', textAlign: 'right' }
        }
        tableData['Date Sold'] = { title: dayjs.unix(saleDetails.dateSold).format('M/D/YY'), props: { padding: '20px 10px', textAlign: 'center' } }

        if (filters.status === 7) {
            tableData['Transaction Id'] = {
                major: 'Paypal Transaction Info', title: paypalDetails ? paypalDetails.paypalTransactionID : '-',
                props: { userSelect: 'all', width: 'auto', padding: '20px 10px', fontWeight: '700', background: "#0079C1", outlineOffset: '0', outline: '1px solid #00457C', color: '#00457C', textAlign: 'center', },
            }
            // tableData['My Paypal Receiver Id'] = {
            //     major: 'Paypal Transaction Info', title: paypalDetails ? paypalDetails.paypalReceiverID : '-',
            //     props: { width: 'auto', padding: '20px 10px', fontWeight: '700', background: "#0079C1", outlineOffset: '0', outline: '1px solid #00457C', color: '#00457C', textAlign: 'center', },
            // }
        }
        return { tableData, tableDataForExport }
    }

    const getTableHeader = () => {
        const tableData = {}
        const minorProps = { fontSize: '10px', padding: '0 10px 5px', textAlign: 'left', opacity: 0.35 }
        tableData['Primary Songwriter Contact Email'] = { padding: '20px 10px 5px', minWidth: '', height: '', width: 'auto', textAlign: '', minorProps }
        tableData['Email'] = { padding: '20px 10px 5px', minWidth: '', height: '', textAlign: 'left', width: 'min-content', whiteSpace: 'nowrap', minorProps }
        tableData['Buyer Label'] = { padding: '20px 10px 5px', minWidth: '', height: '', textAlign: 'left', width: 'min-content', whiteSpace: 'nowrap', minorProps }
        if (filters.status === 5) {
            // tableData['Contact Email'] = { padding: '20px 10px 5px', minWidth: '', height: '', width: 'max-content', whiteSpace: 'nowrap', textAlign: '', minorProps }
            tableData['Songwriter'] = { padding: '20px 10px 5px', colSpan: '2', minWidth: '', height: '', width: 'min-content', whiteSpace: '', textAlign: 'center', fontSize: '10px', minorProps: { ...minorProps, textAlign: 'center' } }
            tableData['Buyer'] = { padding: '20px 10px 5px', colSpan: '2', minWidth: '', height: '', width: 'min-content', textAlign: 'center', whiteSpace: 'nowrap', fontSize: '10px', minorProps: { ...minorProps, textAlign: 'center' } }

        }
        // else {
        //     tableData['Split [%]'] = { padding: '20px 10px 5px', minWidth: '', height: '', textAlign: 'left', width: 'min-content', whiteSpace: 'nowrap', }
        // }

        tableData['Inc. Fee'] = { padding: '20px 10px 5px', minWidth: '', height: '', textAlign: 'left', width: 'min-content', textAlign: 'center', minorProps: { padding: '0', textAlign: 'center', fontSize: '10px' } }
        // tableData['Split [%]'] = { padding: '20px 10px 5px', minWidth: '', height: '', textAlign: 'center', width: 'min-content', whiteSpace: 'nowrap', }
        tableData['Split [%]'] = { padding: '20px 10px 5px', minWidth: '', height: '', textAlign: 'right', width: 'min-content', whiteSpace: 'nowrap', minorProps: { ...minorProps, textAlign: 'right' } }
        tableData['Date Sold'] = { padding: '20px 10px 5px', minWidth: '', height: '', width: 'min-content', whiteSpace: 'nowrap', textAlign: 'center' }

        if (filters.status === 7) {
            tableData['Transaction Id'] = { width: 'auto', background: '#002b45', color: '#0079C1', padding: '20px 10px 5px', colSpan: '3', textAlign: 'center', minorProps: { background: '#002b45', color: '#0079C1', textAlign: 'center', padding: '0 10px 5px' } }
            // tableData['My Paypal Receiver Id'] = { width: 'auto', background: '#002b45', color: '#0079C1', padding: '20px 10px 5px', colSpan: '3', textAlign: 'center', minorProps: { background: '#002b45', color: '#0079C1', textAlign: 'center', padding: '0 10px 5px' } }
        }

        return tableData
    }

    const onSearchTermRemoval = () => {
        setSearchValue(null)
    }

    const getDataSongwriter = async (_page = 1, search = null) => {
        const res = await API.getSales(currentUser, _page, filters, resultsPerPage, search)
        if (res.songs) {
            const { nbHits, totalSales, totalResults: _totalResults } = res
            totalResults.current = _totalResults || 0
            const formattedData = []
            const headerProps = getTableHeader()
            const { songs } = res
            songs.forEach((song, index) => {
                const { isPrimary } = song
                const { tableData: d, tableDataForExport } = formatSongwriterTableData(song)

                if (index === 0) {
                    console.log("D", d)
                    const withHeader = Object.keys(d).map((it, index) => ([it, { ...d[it], headerProps: headerProps[it] }]))

                    formattedData.push({ data: Object.fromEntries(withHeader), tableDataForExport, id: song.song_id })
                }
                else
                    formattedData.push({ data: d, tableDataForExport, id: song.song_id })
            })
            // return formattedData
            setTableData(prev => ({ ...prev, data: formattedData, nbHits: { title: 'Songs Sold', value: nbHits }, totalValue: { title: 'Total Sales', value: numToFormattedPriceString(totalSales) }, }))
        }
        else {
            totalResults.current = 0
            setTableData(prev => ({ ...prev, data: [], nbHits: initialState.nbHits, totalValue: initialState.totalValue, }))
        }
    }

    const getDataBuyer = async (_page = 1, search = null) => {
        const res = await API.getSongsBuyer(currentUser, _page, { ...filters, playlist: 'Purchases',  }, resultsPerPage, search)

        if (res.songs) {
            const { nbHits, totalPurchases, totalResults: _totalResults } = res
            
            totalResults.current = _totalResults || 0
            const formattedData = []
            const headerProps = [
                { padding: '20px 10px 0', minWidth: '', height: '', width: 'auto', textAlign: '' },
                { padding: '20px 10px 0', minWidth: '', height: '', width: 'max-content', whiteSpace: 'nowrap', textAlign: '' },
                { padding: '20px 10px 0', minWidth: '', height: '', width: 'auto', whiteSpace: 'nowrap', textAlign: '' },
                { padding: '20px 10px 0', colSpan: '2', minWidth: '', height: '', width: 'min-content', whiteSpace: '', textAlign: 'center', minorProps: { width: 'min-content', textAlign: 'center', padding: '5px 10px 5px' } },
                { padding: '20px 10px 0', colSpan: '2', minWidth: '', height: '', width: 'min-content', textAlign: 'center', whiteSpace: 'nowrap', minorProps: { width: 'min-content', textAlign: 'center', padding: '5px 10px 5px' } },
                { padding: '20px 10px 0', minWidth: '', height: '', textAlign: 'center', width: 'min-content', minorProps: { textAlign: 'center', padding: '5px 10px 5px' } },
                { padding: '20px 10px 0', minWidth: '', height: '', width: 'min-content', whiteSpace: 'nowrap', textAlign: 'center' },
                // {colSpan: '3', textAlign: 'center', minorProps: { color: '#545454', textAlign: 'center', width: '127px', padding: '0 10px 5px' }},
                { width: 'auto', background: '#002b45', color: '#0079C1', padding: '20px 10px 0', colSpan: '3', textAlign: 'center', minorProps: { background: '#002b45', color: '#0079C1', textAlign: 'center', padding: '5px 10px 5px' } },
                // {colSpan: '3', textAlign: 'center', minorProps: { color: '#545454', textAlign: 'center', width: '127px', padding: '0 10px 5px' }},
                { width: 'auto', background: '#002b45', color: '#0079C1', padding: '20px 10px 0', colSpan: '3', textAlign: 'center', minorProps: { background: '#002b45', color: '#0079C1', textAlign: 'center', padding: '5px 10px 5px' } },
            ]
            const { songs } = res
            songs.forEach((song, index) => {
                const d = (({ song_title, songwriter_email, primarySongwriterName, buyerOrderID, buyerTransactionID, buyerInvoiceID, buyerPaypalPayerID, buyerPaypalPayerEmail, buyer_email, stemTransfer, price, saleDetails, payoutComplete, payoutDate }) =>
                ({
                    'Song Title': { title: song_title, props: { padding: '20px 10px', } },
                    'Songwriter Email': { title: songwriter_email, props: { padding: '20px 10px', } },
                    'Songwriter Name': { title: primarySongwriterName, props: { padding: '20px 10px', } },
                    'Songwriter': { major: 'Deliverables Confirmation', title: getCompletionStatus(undefined, stemTransfer, 0), tableData: tableDeliverablesFormatted(undefined, stemTransfer, 0), props: { width: 'min-content', padding: '0', textAlign: 'center' } },
                    'Buyer': { major: 'Deliverables Confirmation', title: getCompletionStatus(undefined, stemTransfer, 1), tableData: tableDeliverablesFormatted(undefined, stemTransfer, 1), props: { width: 'min-content', padding: '0', textAlign: 'center' } },
                    'Inc. Fee': {
                        major: 'Price',
                        title: price,
                        // title: numToFormattedPriceString(price), 
                        props: { padding: '20px 10px', textAlign: 'center' }
                    },
                    'Date Purchased': { title: dayjs.unix(saleDetails.dateSold).format('M/D/YY'), props: { padding: '20px 10px', textAlign: 'center' } },
                    'Order Id': {
                        major: 'Paypal Transaction Info', title: buyerOrderID,
                        props: { width: 'auto', padding: '20px 10px', fontWeight: '700', background: "#0079C1", outlineOffset: '0', outline: '1px solid #00457C', color: '#00457C', textAlign: 'center', },
                    },
                    // 'Transaction ID': { major: 'Paypal Transaction Info', title: buyerTransactionID, props: {textAlign: 'center', }, },
                    'Invoice Id': {
                        major: 'Paypal Transaction Info', title: buyerInvoiceID,
                        props: { width: 'auto', padding: '20px 10px', fontWeight: '700', background: "#0079C1", outlineOffset: '0', outline: '1px solid #00457C', color: '#00457C', textAlign: 'center', },
                    },
                }))(song)
                if (index === 0) {
                    console.log("D", d)
                    const withHeader = Object.keys(d).map((it, index) => ([it, { ...d[it], headerProps: headerProps[index] }]))

                    formattedData.push({ data: Object.fromEntries(withHeader), id: song.song_id })
                }
                else
                    formattedData.push({ data: d, id: song.song_id })
            })
            // return formattedData
            setTableData(prev => ({ ...prev, data: formattedData, nbHits: { title: 'Songs Purchased', value: nbHits }, totalValue: { title: 'Total Purchases', value: numToFormattedPriceString(totalPurchases) }, }))

        }
        else {
            totalResults.current = 0
            setTableData(prev => ({ ...prev, data: [], nbHits: { title: 'Songs Purchased', value: 0 }, totalValue: { title: 'Total Purchases', value: 0 }, }))
        }
    }

    const isBuyer = () => {
        if (currentUser.userDetails) {
            const { userDetails: { tier } } = currentUser
            return tier === 'BUYER'
        } else {
            return false
        }
    }

    const onPageChange = async (_page) => {
        if (currentUser.userDetails) {
            const { userDetails: { tier } } = currentUser
            if (tier === 'BUYER')
                await getDataBuyer(_page)
            else {
                await getDataSongwriter(_page)
            }
        }
    }
    const fetchPage = async (_page = page, filter = null) => {
        if (typeof (onPageChange) === 'function') {
            if (page !== _page)
                setPage(_page)
            await onPageChange(_page, activeFilters, resultsPerPage)
        }
    }

    const fetchMore = async (_page, filter = null) => {
        if (typeof (onPageChange) === 'function') {
            await onPageChange(_page, activeFilters, resultsPerPage)
        }
    }

    const onSearchFieldChange = (input) => {
        setActiveFilters(prev => ({ ...prev, searchValue: input }))
    }

    const onSearchListUpdate = (newList) => {
        setActiveFilters(prev => ({ ...prev, searchTermList: [...newList] }))
    }

    useEffect(() => {
        if (isBuyer())
            filterOptions.current = { status: [{ title: 'Pending Deliverables Transfer', value: 5 }, { title: 'Past', value: 7 }] }
        else
            filterOptions.current = { status: [{ title: 'Pending Deliverables Transfer', value: 5 }, { title: 'Pending Payout', value: 6 }, { title: 'Past', value: 7 }] }

    }, [currentUser])


    useEffect(() => {
        fetchPage(1)
    }, [currentUser, statusFilter])

    const getSuggestions = async (_input, items, setItems, setInput) => {

        // const filter_ = { filter }
        // // const res = await API.searchAcceptedSongs(currentUser, filter_, _input)
        // const [_songs, nbHits, resultsRevised] = await API.fetchSongs2(currentUser.accessToken, currentUser.email, page, resultsPerPage, filter_, _input)
        // if (resultsRevised) {
        //     let res = resultsRevised.map(it => {
        //         return ({
        //             innerText: it['song_title'],
        //             value: it['song_title'],
        //             onClick: () => {
        //                 if (!items.find(el => el.id !== it['song_title'])) {
        //                     setItems([{ value: it['song_title'], id: it['song_title'], match: it['song_id'] }])
        //                     // setItems([...items, it.name])
        //                 }
        //                 setInput('')
        //             },
        //             key: it['song_id']
        //         })
        //     })
        //     console.log("res", res)
        //     return res
        // } else
        //     return []
    }
    const onSearchEnter = async (_val) => {
        console.log("entered search", _val, typeof (_val), Array.isArray(_val))
        // if (Array.isArray(_val)) {
        //     setPage(1)
        //     if (pageRef)
        //         pageRef.current = 1
        //     await pullSongs(1, filter, sort, true, false,
        //         _val.length > 0 ? _val[0]['value'] : null,
        //         true)
        // }

    }

    // const pageNavProps = { onSearchEnter, getSuggestions, pageRef, resultsPerPage, totalResults, page, setPage, totalResults: totalResults.current }
    const pageNavProps = { onSearchEnter, getSuggestions, pageRef, resultsPerPage, fetchMore, searchValue, searchList: searchTermList, activeFilters, onSearchFieldChange, onSearchListUpdate, page, setPage, totalResults: totalResults.current, fetchPage }
    const closeSideBar = () => { secondColumnSize.current = '2fr min-content'; setSideBarDetails(prev => ({ ...prev, showSideBar: false })); }
    console.log("searchValue", searchValue)
    return {
        tableData,
        selectedSong,
        handleSelect,
        getDotMenu,
        pageNavProps,
        sideBarDetails,
        selected,
        sideBarDetails,
        closeSideBar,
        secondColumnSize,
        filters,
        setFilters,
        filterOptions,
        onSearchChange,
        searchValue,
        nbHits,
        totalValue,
        onPageChange,
        resultsPerPage,
        columns,
        totalResults: totalResults.current,
        makeCSV,
        getTableDataForExport,
        onSearchTermRemoval
    }

}