import React, { useState, useEffect, useRef } from "react";
import anime from "animejs";
import { useNavigate } from 'react-router-dom'
import LoginOverlay from '../LoginOverlay'
import Button from '../Button'
import { BUYER_SIGNUP } from '../../config'
import { throttle } from '../../utils'
// Styles
import { LogoGlassWrapper, Wrapper, VideoHeader, ImageHeader, LogoWrapper, OverlayWrapper, LogoOverlay, ButtonStyle, GridItem, ButtonNoNav } from './HeroVideo.styles';
import Logo from '../../images/azaaLogo.svg';
import VideoFirstFrame from '../../images/videoFirstFrame.png';
import VideoStills2 from '../../images/videoStills2.png';
import VideoStills3 from '../../images/videoStills3.png';
import VideoStills4 from '../../images/videoStills4.png'
import VideoStills5 from '../../images/videoStills5.png'
import VideoStills6 from '../../images/videoStills6.png'
import VideoStills7 from '../../images/videoStills7.png'
import VidVid from '../../images/AzaaMusicPlatformHeroVideo.mp4';

// components
import MobileVisitor from '../MobileVisitor'

const ButtonLink = ({ onClick, position, name, type }) => {
    if (type === 'desktop')
        return (
            <ButtonStyle onClick={onClick} position={position} isSellButtonDesktop={true}>{name}</ButtonStyle>
        )
    else return (
        <ButtonStyle onClick={onClick} position={position} isSellButtonMobile={true}>{name}</ButtonStyle>
    )
};

const LogoGlass = () => {

    return (
        <LogoGlassWrapper>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 440 520">
                <g id="Layer_2" style={{ fill: 'rgba(255,255,255,0.35)', strokeWidth: '1px', stroke: '#ffffff' }}>
                    <g id="Layer_4" >
                        <path className="logoGlass" d="M434.39,302.82q0,51.23,0,102.38c0,20.94-9.13,30-30.18,30q-39.09,0-78.14,0t-78.15,0c-21.62,0-30.54-8.95-30.54-30.5,0-85.92.18-171.87-.07-257.79-.14-39.66-31.68-69.45-68.8-69.45a71,71,0,0,0-16.43,2c-38,9-61,48.87-50,86.35a67.84,67.84,0,0,0,65.16,48.61,68.76,68.76,0,0,0,22.8-3.9c5.44-1.93,10.74-4.3,16.18-6.23a12.66,12.66,0,0,1,4-.75c4,0,6.15,2.9,6.94,7.77a42.24,42.24,0,0,1,.14,6.37V418.84c0,10.52-1.14,13.82-8.48,13.82a54.68,54.68,0,0,1-6.52-.5c-38.37-4.94-71.13-22.66-100.84-46.33C-45.35,285-19.36,88.53,126.59,19.9A209.45,209.45,0,0,1,216,0c94,0,184.14,61.93,209.67,158.37,5.37,20.37,7.34,42,8.41,63.11C435.46,248.51,434.39,275.72,434.39,302.82Z" />
                        <path d="M281.49,510.84H278.7c-4,0-6.95-2-9-6.11l-4.48-11.57H237.08a9.39,9.39,0,0,1,9.38-9.42h15.11l-12.13-31.35-20.29,52.34c-2,4.06-4.94,6.11-8.91,6.11h-2.85L241,449.92a9.59,9.59,0,0,1,5.37-5.46l2.56-.7.51,0,.52,0,2.56.7a9.47,9.47,0,0,1,5.37,5.46Z" /><path className="cls-1" d="M288.42,510.8a9,9,0,0,1,1.31-4.76l26.64-31.17H297.94a9.12,9.12,0,0,1-6.67-2.75,9.25,9.25,0,0,1-2.75-6.67h46a9.16,9.16,0,0,1-1.82,5.6l-25.94,30.37H325a9.39,9.39,0,0,1,9.42,9.38Z" /><path className="cls-1" d="M372.82,505.71a8.94,8.94,0,0,1-1-4.29v-13.3a13.27,13.27,0,1,0-26.54,0,13,13,0,0,0,3.87,9.43,12.75,12.75,0,0,0,9.38,3.87h3.87c3.87,0,6.72,1.87,8.63,5.65a9.1,9.1,0,0,1,.79,3.73H358.5q-11.34,0-18.39-9.38a22.13,22.13,0,0,1-4.29-13.3,22.64,22.64,0,0,1,22.68-22.67h1.16a22,22,0,0,1,2.43.28,14.24,14.24,0,0,1,2.61.56,17.29,17.29,0,0,1,2.43.84,25.1,25.1,0,0,1,4.52,2.52l.14.09a25.38,25.38,0,0,1,2.75,2.34,21.8,21.8,0,0,1,6.63,16V510.8A9.07,9.07,0,0,1,372.82,505.71Z" /><path className="cls-1" d="M425.11,505.71a8.94,8.94,0,0,1-1-4.29v-13.3a13.26,13.26,0,0,0-22.67-9.37,12.79,12.79,0,0,0-3.87,9.37,13,13,0,0,0,3.87,9.43,12.77,12.77,0,0,0,9.38,3.87h3.87c3.87,0,6.72,1.87,8.63,5.65a9.1,9.1,0,0,1,.79,3.73H410.79c-7.56,0-13.67-3.13-18.38-9.38a22.07,22.07,0,0,1-4.3-13.3,22.64,22.64,0,0,1,22.68-22.67H412a22.14,22.14,0,0,1,2.42.28,14.24,14.24,0,0,1,2.61.56,16.86,16.86,0,0,1,2.43.84,24.76,24.76,0,0,1,4.52,2.52l.14.09a26.48,26.48,0,0,1,2.76,2.34,21.79,21.79,0,0,1,6.62,16V510.8A9.07,9.07,0,0,1,425.11,505.71Z" />
                    </g>
                </g>
            </svg>
        </LogoGlassWrapper>
    )
}

const HeroVideo = ({ video }) => {
    const navigate = useNavigate()
    const animationRef = useRef(null)
    const videoRef = useRef(null)
    const headerImageRef = useRef(null)
    const [imageVisibility, setImageVisibility] = useState("hidden")
    const [showImages, setShowImages] = useState(false)
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const [showModal, setShowModal] = useState(false)

    const [showMobileModal, setShowMobileModal] = useState(false)
    useEffect(() => {
        let isMounted = true;
        const handleResize = throttle(() => {
            if (isMounted) {
                setWindowSize(window.innerWidth)
            }
        }, 1000)
        window.addEventListener("resize", handleResize);
        return () => {
            isMounted = false
            window.removeEventListener("resize", handleResize)
        };
    }, [])
    useEffect(() => {

        // animationRef.current.add({
        //     opacity: 0
        // })
        // .add({
        //     opacity: 1
        // })
    }, [])
    useEffect(() => {
        console.log(windowSize)
        if (windowSize > 600 && videoRef.current !== null && headerImageRef.current !== null) {
            videoRef.current.src = VidVid

        } else {
            setShowImages(true)

        }

    }, [windowSize])
    useEffect(() => {
        if (showImages && document.querySelectorAll('.imgCarousel').length === 7) {
            animationRef.current = anime.timeline({
                // targets: ".imgCarousel",
                // opacity: 0,
                // delay: function (el, i) {
                //     return i * 1000;
                // },
                loop: true,
                delay: 3000,
                endDelay: 3000,
                direction: "alternate",
                easing: "linear"
            });
            animationRef.current.add({
                targets: '#image1',
                opacity: 0
            })
                .add({
                    targets: '#image2',
                    opacity: 0
                })
                .add({
                    targets: '#image3',
                    opacity: 0
                })
                .add({
                    targets: '#image4',
                    opacity: 0
                })
                .add({
                    targets: '#image5',
                    opacity: 0
                })
                .add({
                    targets: '#image6',
                    opacity: 0
                })

        }
    }, [showImages])
    return (
        <Wrapper>

            <VideoHeader
                ref={videoRef}
                onLoadedData={() => {
                    if (windowSize > 600 && videoRef.current !== null && headerImageRef.current !== null) {
                        headerImageRef.current.style.zIndex = -1;
                    }
                }}
                id="heroVideo"
                preload="none"
                width="100%"
                height="auto"
                autoPlay={true}
                loop={true}
                controls={false}
                playsInline
                muted
            // src={VidVid}
            />
            {
                showImages && <>
                    <ImageHeader src={VideoStills7} id="image7" className="imgCarousel" visibility={imageVisibility} onLoad={() => { setImageVisibility("visible") }} />
                    <ImageHeader src={VideoStills6} id="image6" className="imgCarousel" visibility={imageVisibility} />
                    <ImageHeader src={VideoStills5} id="image5" className="imgCarousel" visibility={imageVisibility} />
                    <ImageHeader src={VideoStills4} id="image4" className="imgCarousel" visibility={imageVisibility} />
                    <ImageHeader src={VideoStills3} id="image3" className="imgCarousel" visibility={imageVisibility} />
                    <ImageHeader src={VideoStills2} id="image2" className="imgCarousel" visibility={imageVisibility} />
                </>
            }
            <ImageHeader ref={headerImageRef} src={VideoFirstFrame} id="image1" className="imgCarousel" visibility="visible"/>
            <OverlayWrapper>
                <LogoWrapper backgroundIMG={Logo}>
                    <LogoGlass />
                    {/* <LogoOverlay src={Logo} /> */}
                </LogoWrapper>
                <GridItem>
                    <ButtonNoNav onClick={() => navigate("/buyer-application")} text="Buy" position='left' name='Buy' width="100%" >Buy</ButtonNoNav>
                    {/* <ButtonNoNav onClick={() => setShowModal(true)} text="Buy" position='left' name='Buy' width="100%" >Buy</ButtonNoNav> */}
                    </GridItem>
                <ButtonLink onClick={() => navigate("/get-started")} position='right' name='Sell' type="desktop" />
                <ButtonLink onClick={() => setShowMobileModal(true)} position='right' name='Sell' type="mobile" />
                {showMobileModal && <MobileVisitor setShowMobileModal={setShowMobileModal}></MobileVisitor>}
            </OverlayWrapper>
            {showModal &&
                (<LoginOverlay
                    setShowLogin={setShowModal}
                    popupType={BUYER_SIGNUP}

                />)
            }
        </Wrapper>
    )
}

export default HeroVideo;