import React, { useState, useEffect, useRef, useMemo, Suspense } from 'react'

import {
    Wrapper,
    H4
} from '../Visualizer.styles'

import Toggle from '../../Toggle'
import Flexbox from '../../Flexbox'
import GridItem from '../../GridItem'
import Slider from '../../Slider'

import ControlsIcon from '../../../images/controls.svg'
import CloseIcon from '../../../images/close.svg'

import { roundValue } from '../../../helpers'

import anime from 'animejs'

const mapObject = (obj, fn) => {
    return Object.keys(obj).map(it => (fn(it)))
}

const VisualizerControls = ({ showControls, controls, setControls, setShowControls }) => {
    const animation = useRef(null)
    const [checked, setChecked] = useState('texture1')
    // useEffect(() => {
    //     animation.current = {
    //         slideIn: anime({
    //             targets: '.visualizerControls',
    //             easing: 'easeOutQuad',
    //             duration: 500,
    //             loop: false,
    //             translateX: -300
    //         }),
    //         slideOut: anime({
    //             targets: '.visualizerControls',
    //             easing: 'easeOutQuad',
    //             duration: 1500,
    //             loop: false,
    //             translateX: 0
    //         })
    //     }
    // }, [])

    // useEffect(() => {

    //     if (showControls)
    //         animation.current.slideIn.play()
    //     else
    //         animation.current.slideOut.play()


    // }, [showControls])

    return (
        <Flexbox
            className="visualizerControls"
            transition="right 0.5s ease-in-out"
            height="100vh"
            boxShadow="0 0 5px 5px rgb(0,0,0)"
            zIndex="21"
            overflow="hidden"
            background="var(--greyBackground)"
            alignItems="start"
            justifyContent="start"
            width="300px"
            fixed
            right={showControls ? '0' : '-330px'}
            column>
            <GridItem

                alignItems="center"
                display="grid"
                row
                relative
                padding="0 20px 0" gridColGap="10px" gridTempCols="min-content min-content 1fr" gridTempRows="min-content" gridAutoFlow="column">
                <Flexbox height="var(--navBarHeight)" width="15px" relative onClick={() => setShowControls(!showControls)}>
                    <img src={ControlsIcon} style={{ position: 'relative', height: '100%', width: '100%' }} />
                </Flexbox>
                <h3>Settings</h3>
                <Flexbox row justifySelf="end" height="15px" width="15px" relative onClick={() => setShowControls(!showControls)}>
                    <img src={CloseIcon} style={{ position: 'relative', height: '100%', width: '100%' }} />
                </Flexbox>
            </GridItem>
            <Flexbox height="100%" overflow="auto" padding="0 20px 20px" background="var(--greyBackground)" alignItems="start" justifyContent="start" width="100%" gap="20px" column relative>
                <GridItem alignItems="center" row justifySelf="end" justifyContent="center" display="grid" gridTempCols="1fr min-content 1fr" gridTempRows="min-content" gridColGap="20px">
                    <Flexbox row relative justifyContent="end">
                        Rainbow
                    </Flexbox>
                    <Toggle hideLabel={true} fontSize="10px" onChange={(e) => {
                        setChecked(checked === 'texture1' ? 'texture2' : 'texture1')
                        setControls(prev => ({
                            ...prev,
                            'Particles': {
                                ...prev['Particles'],
                                sprites: {
                                    ...prev['Particles']['sprites'],
                                    value: prev['Particles']['sprites'].value === 'texture1' ? 'texture2' : 'texture1'
                                }
                            }
                        }))
                    }}
                        options={['Rainbow', 'Warm White']}
                        checkedSliderBgd="radial-gradient(#ffffff,#feffb8,#f6e9a7)"
                        splitPosition={true}
                        uncheckedBgd="#000"
                        checkedBgd="#000"
                        checkedBoxShadow=" 0 0 5px 0px rgba(255, 255, 255,0.75), inset 0 0 2px 2px rgba(0,0,0,0.15)"
                        uncheckedBoxShadow=" 0 0 5px 0px rgba(255, 173, 198,0.95), inset 0 0 2px 2px rgba(0,0,0,0.15)"
                        uncheckedSliderBgd="linear-gradient(45deg,#ffe0ad, #adffb0, #adfeff, #adb8ff, #ffadfe, #ffadc6)" />
                    <Flexbox justifyContent="start" row relative whiteSpace="nowrap">
                        Warm White
                    </Flexbox>
                </GridItem>

                {
                    mapObject(controls,
                        (it) => {
                            return (
                                <React.Fragment key={it + 'x'}>
                                    <H4 key={it}>{it}</H4>
                                    {
                                        mapObject(controls[it], (it2) => {
                                            if (controls[it][it2].range)
                                                return (<Slider
                                                    key={controls[it][it2].name}
                                                    background="#000"
                                                    upperBackground="#000"
                                                    lowerBackground="#000"
                                                    thumbHeight="20px"
                                                    thumbWidth="25px"
                                                    borderRadius="2px"
                                                    name={controls[it][it2].name}
                                                    step={controls[it][it2]['step']}
                                                    value={controls[it][it2].value}
                                                    range={controls[it][it2].range}
                                                    onChange={(_value) => {
                                                        console.log("value", _value, it2)
                                                        setControls(prev => ({
                                                            ...prev,
                                                            [it]: {
                                                                ...prev[it],
                                                                [it2]: {
                                                                    ...prev[it][it2],
                                                                    value:
                                                                        typeof (parseFloat(_value)) === "number" ?
                                                                            roundValue(parseFloat(_value)) :
                                                                            _value
                                                                }
                                                            }
                                                        }))
                                                    }} />)
                                            else return <React.Fragment key={controls[it][it2].name}></React.Fragment>
                                        }

                                        )
                                    }
                                </React.Fragment>
                            )
                        }
                    )

                }
            </Flexbox>
        </Flexbox>)
}

export default VisualizerControls