import styled from "styled-components";

export const OuterWrapper = styled.div`
width: 100%;
display: flex;
height: 100%;
overflow: hidden;
position: relative;
display: grid;
border-top: 1px solid var(--purpleGrey);
grid-template-areas:
'sideBar messages details'
'sideBar inputbar details';
grid-template-columns: minmax(300px, min-content) 1fr 420px;
grid-template-rows: 1fr min-content;
.hiddenOnSmall{
display: flex;
flex-direction: column;
}
.visibleOnSmall{
display: none;
}
@media only screen and (max-width: 1110px){
    grid-template-areas:
'sideBar details'
'sideBar messages'
'sideBar inputbar';
grid-template-columns: minmax(200px, min-content) 1fr ;
grid-template-rows: 200px 2fr min-content;
.hiddenOnSmall{
    display: none;
}
.visibleOnSmall{
display: flex;
}
}
`;

export const ComponentWrapper = styled.div`
position: relative;
height: calc(100vh);
padding: 50px 0 0;

`;


export const MessagesSideBar = styled.div`
    user-select: none;
display: flex;
flex-direction: column;
    /* background: var(--greyBackground); */
position: relative;
user-select: none;
height: 100%;
width: 100%;

`;

export const Title = styled.div`
text-transform: uppercase;
padding: 20px 50px;
letter-spacing: 0.10rem;
font-weight: 600;

`;

export const SongStatusNote = styled.div`
text-transform: uppercase;
margin-top: 0.7rem;
height: 1rem;
color: var(--purpleGrey);
width: 100%;
position: relative;
font-size: 10px;
text-align: ${props=> props.textalign ? props.textalign : 'left'};
`;

export const SongDetailsHeader = styled.div`
text-transform: uppercase;
user-select: none;
display: flex;
padding: 0;
justify-content: space-between;
text-transform: none;
letter-spacing: 0.10rem;
font-weight: 600;
padding-bottom: 10px;
/* border-bottom: 1px solid #ffffff; */

`;

export const SongDetailsHeaderGrid = styled(SongDetailsHeader)`
display: grid;
text-transform: none;
grid-template-areas: ${props => props.gridAreas ? props.gridAreas : ''};
/* border-bottom: 1px solid #ffffff; */

`;

export const LinkHeader = styled.div`
color: var(--yellow);
width: 100%;
font-weight: ${props => props.isCurrent ? '700' : '300'};
text-transform: uppercase;
letter-spacing: 0.13rem;
margin-top: 10px;
padding: 10px 10px 10px 50px;
font-weight: 500;
font-size: 10px;

`;

export const FlexColumn = styled.div`
display: flex;
align-items: center;
flex-direction: column;
`;

export const Link = styled.div``;

export const Line = styled.div`
height: 1px;
background: var(--purpleGrey);
width: 100%;
`;

export const SubLink = styled(LinkHeader)`
text-transform: none;
display: flex;
align-items: center;
background: ${props => props.isCurrent ? 'var(--backgroundActiveLi)' : 'transparent'};
border-right: ${props => props.isCurrent ? 'var(--borderRightActiveLi)' : 'transparent'};
font-size: 12px;
color: #ffffff;
color: ${props => props.isNew ? 'var(--yellow)' : 'var(--primary)'};
font-weight: ${props => props.isNew ? '900' : '400'};
text-transform: none;
    font-size: 12px;
`;

export const MessageStream = styled.div`
display: flex;
padding-bottom: 1px;
width: 100%;
height: 100%;
position: relative;
display: grid;
`;

export const GridItem = styled.div`
height: 100%;
background: ${props=> props.background ? props.background : 'var(--greyBackground)'};
background: #000000;
border-right: ${props=> props.borderRight ? props.borderRight : ''};
display: flex;
position: ${props=> props.position ? props.position : '' };
overflow: ${props=> props.overflow ? props.overflow : 'visible'};
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
padding: ${props=> props.padding ? props.padding : 'undefined'};
grid-area: ${props => props.gridArea ? props.gridArea : ''};
`;

export const DetailsWrapper = styled.div`
/* background: var(--greyBackground); */
width: 100%;
padding: 20px;
margin-bottom: 10px;
box-shadow: ${props => props.boxShadow ? props.boxShadow : 'undefined'};
overflow: ${props=> props.overflow ? props.overflow : ''};
position: relative;
`;

export const LoadingWrapper = styled.div`
height: 100%;
display: flex;
flex-direction: column;
width: 100%;
justify-content: center;
align-items: center;
`;

export const NavArrow = styled.div`
height: 15px;
width: 15px;
opacity: ${props => props.isValid ? '1' : '0.25'};
transform: ${props => props.back ? 'rotate(180deg)' : ''};
display: flex;
align-items: center;
justify-content: center;
img{
    height: 90%;
    width: 90%;
}
`;

export const ScrollWrapper = styled.div`
overflow: auto;
height: 100%;
position: relative;
display: flex;
flex-direction: column;
`;

export const InnerScrollWrapper = styled.div`
scroll-behavior: smooth;
padding-bottom: 10px;
height: min-content;
position: relative;
`;

export const NewDot = styled.div`
height: 5px;
position: absolute;
left: 40px;
width: 5px;
background: var(--yellow);
border-radius: 50%;
`;

export const PricingTable = styled.div`
font-size: 12px;
width: 100%;
display: grid;

grid-column-gap: .75rem;
grid-template-columns: 1fr 3.5rem 63px;
grid-auto-rows: min-content;
border: 1px solid #2f2f2f;

>div{
    white-space: nowrap;
    text-align: right;
    height: 30px;
    display: flex;
    align-items: center;
    color: var(--purpleGrey);
    /* padding: 5px; */
    /* padding: 1px 0; */
}

>div:nth-of-type(2n + 1){
    /* padding: 5px;// 5px 0 5px; */

    /* letter-spacing: 0.03rem; */
    font-size: 12px;
    padding-left: 20px;
    grid-column: 1 / span 1;
    
    font-weight: 600;
    justify-content: flex-end;
}
>div:nth-of-type(2n){
    font-weight: 500;
    /* padding-right: 20px; */
    grid-column: 2 / span 1;
    /* letter-spacing: .1rem; */
    /* padding: 5px 25px 5px; */
    justify-content: center;
    
    
}
/* &:last-child{
    border-bottom: 0;
} */
/* >div:nth-of-type(4), >div:nth-of-type(3){
    border-bottom: 2px solid var(--greyBackground);
} */
`;