import styled from 'styled-components'

export const Action = styled.div`
width: ${props => props.size ? props.size : '20px'};
height: ${props => props.size ? props.size : '20px'};
padding: 3px;
position: relative;
display: flex;
align-items: center;
justify-content: center;
opacity: ${props => props.opacity ? props.opacity : 1};
svg{
    height: 90%;
    width: 90%;
}
`;

export const AlertIcon = styled(Action)`
position: ${props => props.position ? props.position : 'relative'};
padding: 3px;
right: ${props => props.right ? props.right : ''};
`;