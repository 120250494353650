import styled, {css} from 'styled-components'
import { NavLink as Link } from 'react-router-dom'
import {FaBars} from 'react-icons/fa'


export const Nav = styled.nav`
  position: absolute;
  font-weight: 400;
  font-size: 0.85em;
  display: grid;
  letter-spacing: .02rem;
  grid-template-columns: 3.22vw 1fr 1fr 1fr 3.22vw;
  grid-template-areas: "spacer home links login spacer2";
  grid-template-rows: 1fr;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: var(--navBarHeight);
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: var(--white);
  z-index: 3;
  box-shadow: 0 0px 5px rgb(0,0,0);
  
  

  button{
    grid-area: login;
    justify-self: end;
  }
  
`
export const HomeContainer = styled.div`
grid-area: home;
`;

export const NavLinkHome = styled(Link)`
all: unset;
width: min-content;
    color: #fff;
    font-family: Cano;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 1.1em;
    letter-spacing: 0.05rem;
    height: 100%;
    cursor: pointer;
    grid-area: home;
    
    color: #fff;

`


export const NavLink = styled(Link)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
    height: 100%;
    cursor: pointer;
    text-transform: uppercase;

    &.active{
        font-weight: 700;
        letter-spacing: 0.02rem;
    }
    @media screen and (max-width: 600px){
        height: min-content;
        width: min-content;
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            height: min-content;
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            height: min-content;
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            height: min-content;
    }

`

export const MobileBurger = styled.div`
    display: none;
    grid-area: login;
    img{
    height: 100%;
    width: 23px;
    position: relative;}
    @media screen and (max-width: 600px){
        display: block;
        position: relative;
        height: 30px;
        width: 30px;
        cursor: pointer;
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            display: block;
            height: 30px;
        width: 30px;
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            display: block;
            height: 30px;
        width: 30px;
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            display: block;
            height: 30px;
        width: 30px;
    }
`

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    column-gap: 20px;
    letter-spacing: .02rem;
    grid-area: links;
    @media screen and (max-width: 600px){
        display: none;
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            display: none;
    }
`
export const Hamburger = styled.div`
grid-area: login;
display: flex;
flex-direction: column;
align-items: flex-end;
`;

export const NavBtn = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    grid-area: login;
    img{
    height: 100%;
    width: 23px;
    position: relative;
}


    `;

export const NavBtnLink = styled(Link)`

all: unset;
color: #000000;
font-family: Raleway;
width: min-content;
height:25px;
right: 0;
display: flex;
align-items: center;
position: relative;
background: transparent;
border: none;
cursor: pointer;
 img{
     height: 100%;
     width: 23px;
     position: relative;
 }

    /* &:hover{
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    } */

    span{
        color: #000000 !important;
white-space: nowrap;
height: 100%;
position: relative;
width: fit-content;
display: flex;
justify-content: center;
align-items: center;
padding: 0 4px;
font-size: inherit;
text-transform: uppercase;
font-weight: 400;
letter-spacing: .02rem;
background: var(--white);
margin-left: 2px;
border-radius: 2px;
font-weight: 400; 
    }
    @media screen and (max-width: 600px){
        display: none;
        
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            display: none;
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            display: none;
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            display: none;
    }


`;

export const MobileMenuWrapper = styled.div`
width: 100%;
position: fixed;
flex-direction: column;
justify-content: end;
align-items: end;
height: 100vh;
background: rgba(0,0,0,0.90);
display: flex;
row-gap: 20px;
padding: 100px 20px;
top: 0;
right: 0;
transform: ${props => props.isOpen? 'translateX(0)' : 'translateX(100%)'};
transition: all 250ms ease-out;
a{
    font-size: 2em;
}
`;