import styled from 'styled-components'

export const Wrapper = styled.div`
`;

export const DropDownContainerBox = styled.div`
position: absolute;
z-index: 10;
width:140px;
top: 49px;
align-items: center;
`;

export const BackgroundWrapper = styled.div`
position: fixed;
top: 0;
right: 0;
width: 100%;
height: 100%;
overflow: hidden;
`;