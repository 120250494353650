import React, { useEffect, useRef, useState } from 'react'

import { SearchIcon, AlertIconWrapper, Wrapper, InputStyled, LabelStyled, InnerWrapper, DollarSign } from './InputField.styles'

import AlertIcon from '../../images/alertYellow.svg'
import searchIcon from '../../images/searchIcon.svg'
import closeIcon from '../../images/close.svg'

import AnimatedCheckIcon from '../AnimatedCheckIcon'
import AnimatedInviteIcon from '../AnimatedInviteIcon'
import ToolTip from '../ToolTip'

const Label = ({ labelWeight, name, marginBottom, label, labelFontSize, labelTextTransform }) => (


    <LabelStyled
        htmlFor={name}
        labelWeight={labelWeight}
        labelFontSize={labelFontSize}
        labelTextTransform={labelTextTransform}
        marginBottom={marginBottom}
    >{label}</LabelStyled>
)

const FieldAlert = ({ alertType, id, onClick_alert }) => {
    switch (alertType) {
        case 'BUYER':
            return <ToolTip background="#000000" bottom="10px" border="2px solid var(--yellow)" absolute toolTipText={<><p style={{ fontWeight: '700', margin: '0 0 5px', color: 'var(--yellow)' }}>Buyer Account</p>This email is in use for a buyer account. Please provide an alternative email address for this user.</>}><AlertIconWrapper><img src={AlertIcon} alt="alert-icon" style={{ width: '15px', height: '15px' }} /></AlertIconWrapper></ToolTip>
        case 'EXISTS':
            return <ToolTip background="#000000" bottom="10px" border="2px solid var(--accountExistsBlue)" absolute toolTipText={<><p style={{ fontWeight: '700', margin: '0 0 5px', color: 'var(--accountExistsBlue)' }}>Registered User</p>This is a registered Azaa user. They'll get an email asking them to login and respond to the terms you set.</>}><AlertIconWrapper><AnimatedCheckIcon className={id}></AnimatedCheckIcon></AlertIconWrapper></ToolTip>
        case 'ADD_NEW':
            return <ToolTip background="#000000" bottom="10px" border="2px solid var(--green)" absolute toolTipText={<><p style={{ fontWeight: '700', margin: '0 0 5px', color: 'var(--green)' }}>New User</p>We'll send this user an email to register and let them know that they've been listed as a contributor on this song. They can respond to the terms you set once they sign up.</>}><AlertIconWrapper><AnimatedInviteIcon className={id} /></AlertIconWrapper></ToolTip>
        default:
            return <></>;
    }
}


const InputField = ({ onKeyDown, onKeyUp, activeSearch, clearAll, clearListOnSearch, onSearch, isSearch = false, height, borderRadius, onClick_alert, id, transition, alertType, focusOnMount, labelWeight, alertThisField, padding, colorDisabled, backgroundDisabled, outlineFocus, boxShadowFocus, borderFocus, borderBottomFocus, borderRightFocus, borderLeftFocus, borderTopFocus, marginTop, textAlign, width, uppercaseOff,
    boxShadow, background, border, borderTop, borderBottom, borderRight, borderLeft, disabled, labelFontSize, labelTextTransform, type, image, onChange, placeholder, value, label, name, marginBottom, onKeyPress, priceOnBlur }) => {
    const initial = useRef(true);
    const inputRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [initialLength, setInitialLength] = useState(0);
    const handleCursor = (event) => {

        event.preventDefault();

        setCursorPosition(inputRef.current.selectionStart)
        setInitialLength(inputRef.current.value.length)
        onChange(event);
    }
    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return; // if initial render, skip useEffect
        }
        if (name === 'price') {
            let length = inputRef.current.value.length
            let caretPos = length - initialLength + cursorPosition;
            console.log(length, initialLength, cursorPosition);
            inputRef.current.setSelectionRange(caretPos, caretPos);



        }
    }, [value])
    useEffect(() => {
        if (focusOnMount && inputRef.current !== null)
            inputRef.current.focus()
    }, [name])

    if (label !== undefined) {

        return (
            <Wrapper width={width} marginTop={marginTop}>
                <Label htmlFor={name}
                    labelWeight={labelWeight}
                    marginBottom={marginBottom}
                    label={label}
                    labelFontSize={labelFontSize}
                    labelTextTransform={labelTextTransform}
                ></Label>
                <InnerWrapper >
                    {name === 'price' && <DollarSign>$</DollarSign>}
                    <InputStyled
                        isSearch={isSearch}
                        type={type}
                        height={height}
                        borderRadius={borderRadius}
                        transition={transition}
                        uppercaseOff={uppercaseOff}
                        padding={padding}
                        textAlign={textAlign}
                        background={background}
                        border={border}
                        borderBottom={borderBottom}
                        borderTop={borderTop}
                        borderLeft={borderLeft}
                        backgroundDisabled={backgroundDisabled}
                        colorDisabled={colorDisabled}
                        borderRight={borderRight}
                        boxShadow={boxShadow}
                        placeholder={placeholder}
                        disabled={disabled ? true : false}
                        value={value}
                        onChange={handleCursor}
                        name={name}
                        onKeyPress={onKeyPress}
                        onKeyUp={onKeyUp}
                        onKeyDown={onKeyDown}
                        ref={inputRef}
                        onBlur={priceOnBlur !== undefined ? priceOnBlur : undefined}
                    />
                    {
                    (isSearch && clearListOnSearch && activeSearch) && <SearchIcon src={closeIcon} opacity="1.0" alt="clear-search" onClick={(e) => {
                        if (typeof (clearAll) === 'function')
                            clearAll()
                    }} />
                }
                {(isSearch && !clearListOnSearch || (clearListOnSearch && !activeSearch)) &&
                    <>
                        <SearchIcon src={searchIcon} alt="search" onClick={(e) => {
                            if (typeof (onSearch) === 'function')
                                onSearch(e)
                        }} />
                    </>
                }
                    {alertThisField && <FieldAlert alertType={alertType} id={id} onClick_alert={onClick_alert} />}
                </InnerWrapper>
            </Wrapper >

        )
    }
    else {
        return (
            <InnerWrapper width={width} marginTop={marginTop}>
                {name === 'price' && <DollarSign>$</DollarSign>}
                <InputStyled
                    isSearch={isSearch}
                    type={type}
                    height={height}
                    borderRadius={borderRadius}
                    transition={transition}
                    padding={padding}
                    background={background}
                    backgroundDisabled={backgroundDisabled}
                    colorDisabled={colorDisabled}
                    outlineFocus={outlineFocus}
                    borderFocus={borderFocus}
                    borderBottomFocus={borderBottomFocus}
                    borderRightFocus={borderRightFocus}
                    borderTopFocus={borderTopFocus}
                    borderLeftFocus={borderLeftFocus}
                    boxShadowFocus={boxShadowFocus}
                    border={border}
                    borderBottom={borderBottom}
                    borderTop={borderTop}
                    borderLeft={borderLeft}
                    borderRight={borderRight}
                    uppercaseOff={uppercaseOff}
                    boxShadow={boxShadow}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled ? true : false}
                    textAlign={textAlign}
                    onChange={handleCursor}
                    name={name}
                    onKeyPress={onKeyPress}
                        onKeyUp={onKeyUp}
                        onKeyDown={onKeyDown}
                    ref={inputRef}
                    onBlur={priceOnBlur !== undefined ? priceOnBlur : undefined}
                />
                {
                    (isSearch && clearListOnSearch && activeSearch) && <SearchIcon src={closeIcon} opacity="1.0" alt="clear-search" onClick={(e) => {
                        if (typeof (clearAll) === 'function')
                            clearAll()
                    }} />
                }
                {(isSearch && !clearListOnSearch || (clearListOnSearch && !activeSearch)) &&
                    <>
                        <SearchIcon src={searchIcon} alt="search" onClick={(e) => {
                            if (typeof (onSearch) === 'function')
                                onSearch(e)
                        }} />
                    </>
                }
                {alertThisField && <FieldAlert alertType={alertType} id={id} onClick_alert={onClick_alert} />}
            </InnerWrapper>
        )
    }



}

export default InputField
