import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import API from '../../API'
import {
    VALID_FILE_TYPES,
    MAX_SIZE,
    MAX_UPLOADS
} from '../../config'

// Styles
import { FormStyled, Wrapper } from './Form.styles'

import { useParams, useLocation } from 'react-router-dom'


// Components
import InputField from '../InputField'
import UserDetails from '../UploadFormSteps/UserDetails'
import FormHeader from '../UploadFormSteps/FormHeader'
import SongTileContainer from '../UploadFormSteps/SongTileContainer'
import SongDetails from '../UploadFormSteps/SongDetails'
import UploadSongs from '../UploadFormSteps/UploadSongs'
import SuccessPage from '../UploadFormSteps/SuccessPage';
import UploadProgressMeter from '../UploadFormSteps/UploadProgressMeter';


// Hook
import { useFormHooks } from '../../hooks/useFormHooks'

import { useAuth } from '../../contexts/AuthContext'



const Form = ({ prelimUserDetails }) => {
    const [loading, setLoading] = useState(false)
    const fileSizeLimit = Math.pow(10, 6) * 50; //50MB
    const [error, setError] = useState(false)
    const [vettedEmail, setVettedEmail] = useState('')
    const [badEmails, setBadEmails] = useState([])
    const [showDropboxField, setShowDropboxField] = useState(false)
    const { currentUser } = useAuth()
    const [membershipTier, setMembershipTier] = useState({ id: 0, tier: '', file_limit: 1 })
    let location = useLocation();

    const initName = location.state !== undefined && location.state !== null && location.state.name !== undefined ? location.state.name
        : currentUser !== null && currentUser.userDetails !== undefined ? currentUser.userDetails.first_name : '';
    const initEmail = location.state !== undefined && location.state !== null && location.state.email !== undefined ? location.state.email
        : currentUser !== null ? currentUser.email : '';

    if (location.state !== undefined) {
        console.log("prelimUserDetails" + location.state)
    }

    const fileLimit = (tier_id) => {
        switch (tier_id) {
            case 1:
                return 0;
            case 2:
                return 100; // this is really inf
            case 3:
                return 100; // this is really inf
            default:
                return 0;
        }
    }


    const userIsRegistered = currentUser !== null && currentUser.userDetails !== undefined;
    const startingStep = userIsRegistered ? 1 : 0;

    const initUserDetails = {
        name: initName,
        email: initEmail,
        spotify: '',
        instagram: '',
        publisher: '',
    }

    const initialState = {
        step: startingStep,
        userAgreement: false,
        songList: [],
        dropbox: ''
    }

    const [userDetails, setUserDetails] = useState(initUserDetails);
    const [userDetailsStep, setUserDetailsStep] = useState(initName !== '' && initEmail !== '' ? 2 : 0);
    const [songsSubmitted, setSongsSubmitted] = useState(false);
    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (currentUser !== null && currentUser.userDetails !== undefined) {
            const tier_id = parseInt(currentUser.userDetails.membership);
            console.log("tier_id", currentUser.userDetails.membership, fileLimit(tier_id))
            setMembershipTier({
                id: tier_id,
                tier: currentUser.userDetails.tier,
                file_limit: fileLimit(tier_id)
            })
            setState(prev => ({ ...prev, step: 1 }))
        }
    }, [currentUser])
    const nextStep = () => {
        const { step } = state;
        setState(prev => ({ ...prev, step: prev.step + 1 }))
    }

    const prevStep = () => {
        const { step } = state;
        setState(prev => ({ ...prev, step: prev.step - 1 }))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        alert('An email was submitted: ' + state);

    }

    const handleUserAgreement = () => {

        setState(prev => ({ ...prev, userAgreement: !(prev.userAgreement) }))

    }

    const handleKeyPress = (event) => {
        // console.log("EVENT: " + event.key)
    }



    const handleSongRemoval = (event) => {
        event.preventDefault();

        if (event.target.parentNode.attributes.value.value !== undefined
        ) {
            const songIndex = values.songList.findIndex(x => x.fileName === event.target.parentNode.attributes.value.value);
            setState(prev => ({
                ...prev,
                songList: prev.songList.filter((song) => { return song.fileName != event.target.parentNode.attributes.value.value })
            }))
            if (state.songList.length < 2 && state.step != 1) {
                prevStep()
            } else if (songIndex < index) {
                setIndex(index - 1);
            }
        }

    }

    const handleUserDetails = (userDetailsStep) => {
        return (
            <Wrapper>
                < FormHeader
                    step={step}
                    userDetailsStep={userDetailsStep}
                    totalLength={Object.keys(userDetails).length + 4} //is this still right?
                />
                <FormStyled>
                    <UserDetails
                        setVettedEmail={setVettedEmail}
                        vettedEmail={vettedEmail}
                        setBadEmails={setBadEmails}
                        badEmails={badEmails}
                        setError={setError}
                        setLoading={setLoading}
                        loading={loading}
                        error={error}
                        handleKeyPress={handleKeyPress}
                        handleChange={handleUserDetailsInput}
                        fieldName={Object.keys(userDetails)[userDetailsStep]}
                        currStep={userDetailsStep}
                        nextStep={incrementUserDetailsStep}
                        prevStep={backUserDetailsStep}
                        value={userDetails[Object.keys(userDetails)[userDetailsStep]]}
                    />
                </FormStyled>
            </Wrapper>
        )
    }

    const handleUserDetailsInput = (input) => (event) => {
        setUserDetails(prev => ({
            ...prev,
            [input]: event.target.value
        }))
    }

    const handleDropbox = (event) => {
        setState(prev => ({
            ...prev,
            dropbox: event.target.value
        }))
    }



    const handleSongDetailsInput = (input) => (event) => {
        if (input === 'songTitle') {
            setState(prev => ({
                ...prev,
                songList: prev.songList.map((el, idx) => idx == index ? { ...el, songTitle: event.target.value } : el)
            }))
        }
        else if (input === 'songListBPM') {
            setState(prev => ({
                ...prev,
                songList: prev.songList.map((el, idx) => idx == index ? { ...el, BPM: event.target.attributes.value.value } : el)
            }))
        }
        else if (input === 'songListGENRE') {
            if (state.songList[index].genre.includes(event.target.attributes.value.value)) {
                setState(prev => ({
                    ...prev,
                    songList: prev.songList.map((el, idex) => idex == index ? {
                        ...el,
                        genre: prev.songList[index].genre.filter((n) => { return n != event.target.attributes.value.value })
                    } : el)
                }))
            }
            else if (!state.songList[index].genre.includes(event.target.attributes.value.value) && state.songList[index].genre.length < 2) {
                setState(prev => ({
                    ...prev,
                    songList: prev.songList.map((el, idx) => idx == index ? {
                        ...el,
                        genre: [...prev.songList[index].genre, event.target.attributes.value.value]
                    } : el)
                }))
            }
        }
    }
    const handleSongUpload = (files) => {
        console.log("files", files, membershipTier.file_limit - state.songList.length)
        const newSongList = [];
        if (state.songList.length < membershipTier.file_limit) {
            const max = membershipTier.file_limit - state.songList.length;

            Array.from(files).forEach((file, idx) => {
                if (!state.songList.map(song => song['fileName']).includes(file.name)
                    && VALID_FILE_TYPES.includes(file.type)
                    && idx < max
                    // && fileArrSize < fileSizeLimit
                    //&& state.songList.reduce((a, b)=> a + b.size, 0) + fileArrSize < fileSizeLimit
                ) {

                    const songDetails = {
                        file: file,
                        size: file.size,
                        fileName: file.name,
                        songTitle: '',
                        BPM: '--',
                        genre: [],
                    }
                    setState(prev => ({ ...prev, songList: [...prev.songList, songDetails] }))
                }
            })
        } else if (membershipTier.file_limit > 0) {
            if (!state.songList.map(song => song['fileName']).includes(files[0].name)
                && VALID_FILE_TYPES.includes(files[0].type)
            ) {

                const songDetails = {
                    file: files[0],
                    size: files[0].size,
                    fileName: files[0].name,
                    songTitle: '',
                    BPM: '--',
                    genre: [],
                }
                setState(prev => ({ ...prev, songList: [...prev.songList.slice(0,prev.songList.length - 1), songDetails] }))
            }

        }
    }

    
    const { step } = state;
    const [index, setIndex] = useState(0);

    const [scrollTo, setScrollTo] = useState(0);

    const { firstName, lastName, email, songList, userAgreement, dropbox } = state;
    const { name } = userDetails;

    const userdetails_value = { name }

    const values = { firstName, lastName, email, songList, userAgreement, dropbox }
    console.log(songList)

    const incrementUserDetailsStep = (i) => {
        if (userDetailsStep < (Object.keys(userDetails).length - 1)) {
            setUserDetailsStep(userDetailsStep + 1);
        }
        else {
            setState(prev => ({ ...prev, step: prev.step + 1 }))
        }
    }

    const backUserDetailsStep = (i) => {
        if (userDetailsStep > 0) {
            setUserDetailsStep(userDetailsStep - 1);
        }
    }



    const incrementI = (i) => {
        setIndex(index + 1)
        setScrollTo(50);
    }

    const decI = () => {
        if (index > 0) {
            var number = index - 1;
            setIndex(index - 1)
            setScrollTo(scrollTo - 50 * (index + 1));
        }
        else {
            prevStep()
        }
    }


    const handleSongDetails = () => {
        return (
            <Wrapper>
                < FormHeader
                    step={step}
                    userDetailsStep={userDetailsStep}
                    totalLength={Object.keys(userDetails).length + 4}
                />
                <FormStyled>
                    <SongDetails
                        songIndex={index}
                        nextStep={incrementI}
                        prevStep={decI}
                        handleChange={handleSongDetailsInput}
                        values={values}
                        handleStateChange={setState}
                        handleKeyPress={handleKeyPress}
                    ></SongDetails>
                    <SongTileContainer
                        scrollTo={scrollTo}
                        index={index}
                        step={step}
                        removeItem={handleSongRemoval}
                        values={values.songList} />
                </FormStyled>
            </Wrapper>
        )
    }

    const submitSong = async () => {
        setLoading(true)
        if (!songsSubmitted) {
            setSongsSubmitted(false)
            try {
                await API.submitSongs(state, currentUser === null ? userDetails : null, currentUser !== null ? currentUser : null)
                setLoading(false)
                setError(false)
                setSongsSubmitted(true)
                setState(prev => ({ ...initialState, step: 3 }))
            } catch (error) {
                setError(true)
                setLoading(false)
            }
        }
    }
    const resetForm = () => setState(initialState)


    switch (step) {
        case 0:
            while (userDetailsStep < Object.keys(userDetails).length) {
                return handleUserDetails(userDetailsStep)
            }
        case 1:
            return (
                <Wrapper>
                    < FormHeader
                        step={step}
                        userDetailsStep={userDetailsStep}
                        totalLength={Object.keys(userDetails).length + 4}
                    />
                    <FormStyled>
                        <UploadSongs
                            membershipTier={membershipTier}
                            handleDropbox={handleDropbox}
                            showDropboxField={showDropboxField}
                            handleSongUpload={handleSongUpload}
                            values={values}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            startingStep={startingStep}
                            handleUserAgreement={handleUserAgreement}
                            userAgreement={state.userAgreement}
                        />
                        <SongTileContainer
                            removeItem={handleSongRemoval}
                            values={values.songList}
                            index={index}
                            step={step}
                        />
                    </FormStyled>
                </Wrapper>
            )
        case 2:
            while (index < songList.length) {
                return handleSongDetails()
            }
        case 3:
            return <SuccessPage numSongsSubmitted={state.songList.length} resetForm={resetForm} onSubmit={submitSong} values={{ state, userDetails, loading, error, songsSubmitted }} />
        case 4:
            return <h1>Success</h1>
        default:
            return <h1>Error</h1>
    }

}

export default Form
