import styled from 'styled-components'


export const SideBar = styled.div`
/* font-size: 55px;
font-weight: 900; */
height: 100%;
background: var(--greyBackground);
flex-grow: 1;
padding-left: 3.2vw;
padding-top: 50px;
padding: var(--navBarHeight) 3.2vw 0 3.2vw;
display: flex;
flex-direction: column;
justify-content: flex-start;
@media screen and (max-width: 800px){
        width: 100%;
    }
`;

export const TextWrapper = styled.p`
max-width: 500px;
margin-bottom: 20px;
`;

export const Header = styled.h1`
/* margin: 0;
text-decoration: underline;
height: min-content;
line-height: 2.2rem;
letter-spacing: 0.07rem;
font-size: 55px;
font-weight: 900;
margin-bottom: 25px; */
user-select: none;
`;

export const OuterWrapper = styled.div`
position: relative;
height: 100vh;
display: flex;
`;

export const Wrapper = styled.div`
height: ${props => props.isLoading ? '100vh' : '100%'};
/* padding: 50px 0 0; */
width: 100%;
justify-content: center;
align-items: center;
display: flex;
position: relative;
@media screen and (max-width: 800px){
        flex-direction: column;
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
          flex-direction: column;
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
          flex-direction: column;
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
          flex-direction: column;
    }
`;

export const GridWrapper = styled.div`
height: ${props => props.isLoading ? '100vh' : '100%'};
justify-content: center;
align-items: ${props => props.isLoading ? 'center' : 'start'};
display: grid;
grid-column-gap: 50px;
grid-template-columns: minmax(30%,300px) minmax(30%,300px);
grid-template-rows: repeat(1fr);

`;

export const RowWrapper = styled.div`
display: flex;
justify-content: center;
width: 70%;
/* mask-image: linear-gradient(0deg, transparent 0, red 20%, red 90%, transparent 100%); */
height: calc(100% - var(--navBarHeight));
overflow: auto;
padding: 2em;
margin-top: calc(var(--navBarHeight));
@media screen and (max-width: 800px){
  width: 100%;
  /* height: 50%; */
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 0;
          /* height: 50%; */
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 0;
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 0;
    }
`;

export const ColumnWrapper = styled.div`
display: flex;
position: relative;
min-height: 800px;
justify-content: flex-start;
height: calc(100vh - 100px);
/* max-width: 850px; */
min-height: 800px;
flex-flow: row wrap;
/* height: min-content; */
height: 100%;
column-gap: 20px;
width: 100%;
flex-flow: column wrap;
@media screen and (max-width: 1430px) {
    flex-flow: row wrap;
    
  }
  @media screen and (max-width: 600px){
    column-gap: 0;
    justify-content: flex-start;
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
          column-gap: 0;
          justify-content: flex-start;
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
          column-gap: 0;
          justify-content: flex-start;
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
          column-gap: 0;
          justify-content: flex-start;

    }
`;

export const ColumnWrapperOLD = styled.div`
display: flex;
position: relative;
min-height: 800px;
justify-content: space-between;
height: calc(100vh - 100px);

/* flex-direction: column;
flex-wrap: wrap; */
/* writing-mode: horizontal-tb; */
flex-flow: row wrap;
/* @media (max-width: 1300px)
{
    height: 100%;
} */
column-gap: 20px;
`;