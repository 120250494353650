import React from 'react'

import { ToolTipItem } from './ToolTip.styles'

const ToolTip = ({ toolTipPosition, justifyContent, toolTipText, absolute, relative, fixed, border, background, right, bottom, children }) => {
    const getPosition = () => {
        if (absolute)
            return 'absolute'
        if (relative)
            return 'relative'
        if (fixed)
            return 'fixed'
        return 'absolute'
    }
    return (
        <ToolTipItem
            position={getPosition()}
            justifyContent={justifyContent}
            toolTipPosition={toolTipPosition ? toolTipPosition : getPosition()}
            right={right}
            bottom={bottom}
            border={border}
            background={background}>
            {children}
            <div className="tooltip" title="t">{toolTipText}</div>
        </ToolTipItem>
    )
}

export default ToolTip