import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Dropdown from '../../Dropdown'
import SongDetailsTable from '../../SongDetailsTable'

import DotMenuIcon from '../../../images/verticalDotMenu.svg'

import {
    DropDownContainerBox,
    DotMenuWrapper
} from './DotMenu.styles'


const DotMenu = ({ className, bottom, _getOptions, _options = undefined, right, isSplitsPage = false, setShowModal, setSelectedSong, status, playlistOpen, hoverActive, song, fill }) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const navigate = useNavigate()
    const handleChange = () => {
        console.log("change")
    }
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (!hoverActive)
            setMenuOpen(false)
    }, [hoverActive])

    const getOptions = (status) => {
        const baseOptions = [{ value: 3, innerText: 'View Details', onClick: () => { setShowModal(true); } }]
        switch (parseInt(status)) {
            case 1:
                return []
            case 2:
                if (song.isPrimary)
                    return [{
                        value: 2,
                        innerText: 'Set Splits',
                        onClick: () => { navigate('/recently-approved') }
                    }]
                else
                    return [{
                        value: 2,
                        innerText: 'View Details',
                        onClick: () => { setShowModal(true); }
                    }]
            case 3:
                if (song.actionRequired === 2) {
                    return [
                        { value: 3, innerText: 'View Details', onClick: () => { setShowModal(true); } },
                        { value: 31, innerText: song.action, onClick: () => { navigate('/recently-approved') } }
                    ]
                }
                else {
                    return [
                        { value: 3, innerText: 'View Details', onClick: () => { setShowModal(true); } }
                    ]
                }
            case 4:
                return [{ value: 4, innerText: 'View Details', onClick: () => { setShowModal(true); } }]
            case 5:
                if (song.actionRequired !== -1) {
                    return [
                        { value: 3, innerText: 'View Details', onClick: () => { setShowModal(true); } },
                        { value: 31, innerText: song.action, onClick: () => { navigate('/recently-approved') } }
                    ]
                }
                else {
                    return [
                        { value: 3, innerText: 'View Details', onClick: () => { setShowModal(true); } }
                    ]
                }
            case 6:
                return [...baseOptions]
            case 7:
                return [...baseOptions]
            default:
                return []
        }
    }

    useEffect(() => {
        // if (typeof (_getOptions) === 'function') {
        //     const r = _getOptions(status)
        //     if (r)
        //         setOptions(r)
        //     else
        //         setOptions(getOptions(status))
        // } else
        if (_options === undefined)
            setOptions(getOptions(status))
    }, [status, song])

    return (
        <>
            <DropDownContainerBox right={right} bottom={bottom}>
                <Dropdown
                    className={className}
                    name='header'
                    width="200px"
                    textAlign="right"
                    headerWidth="100px"
                    dropdownWidth="200px"
                    marginBottom={'0'}
                    listBackground="var(--dropdownBackground)"
                    color="#ffffff"
                    boxShadow="0 0 10px rgb(0,0,0)"
                    colorOnSelect="var(--goldSolid)"
                    dropdownListMarginTop="20px"
                    justifyContent="flex-end"
                    background="var(--dropdownBackground)"
                    options={_options ? _options : options}
                    valueX=""
                    setMenuState={setMenuOpen}
                    menuState={menuOpen}
                    handleStateChange={handleChange}
                    index={0}
                    id='filter'
                    setValidContinue={true}
                ></Dropdown>
            </DropDownContainerBox>
            <DotMenuWrapper

                onClick={() => { if (playlistOpen) { setMenuOpen(!menuOpen); setSelectedSong(song); } }}
                onMouseOver={() => { if (playlistOpen) { setMenuOpen(true); setSelectedSong(song); } }}>
                {/* <img src={DotMenuIcon} alt="menu" onClick={() => { setMenuOpen(!menuOpen); setSelectedSong(song); }} /> */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 13">
                    <g style={{ fill: fill }}>
                        <circle cx="1.5" cy="1.5" r="1.5" />
                        <circle cx="1.5" cy="6.5" r="1.5" />
                        <circle cx="1.5" cy="11.5" r="1.5" />
                    </g>
                </svg>
            </DotMenuWrapper>
        </>
    )
}

export default DotMenu
