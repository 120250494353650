import React, { useState, useEffect, useRef } from 'react';
import { _requiredAction, showTrailing } from '../helpers'
import { AZAA_PLATFORM_FEE_PERCENT } from '../config'
import API from '../API'
import { useAuth } from '../contexts/AuthContext'

import * as THREE from 'three'

export const useDash = () => {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true)
    const [softLoading, setSoftLoading] = useState(false)
    const initialState = {
        song_title: '',
        tableData: [],
        selectedSong: {},
        actionRequired: 1,
        nbHits: 0
    }
    const resultsPerPage = 5//20
    const history = useRef([])
    const page = useRef(1)
    const [sort, setSort] = useState({})
    const [songs, setSongs] = useState(initialState)
    const [newApproval, setNewApproval] = useState(false)
    const stepsBack = useRef(0)
    const isInitialLoad = useRef(true)
    const [skipBackValid, setSkipBackValid] = useState(false)
    const newSongs = useRef();
    const { currentUser } = useAuth();
    const audioRef = useRef(null)
    const currentSong = useRef(null)
    const [playing, setPlaying] = useState(false)
    const [selectedSong, setSelectedSong] = useState(null)
    const [nowPlaying, setNowPlaying] = useState(null)
    //const nowPlaying = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(null)
    const [file, setFile] = useState(null)
    const [showModal, setShowModal] = useState(false)

    const listener = useRef(new THREE.AudioListener());
    const loader = useRef(new THREE.AudioLoader())

    const initialFilterState = {
        genre: [],
        playlist: 'Top 100',
        tempo: ''
    }
    const [filter, setFilter] = useState(initialFilterState)

    const pullSongs = async (_page = page.current, _filter = filter, _sort = sort, skipLoading = false, retainValues = false) => {
        if (!skipLoading) {
            setLoading(true)
        } else {
            setSoftLoading(true)
        }
        setError("")
        try {
            const [_songs, nbHits, resultsRevised] = await API.fetchSongs2(currentUser.accessToken, currentUser.email, _page, resultsPerPage, _filter)

            if (Object.keys(_songs).length > 0) {
                if (!retainValues) {
                    const formattedData = []
                    resultsRevised.forEach((song, index) => {
                        const d = (({
                            song_title,
                            id,
                            created_at,
                            price,
                            my_split_percent,
                            my_split_value,
                            status,
                            isPrimary,
                            genre,
                            contributors,
                            status_text,
                            action,
                            file_path,
                            is_new,
                            actionRequired,
                            index,
                            saleDetails,
                            buyerEmail,
                            buyerName,
                            buyerLabel,
                            stemTransfers,
                            priceWithFee,
                            dateSold
                        }) =>
                        ({
                            song_title: song_title,
                            song_id: id,
                            created_at,
                            price,
                            my_split_percent,
                            my_split_value,
                            status,
                            isPrimary,
                            genres: JSON.parse(genre),
                            contributors,
                            status_text: parseInt(status) === 2 ? 'Needs Price & Splits' : status_text,
                            action,
                            file_path,
                            is_new: parseInt(is_new),
                            actionRequired,
                            index,
                            showTrailing: showTrailing(price ? price : undefined, contributors ? contributors : undefined),
                            saleDetails,
                            buyerEmail,
                            buyerName,
                            buyerLabel,
                            stemTransfers,
                            priceWithFee,
                            dateSold

                        }))(song)

                        formattedData.push(d)
                    })

                    setSongs(prev => ({
                        ...prev,
                        nbHits: nbHits,
                        tableData: [
                            ...prev.tableData,
                            ...formattedData
                        ]
                    }))
                    
                }
                else {
                    const formattedData = []

                    resultsRevised.forEach((song, index) => {
                        const d = (({
                            song_title,
                            id,
                            created_at,
                            price,
                            my_split_percent,
                            my_split_value,
                            status,
                            isPrimary,
                            genre,
                            contributors,
                            status_text,
                            action,
                            file_path,
                            is_new,
                            actionRequired,
                            index,
                            saleDetails,
                            buyerEmail,
                            buyerName,
                            buyerLabel,
                            stemTransfers,
                            priceWithFee,
                            dateSold
                        }) =>
                        ({
                            song_title: song_title,
                            song_id: id,
                            created_at,
                            price,
                            my_split_percent,
                            my_split_value,
                            status,
                            isPrimary,
                            genres: JSON.parse(genre),
                            contributors,
                            status_text: parseInt(status) === 2 ? 'Needs Price & Splits' : status_text,
                            action,
                            file_path,
                            is_new: parseInt(is_new),
                            actionRequired,
                            index,
                            showTrailing: showTrailing(price ? price : undefined, contributors ? contributors : undefined),
                            saleDetails,
                            buyerEmail,
                            buyerName,
                            buyerLabel,
                            stemTransfers,
                            priceWithFee,
                            dateSold

                        }))(song)

                        formattedData.push(d)
                    })


                    setSongs(prev => ({
                        ...prev,
                        nbHits: nbHits,
                        tableData: [
                            ...prev.tableData,
                            ...formattedData
                        ]
                    }))
                    // setSongs(prev => ({
                    //     ...prev,
                    //     nbHits: nbHits,
                    //     tableData: [
                    //         ...prev.tableData,
                    //         ...newData
                    //     ]
                    // }))
                }
                newSongs.current = Object.keys(_songs).filter(key => _songs[key].is_new === "1")
            }
            if (!skipLoading) {
                setLoading(false)
            } else {
                setSoftLoading(false)
            }
            // setLoading(false)
            setError("")
        } catch (error) {
            // alert(error.response.data.message)
            console.log(error)
            setLoading(false)
            setSoftLoading(false)
            setError(error)

        }
    }

    const fetchMore = async () => {
        console.log("fetching more")
        page.current = page.current + 1;
        await pullSongs(page.current, filter, sort, true, true)
    }

    useEffect(() => {
        const replaceBlankEntry = (entry) => { return (entry ? entry : '-') }

        if (currentUser !== null && currentUser.userDetails !== undefined)
            pullSongs()
    }, [currentUser])
    const handleSongChange = (song, addToHistory = true, forcePlay = false, skipPlayingUpdate = false) => {
        const { file_path: songPath, song_id: songID } = song
        setFilePath(songPath);
        if ((!history.current.length) && addToHistory) {
            history.current = [...history.current, song]
        }
        else if (history.current[history.current.length - 1].song_id !== songID && addToHistory)
            history.current = [...history.current, song]
        if ((songID !== nowPlaying || forcePlay) && nowPlaying !== null) {
            setNowPlaying(songID)
            if (!skipPlayingUpdate)
                setPlaying(true)
            //nowPlaying.current = songID
        }
        else if (nowPlaying === null) {
            setNowPlaying(songID)
            // if (!skipPlayingUpdate)
            //     setPlaying(true)
            //nowPlaying.current = songID
        }
        else {
            setNowPlaying(songID)
            if (!skipPlayingUpdate)
                setPlaying(!playing)
            //nowPlaying.current = songID
        }
    }
    const onSkip = (dir) => {
        console.log("now playing inside skip", nowPlaying, "songs are", songs, songs.tableData.filter(song => song.song_id === nowPlaying)[0])
        const currSongIdx = songs.tableData.findIndex(song => song.song_id === nowPlaying)
        const adjustedIndex = dir + currSongIdx
        const s_idx = adjustedIndex >= songs.tableData.length ? 0 : adjustedIndex < 0 ? songs.tableData.length - 1 : adjustedIndex;
        const newSong = songs.tableData[s_idx];
        console.log("new song is", newSong, currSongIdx, adjustedIndex)
        // if (newSong)
        //     handleSongChange(newSong.file_path, newSong.song_id)
        if (dir > 0 || !history.current.length) {
            setSkipBackValid(true)
            if (!history.current.length) {
                console.log('here1')
                handleSongChange(newSong)
            }
            else if (history.current.length > 0 && stepsBack.current > 0) {
                stepsBack.current -= 1
                handleSongChange(history.current[history.current.length - stepsBack.current - 1], false, true)
            } else {
                handleSongChange(newSong, true, true)
            }

        } //stepsBack
        else {
            console.log(
                'playing index: ', history.current.length - stepsBack.current - 1,
                "history.current", history.current,
                "stepsBack.current", stepsBack.current,
                history.current[history.current.length - stepsBack.current - 1]
            )
            if (stepsBack.current + 2 >= history.current.length) {
                // handleSongChange(songs[songs.length - 1])
                // stepsBack.current = 0
                // console.log("here3")
                setSkipBackValid(false)
            }
            if (skipBackValid) {
                stepsBack.current += 1
                handleSongChange(history.current[history.current.length - stepsBack.current - 1], false, true)
            }
        }
    }
    // useEffect(() => {

    //     if (audioRef.current !== null && audioRef.current.src !== "") {

    //         playing ? audioRef.current.play().catch((e) => {
    //         }) : audioRef.current.pause();
    //     }

    // }, [playing, nowPlaying])

    useEffect(() => {
        if (audioRef.current && nowPlaying !== null) {
            if (audioRef.current.isPlaying)
                audioRef.current.pause()
            else
                audioRef.current.play()
        }
    }, [playing])


    useEffect(() => {
        if (songs.tableData.length > 0) {
            const initial_song = songs.tableData[0]
            if (isInitialLoad.current) {
                isInitialLoad.current = false
                handleSongChange(initial_song)
            }
            // setPlaying(false)
        }
    }, [songs])

    const setFilePath = async (path) => { setFile(`${process.env.REACT_APP_API_DOMAIN}` + path) }

    const handleSelect = (e) => {
        console.log(e.target)
    }

    return {
        onSkip,
        handleSongChange,
        handleSelect,
        currentUser,
        error,
        setError,
        loading,
        setLoading,
        songs,
        playing,
        setPlaying,
        nowPlaying,
        //setNowPlaying,
        audioRef,
        file,
        showModal,
        setShowModal,
        setSelectedSong,
        selectedSong,
        newApproval,
        fetchMore,
        resultsPerPage,
        page,
        listener,
        loader
    };
}