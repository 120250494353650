import React, { useState, useEffect, useRef } from 'react'

import { TermsLink, Wrapper, NavWrapper, CheckboxStyle, AlertNote } from './UploadSongs.styles'

import DragAndDrop from '../DragAndDrop'
import InputField from '../../InputField'
import FormNavArrow from '../FormNavArrow';
import SongTile from '../SongTile';
import UploadProgressMeter from '../UploadProgressMeter';
import { useAuth } from '../../../contexts/AuthContext'

const TandCReminder = ({ setShowTandCReminder }) => {

    const onClick = () => {
        setShowTandCReminder(false);
    }

    return (
        <AlertNote>
            <span>
                To upload songs to our site, you must agree to the Azaa terms and conditions and the Azaa Seller Agreement.
            </span>
            <button
                onClick={onClick}

            >&#10006;
            </button>
        </AlertNote>
    )
}

const Checkbox = ({ onCheck, userAgreement }) => {

    return (
        <CheckboxStyle >By checking this box, I agree to the Azaa <TermsLink to={{  pathname: "/terms" }} target="_blank">Terms and Conditions </TermsLink> and the Azaa <TermsLink to={{  pathname: "/azaa-seller-agreement" }} target="_blank">Seller Agreement.</TermsLink><input type="checkbox" onChange={onCheck} checked={userAgreement} /><span></span></CheckboxStyle>
    )
}

const UploadSongs = ({ showDropboxField, handleDropbox, startingStep, handleSongUpload, values, nextStep, prevStep, handleUserAgreement, userAgreement, membershipTier }) => {
    const [validContinue, setValidContinue] = useState(false);
    const [showTandCReminder, setShowTandCReminder] = useState(false);
    const initial = useRef(true);
    const { currentUser } = useAuth()

    const onCheck = (event) => { handleUserAgreement(); }

    useEffect(() => {

        if (initial.current) {
            initial.current = false;
            validateStep()
            return;
        }
        validateStep()
    }, [values.songList[0], userAgreement, values.dropbox])


    const validateStep = () => {

        if (values.songList.length > 0 && values.userAgreement) {
            setValidContinue(true)
            setShowTandCReminder(false)
            return true
        }
        // else if ( showDropboxField && values.userAgreement && values.dropbox !== '' 
        // && values.dropbox.includes('dropbox.com')
        // && values.dropbox.split('.com/').length > 1
        // ) {
        //     setValidContinue(true)
        //     setShowTandCReminder(false)
        //     return true
        // }
        else {
            setValidContinue(false)
            return false
        }
    }

    const continueStep = (event) => {
        event.preventDefault();

        if (validateStep()) {
            nextStep();
        }
        else {
            if (!values.userAgreement) {
                console.log(" user must agree")
                setShowTandCReminder(true)
            }
        }

    }
    const membership_tier_text = (tier_obj) => {
        const { id, tier } = tier_obj
        switch (id) {
            case 0:
                return 'You can submit one song with your application.'
            case 1:
                return 'You can submit one song with your application.'
            case 2:
                return `Thanks for being an Azaa ${tier} member. Enjoy unlimited uploads.`
            case 3:
                return `Thanks for being an Azaa ${tier} member. Enjoy unlimited uploads.`
        }
    }
    const subtext = membership_tier_text(membershipTier);

    return (
        <>
            {/* <UploadProgressMeter
        progress={5}
        numOfUploads={100} /> */}
            <NavWrapper>
                <FormNavArrow
                    direction='back'
                    text='back'
                    onClick={currentUser === undefined || currentUser === null ? prevStep : (e) => { e.preventDefault() }}
                    continueValid={startingStep === 0 ? true : false} />
                <Wrapper>
                    <Checkbox
                        onCheck={onCheck}
                        userAgreement={values.userAgreement}
                    />
                    <DragAndDrop
                        subtext={subtext}
                        isRegistrationForm={true}
                        title={<>Drag & Drop<br />
                            your song here</>}
                        handleSongUpload={handleSongUpload} />
                </Wrapper>
                <FormNavArrow
                    direction='next'
                    text='next'
                    onClick={continueStep}
                    continueValid={validContinue}
                />
            </NavWrapper>
            {showTandCReminder &&
                <TandCReminder
                    setShowTandCReminder={setShowTandCReminder} />
            }
        </>
    )
}

export default UploadSongs
