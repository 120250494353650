import React from 'react'

import { Wrapper } from './GridContainer.styles'

const GridContainer = ({ children, ...props }) => {
    return (
        <Wrapper {...props}>{children}</Wrapper>
    )
}

export default GridContainer