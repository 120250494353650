import React from 'react'

import {Wrapper, Button} from './SongTile.styles';

const SongTile = ({title, file, onClick, step, currentIndex, itemIndex}) => {

    return (
        <Wrapper value={title} step={step} currentIndex={currentIndex} itemIndex={itemIndex}>
            <span>{title}</span>
            <Button
            step={step} currentIndex={currentIndex} itemIndex={itemIndex}
                onClick={onClick}
            >&#10006;</Button>
        </Wrapper>
    )
}

export default SongTile;
