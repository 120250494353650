import styled from "styled-components";

export const MessageWrapper = styled.div`
background: ${ props => props.senderIsCurrentUser ? "var(--purpleGrey)" : "var(--greyBackground)"};
padding: 10px;

border-radius: 5px;
grid-area: message;
`;

export const Wrapper = styled.div`
display: grid;
align-self: ${props => props.senderIsCurrentUser ? "flex-end" : "flex-start"};
font-size: 12px;
line-height: 1.1rem;
position: relative;
grid-template-areas: 'message' 'row';
min-width: 350px;
max-width: 50%;

`;

export const Sender = styled.div`
margin-right: 0.25rem;
position: relative;
text-align: ${ props => props.senderIsCurrentUser ? "right" : "left"};
white-space: nowrap;
`;

export const Time = styled.div`
margin-left: .25rem;`;

export const Row = styled.div`
user-select: none;
font-size: 10px;
display: flex;
width: 100%;
justify-content: ${props => props.senderIsCurrentUser ? "flex-end" : "flex-start"};
`;