import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
align-items: center;
min-height: 100vh;
padding: 0 3.22vw;
justify-content: center;
`;

export const OuterWrapper = styled.div`
height: 100%;
padding-top: var(--navBarHeight);
display: flex;
flex-direction: column;
`;

export const Header = styled.h1`

user-select: none;

`;

export const Row = styled.div`
display: flex;
align-items: center;
margin: ${props => props.margin ? props.margin : ''};
`;

export const Col = styled.div`
display: flex;
flex-direction: column;
justify-content: center;

strong{
    font-weight: var(--bold);
}
`;

export const List = styled.ol`
/* margin: 0; */

li{
    margin: 1rem;
    font-weight: 300;
}
`;