import styled from 'styled-components'

export const Wrapper = styled.div`
height: calc(100vh - 60px);
bottom: ${props => props.showBufferPlaylist ? '60px' : '-100vh'};
transition: all 0.5s ease-in-out;
width: 100%;
position: fixed;
z-index: 2;
background: var(--dropdownBackground);
`;