import React from 'react'

import { Wrapper } from './Flexbox.styles'

const Flexbox = ({
    isHeader,
    refID,
    innerRef,
    center,
    start,
    dimensions,
    end,
    width,
    maxWidth,
    relative,
    absolute,
    fixed,
    height,
    minHeight,
    position,
    transition,
    top,
    right,
    bottom,
    boxShadow,
    left,
    margin,
    children,
    whiteSpace,
    nowrap,
    gridArea,
    gridCol,
    gridRow,
    display,
    gridTempRows,
    gridTempCols,
    gridTemplate,
    gap,
    alignText,
    flexWrap,
    flexGrow,
    flexBasis,
    justifyContent,
    column,
    row,
    flexDirection,
    justifyItems,
    justifySelf,
    alignSelf,
    alignItems,
    alignContent,
    fontSize,
    letterSpacing,
    transformText,
    overflow,
    overflowX,
    color,
    background,
    border,
    borderRight,
    borderRadius,
    zIndex,
    onClick,
    cursor,
    transform,
    padding,
    fontWeight,
    borderBottom,
    className,
    ...props
}) => {

    return (
        <Wrapper
            className={className}
            transform={transform}
            flexGrow={flexGrow}
            flexBasis={flexBasis}
            minHeight={minHeight}
            maxWidth={maxWidth}
            transition={transition}
            transformText={transformText}
            borderRadius={borderRadius}
            isHeader={isHeader ? isHeader : undefined}
            ref={refID ? refID : innerRef ? innerRef : undefined}
            position={relative ? "relative" : absolute ? 'absolute' : fixed ? 'fixed' : position}
            zIndex={zIndex}
            top={top}
            right={right}
            bottom={bottom}
            background={background}
            boxShadow={boxShadow}
            borderRight={borderRight}
            cursor={cursor}
            display={display}
            onClick={typeof (onClick) === 'function' ? onClick : undefined}
            margin={margin}
            padding={padding}
            overflow={overflow}
            overflowX={overflowX}
            height={dimensions ? dimensions['h'] : height}
            left={left}
            width={dimensions ? dimensions['w'] : width}
            flexDirection={column ? 'column' : row ? 'row' : flexDirection}
            flexWrap={flexWrap}
            justifySelf={justifySelf}
            alignSelf={alignSelf}
            gap={gap}
            whiteSpace={nowrap ? 'nowrap' : whiteSpace}
            letterSpacing={letterSpacing}
            alignText={alignText}
            justifyContent={center ? "center" : start ? 'start' : end ? 'end' : justifyContent}
            justifyItems={justifyItems}
            alignContent={alignContent}
            alignItems={center ? "center" : start ? 'start' : end ? 'end' : alignItems}
            color={color}
            fontSize={fontSize}
            fontWeight={fontWeight}
            borderBottom={borderBottom}
            border={border}
            {...props}
        >{children}</Wrapper>
    )
}

export default Flexbox