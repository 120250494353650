import React from 'react'

import { Wrapper, Step, Description, Content, Button } from './FormHeader.styles'

import FormProgressBar from '../FormProgressBar'


const FormHeader = ({step, totalLength, userDetailsStep}) => {
    console.log(step, totalLength, userDetailsStep)

    var stepTitle, desciption;
    switch (step) {
        case 1:
            stepTitle = 'Song Uploader';
            desciption = "Upload your tunes and agree to the Azaa terms and conditions and the Azaa Seller Agreement."
            break;
        case 2:
            stepTitle = 'Song Details';
            desciption = 'Complete song details for your upload.'
            break;

            
    }

    return (
        <Wrapper>
            <FormProgressBar step={step} totalLength={totalLength} userDetailsStep={userDetailsStep}/>
            <Content>
                <Step>{stepTitle}</Step>
                <Description>{desciption}</Description>
            </Content>
            <Button to="/">&#10006;</Button>
        </Wrapper>
    )
}

export default FormHeader;