import React, { useState, useEffect, useRef, useCallback } from 'react'

import InputField from '../../InputField';
import FormNavArrow from '../FormNavArrow';
import Dropdown from '../Dropdown';
import DropdownMultiSelect from '../DropdownMultiSelect';


import NextArrow from '../../../images/formNavArrow.svg'


import { NavWrapper, Wrapper, SongCount, QuestionWrapper, Button } from './SongDetails.styles'

const SongDetails = ({ handleKeyPress, songIndex, songTitle, handleChange, setState, nextStep, values, prevStep }) => {

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
        }
    }


    const nextButtonText = (songIndex + 1) === values.songList.length ? 'Submit' : 'next';



    const [validContinue, setValidContinue] = useState(false);
    const initial = useRef(true);

    useEffect(() => {

        if (initial.current) {
            initial.current = false;
            validateStep()
            return;
        }
        validateStep()
    }, [values.songList[songIndex]])
    const validateStep = () => {

        if (values.songList[songIndex].songTitle.replace(/\s+/g, '') != "" && values.songList[songIndex].genre.length > 0 && values.songList[songIndex].genre != '--' && values.songList[songIndex].BPM != '--') {
            setValidContinue(true)
            return true
        }
        else {

            setValidContinue(false)
            return false
        }
    }

    const continueStep = (event) => {
        event.preventDefault();

        if (validateStep()) {
            nextStep(songIndex);
        }

    }

    const continueKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleKeyPress(event)

            if (validateStep()) {
                nextStep(songIndex);
            }
        }
    }

    const previousStep = (event) => {
        event.preventDefault();
        prevStep()
    }

    const BPM = [
        {
            value: 'Downtempo (50-80BPM)',
            innerText: 'Downtempo (50-80BPM)'
        },
        {
            value: 'Midtempo (80-105BPM)',
            innerText: 'Midtempo (80-105BPM)'
        },
        {
            value: 'Uptempo (+105BPM)',
            innerText: 'Uptempo (+105BPM)'
        }
    ];

    const genres = [
        {
            value: 'Country',
            innerText: 'Country'
        },
        {
            value: 'Dance',
            innerText: 'Dance'
        },
        {
            value: 'Indie',
            innerText: 'Indie'
        },
        {
            value: 'Pop',
            innerText: 'Pop'
        },
        {
            value: 'Rap',
            innerText: 'Rap'
        }
    ];

    const index = songIndex + 1;
    const totalSongs = values.songList.length;
    return (
        <NavWrapper>
            <FormNavArrow
                direction='back'
                text='back'
                onClick={previousStep}
                continueValid={true} />
            <Wrapper >
                <SongCount>Song {index} of {totalSongs}</SongCount>
                <QuestionWrapper>
                    <InputField
                        type="text"
                        onChange={handleChange('songTitle')}
                        onKeyPress={continueKey}
                        uppercaseOff
                        name='firstName'
                        label='Song Title'
                        placeholder={'Enter Song Title'.toUpperCase()}
                        marginBottom={'20px'}
                        value={values.songList[songIndex].songTitle}
                        setValidContinue={setValidContinue}
                    />
                </QuestionWrapper>
                <QuestionWrapper>
                    {/* <Dropdown
                    label='BPM'
                    name='tempo'
                    marginBottom={'1rem'}
                    options={BPM}
                ></Dropdown> */}
                </QuestionWrapper>
                <QuestionWrapper>
                    <Dropdown
                        label='BPM'
                        name='tempo'
                        marginBottom={'1rem'}
                        options={BPM}
                        handleStateChange={handleChange('songListBPM')}
                        valueX={values.songList[songIndex].BPM}
                        index={index}
                        id='BPM'
                        setValidContinue={setValidContinue}
                    ></Dropdown>
                </QuestionWrapper>
                <QuestionWrapper>
                    <DropdownMultiSelect
                        label='Select up to two genres'
                        name='tempo'
                        marginBottom={'1rem'}
                        options={genres}
                        handleStateChange={handleChange('songListGENRE')}
                        valueX={values.songList[songIndex].genre}
                        index={index}
                        setValidContinue={setValidContinue}

                    ></DropdownMultiSelect>
                </QuestionWrapper>
            </Wrapper>
            {/* <Button>
                <img src={NextArrow} alt='next-arrow' />
            </Button> */}
            <FormNavArrow
                direction='next'
                text={nextButtonText}
                onClick={continueStep}

                continueValid={validContinue}
            />
        </NavWrapper>
    )
}

export default SongDetails
