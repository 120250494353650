import { useState, useEffect, useRef } from 'react';
import API from '../API';
import dayjs from 'dayjs'
import { useAuth } from '../contexts/AuthContext'
import { updateRelTime } from '../utils'

import * as THREE from 'three'

export const useAudioHook = (args) => {
    const { payload, songs, setSongs, songBuffer, setSongBuffer, changeCurrPlaylistHeader } = args
    const [file, setFile] = useState(null)
    // audio
    const playRef = useRef(null)
    const initial = useRef(true)
    const stepsBack = useRef(0)
    const isPlaylistChange = useRef(false)
    const isInitialLoad = useRef(true)
    const audioRef = useRef(null)
    const [playing, setPlaying] = useState(false)
    const [skipBackValid, setSkipBackValid] = useState(false)
    const history = useRef([])
    const [nowPlaying, setNowPlaying] = useState(null)
    const [playBarSong, setPlayBarSong] = useState(null)

    const listener = useRef(new THREE.AudioListener());
    const loader = useRef(new THREE.AudioLoader())

    const importFile = async (path) => {
        // if (audioRef.current)
        //     loader.current.load(path, function (buffer) {
        //         console.log("setting buffer:...", buffer, path, audioRef.current)
        //         audioRef.current.setBuffer(buffer);
        //         audioRef.current.setLoop(false);
        //         audioRef.current.setVolume(1.0);
        //         audioRef.current.onEnded = () => { console.log("song has ended") }

        //     });
        // else {
        //     console.log("audioRef.current is null")
        // }
        setFile(path)
    }

    const handleSongChange = (song, addToHistory = true, forcePlay = false, skipPlayingUpdate = false) => {
        console.log("song", song)
        const { file_path, song_id } = song
        importFile(file_path)
        if ((!history.current.length || isPlaylistChange.current) && addToHistory) {
            history.current = [...history.current, song]
        }
        else if (history.current[history.current.length - 1].song_id !== song.song_id && addToHistory)
            history.current = [...history.current, song]
        if ((song_id !== nowPlaying || isPlaylistChange.current || forcePlay) && nowPlaying !== null) {
            console.log('here1 -  in handleSongChange')
            setNowPlaying(song_id)
            if (!skipPlayingUpdate)
                setPlaying(true)


            // if (isPlaylistChange.current || forcePlay) {
            //     audioRef.current.pause()
            //     audioRef.current.currentTime = 0
            //     audioRef.current.play()
            // }
            // playRef.current = song_id

        }
        else if (nowPlaying === null) {
            console.log('here2 -  in handleSongChange')
            // setPlaying(true)
            setNowPlaying(song_id)
            playRef.current = song_id
        }
        else {
            console.log('here3 -  in handleSongChange', playing, nowPlaying)
            setNowPlaying(song_id)
            if (!skipPlayingUpdate)
                setPlaying(!playing)
            playRef.current = song_id
            // if (audioRef.current) {
            //     if (audioRef.current.isPlaying)
            //         audioRef.current.pause()
            //     else
            //         audioRef.current.play()
            // }
        }

    }

    const handlePlaylistChange = (song = null) => {
        isPlaylistChange.current = true
        const _buffer = songBuffer
        setSongBuffer([])
        setSongs(_buffer)
        if (song !== null) {
            handleSongChange(song)
        } else {
            handleSongChange(_buffer[0])
        }
        setSkipBackValid(true)
        changeCurrPlaylistHeader()
    }

    const cleanupPlayingSong = () => {
        console.log('cleaning up')
        const songIsPlaying = payload.songID === nowPlaying
        if (songIsPlaying) {
            setFile(null)
            setNowPlaying(null)
            setPlaying(false)
        }
    }

    const togglePlayPause = () => {
        setPlaying(!playing)
    }


    const onSkip = (dir) => {
        const currSongIdx = songs.findIndex(song => song.song_id === nowPlaying)
        const adjustedIndex = dir + currSongIdx
        const s_idx = adjustedIndex >= songs.length ? 0 : adjustedIndex < 0 ? songs.length - 1 : adjustedIndex;
        const newSong = songs[s_idx];
        console.log(
            "currSongIdx", currSongIdx,
            "dir", dir,
            "newSong", newSong,
            "s_idx", s_idx,
            "history.current", history.current,
            "stepsBack.current", stepsBack.current)
        if (dir > 0 || !history.current.length) {
            setSkipBackValid(true)
            if (!history.current.length) {
                console.log('here1')
                handleSongChange(newSong)
            }
            else if (history.current.length > 0 && stepsBack.current > 0) {
                stepsBack.current -= 1
                handleSongChange(history.current[history.current.length - stepsBack.current - 1], false, true)
            } else {
                handleSongChange(newSong, true, true)
            }

        } //stepsBack
        else {
            console.log(
                'playing index: ', history.current.length - stepsBack.current - 1,
                "history.current", history.current,
                "stepsBack.current", stepsBack.current,
                history.current[history.current.length - stepsBack.current - 1]
            )
            if (stepsBack.current + 2 >= history.current.length) {
                // handleSongChange(songs[songs.length - 1])
                // stepsBack.current = 0
                // console.log("here3")
                setSkipBackValid(false)
            }
            if (skipBackValid) {
                stepsBack.current += 1
                handleSongChange(history.current[history.current.length - stepsBack.current - 1], false, true)
            }
        }


    }

    useEffect(() => {
        if (songs.length > 0 && !isPlaylistChange.current) {
            console.log('songs changed', songs)
            const initial_song = songs[0]
            if (isInitialLoad.current){
                isInitialLoad.current = false
                handleSongChange(initial_song)
            }
            // handleSongChange(initial_song, true, false, true)
            // setPlaying(false)
        } else if (isPlaylistChange.current) {
            isPlaylistChange.current = false
        }
    }, [songs])

    useEffect(() => {
        if (audioRef.current && nowPlaying !== null) {
            if (audioRef.current.isPlaying)
                audioRef.current.pause()
            else
                audioRef.current.play()
        }
    }, [playing])

    return {
        file,
        audioRef,
        skipBackValid,
        stepsBack,
        playing,
        togglePlayPause,
        handlePlaylistChange,
        nowPlaying,
        handleSongChange,
        cleanupPlayingSong,
        onSkip,
        listener,
        loader
    }
}