import styled from 'styled-components'

export const DropDownContainerBox = styled.div`
position: ${props => props.position ? props.position : 'absolute'};
right: 3.22vw;
z-index: 10;
`;

export const Action = styled.div`

    width: 50px;
height: 50px;


position: relative;
display: flex;
align-items: center;
justify-content: center;
`;

export const DotMenuWrapper = styled(Action)`

svg{
    height: 15px;
}

`;