import React, { useEffect, useState } from 'react'

import Modal from '../../Modal'
import Flexbox from '../../Flexbox'
import TextArea from '../../TextArea'
import Button from '../../Button'
import MessageInputBar from '../../MessageInputBar'
import StemsTransferModal from '../../StemsTransferModal'

import API from '../../../API'
import { useAuth } from '../../../contexts/AuthContext'
import { useMessengerFetch } from '../../../hooks/useMessengerFetch'

import { BUYER_DASH_ACTIONS } from '../../../config'

const MessageModal = ({ modalType, modalDetails, data, loading, error, setError, setLoading }) => {
    const { currentUser } = useAuth()
    const [messageInput, setMessageInput] = useState({
        song_id: '',
        songwriter_id: '',
        sender_id: '',
        receiver_id: '',
        message: modalDetails.defaultMessage
    });

    const handleChange = (event) => {
        setMessageInput(prev => ({ ...prev, message: event.target.value }))
    }
    const continueKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            sendMessage().then(() => { setMessageInput((prev) => ({ ...prev, message: "" })) });
        }
    }

    const sendMessage = async () => {
        if (messageInput.message.replace(/\s/g, '').length) {
            try {
                setError(false);
                setLoading(true);
                const res = await modalDetails.onClick(currentUser, data.song_id, messageInput.message);

            } catch (error) {
                setError(true);

            }
            setLoading(false);
        }

    };

    useEffect(() => {
        if (data && currentUser && currentUser.userDetails && currentUser.userDetails.id && data.song_id) {
            setMessageInput(prev => ({
                ...prev,
                song_id: data.songId,
                songwriter_id: currentUser.userDetails.id,
                sender_id: currentUser.userDetails.id,
                receiver_id: '1',
                message: modalDetails.defaultMessage
            }))
        }
    }, [data,modalDetails])
    return (
        <Flexbox column gap="20px">
            <TextArea margin="0" padding="20px" height="100px" onChange={handleChange} value={messageInput.message} placeholder={`I'm interested in purchasing the song, '${data.song_title}'.`} />
            <Button onClick={async () => { await sendMessage() }} text={modalDetails.buttonText} width="100%" border="1px solid white" />
        </Flexbox>
    )
}

const Content = ({ modalType, data, loading, error, setError, setLoading, modalDetails }) => {
    switch (modalType) {
        case BUYER_DASH_ACTIONS.MESSAGE_ADMIN:
            return (
                <MessageModal modalType={modalType} modalDetails={modalDetails} data={data} loading={loading} setLoading={setLoading} error={error} setError={setError} />
            )
        default:
            return (
                <></>
            )
    }

}


const BuyerDashModal = ({ payload, modalType, showModal, closeModal }) => {
    const { currentUser } = useAuth()
    const [modalDetails, setModalDetails] = useState({
        title: '',
        subtext: '',
        defaultMessage: '',
        buttonText: '',
        onClick: () => { },
        hideButton: false
    })
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const getModal = () => {
        switch (modalType) {
            case BUYER_DASH_ACTIONS.PLACE_ON_HOLD:
                setModalDetails({ title: 'Place A Hold', subtext: '', onClick: () => { }, hideButton: false })
                break;
            case 2:
                setModalDetails({ title: 'Place A Hold', subtext: '', onClick: () => { }, hideButton: false })
                break;
            case BUYER_DASH_ACTIONS.MESSAGE_ADMIN:
                setModalDetails({
                    title: `Message Azaa about '${payload.song_title}'`, buttonText: "Send Message", 
                    defaultMessage: parseInt(payload.status) >= 5 ? `I've a question about my purchased song, '${payload.song_title}'.` : `I'm interested in purchasing the song, '${payload.song_title}'.`,
                    onClick: async (currentUser, songId, message) => {
                        try {
                            setError(false);
                            setLoading(true);
                            const res = await API.sendMessage(currentUser, songId, message);
                            setModalDetails(prev => ({ ...prev, buttonText: 'Message Sent!', onClick: () => { } }))

                        } catch (error) {
                            setError(true);

                        }
                        setLoading(false);
                    },
                    hideButton: true
                })
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        if (modalType)
            getModal()
    }, [modalType,payload])
    if (modalType === BUYER_DASH_ACTIONS.CONFIRM)
        return <StemsTransferModal showModal={showModal} currentUser={currentUser} closePopup={closeModal} selectedSong={payload} />
    return (
        <Modal
            zIndex="5"
            showModal={showModal}
            title={modalDetails.title}
            subtext={modalDetails.subtext}
            closeModal={closeModal}
            onClick={modalDetails.onClick}
            hideButton={modalDetails.hideButton}
            buttonText={modalDetails.buttonText !== '' ? modalDetails.buttonText : undefined}
            uppercaseOff={modalType === BUYER_DASH_ACTIONS.MESSAGE_ADMIN ? true : undefined}
            contentHeight='100%'
        >
            <Content modalDetails={modalDetails} modalType={modalType} data={payload}
                loading={loading}
                error={error}
                setError={setError}
                setLoading={setLoading}
            />
        </Modal>
    )
}

export default BuyerDashModal