import styled from "styled-components";

export const Action = styled.div`
width: 20px;
height: 20px;
padding: 5px;

position: relative;
display: flex;
align-items: center;
justify-content: center;
`;


export const PlayButtonContainer = styled(Action)`
svg{
    height: 100%;
}
`;