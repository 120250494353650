import styled from "styled-components";

export const Wrapper = styled.div`
width: 100%;
position: relative;
display: flex;
flex-direction: column;
align-items: center;
justify-content: start;
padding: 80px 0 ;
background: ${props => props.background};

    h1{
        text-transform: uppercase;
        font-size: 1.1em;
        line-height: 1em;
        font-weight: 400;
        margin: 0;
    }

`;

export const Content = styled.div`
/* max-width: 800px; */
display: grid;
position: relative;
width: min-content;
grid-column-gap: 10%;
margin-top: 60px;
grid-template-columns: repeat(3, 1fr);
display: flex;
`;

export const GridWrapper = styled.div`
display: grid;
position: relative;
padding: 50px 0 0;
background: ${props => props.background};
grid-template-columns: repeat(3, 220px);
grid-row-gap: 20px;
grid-column-gap: 50px;
grid-auto-flow: column;
grid-template-rows: min-content 1fr;
justify-items: center;
text-align: center;
img{
    height: 65px;
    position: relative;
}

@media screen and (max-width: 750px){
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 50px;
    grid-auto-flow: column;
    grid-auto-flow: row;
    grid-template-rows: min-content;
}

`;