import React, { useRef, useState, useEffect } from 'react'
import { Wraper, Innercontainer, Row, NavArrow, DateWrapper, LoadWrapper } from './PageNav.styles'
import FormNavArrow from '../../images/formNavArrow.svg';
import GridItem from '../GridItem'
import Flexbox from '../Flexbox'
import SearchBar from '../SearchBar'
import SuggestSearch from '../SuggestSearch'

import dayjs from 'dayjs'

const Label = ({ index, labelRef, label, setShowFilterDropdown, width }) => {
    if (index === 0) {
        return (
            <GridItem
                background="#1c1c1c"
                width={width ? width : "100%"}
                borderRadius="5px"
                boxShadow="0 0 2px #000"
                cursor="pointer"
                position='relative'
                whiteSpace="nowrap"
                letterSpacing="0.1rem" fontSize="var(--fontSmall)" fontWeight="700"
                height="45px"
                // justifySelf="end" alignItems="end" 
                justifySelf="center" alignItems="center"
                onClick={setShowFilterDropdown}
                justifyContent="center"
                refID={labelRef}>{label}</GridItem>)
    }
    else
        return (<GridItem
            width={width ? width : "100%"}
            background="#1c1c1c"
            borderRadius="5px"
            boxShadow="0 0 2px #000"
            cursor="pointer" onClick={setShowFilterDropdown}
            position='relative' letterSpacing="0.1rem" alignText="center" fontSize="var(--fontSmall)" fontWeight="700" height="45px" alignItems="center" justifyContent="center" refID={labelRef}>{label}</GridItem>)
}

const Page = ({ number, onClick, isActive, isPlaceholder, shadowOff }) => {
    if (isPlaceholder)
        return (
            <GridItem
                width="40px"
                height="40px"
                lineHeight="0.7em"
                borderRadius="5px"
                // border={!isActive ? "" : "1px solid #4f4f4f"}
                opacity={"0.25"}
                position='relative'
                whiteSpace="nowrap"
                letterSpacing="0.1rem"
                fontSize={"var(--fontSmall)"}
                fontWeight="700"
                // justifySelf="end" alignItems="end" 
                justifySelf="center" alignItems="center"
                justifyContent="center">...</GridItem>)
    return (
        <GridItem
            width="40px"
            height="40px"
            lineHeight="0.7em"
            borderRadius="5px"
            // border={!isActive ? "" : "1px solid #4f4f4f"}
            boxShadow={shadowOff && isActive ? 'inset 0 0 4px #000' : ""}
            opacity={!isActive ? "0.25" : "1.0"}
            background={!isActive ? "" : "#313131"}
            cursor="pointer"
            position='relative'
            whiteSpace="nowrap"
            letterSpacing="0.1rem"
            fontSize={"var(--fontSmall)"}
            fontWeight="700"
            // justifySelf="end" alignItems="end" 
            justifySelf="center" alignItems="center"
            onClick={onClick}
            justifyContent="center">{number}</GridItem>)
}
const PageNav = ({ onSearchTermRemoval, placeHolder, getSuggestions, onSearchEnter, pageRef, shadowOff, content, filter, totalResults, resultsPerPage, page, setPage, fetchMore, loading, children, ...props }) => {

    const { onSearchListUpdate, onSearchChange, searchList, pageGridGap, searchBarFirst } = props

    const [paginate, setPaginate] = useState({
        totalPages: 1,
        pageArray: [],
        showFirst: false,
        showLast: true,
        tabsToShow: 2
    })
    useEffect(() => {
        if (resultsPerPage !== 0) {
            const length = Math.ceil(totalResults / resultsPerPage) !== 0 ? Math.ceil(totalResults / resultsPerPage) : 1
            setPaginate(prev => ({
                ...prev,
                pageArray: Array.from({ length: length }, (_, i) => i + 1),
                totalPages: length,
                tabsToShow: 5
            }))
        }

    }, [totalResults, resultsPerPage, content])

    const numberIsInRange = (number) => {
        if (number === 1 && page > tabsToShow)
            return false
        if (number < (page % tabsToShow > 0 ? page - page % tabsToShow : (page - 1) - (page - 1) % tabsToShow))
            return false
        if (number > (page % tabsToShow > 0 ? page - page % tabsToShow + tabsToShow : (page - 1) - (page - 1) % tabsToShow + tabsToShow))
            return false
        return true
    }
    const { totalPages, pageArray, tabsToShow, showFirst, showLast } = paginate
    useEffect(() => {
        if (!numberIsInRange(1) || !numberIsInRange(totalPages)) {

            setPaginate(prev => ({
                ...prev,
                showFirst: !numberIsInRange(1),
                showLast: !numberIsInRange(totalPages),
            }))

        }

    }, [page, totalPages])


    return (
        <Flexbox flexDirection={searchBarFirst ? 'row-reverse' : undefined}>
            <GridItem
                alignItems="center"
                borderRadius="5px"
                background="#1c1c1c"
                height="45px"
                width="min-content"
                padding={shadowOff ? '' : "0 10px"}
                flexDirection="row"
                boxShadow={shadowOff ? '' : "0 0 2px #000"}
                textTransform="uppercase"
                fontSize="var(--fontSmall)"
                letterSpacing="0.1rem"
                fontWeight="700"
                gap={pageGridGap ? pageGridGap : "20px"}
                gridRow="2">
                {/* <div style={{ display: 'flex' }}>Page {page} of
                                    <div style={{ display: 'flex', width: 'min-content', marginLeft: '3px' }}
                                        onClick={!loading ? () => {
                                            if (page !== Math.ceil(totalResults / resultsPerPage)) {
                                                setPage(Math.ceil(totalResults / resultsPerPage))
                                                fetchMore(Math.ceil(totalResults / resultsPerPage), filter)
                                            }
                                        } : undefined}>
                                        {Math.ceil(totalResults / resultsPerPage) !== 0 ? Math.ceil(totalResults / resultsPerPage) : 1}
                                    </div>
                                </div> */}

                <NavArrow back isValid={page > 1}
                    onClick={() => {
                        if (!loading)
                            if (page > 1) {
                                setPage(page - 1)
                                fetchMore(page - 1, filter)
                            }
                    }}><img src={FormNavArrow} alt="back" /></NavArrow>
                {
                    showFirst &&
                    <>
                        <Page
                            shadowOff={shadowOff}
                            number={1}
                            onClick={() => {
                                if (page !== 1) {
                                    setPage(1)
                                    fetchMore(1, filter)
                                }
                            }}
                            isActive={1 === page} />
                        <Page isPlaceholder={true} />
                    </>
                }

                {
                    pageArray
                        .slice((page % tabsToShow > 0 ? page - page % tabsToShow : (page - 1) - (page - 1) % tabsToShow),
                            (page % tabsToShow > 0 ? page - page % tabsToShow + tabsToShow : (page - 1) - (page - 1) % tabsToShow + tabsToShow))
                        .map(it =>
                            <Page
                                shadowOff={shadowOff}
                                key={it}
                                number={it}
                                onClick={() => {
                                    if (page !== it) {
                                        setPage(it)
                                        fetchMore(it, filter)
                                    }
                                }}
                                isActive={it === page} />)
                }
                {
                    (showLast && totalPages > tabsToShow) &&
                    <>
                        <Page isPlaceholder={true} />
                        <Page number={totalPages}
                            onClick={() => {
                                if (page !== totalPages) {
                                    setPage(totalPages)
                                    fetchMore(totalPages, filter)
                                }
                            }}
                            isActive={totalPages === page} />
                    </>
                }

                <NavArrow isValid={page <= Math.ceil(totalResults / resultsPerPage) - 1}
                    onClick={() => {
                        if (!loading)
                            if (page + 1 <= Math.ceil(totalResults / resultsPerPage)) {
                                setPage(page + 1)
                                fetchMore(page + 1, filter)
                            }
                    }}><img src={FormNavArrow} alt="next" /></NavArrow>
            </GridItem>
            {
                <GridItem gridRow="2" width="100%" maxWidth="600px">
                    <SuggestSearch
                        margin="0"
                        padding="0"
                        maxWidth="100%"
                        borderRadius="5px"
                        minHeight="45px"
                        getSuggestions={getSuggestions}
                        onSearchTermRemoval={onSearchTermRemoval}
                        placeHolder={placeHolder}
                        data={searchList}
                        onChange={onSearchEnter}
                        height="45px"
                        isFooter={false} />
                    {/* <SearchBar borderRadius="5px" height="45px" list={searchList} onChange={onSearchChange} onSearchListUpdate={onSearchListUpdate} /> */}

                </GridItem>
            }
        </Flexbox>


    )


}

export default PageNav