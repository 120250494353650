import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'

export const OuterWrapper = styled.div`
width: 100%;
display: flex;
position: relative;
top: 60px;
overflow: hidden;
height: calc( 100vh - 60px);
`;

export const SideBarWrapper = styled.div`
width: 30%;
position: relative;
display: flex;
flex-direction: column;
user-select: none;
padding: 20px 0;
background: linear-gradient( 90deg,  #000000, rgba(255,255,255,0.1));
max-width: 250px;
height: 100%;
`;

export const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    overflow: hidden;
`;
export const SideBar = styled.div`
    user-select: none;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.03rem;
    width: 30%;
    max-width: 250px;
    height: 100%;
    position: relative;
    padding: 40px 0px 20px;
    background: var(--greyBackground);
`;

export const SideBarLink = styled.div`
    padding: 10px 20px;
    background: ${props => props.isActive ? 'var(--backgroundActiveLi)' : 'transparent'};
    border-right: ${props => props.isActive ? 'var(--borderRightActiveLi)' : 'none'};
`;

export const MessagesLink = styled(SideBarLink)`
    font-size: 15px;
    font-weight: 500;
`;

export const SideBarLinkHeader = styled.div`
    padding: 10px 20px;
    font-weight: 500;
    font-size: 15px;
`;

export const Spacer = styled.div`
margin-top: 30px;
`;

export const RightContainer = styled.div`
    width: 100%;
    position: relative;
    height: 100%;
    overflow: auto;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    padding: ${props => props.isMessages ? '50px 20px' : '50px'};
    align-items: center;
`;

export const Row = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
margin-bottom: 10px;
text-transform: uppercase;
`;

export const Innercontainer = styled.div`
width: 100%;
height: 100%;
position: relative;
`;

export const DateWrapper = styled.div`
font-size: 10px;
display: flex;
align-items: flex-end;
`;

export const SiteHeader = styled.div`
    color: #fff;
    padding: 10px 20px;
    font-family: Cano;
    text-transform: none;
    font-size: 16px;
`;