import styled from "styled-components";


export const ModalWrapper = styled.div`
position: ${({ modalPosition = 'absolute' }) => modalPosition};
background: var(--greyBackground);
display: grid;
justify-content: center;
align-items: center;
box-shadow: 0 0 20px rgba(0,0,0,0.5);
min-width: ${props => props.minWidth ? props.minWidth : '500px'};
max-width: ${props => props.maxWidth ? props.maxWidth : ''};
width: ${props => props.width ? props.width : ''};
grid-template-columns: 1fr min-content;
grid-column-gap: 10px;
grid-template-areas: ${props => props.subtext ? "'title close''subtext subtext''content content''button button'" : "'title close''content content''button button'"};
padding: 20px;
grid-row-gap: 20px;
z-index: 3;
margin-top: auto;
margin-bottom: auto;
overflow: auto;
height: ${({ modalWrapperHeight }) => modalWrapperHeight};
`;

export const ModalOverlay = styled.div`
position: ${props => props.position ? props.position : ''};
left: ${props => props.left ? props.left : ''};
top: ${props => props.top ? props.top : ''};
visibility: ${props => props.showModal ? 'visible' : 'hidden'};
z-index: ${props => props.zIndex ? props.zIndex : '4'};
display: flex;
align-items: start;
justify-content: center;
width: 100vw;
height: 100vh;
background: transparent;
overflow: auto;
`;

export const CloseButtonContainer = styled.div`
display: flex;
width: 100%;
justify-content: flex-end;
position: relative;
img{
    height: 20px;
    width: 20px;
    cursor: pointer;
}
`;

export const GridItem = styled.div`
grid-area: ${props => props.gridArea ? props.gridArea : ''};
height: ${props => props.contentHeight ? props.contentHeight : ''};
`;

export const SubText = styled.div`
font-size: 12px;
margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};
user-select: none;
color: ${props => props.color ? props.color : 'var(--purpleGrey)'};
`;

export const ModalTitle = styled.div`
user-select: none;
text-transform: ${props => props.uppercaseOff ? '' : 'uppercase'};
`;

