import React from 'react';

import {
    Wrapper,
    Question,
    Answer
} from './QAModule.styles'

const QAModule = ({ question, answer }) => {
    return (
        <Wrapper>
            <Question>{question}</Question>
            <Answer>{answer}</Answer>
        </Wrapper>
    );
};

export default QAModule;
