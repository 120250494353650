import styled from 'styled-components';

export const FormStyled = styled.form`

min-height: calc(100vh - 60px);
width: 100vw; // changed this to 100vw to keep stuff from shifting due to vert scroll bar
top: 60px;
position: absolute;
flex-direction: column;
display: flex;
align-items: center;
justify-content: center;
background: #000;
`;


export const Wrapper = styled.div`
    width: 100%;
    top: 0;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;