import React, { useEffect, useState } from 'react'

import { Wrapper, ScrollWrapper, EmptySpacer, OuterWrapper, Button } from './SongTileContainer.styles'

import SongTile from '../SongTile'
import Arrow from '../../../images/formNavArrow.svg'

const ScrollButton = ({name, onClick, rotate, visible}) => (
    <Button 
        name={name}
        onClick={onClick}
        rotate={rotate} 
        visible={visible}
    >
        <img src={Arrow} alt={name} />
    </Button>
)

const SongTileContainer = ({values, scrollTo, index, removeItem, step}) => {

    const [ visible, setVisible ] = useState( values.length > 0 ? true : false )
    const [scrollWithStep, setScrollWithStep] = useState(scrollTo || 0);

    useEffect(() => {
        setVisible(values.length > 1 ? true : false )

    },[values.length])

    useEffect(() => {
       
        document.getElementById('scrollWrapper').scrollTop = 50 * (index );
    },[index,step])

    const scrollUp = (event) => {
        event.preventDefault()
       
        document.getElementById('scrollWrapper').scrollTop += 50;
    }

    const scrollDown = (event) => {
        event.preventDefault()
        
        document.getElementById('scrollWrapper').scrollTop -= 50;
    }

    

    
    return (
        <OuterWrapper  >
        <ScrollButton
            visible={visible}
            name='scrollUp'
            onClick={scrollUp} rotate={'180deg'}></ScrollButton>
        <Wrapper id="scrollWrapper" index={index}>
            <ScrollWrapper>
                <EmptySpacer />
            
            {
                values.map( (song, idx) => (
                    <SongTile
                    title={song.fileName}
                    key={idx}
                    onClick={removeItem}
                    step={step}
                    currentIndex={index}
                    itemIndex={idx}
                    />
                ))
            }
            <EmptySpacer />
            </ScrollWrapper>
        </Wrapper>
        <ScrollButton
            visible={visible}
            name='scrollDown'
            onClick={scrollDown} />
        </OuterWrapper>
    )
}


export default SongTileContainer
