// const fragmentShader = `uniform sampler2D pointTexture;
// varying vec3 vColor;


// void main() {
// gl_FragColor = vec4( vColor, 1.0 );
// gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );
// }
// `
const fragmentShader = `
uniform vec2 u_resolution;

float circleshape(vec2 position, float radius){
    return length(position - vec2(0.5));
}

void main() {
    vec2 position = gl_FragCoord.xy / u_resolution;
    vec3 color = vec3(0.0);
    float circle = circleshape(position, 0.2);

    color = vec3(circle);

    gl_FragColor =vec4(vec3(1.0,0.0,0.0), (1.0 - circle / 0.3) );
    // gl_FragColor = vec4(vec3(1.0,0.0,0.0), 1.0);
}
`

export default fragmentShader