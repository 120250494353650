import styled from 'styled-components'

export const Wrapper = styled.div`
flex-grow: 1;
height: 100vh;
padding-top: var(--navBarHeight);
display: flex;
justify-content: center;
align-items: flex-start;
object{
    height: 100%;
    width: 100%;
}
`;