import React, { useState, useEffect, useRef, useMemo, Suspense, forwardRef } from 'react'
import * as THREE from 'three'
import axios from 'axios'
import { Canvas, extend, useFrame, useLoader } from '@react-three/fiber'
// import { TextureLoader } from "three/src/loaders/TextureLoader";
import { Sphere as Sphere2, PositionalAudio, useTexture, useGLTF, Environment, Loader, PerspectiveCamera } from '@react-three/drei';
import { EffectComposer, DepthOfField, Bloom, Noise, Vignette } from '@react-three/postprocessing'
import SimplexNoise from 'simplex-noise';
import { KernelSize } from 'postprocessing'
import glsl from 'babel-plugin-glsl/macro'
import LoadingWheel from '../../LoadingWheel'
import { useDash } from '../../../hooks/useDash'
import { useAuth } from '../../../contexts/AuthContext'
import SpriteWarmTone from '../../../textures/spark1.png'
import SpriteCoolTone from '../../../textures/spark3.png'
import Sprite from '../../../textures/spark5.png'
import Sprite6 from '../../../textures/spark6.png'
import vertexShader from '../../../shaders/vertex.js'
import fragmentShader from '../../../shaders/fragment.js'
import vertexShader3 from '../../../shaders/vertex3.js'
import fragmentShader3 from '../../../shaders/fragment3.js'
import vertexShader4 from '../../../shaders/vertex4.js'
import fragmentShader4 from '../../../shaders/fragment4.js'

import song from '../../../images/Sante.mp3'

import { roundValue } from '../../../helpers'


//initialise simplex noise instance
var noise = new SimplexNoise();
var noise_level = 0.00001;

let fHighThresh = 20;
let fLowThresh = 105;

const avg = (array) => array.reduce((a, b) => a + b) / array.length;

const filterOutliers = (array) => {
    // Copy the values, rather than operating on references to existing values
    var values = array;
    if (values.length < 4)
        return array
    // Then sort
    values.sort(function (a, b) {
        return a - b;
    });


    /* Then find a generous IQR. This is generous because if (values.length / 4) 
     * is not an int, then really you should average the two elements on either 
     * side to find q1.
     */
    var q1 = values[Math.floor((values.length / 4))];
    // Likewise for q3. 
    var q3 = values[Math.ceil((values.length * (3 / 4)))];
    var iqr = q3 - q1;

    // Then find min and max values
    var maxValue = q3 + iqr * 1.5;
    var minValue = q1 - iqr * 1.5;

    // Then filter anything beyond or beneath these values.
    var filteredValues = values.filter(function (x) {
        return (x <= maxValue) && (x >= minValue);
    });

    // Then return
    return filteredValues;
}

// const Audio = forwardRef(({ listener, track, volume, ...props }, ref) => {

//     // const setLoaded = useMusicStore((state) => state.setLoaded);
//     // const init = useMusicStore((state) => state.init);

//     // const buffer = useLoader(AudioLoader, urls[track], null, (xhr) => {
//     //     if (xhr.loaded === xhr.total) {
//     //         setLoaded(track, true);
//     //     }
//     // });

//     useEffect(() => {
//         const sound = ref.current;
//         if (sound ) {
//             sound.setBuffer(buffer);
//             sound.setLoop(false);
//             sound.setVolume(volume);
//             sound.play();
//         }

//         return () => {
//             if (sound && init) {
//                 sound.stop();
//                 sound.disconnect();
//             }
//         };
//     }, [buffer, listener]);

//     return <audio ref={ref} args={[listener]} {...props} />;
// });


const Analyzer2 = ({ sound, positionArray, sphere, controls, canvasRef }) => {

    const mesh = useRef();
    const analyzer = useRef();

    const audioRef = useRef(null)
    const shaderMaterialRef = useRef(null)
    const colorAttrRef = useRef(null)
    const pointsRef = useRef(null)
    const isRainbow = useRef(false)
    const [texture1, texture2] = useTexture([SpriteWarmTone, SpriteCoolTone])
    const {
        'Particles': { pointSize: { value: pointSize }, sprites: { value: texture }, },
        'Orb': { radius: { value: radius }, detail: { value: detail }, },
        'Orb Distortion': { waves: { value: waviness }, },
        'Sound': { bassInfluence: { value: bassInfluence }, trebleInfluence: { value: trebleInfluence }, fftSize: { value: fftSize } } } = controls
    // console.log("bassInfluence", bassInfluence, trebleInfluence, visualizerControls['Orb'].radius.value, radius)
    const generateSizeGeo = (geom) => {
        const sizes_X = [];
        const { array } = geom.attributes.position;
        for (let i = 0; i < array.length; i++) {
            sizes_X.push(pointSize * 4);
            // sizes_X.push(world.textureSprites.size * 4);// * 5 );
        }
        geom.setAttribute('size', new THREE.Float32BufferAttribute(sizes_X, 1))
    }

    const shaderMaterialProps = useMemo(() => {

        return ({
            uniforms: {
                time: { value: 0.0 },
                pointTexture: { value: texture === 'texture1' ? texture1 : texture2 },
                color: { value: 0xffffff },
                alpha: { value: 1.0 },
                size: { value: pointSize },
                isRainbow: { value: texture === 'texture1' ? 0.0 : 1.0 },
                // iResolution: { value: [window.innerWidth, window.innerHeight, 1.0] },
                iResolution: { value: { x: window.innerWidth, y: window.innerHeight, z: 1.0 } },
            },

            depthTest: false,
            transparent: true,
            blending: THREE.AdditiveBlending,
            vertexColors: true,
            // fragmentShader: fragmentShader3,
            // vertexShader: vertexShader3,
            // fragmentShader: fragmentShader4,
            // vertexShader: vertexShader4,
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
        })


    }, [])
    ////////////////////////////////////////////////////////////////////////////////////////


    const editFFTsize = () => {
        // if (analyzer.current)
        //     analyzer.current.analyser.fftSize = Math.pow(2, parseInt(world.sound.fftSize))
    }

    const roughenBall = (mesh, bassFr, treFr) => {
        const { array, originalPosition } = mesh.geometry.attributes.position;
        for (let i = 0; i < array.length; i += 3) {
            var offset = radius;

            // make amp small (~1) if using bassFr in the noise function
            var amp = 9;//7;
            var time = window.performance.now();
            var rf = noise_level * waviness;
            // normalize
            let magnitude = Math.sqrt(array[i] * array[i] + array[i + 1] * array[i + 1] + array[i + 2] * array[i + 2])

            array[i] = array[i] / magnitude //|| 0;
            array[i + 1] = array[i + 1] / magnitude //|| 0;
            array[i + 2] = array[i + 2] / magnitude //|| 0;

            var distance = (offset + bassFr) + noise.noise3D(array[i] + time * rf * 7, array[i + 1] + time * rf * 8, array[i + 2] + time * rf * 9) * amp * treFr;// * treFr;
            if (bassFr > .8) {

            }

            array[i] = array[i] * Math.abs(distance);
            array[i + 1] = array[i + 1] * Math.abs(distance);
            array[i + 2] = array[i + 2] * Math.abs(distance);

        }


        mesh.geometry.attributes.position.needsUpdate = true;
    }


    useEffect(() => {
        const init = () => {
            analyzer.current = new THREE.AudioAnalyser(sound.current, Math.pow(2, fftSize))
            analyzer.current.analyser.smoothingTimeConstant = 0.6;
        }
        init()
    }, [sound]);


    const [trebleIndices, midIndices, bassIndices] = useMemo(() => {
        if (analyzer.current) {
            analyzer.current.analyser.fftSize = Math.pow(2, parseInt(fftSize));
        }
        const frequencyBinCount = Math.pow(2, fftSize) / 2; //length of fft array
        var sampleRate = 48000;
        const frequencyBin = (sampleRate / 2) / Math.pow(2, fftSize) //23.4

        const bassLow = 23.4 //Hz
        const bassHigh = 257.4 - 23.4 * 5;
        const trebleLow = 2012.4 // 23.4 * 86
        const maxIndex = sampleRate / 2
        const _bassIndices = [Math.floor(bassLow / frequencyBin), Math.ceil(bassHigh / frequencyBin)]//(bassHigh / frequencyBin - 1)]// base range is 23 - 257.4Hz
        const _trebleIndices = [Math.floor(trebleLow / frequencyBin), frequencyBinCount - 1]
        const _midIndices = [_bassIndices[1] + 1, _trebleIndices[1] - 50]

        return [_trebleIndices, _midIndices, _bassIndices]
    }, [fftSize])
    console.log(trebleIndices, midIndices, bassIndices)

    useFrame(() => {
        if (analyzer.current && sound.current) {

            const _data = analyzer.current.getFrequencyData();
            var lowerSpectrum = _data.slice(bassIndices[0], bassIndices[1]).filter(item => (item > fLowThresh));
            // var lowAVG = lowerSpectrum.length > 0 ? parseInt(avg(lowerSpectrum)) : 0;
            var lowAVG = lowerSpectrum.length > 0 ? parseInt(avg(filterOutliers(lowerSpectrum))) : 0;
            var upperSpectrum = _data.slice(midIndices[0], trebleIndices[1]).filter(item => (item > fHighThresh));
            // var upperAVG = upperSpectrum.length > 0 ? parseInt(avg(upperSpectrum)) : 0;
            var upperAVG = upperSpectrum.length > 0 ? parseInt(avg(filterOutliers(upperSpectrum))) : 0;

            roughenBall(
                pointsRef.current,
                adjustScale(lowAVG, 0, 255, 0, bassInfluence),
                adjustScale(upperAVG, 0, 255, 0, trebleInfluence));
            pointsRef.current.rotation.z += adjustScale(lowAVG, 0, 255, 0, .05 * bassInfluence / 10)
            if (sound.current.isPlaying) {

                pointsRef.current.rotation.y += 0.005
            }
            //pointsRef.current.instanceMatrix.needsUpdate = true

        }
    });
    ///////////////////////////

    const adjustScale = (number, inMin, inMax, outMin, outMax) => {
        return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
    }


    const [positions, sizes] = useMemo(() => {
        const sphere = new THREE.IcosahedronGeometry(radius, detail)
        const geom = sphere.attributes.position.array
        //const color_X = new THREE.Color();
        let particleCoords = [];
        //let colorCoords = [];

        let sizes = Array(geom.length).fill(pointSize * 1);
        for (let i = 0; i < geom.length; i += 3) {
            particleCoords.push(geom[i], geom[i + 1], geom[i + 2])
            //let hue = adjustScale(geom[i] * geom[i + 1] * geom[i + 2], Math.pow(radius, 2), 0, 1);

        }

        let p_geometry = new THREE.BufferGeometry(); //change to const later
        p_geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(particleCoords), 3))

        const positions = p_geometry.attributes.position.array

        return [new Float32Array(positions), new Float32Array(sizes)]
    }, [pointSize, detail])
    // useEffect(() => {
    //     if (pointsRef.current){
    //         const drawCount = 10000; // draw the first 2 points, only
    //         pointsRef.current.geometry.setDrawRange( 0, drawCount );
    //     }

    // },[pointSize, detail])


    useEffect(() => {
        if (pointsRef.current) {
            pointsRef.current.geometry.attributes.size.needsUpdate = true;
            // pointsRef.current.geometry.attributes.color.needsUpdate = true;
            // pointsRef.current.geometry.attributes.position.needsUpdate = true;
            // shaderMaterialRef.current.needsUpdate = true
        }

    }, [sizes, positions])

    useEffect(() => {

        if (pointsRef.current) {

            pointsRef.current.material.uniforms.pointTexture.value = texture === 'texture1' ? texture1 : texture2
            shaderMaterialRef.current.uniforms.pointTexture.value = texture === 'texture1' ? texture1 : texture2
            shaderMaterialRef.current.uniforms.isRainbow.value = texture === 'texture1' ? 0.0 : 1.0
            shaderMaterialRef.current.uniformsNeedUpdate = true
            shaderMaterialRef.current.needsUpdate = true
            pointsRef.current.material.uniformsNeedUpdate = true
        }

    }, [texture])
    useEffect(() => {

        if (pointsRef.current) {
            pointsRef.current.material.uniforms.size.value = pointSize
            shaderMaterialRef.current.uniforms.size.value = pointSize
            shaderMaterialRef.current.uniformsNeedUpdate = true
            shaderMaterialRef.current.needsUpdate = true
            pointsRef.current.material.uniformsNeedUpdate = true
        }

    }, [pointSize])


    return (
        <>
            <points ref={pointsRef}>

                <bufferGeometry ref={colorAttrRef}>
                    <bufferAttribute attach="attributes-position" count={positions.length / 3} array={positions} itemSize={3} />
                    {/* <bufferAttribute attach="attributes-color" count={colors.length / 3} array={colors} itemSize={3} /> */}
                    <bufferAttribute attach="attributes-size" count={sizes.length / 1} array={sizes} itemSize={1} />
                </bufferGeometry>
                <shaderMaterial attach="material" {...shaderMaterialProps} ref={shaderMaterialRef} />

            </points>
        </>
    )
}

const Analyzer3 = ({ sound, positionArray, sphere, controls, canvasRef }) => {

    const mesh = useRef();
    const analyzer = useRef();

    const audioRef = useRef(null)
    const shaderMaterialRef = useRef(null)
    const colorAttrRef = useRef(null)
    const pointsRef = useRef(null)
    const isRainbow = useRef(false)
    const [texture1, texture2] = useTexture([SpriteWarmTone, SpriteCoolTone])

    const {
        'Particles': { pointSize: { value: pointSize }, sprites: { value: texture }, },
        'Orb': { radius: { value: radius }, detail: { value: detail }, },
        'Orb Distortion': { waves: { value: waviness }, },
        'Sound': { bassInfluence: { value: bassInfluence }, trebleInfluence: { value: trebleInfluence }, fftSize: { value: fftSize } } } = controls
    // console.log("bassInfluence", bassInfluence, trebleInfluence, visualizerControls['Orb'].radius.value, radius)
    const generateSizeGeo = (geom) => {
        const sizes_X = [];
        const { array } = geom.attributes.position;
        for (let i = 0; i < array.length; i++) {
            sizes_X.push(pointSize * 4);
            // sizes_X.push(world.textureSprites.size * 4);// * 5 );
        }
        geom.setAttribute('size', new THREE.Float32BufferAttribute(sizes_X, 1))
    }

    const shaderMaterialProps = useMemo(() => {

        return ({
            uniforms: {
                time: { value: 0.0 },
                pointTexture: { value: texture === 'texture1' ? texture1 : texture2 },
                color: { value: 0xffffff },
                alpha: { value: 1.0 },
                size: { value: pointSize },
                isRainbow: { value: texture === 'texture1' ? 0.0 : 1.0 },
                // iResolution: { value: [window.innerWidth, window.innerHeight, 1.0] },
                iResolution: { value: { x: window.innerWidth, y: window.innerHeight, z: 1.0 } },
            },
            size: 10.0,
            depthTest: false,
            transparent: true,
            blending: THREE.AdditiveBlending,
            vertexColors: true,
            fragmentShader: fragmentShader4,
            vertexShader: vertexShader4,
            // fragmentShader: fragmentShader3,
            // vertexShader: vertexShader3,
        })


    }, [])
    ////////////////////////////////////////////////////////////////////////////////////////


    const editFFTsize = () => {
        // if (analyzer.current)
        //     analyzer.current.analyser.fftSize = Math.pow(2, parseInt(world.sound.fftSize))
    }

    const roughenBall = (mesh, bassFr, treFr) => {
        const { array, originalPosition } = mesh.geometry.attributes.position;
        for (let i = 0; i < array.length; i += 3) {
            var offset = radius;

            // make amp small (~1) if using bassFr in the noise function
            var amp = 9;//7;
            var time = window.performance.now();
            var rf = noise_level * waviness;
            // normalize
            let magnitude = Math.sqrt(array[i] * array[i] + array[i + 1] * array[i + 1] + array[i + 2] * array[i + 2])

            array[i] = array[i] / magnitude //|| 0;
            array[i + 1] = array[i + 1] / magnitude //|| 0;
            array[i + 2] = array[i + 2] / magnitude //|| 0;

            var distance = (offset + bassFr) + noise.noise3D(array[i] + time * rf * 7, array[i + 1] + time * rf * 8, array[i + 2] + time * rf * 9) * amp * treFr;// * treFr;
            if (bassFr > .8) {

            }

            array[i] = array[i] * Math.abs(distance);
            array[i + 1] = array[i + 1] * Math.abs(distance);
            array[i + 2] = array[i + 2] * Math.abs(distance);

        }

        mesh.geometry.attributes.position.needsUpdate = true;

    }

    useEffect(() => {
        const init = () => {
            analyzer.current = new THREE.AudioAnalyser(sound.current, Math.pow(2, fftSize))
            analyzer.current.analyser.smoothingTimeConstant = 0.6;
        }
        init()
    }, [sound]);


    const [trebleIndices, midIndices, bassIndices] = useMemo(() => {
        if (analyzer.current) {
            analyzer.current.analyser.fftSize = Math.pow(2, parseInt(fftSize));
        }
        const frequencyBinCount = Math.pow(2, fftSize) / 2; //length of fft array
        var sampleRate = 48000;
        const frequencyBin = (sampleRate / 2) / Math.pow(2, fftSize) //23.4

        const bassLow = 23.4 //Hz
        const bassHigh = 257.4 - 23.4 * 5;
        const trebleLow = 2012.4 // 23.4 * 86
        const maxIndex = sampleRate / 2
        const _bassIndices = [Math.floor(bassLow / frequencyBin), Math.ceil(bassHigh / frequencyBin)]//(bassHigh / frequencyBin - 1)]// base range is 23 - 257.4Hz
        const _trebleIndices = [Math.floor(trebleLow / frequencyBin), frequencyBinCount - 1]
        const _midIndices = [_bassIndices[1] + 1, _trebleIndices[1] - 50]

        return [_trebleIndices, _midIndices, _bassIndices]
    }, [fftSize])
    console.log(trebleIndices, midIndices, bassIndices)

    useFrame(() => {
        if (analyzer.current && sound.current) {

            const _data = analyzer.current.getFrequencyData();
            var lowerSpectrum = _data.slice(bassIndices[0], bassIndices[1]).filter(item => (item > fLowThresh));
            // var lowAVG = lowerSpectrum.length > 0 ? parseInt(avg(lowerSpectrum)) : 0;
            var lowAVG = lowerSpectrum.length > 0 ? parseInt(avg(filterOutliers(lowerSpectrum))) : 0;
            var upperSpectrum = _data.slice(midIndices[0], trebleIndices[1]).filter(item => (item > fHighThresh));
            // var upperAVG = upperSpectrum.length > 0 ? parseInt(avg(upperSpectrum)) : 0;
            var upperAVG = upperSpectrum.length > 0 ? parseInt(avg(filterOutliers(upperSpectrum))) : 0;

            roughenBall(
                pointsRef.current,
                adjustScale(lowAVG, 0, 255, 0, bassInfluence),
                adjustScale(upperAVG, 0, 255, 0, trebleInfluence));
            pointsRef.current.rotation.z += adjustScale(lowAVG, 0, 255, 0, .05 * bassInfluence / 10)
            if (sound.current.isPlaying) {

                pointsRef.current.rotation.y += 0.005
            }
            //pointsRef.current.instanceMatrix.needsUpdate = true
            // if (shaderMaterialRef.current){
            //     shaderMaterialRef.current.size = adjustScale(upperAVG, 0, 255, 0.25, 5.9);
            // }

        }
    });
    ///////////////////////////

    const adjustScale = (number, inMin, inMax, outMin, outMax) => {
        return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
    }


    const [positions, sizes, colors] = useMemo(() => {
        const sphere = new THREE.SphereGeometry(5, 100, 100);
        const geom = sphere.attributes.position.array
        //const color_X = new THREE.Color();
        let particleCoords = [];
        let colorCoords = [];
        const color_X = new THREE.Color(0xff0000);
        var vertex = new THREE.Vector3();
        const bufferArrayLength = 30000
        let sizes = Array(bufferArrayLength).fill(pointSize * 10);
        for (let i = 0; i < bufferArrayLength; i += 3) {
            let hue = adjustScale(geom[i] * geom[i + 1] * geom[i + 2], Math.pow(radius, 2), 0, 1);
            // color_X.setHSL( Math.random(), 1.0, 0.5 );
            color_X.setHSL(0.09, 1.0, 0.65);
            colorCoords.push(color_X.r, color_X.g, color_X.b);

            particleCoords.push(Math.random() * (10) - 5, Math.random() * (10) - 5, Math.random() * (10) - 5)
            // var theta = THREE.Math.randFloatSpread(360);
            // var phi = THREE.Math.randFloatSpread(360);
            // vertex.x = 5.0 * Math.sin(theta) * Math.cos(phi);
            // vertex.y = 5.0 * Math.sin(theta) * Math.sin(phi);
            // vertex.z = 5.0 * Math.cos(theta);
            // particleCoords.push(vertex.x, vertex.y, vertex.z);
        }

        let p_geometry = new THREE.BufferGeometry(); //change to const later
        p_geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(particleCoords), 3))

        const positions = p_geometry.attributes.position.array
        return [new Float32Array(particleCoords), new Float32Array(sizes), new Float32Array(colorCoords)]
    }, [pointSize, detail])
    // useEffect(() => {
    //     if (pointsRef.current){
    //         const drawCount = 10000; // draw the first 2 points, only
    //         pointsRef.current.geometry.setDrawRange( 0, drawCount );
    //     }

    // },[pointSize, detail])


    useEffect(() => {
        if (pointsRef.current) {
            // pointsRef.current.geometry.attributes.size.needsUpdate = true;
            // pointsRef.current.geometry.attributes.color.needsUpdate = true;
            // pointsRef.current.geometry.attributes.position.needsUpdate = true;
            // shaderMaterialRef.current.needsUpdate = true
        }

    }, [sizes, positions])

    // useEffect(() => {

    //     if (pointsRef.current) {

    //         pointsRef.current.material.uniforms.pointTexture.value = texture === 'texture1' ? texture1 : texture2
    //         shaderMaterialRef.current.uniforms.pointTexture.value = texture === 'texture1' ? texture1 : texture2
    //         shaderMaterialRef.current.uniforms.isRainbow.value = texture === 'texture1' ? 0.0 : 1.0
    //         shaderMaterialRef.current.uniformsNeedUpdate = true
    //         shaderMaterialRef.current.needsUpdate = true
    //         pointsRef.current.material.uniformsNeedUpdate = true
    //     }

    // }, [texture])
    // useEffect(() => {

    //     if (pointsRef.current) {
    //         pointsRef.current.material.uniforms.size.value = pointSize
    //         shaderMaterialRef.current.uniforms.size.value = pointSize
    //         shaderMaterialRef.current.uniformsNeedUpdate = true
    //         shaderMaterialRef.current.needsUpdate = true
    //         pointsRef.current.material.uniformsNeedUpdate = true
    //     }

    // }, [pointSize])
    const texture3 = useTexture(Sprite)
    const texture6 = useTexture(Sprite6)
    return (
        <>
            <points ref={pointsRef}>

                <bufferGeometry ref={colorAttrRef}>
                    <bufferAttribute attach="attributes-position" count={positions.length / 3} array={positions} itemSize={3} />
                    <bufferAttribute attach="attributes-color" count={colors.length / 3} array={colors} itemSize={3} />
                    {/* <bufferAttribute attach="attributes-size" count={sizes.length / 1} array={sizes} itemSize={1} /> */}
                </bufferGeometry>
                {/* <shaderMaterial attach="material" {...shaderMaterialProps} ref={shaderMaterialRef} /> */}
                <pointsMaterial attach="material"
                    size={0.05}
                    // size={0.5}
                    sizeAttenuation={true}
                    ref={shaderMaterialRef}
                    vertexColors={true}
                    map={texture6}
                    depthTest={false}
                    opacity={0.95}
                    blending={THREE.AdditiveBlending}
                    // alphaTest={0.5}
                    transparent={true} />

            </points>
            {/* <EffectComposer multisampling={8}>
                <Bloom kernelSize={1} luminanceThreshold={0} luminanceSmoothing={0.4} intensity={20.0} />
                <Bloom kernelSize={KernelSize.HUGE} luminanceThreshold={0} luminanceSmoothing={0} intensity={0.5} />
            </EffectComposer> */}
        </>
    )
}

const Analyzer4 = ({ sound, positionArray, sphere, controls, canvasRef }) => {

    const mesh = useRef();
    const analyzer = useRef();

    const audioRef = useRef(null)
    const shaderMaterialRef = useRef(null)
    const colorAttrRef = useRef(null)
    const pointsRef = useRef(null)
    const isRainbow = useRef(false)
    const [texture1, texture2] = useTexture([SpriteWarmTone, SpriteCoolTone])
    const {
        'Particles': { pointSize: { value: pointSize }, sprites: { value: texture }, },
        'Orb': { radius: { value: radius }, detail: { value: detail }, },
        'Orb Distortion': { waves: { value: waviness }, },
        'Sound': { bassInfluence: { value: bassInfluence }, trebleInfluence: { value: trebleInfluence }, fftSize: { value: fftSize } } } = controls
    // console.log("bassInfluence", bassInfluence, trebleInfluence, visualizerControls['Orb'].radius.value, radius)
    const generateSizeGeo = (geom) => {
        const sizes_X = [];
        const { array } = geom.attributes.position;
        for (let i = 0; i < array.length; i++) {
            sizes_X.push(pointSize * 4);
            // sizes_X.push(world.textureSprites.size * 4);// * 5 );
        }
        geom.setAttribute('size', new THREE.Float32BufferAttribute(sizes_X, 1))
    }

    const shaderMaterialProps = useMemo(() => {

        return ({
            uniforms: {
                time: { value: 0.0 },
                pointTexture: { value: texture === 'texture1' ? texture1 : texture2 },
                color: { value: 0xffffff },
                alpha: { value: 1.0 },
                size: { value: pointSize },
                isRainbow: { value: texture === 'texture1' ? 0.0 : 1.0 },
                // iResolution: { value: [window.innerWidth, window.innerHeight, 1.0] },
                iResolution: { value: { x: window.innerWidth, y: window.innerHeight, z: 1.0 } },
            },
            size: 1.0,
            depthTest: false,
            transparent: true,
            blending: THREE.AdditiveBlending,
            vertexColors: true,
            fragmentShader: fragmentShader4,
            vertexShader: vertexShader4,
            // fragmentShader: fragmentShader3,
            // vertexShader: vertexShader3,
        })


    }, [])


    return (
        <>
            <>
                <Sphere2 args={[10, 10]} >
                    <shaderMaterial attach="material" {...shaderMaterialProps} ref={shaderMaterialRef} />
                </Sphere2>

            </>
        </>
    )
}

const Audio = ({ audioFile, listener, loader, sound, nowPlayingSongId, onSkip }) => {
    const audioRef = useRef(null)

    const onEnded = () => {

        sound.current.isPlaying = false
    }

    useEffect(() => {

        const loading = () => {

            loader.current.load(audioFile, function (buffer) {
                const playAfterReset = sound.current.isPlaying
                console.log("now playing: ", nowPlayingSongId)
                if (sound.current.source)
                    sound.current.stop()
                // sound.current.disconnect()
                sound.current.setBuffer(buffer);
                sound.current.setLoop(false);
                sound.current.setVolume(1.0);
                sound.current.onEnded = () => {
                    console.log("sound has ended(x)")
                    onSkip(1)
                }
                // sound.current.currentTime = 0
                // sound.current.connect()
                // sound.current.source.start(0)
                if (playAfterReset) {
                    sound.current.play()
                }

            });

        }

        if (sound.current)
            loading()
        else {
            console.log("sound current is null")
        }

    }, [audioFile])
    console.log("sound current", sound.current)

    return (<audio ref={sound} url={audioFile} distance={10} args={[listener.current]} loop={false} />)
}

/*
USE Analyzer3 for better performance, but it won't have the rainbow effects and such
Analyzer2 is the original one.
*/
const PlaySound = ({ sound, sphere, controls, audioFile, listener, loader, canvasRef, nowPlayingSongId, ...props }) => {
    console.log("props", props)
    // const getSong = async () => {
    //     const filePath = '/uploads/df0b834a9159ea94b5d3cc8d1ddf0bea.mp3'
    //     let res = await axios({
    //         method: 'get', // & controllers
    //         url: `${process.env.REACT_APP_API_DOMAIN}/getSong?filePath=${filePath}`, //api/config/database.php
    //         responseType: 'blob'
    //     });
    //     console.log("res", res, sound.current)
    //     url.current = URL.createObjectURL(new Blob([res.data]), { type: res.data.type });
    //     const _fileTypeExtension = res.data.type.includes('mpeg') ? 'mp3' : 'wav'
    //     // return url
    // }

    // useEffect(() => {
    //     const handleSong = async () => {
    //         // const res = await getSong()
    //         // setSong(url.current)
    //         // setSong('http://localhost:8056/azaa-curr/uploads/Tuyo.mp3')
    //         console.log("audio file", audioFile)
    //         setSong(audioFile)
    //     }
    //     if (audioFile && song !== audioFile)
    //         handleSong()
    // }, [audioFile])
    // const loader = new THREE.AudioLoader();
    // loader.setCrossOrigin()

    // useEffect(() => {
    //     const setup = () => {
    //         loader.current.load(audioFile, function (buffer) {
    //             console.log("setting buffer...", buffer, audioFile)
    //             sound.current.setBuffer(buffer);
    //             // sound.current.setAutoplay(false)
    //             sound.current.setLoop(false);
    //             sound.current.setVolume(1.0);
    //             if (sound.current.isPlaying) {
    //                 sound.current.pause()
    //                 sound.current.play()
    //             }
    //             // sound.current.play();
    //             sound.current.onEnded = () => { console.log("song has ended") }

    //         });
    //         setSong(audioFile)
    //         // sound.current.setBuffer(buffer)
    //         // sound.current.setRefDistance(1)
    //         // sound.current.setLoop(false)
    //         // sound.current.setAutoplay(true)
    //         // if (sound.current.source)
    //         //     sound.current.source.onended = () => {
    //         //         console.log("ended")
    //         //         if (url.current)
    //         //             URL.revokeObjectURL(url.current) //if blob
    //         //     }
    //     }
    //     if (sound.current && audioFile && loader.current) {
    //         console.log("triggered")
    //         setup()
    //     }

    // }, [sound, audioFile])

    // useEffect(() => {

    //     const loading = () => {
    //         console.log("loading!")
    //         loader.current.load(audioFile, function (buffer) {
    //             // console.log("setting buffer:... (3)", buffer, audioFile, sound.current)
    //             sound.current.setBuffer(buffer);
    //             sound.current.setLoop(false);
    //             sound.current.setVolume(1.0);
    //             sound.current.onEnded = () => { console.log("sound has ended") }
    //             // sound.curreny.play()

    //         });
    //         console.log("loaded!")
    //     }
    //     if (sound.current && audioFile)
    //         loading()
    //     else {
    //         console.log("sound current is null")
    //     }

    // }, [audioFile])

    const onEnded = () => {
        console.log("SONG ENDED")
        sound.current.isPlaying = false
    }
    // if (song === undefined)
    //     return <></>
    return (
        <>
            <Suspense fallback={null}>
                <Audio sound={sound} audioFile={audioFile} nowPlayingSongId={nowPlayingSongId} listener={listener} loader={loader} distance={10} args={[listener.current]} loop={false} {...props} />
                {/* <PositionalAudio ref={sound} url={audioFile} distance={10} args={[listener.current]} loop={false}
                    onEnded={onEnded} /> */}
                {/* <Analyzer4 sound={sound} sphere={sphere} canvasRef={canvasRef} controls={controls} /> */}
                {/* <Analyzer3 sound={sound} sphere={sphere} canvasRef={canvasRef} controls={controls} /> */}
                <Analyzer2 sound={sound} sphere={sphere} canvasRef={canvasRef} controls={controls} />
            </Suspense>
        </>
    );
}

const Sphere = ({ sphere, sound, controls, ...props }) => {
    // const { currentUser } = useAuth()

    return (
        <>
            <PlaySound sound={sound} sphere={sphere} controls={controls} {...props} />
            <pointLight position={[0, 0, 20]} intensity={1} color="#fff" />
            <pointLight position={[20, 20, -20]} intensity={0.5} color="#fff" />
            <directionalLight />
        </>

    )
}

export default Sphere
