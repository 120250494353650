import React, { useState, useEffect } from 'react'
import { MobileMenuWrapper, MobileBurger, Nav, NavLink, NavLinkHome, NavMenu, NavBtn, NavBtnLink, HomeContainer, Hamburger } from './NavbarElement.styles';

import LoginButton from '../LoginButton'
import HamburgerMenu from '../HamburgerMenu'
import LoginOverlay from '../LoginOverlay'

import Logo from '../../images/headerLogo.svg'

import {
    LOGIN_POPUP,
    SIGNUP_POPUP,
    SET_PASSWORD_POPUP,
    LOGOUT
} from '../../config.js'

import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'

const Navbar = () => {
    const [visitorStatus, setVisitorStatus] = useState('new')
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
    const [error, setError] = useState('')
    const [popupType, setPopupType] = useState(SIGNUP_POPUP)
    const location = useLocation();

    const navigate = useNavigate()
    const { currentUser, logout, pastLoginExists, loadingUser } = useAuth();
    useEffect(() => {
        if (currentUser !== null) {
            setPopupType(LOGOUT)
        }
        else if (pastLoginExists !== null) {
            setPopupType(LOGIN_POPUP)
        }
    }, [currentUser])

    const handleLogout = () => {
        setError('')
        logout()
            .then((userCred) => {
                navigate('/')
                setPopupType(LOGIN_POPUP)
            })
            .catch((error) => {
                const errorCode = error.code;
                setError(error)
            })
    }

    const [showLogin, setShowLogin] = useState(false)

    useEffect(() => {
        if (isMobileMenuOpen){
            setIsMobileMenuOpen(false)
        }
    },[location])

    // if (loadingUser) {
    //     return <></>
    // }
    //else 
    // if (location.pathname.includes('cart'))
    // return <></>
    if (currentUser !== null) {
        return (

            <Nav >
                <HomeContainer>
                    {
                        currentUser !== null &&
                        <NavLinkHome to="/">
                            Azaa
                        </NavLinkHome>
                    }
                    {
                        currentUser === null &&
                        <NavLinkHome to="/">
                            Azaa
                        </NavLinkHome>
                    }
                </HomeContainer>
                {/* <Bars></Bars> */}
                <Hamburger><HamburgerMenu /></Hamburger>
                <MobileBurger><img src={Logo} alt="menu" /></MobileBurger>
            </Nav>

        )
    }
    else {
        return (
            <>
                {showLogin &&
                    (<LoginOverlay
                        setShowLogin={setShowLogin}
                        popupType={popupType}
                        setPopupType={setPopupType}
                    />)

                }
                <Nav >
                    <HomeContainer>
                        {
                            currentUser !== null &&
                            <NavLinkHome to="/">
                                Azaa
                            </NavLinkHome>
                        }
                        {
                            currentUser === null &&
                            <NavLinkHome to="/">
                                Azaa
                            </NavLinkHome>
                        }
                    </HomeContainer>
                    {/* <Bars></Bars> */}
                    <NavMenu>
                        <NavLink to="/about" >About</NavLink>
                        <NavLink to="/the-founders" >Who We Are</NavLink>
                        <NavLink to="/faq" >FAQ</NavLink>
                        <NavLink to="/contact-us" >Contact Us</NavLink>
                    </NavMenu>

                    <NavBtn>
                        <MobileMenuWrapper isOpen={isMobileMenuOpen}>
                            <NavLink to="/" >Home</NavLink>
                            <NavLink to="/about" >About</NavLink>
                            <NavLink to="/the-founders" >Who We Are</NavLink>
                            <NavLink to="/contact-us" >Contact Us</NavLink>
                            <NavLink to="/faq" >FAQ</NavLink>
                        </MobileMenuWrapper>
                        <MobileBurger onClick={() => { setIsMobileMenuOpen(!isMobileMenuOpen) }}><img src={Logo} alt="menu" /></MobileBurger>
                        <NavBtnLink
                            // to="/signin"
                            to="/"
                            onClick={currentUser === null ? event => (setShowLogin(!showLogin)) : handleLogout}
                        ><img src={Logo} alt="logo-login-button" /><span>{popupType}</span></NavBtnLink></NavBtn>
                </Nav>
            </>
        )
    }

}

export default Navbar
