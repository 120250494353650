import styled from "styled-components";

export const Wrapper = styled.div`
display: grid;
user-select: none;
position: relative;
width: ${props => props.width ? props.width : '100%'};
height: ${props => props.height ? props.height : ''};
min-height: ${props => props.minHeight ? props.minHeight : ''};
font-size: ${props=> props.fontSize ? props.fontSize : '14px'};
padding: ${props => props.padding ? props.padding : ''};
grid-template-columns: ${props => props.gridTemplateColumns ? props.gridTemplateColumns : ''};
grid-column-gap: ${props => props.gridColGap ? props.gridColGap : '10px'};
text-transform: ${props => props.isHeader ? 'uppercase' : 'undefined' };
background: ${props => props.rowHighlight ? props.rowHighlight.background : 'transparent'};
border: ${props => props.border ? props.border : '1px inset solid #000000'};
font-weight: ${props => props.rowHighlight ? props.rowHighlight.fontWeight : ''};
color: ${props => props.rowHighlight ? props.rowHighlight.textColor : '#ffffff'};
&:hover{
    background: ${props => props.isHeader || props.noHover ? 'transparent' && props.rowHighlight === undefined : props.rowHighlight ? props.rowHighlight.background : 'rgba(255,255,255,0.15)' };
}
&:hover >div{
    visibility: visible;
}

`;

export const RowElementWrapper = styled.div`
    display: flex;
    align-items: center;
    visibility: ${props => props.hideOffHover === true && props.showMenu === false ? 'hidden' : 'visible'};

    justify-content: ${ props => props.align ? props.align : 'flex-start'};
    text-align: ${ props => 
        props.align ? 
        props.align === 'flex-start' ? 'left' 
        : props.align === 'flex-end' ? 'right' 
        : props.align === 'center' ? 'center' : 'left' 
        : 'left'};


`;