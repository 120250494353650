import styled from 'styled-components'

export const DropDownContainerBox = styled.div`
/* right: 30px;
position: absolute;
margin-top: 30px; */
`;

export const DropDownNavBtn = styled.div`
width: 25px;
height: 25px;
display: flex;
align-items: center;
position: relative;
background: transparent;
display: flex;
cursor: pointer;

 svg{

     position: relative;
     visibility: visible;
 }
    span{
        color: #000;
    white-space: nowrap;
    height: 100%;
    position: relative;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    font-size: inherit;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: .02rem;
    background: var(--white);
    margin-left: 2px;
    border-radius: 2px;
    font-weight: 400; 
    }


`

export const DropdownList = styled.ul`
margin: 0;
margin-top: ${props => props.marginTop ? props.marginTop : ''};
padding: 0;
background: ${props => props.listBackground ? props.listBackground : 'var(--dropdownBackground)'};
box-shadow: ${props => props.boxShadow ? props.boxShadow : "0 0 10px rgb(0,0,0)"};
position: absolute;
border: ${props => props.border ? props.border : ''};
width: 100%;
z-index: 2;
height: min-content;
max-height: ${({ maxHeight }) => maxHeight};
overflow: auto;
`;

export const DropdownListContainer = styled.div`
    
`;

export const DropdownContainer = styled.div`
    height: 100%;
    width: ${props => props.width ? props.width : '100%'};
    position: relative;
    background: ${props => props.background ? props.background : 'var(--grey)'};
    /* text-transform: uppercase; */
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    border: ${props => props.border ? props.border : ''};
font-size: 10px;
font-weight: 500;
letter-spacing: 0.1rem;
position: relative;
    
`;

export const ListItem = styled.li`
    height: ${props => props.height ? props.height : '40px'};
    list-style: none;
    display: flex;
    position: relative;
    letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : ''};
    font-size: ${props => props.fontSize ? props.fontSize : ''};
    font-weight: ${props => props.fontWeight ? props.fontWeight : ''};
    align-items: center;
    margin: 0;
    padding: ${props => props.itemPadding ? props.itemPadding : '0 20px'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-end'};
    user-select: none;
    cursor: pointer;
    background: ${props => props.isSelected ? 'rgba(255,255,255,0.2)' : 'transparent'};
    color: ${props => props.isSelected ? props.colorOnSelect ? props.colorOnSelect : 'rgb(255,255,255)' : props.color ? props.color : 'var(--purpleGrey)'};
    :hover{
        background: rgba(255,255,255,0.2);
    }
`;

export const BackgroundWrapper = styled.div`
position: fixed;
top: 0;
right: 0;
width: 100%;
height: 100%;
background: var(--purpleGrey);
overflow: hidden;
z-index: 1;
`;