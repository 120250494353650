import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary'
// Components
import Navigation from './components/Navbar';
import Cart from './components/Cart';
import AnimatedCheckIcon from './components/AnimatedCheckIcon'
import Form from './components/UploadForm/Form';
import About from './components/About'
import AccountSettings from './components/AccountSettings';
import Account from './components/Account';
import ContactUs from './components/ContactUs'
import Sphere from './components/Sphere'
import FAQ from './components/FAQ';
import Help from './components/Help';
import Button from './components/Button';
import TermsAndConditions from './components/TermsAndConditions'
import PrivacyPolicy from './components/PrivacyPolicy'
import SellerAgreement from './components/SellerAgreement'
import Login from './components/Login';
import Test from './components/Test';
import Dashboard from './components/Dashboard'
import BuyerDashboard from './components/BuyerDashboard'
import Messages from './components/Messages'
import Flexbox from './components/Flexbox'
import Table from './components/Table2'
import TableRow from './components/TableRow'
import TableCell from './components/TableCell'
import SetSplits from './components/SetSplits'
import Home from './components/Home'
import TheFounders from './components/TheFounders'
import Register from './components/Register'
import ScrollToTop from "./components/scrollToTop";
import PrivateRoute from './components/PrivateRoute'
import NotFound from './components/NotFound'
import Maintenance from './components/Maintenance';
import Visualizer from './components/Visualizer'
import BuyerApplicationForm from './components/BuyerApplicationForm'
import TransactionHistory from './components/TransactionHistory'
// Contexts
import { AuthProvider } from './contexts/AuthContext'
import { MessengerProvider } from './contexts/MessengerContext'

// CSS
import { GlobalStyle } from './GlobalStyle';

import { updateRelTime } from './utils'

import dayjs from 'dayjs'

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const date = new Date(Date.now()).toString()
  const emailBody = `
  I encountered a site error.
  \n
  Page I was trying to access: [Please enter the url you were trying to access here.]\n
  Time Error Occurred: ${date}\n
  Error Name: ${error.name}\n
  Error Message: ${error.message}\n`.replaceAll(' ', '%20').replaceAll(/"/g, '%22').replaceAll('\n', '%0D%0A')
  return (
    <Flexbox column height="100vh" center>
      <h2 style={{ textTransform: 'none' }}>An error occurred.</h2>
      <h3>If this error persists, please contact us and provide the following details:</h3>
      <Flexbox column padding="20px" center width="300px" relative>
        <Table hoverOff={true} borderCollapse="unset" width="300px" borderSpacing="0">
          <TableRow userSelect="text" hoverOff={true}>
            <TableCell background="#1A1A1A" whiteSpace="nowrap" border="1px solid #000">
              <h4>Time Occurred</h4>
            </TableCell>
            <TableCell background="var(--greyBackground)" border="1px solid #000">
              <p>{date}</p>
            </TableCell>
          </TableRow>
          <TableRow border="1px solid #fff" hoverOff={true} userSelect="text" >
            <TableCell whiteSpace="nowrap" background="#1A1A1A" border="1px solid #000">
              <h4>Error Name</h4>
            </TableCell>
            <TableCell userSelect="text" background="var(--greyBackground)" border="1px solid #000">
              <p>{error.name}</p>
            </TableCell>
          </TableRow>
          <TableRow userSelect="text" hoverOff={true}>
            <TableCell background="#1A1A1A" whiteSpace="nowrap" border="1px solid #000">
              <h4>Error Message</h4>
            </TableCell>
            <TableCell background="var(--greyBackground)" border="1px solid #000">
              <p>{error.message}</p>
            </TableCell>
          </TableRow>

        </Table>
      </Flexbox>
      <Button text="Contact Us" border="var(--buttonBorder)" width="200px" onClick={() => {
        // window.open('/contact-us', '_blank')
        window.open(`mailto:info@azaamusic.com?subject=Bug%20Report&body=${emailBody}`, '_self')
      }} />
      <GlobalStyle />
    </Flexbox>
  )
}

function App() {
  updateRelTime()
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      // onError={(error, errorInfo) => errorService.log({ error, errorInfo })}
      onReset={() => {
        // reset the state of your app
      }}>
      <AuthProvider>
        <MessengerProvider>
          <div className="App">
            <Router>
              <ScrollToTop />
              <Navigation />
              <Routes>
                {/* <Route path="/" exact component={Home} /> */}
                <Route path="/the-founders" exact element={<TheFounders />} />
                <Route path="/test" exact element={<Test />} />
                <Route path="/cart" exact element={<Cart />} />
                <Route path="/" exact element={<Home />} />
                <Route path="/dashboard" element={<PrivateRoute />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                </Route>
                <Route path="/buyerdash" element={<PrivateRoute />}>
                  <Route path="/buyerdash" element={<BuyerDashboard />} />
                </Route>
                <Route path="/recently-approved" element={<PrivateRoute />}>
                  <Route path="/recently-approved" exact element={<SetSplits />} />
                </Route>
                <Route path="/messages" element={<PrivateRoute />}>
                  <Route path="/messages" exact element={<Messages />} />
                </Route>
                <Route path="/account" element={<PrivateRoute />}>
                  <Route path="/account" exact element={<AccountSettings />} />
                </Route>
                <Route path="/transactions" element={<PrivateRoute />}>
                  <Route path="/transactions" exact element={<TransactionHistory />} />
                </Route>
                <Route path="/account2" element={<PrivateRoute />}>
                  <Route path="/account2" exact element={<Account />} />
                </Route>
                <Route path="/upload" element={<PrivateRoute />}>
                  <Route path="/upload" exact element={<Form />} />
                </Route>
                <Route path="/get-started" exact element={<Form />} />
                <Route path="/contact-us" exact element={<ContactUs />} />
                <Route path="/register/:regKey" exact element={<Register />} />
                <Route path="/about" exact element={<About />} />
                <Route path="/test" exact element={<AnimatedCheckIcon />} />
                <Route path="/faq" exact element={<FAQ />} />
                <Route path="/help" exact element={<Help />} />
                <Route path="/login" exact element={<Login />} />
                <Route path="/buyer-application" exact element={<BuyerApplicationForm />} />
                <Route path="/terms" exact element={<TermsAndConditions />} />
                <Route path="/azaa-seller-agreement" exact element={<SellerAgreement />} />
                <Route path="/tempvisualizer" element={<Visualizer />} />
                <Route path="/sphere" element={<Sphere />} />
                <Route path="/privacy" exact element={<PrivacyPolicy />} />
                {/* <Route path="/maintenance" exact element={<Maintenance />} /> */}
                <Route path="*" element={<NotFound />} />

              </Routes>
            </Router>
            <GlobalStyle />
          </div>
        </MessengerProvider>
      </AuthProvider>
    </ErrorBoundary>

  );
}



export default App;
