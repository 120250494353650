import React, { useState, useEffect } from 'react'
import anime from 'animejs'
const AnimatedCheckIcon = ({ className }) => {
    useEffect(() => {
        const animation = anime({
            targets: `.${className} .checkmark`,
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeOutSine',
            duration: 500,
            delay: function (el, i) { return i * 250 },
            // direction: 'alternate',
            loop: false
        });
        if (className)
            animation.play()

    }, [className])
    if (!className)
        return <></>
    return (
        <div style={{ height: '15px', width: '15px', objectFit: 'contain', position: 'relative' }} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 28" style={{ height: '100%', width: '100%', objectFit: 'contain' }}>
                <g style={{ fill: 'none', stroke: 'var(--accountExistsBlue)', strokeWidth: '2px' }}>
                    <g >
                        <circle className="checkmark" cx="13" cy="13" r="12.5" />
                        <polyline className="checkmark" points="6.5 13.21 10.7 17.4 19.5 8.6" />
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default AnimatedCheckIcon