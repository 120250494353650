import { useState, useEffect, useRef } from 'react';

import {
    ACCEPTED_SONGS,
    SONG_ID_SPECIFIED,
    USER_ID_SPECIFIED,
    AZAA_PLATFORM_FEE_PERCENT
} from '../config';

import API from '../API';
import { useAuth } from '../contexts/AuthContext'
import { isBuyer, calculatePriceWithFee } from '../helpers'
import { useInterval } from './useInterval'

export const useMessengerFetch = () => {
    const { currentUser } = useAuth();
    const [songs, setSongs] = useState();
    const [songDetails, setSongDetails] = useState(null)
    const [loading, setLoading] = useState(false);
    const [loadingSideBar, setLoadingSideBar] = useState(false);
    const [messageHeaders, setMessageHeaders] = useState([]);
    const [messageInput, setMessageInput] = useState({
        song_id: '',
        songwriter_id: '',
        sender_id: '',
        receiver_id: '',
        message: ''
    });
    const [messages, setMessages] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState({ user_id: '', song_id: '', song: '' });
    const [user, setUser] = useState({ user_id: null, first_name: '', last_name: '' });
    const [error, setError] = useState(false);
    const [userIDs, setUserIDs] = useState([])
    const initial = useRef(true)
    const initialSelectedArtist = useRef(true)
    const [linkheaders, setLinkheaders] = useState({ links: [], selectedArtist: { acceptedSongs: [] } })
    const resultsPerPage = 20
    const [page, setPage] = useState(1)
    const [totalResults, setTotalResults] = useState(resultsPerPage)
    useEffect(() => {
        if (currentUser !== null && currentUser.userDetails !== undefined) {
            setMessageInput(prev => ({ ...prev, sender_id: currentUser.userDetails.id }))
            // if (currentUser.accessToken !== undefined)
            // fetchSongs(currentUser.accessToken)
        }
    }, [currentUser])
    // const fetchSongs = async (accessToken) => {

    //     try {
    //         setError(false);
    //         setLoading(true);
    //         const fetched_songs = await API.fetchSongs(accessToken, currentUser.email);
    //         setSongs([...fetched_songs]);
    //         const grouped_arr = fetched_songs.reduce((s, k) => {
    //             s[k.user_id] = s[k.user_id] || []
    //             s[k.user_id].push(k)
    //             return s
    //         }, Object.create(null))
    //         const uniqueListOfLinkHeaders = [...new Map(fetched_songs.map(item => [item.user_id, item])).values()].map(item => ({ email: item.email, user_id: item.user_id, acceptedSongs: [] }))

    //         setLinkheaders(prev => ({
    //             ...prev,
    //             links: uniqueListOfLinkHeaders
    //         })
    //         )
    //     } catch (error) {
    //         setError(true);
    //         console.log("error", error)
    //     }

    //     setLoading(false);
    //     setLoadingSideBar(false)
    // }; //fetchMessageStreamTabs
    const fetchMessageHeaders = async () => {
        API.fetchMessageStreamTabs(currentUser.accessToken, currentUser.email, page, resultsPerPage, null, null).then((res) => {
            const [mh, total] = res
            console.log("res is", res)
            setMessageHeaders([...mh])
            setTotalResults(total)
        }).catch((e) => { console.log(e) })

    }

    const fetchMessages = async (songID = 1) => {
        try {
            setError(false);
            setLoading(true);
            //const [messagesX, u, song] = await API.fetchMessages(currentUser.accessToken, currentUser.email, songID)
            const data = await API.fetchMessages(currentUser.accessToken, currentUser.email, songID)
            //[res.data.messages, res.data.user, res.data.song]
            console.log("data is", data)
            setMessages(data.messages)
            if (data.song !== null && !isBuyer(currentUser)) {
                setSongDetails(
                    {
                        ...data.song,
                        showTrailing: data.song.price === null || data.song.price === undefined || data.song.contributors.length === 0 ? false
                            : parseFloat(data.song.price.replace(/,/g, '')) % 1 ? true
                                : (parseFloat(data.song.price.replace(/,/g, '')) * (AZAA_PLATFORM_FEE_PERCENT) / 100.00) % 1 ? true
                                    : data.song.contributors.map(c => parseFloat(data.song.price.replace(/,/g, '')) * parseFloat(c.split) / 100.00).reduce((a, b) => { return b % 1 ? true : a }, false)
                    })

            } if (data.song !== null && isBuyer(currentUser)) {
                console.log("data song", data.song)
                setSongDetails(
                    {
                        ...data.song,
                        price: data.song.price
                    })
            } else {
                setSongDetails(data.song)
            }
            setUser({ ...data.user })
            setLoading(false)
            console.log(data.user, data.messages)
            if (data.user.user_id === data.messages[0].receiver_id && data.messages[0].is_new === '1') {
                setTimeout(() => {
                    console.log("sending")
                    API.updateMessageReadStatus(data.messages[0].song_id, data.messages[0].receiver_id, data.user.user_id)
                    setMessageHeaders(prev => [...prev.map(it => it.song_id === data.messages[0].song_id ? { ...it, is_new: "0" } : { ...it })])
                }, 500)
            }
        } catch (error) {
            setError(true);
            console.log(error)
            setLoading(false)
        }

    }

    useEffect(() => {
        setLoadingSideBar(true)
    }, [])

    const handleChange = (event) => {
        setMessageInput(prev => ({ ...prev, message: event.target.value }))
    }


    const sendMessage = async () => {
        if (messageInput.message.replace(/\s/g, '').length) {
            try {
                setError(false);
                setLoading(true);
                const res = await API.sendMessage(currentUser, messageInput.song_id, messageInput.message);
                fetchMessages(selectedMessage.song_id)

            } catch (error) {
                setError(true);

            }
            setLoading(false);
        }

    };
    const continueKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            sendMessage().then(() => { setMessageInput((prev) => ({ ...prev, message: "" })) });
        }
    }




    useEffect(() => {
        if (initialSelectedArtist.current) {
            initialSelectedArtist.current = false;
            return;
        }
        if (messageHeaders.length > 0 && !messageHeaders.find(h => h.song_id === selectedMessage.song_id)) {
            //if (selectedMessage === '')
            setSelectedMessage({ user_id: messageHeaders[0].user_id, song_id: messageHeaders[0].song_id, song: messageHeaders[0].song })
            //else {
            //const _selectedMessage = selectedMessage;
            //setSelectedMessage(_selectedMessage)
            //}

        }



    }, [messageHeaders])


    useEffect(() => {

        if (selectedMessage.song_id !== undefined && currentUser.userDetails !== undefined && messageHeaders.length > 0) {
            fetchMessages(selectedMessage.song_id)
            setMessageInput({
                song_id: selectedMessage.song_id,
                songwriter_id: currentUser.userDetails.id,
                sender_id: currentUser !== undefined ? currentUser.userDetails.id : '',
                receiver_id: '1',
                message: ''
            })
        }

    }, [selectedMessage, currentUser])

    useEffect(() => {
        setLoadingSideBar(true)
        fetchMessageHeaders().then(() => {
            setLoading(false);
            setLoadingSideBar(false)
        })
    }, [page])

    // useEffect(() => {
    //     if (initial.current) {
    //         initial.current = false;
    //         return;
    //     }
    //     setLinkheaders(prev => ({
    //         ...prev,
    //         links: prev.links.map((item, index) => ({ ...item, acceptedSongs: [...songs.filter(song => song.user_id === item.user_id)] })),
    //         selectedArtist: linkheaders.links[0]
    //     })
    //     )
    //     fetchMessageHeaders()


    // }, [songs]); 
    // triggered once on mount and any time searchTerm is updated.
    return { showModal, setShowModal, resultsPerPage, totalResults, page, setPage, songDetails, messageHeaders, sendMessage, user, continueKey, loadingSideBar, handleChange, messageInput, setMessageInput, setUserIDs, loading, error, linkheaders, messages, setSelectedMessage, selectedMessage };

}

