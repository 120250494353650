import styled from 'styled-components'

export const ToolTipItem = styled.div`
position: ${props => props.position ? props.position : 'absolute'};
width: 100%;
display: flex;
font-size: 12px;
justify-content: ${props => props.justifyContent ? props.justifyContent : 'center'};
.tooltip{
    border-radius: 5px;
    width: 100%;
    min-width: min-content;
    background: ${props => props.background ? props.background : 'var(--greyBackground)'};
    border: ${props => props.border ? props.border : '1px solid #858585'};
    padding: 10px 20px;
    position: ${props => props.toolTipPosition ? props.toolTipPosition : props.position ? props.position : 'absolute'};
    visibility: hidden;
    /* visibility: visible; */
    z-index: ${props => props.zIndex ? props.zIndex : '1'};
    text-transform: none;
    right: ${props => props.right ? props.right : '0'};
    white-space: break-spaces;
    bottom: ${props => props.bottom ? props.bottom : '0'};
}
&:hover .tooltip{
    visibility: visible;
}
`;