import React, { useState } from 'react'

import { Wrapper, TableCell, Table, TableRow } from './RecentlyApproved.styles'
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
// import Table from '../../Table'
import DotMenu from '../../Dashboard/DotMenu'

import { timeElapsedFromCreatedAt } from '../../../helpers'

import clock from '../../../images/clockIcon.svg'

import AcceptAndReject from '../AcceptAndReject'

import { adjustPrice, calculateCut } from '../../../helpers'


const Row = ({ content, handleSelect, setSelectedSong, setShowModal, resetSplits }) => {
    const [hoverActive, setHoverActive] = useState(false)
    const getOptions = (song, status) => {
        const _defaultOptions = [{ value: 3, innerText: 'View Details', onClick: (e) => { setSelectedSong(song.song_id); setShowModal(true); handleSelect(song.song_id, true, 'details', e) } }]
        switch (parseInt(status)) {
            case 1:
                return []
            case 2:
                if (song.isPrimary && song.hasContributorsAndPrice)
                    return [{
                        value: 2,
                        innerText: 'Set Splits',
                        onClick: (e) => handleSelect(song.song_id, true, e)
                    },
                    { value: 3, innerText: 'View Details', onClick: (e) => { setSelectedSong(song.song_id); setShowModal(true); handleSelect(song.song_id, true, 'details', e) } }]
                else if (song.isPrimary)
                    return [{
                        value: 2,
                        innerText: 'Set Splits',
                        onClick: (e) => handleSelect(song.song_id, true, e)
                    }]
                else
                    return [{
                        value: 1,
                        innerText: 'View Details',
                        onClick: (e) => { setSelectedSong(song.song_id); setShowModal(true); handleSelect(song.song_id, true, 'details', e) }
                    }]
            case 3:
                if (song.actionRequired === 2) {
                    return [
                        { value: 3, innerText: 'View Details', onClick: (e) => { setSelectedSong(song.song_id); setShowModal(true); handleSelect(song.song_id, true, 'details', e) } },
                        { value: 31, innerText: song.action, onClick: (e) => handleSelect(song.song_id, true, e) }
                    ]
                }
                else if (song.isPrimary) {
                    return [
                        { value: 3, innerText: 'View Details', onClick: (e) => { setSelectedSong(song.song_id); setShowModal(true); handleSelect(song.song_id, true, 'details', e) } },
                        { value: 2, innerText: 'Edit Splits', onClick: () => { resetSplits(song.song_id); } }
                    ]
                }
                else {
                    return [
                        { value: 3, innerText: 'View Details', onClick: (e) => { setSelectedSong(song.song_id); setShowModal(true); handleSelect(song.song_id, true, 'details', e) } }
                    ]
                }
            case 4:
                return [{ value: 4, innerText: 'View Details', onClick: (e) => { setSelectedSong(song.song_id); setShowModal(true); handleSelect(song.song_id, true, 'details', e) } }]
            case 5:
                if (song.actionRequired === 3) {
                    return [
                        { value: 3, innerText: 'View Details', onClick: (e) => { setSelectedSong(song.song_id); setShowModal(true); handleSelect(song.song_id, true, 'details', e) } },
                        { value: 31, innerText: song.action, onClick: (e) => handleSelect(song.song_id, true, 'action', e) }
                    ]
                }
            case 6:
                return [{ value: 4, innerText: 'View Details', onClick: (e) => { setSelectedSong(song.song_id); setShowModal(true); handleSelect(song.song_id, true, 'details', e) } }]
            default:
                return [..._defaultOptions]
        }
    }

    return (
        <TableRow className="row"
            
            onMouseEnter={(e) => setHoverActive(true)}
            onMouseLeave={(e) => setHoverActive(false)}>
            <TableCell className="row" width="calc(3.22vw - 10px)" minWidth="calc(3.22vw - 10px)"></TableCell>
            <TableCell className="row" width="100%">{content.songTitle}</TableCell>
            <TableCell textAlign="right" className="row" minWidth="min-content" whiteSpace="nowrap">{content.status_text}</TableCell>
            <TableCell className="row" minWidth="min-content">{adjustPrice(content.price)}</TableCell>
            <TableCell className="row" minWidth="min-content">{content.my_split.includes('-') ? '-' : `${content.my_split}%`}</TableCell>
            <TableCell className="row" width="min-content">{`${calculateCut(content.my_split, content.price)}`}</TableCell>
            <TableCell textAlign="center" className="row" width="50px">{dayjs.unix(content.created_at).fromNow()}</TableCell>
            <TableCell textAlign="center" className="row" minWidth="min-content" whiteSpace="nowrap" 
            onClick={content.actionRequired > -1 ? (e) => handleSelect(content.song_id, true, 'action', e) : undefined}
            color={content.actionRequired > -1 ? 'var(--yellow)' : '#ffffff'}>{content.action}</TableCell>
            <TableCell className="row" minWidth="70px" height="70px">
                {(parseInt(content.status) !== 1 && hoverActive) &&
                    <DotMenu
                        className="row"
                        bottom="40px"
                        isSplitsPage={true}
                        right="15px"
                        _options={getOptions(content, content.status)}
                        // _getOptions={(status) => getOptions(content, status)}
                        fill={getOptions(content, content.status).length ? '#ffffff' : '#000000'}
                        setShowModal={setShowModal} setSelectedSong={() => { setSelectedSong(content.song_id) }} song={content} hoverActive={hoverActive} status={content.status} playlistOpen={true}></DotMenu>
                }

            </TableCell>
        </TableRow>)
}

const RecentlyApproved = ({ setSelectedSong, setShowModal, content, handleSelect, resetSplits }) => {

    dayjs.extend(RelativeTime)
    var updateLocale = require('dayjs/plugin/updateLocale')
    dayjs.extend(updateLocale)
    dayjs.updateLocale('en', {
        relativeTime: {
            future: "in %s",
            past: "%s",
            s: 'now',
            m: "1m",
            mm: "%dm",
            h: "1h",
            hh: "%dh",
            d: "1d",
            dd: "%dd",
            M: "1M",
            MM: "%dMs",
            y: "a year",
            yy: "%d years"
        }
    })
    return (
        <Wrapper>
            <Table>
                <TableRow className="tableHeader" >
                    <TableCell className="row" width="calc(3.22vw - 10px)"></TableCell>
                    <TableCell className="row" width="max-content">Song Title</TableCell>
                    <TableCell textAlign="right" className="row" width="min-content">Status</TableCell>
                    <TableCell className="row" width="min-content">Price</TableCell>
                    <TableCell className="row" minWidth="min-content" whiteSpace="nowrap">My Split [%]</TableCell>
                    <TableCell className="row" minWidth="min-content" whiteSpace="nowrap">My Split [$]</TableCell>
                    <TableCell textAlign="center" className="row" width="50px"><img src={clock} alt="clockIcon" style={{ width: "15px" }} /></TableCell>
                    <TableCell minWidth="min-content" whiteSpace="nowrap" textAlign="center" className="row" width="min-content">Action Required</TableCell>
                    <TableCell className="row" width="70px" height="70px"></TableCell>
                </TableRow>
                {
                    content.map((c) => (
                        <Row content={c} resetSplits={resetSplits} setSelectedSong={setSelectedSong} key={c.song_id} setShowModal={setShowModal} handleSelect={handleSelect} />
                    ))
                }
            </Table>
        </Wrapper>
    )
}

export default RecentlyApproved
