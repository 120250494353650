import styled from "styled-components";

export const Wrapper = styled.div`
position: relative;
display: flex;
flex-direction: column;
/* top: 60px; */
width: 100%;
height: 100%;
flex-grow: 1;
min-height: ${props => props.step === 0 ? 'calc(100vh - 60px)' : '100vh'};
margin-top: ${props => props.step === 0 ? '60px' : '0'};
`;

export const AlertNote = styled.div`
    background: #4D4D4D;
    border-radius: 10px;
    max-width: 90%;
    color: #fff;
    position: fixed;
    bottom: ${props => props.showMessage ? '20px' : '-100px'};
    font-size: var(--fontSmall);
    text-align: center;
    transition: all 0.5s;
    padding: 10px 20px;
    letter-spacing: 0.05rem;
    display: flex;
    justify-content: space-between;
    button{

        all: unset;
        font-family: var(--defaultFont);
        color: #ffffff;
        cursor: pointer;
        margin-left: 20px;
    }
`;

export const CloseButtonWrapper = styled.div`
display: flex;
position: absolute;
user-select: none;
font-size: 10px;
text-transform: uppercase;
background: var(--greyBackground);
font-weight: 600;
letter-spacing: 0.03rem;
padding: 10px 70px;
width: 100%;

align-items: center;
img{
    height: 12px;
    transform: rotate(180deg);
    margin-right: 5px;
}
`;

export const InnerWrapper = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-grow: 1;
justify-content: center;
position: absolute;
top: 0;
background: #000000;
left: 0;
align-items: center;`;

export const CloseButtonWrapperText = styled.div`
display: flex;
align-items: center;
`;

export const WrapperOverlay = styled.div`
background: #000000;
width: 100%;
height: 100%;
min-height: 100vh;
z-index: 3;
position: relative;
display: flex;
flex-direction: column;
align-items: center;
top: 0;
`;