import styled from 'styled-components'

import { NavLink as Link } from 'react-router-dom'
export const Wrapper = styled.div`
height: 60px;
width: 100%;
position: fixed;
top: 0;
z-index: 5;
background: #000;
display: flex;
align-items: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    height: 100%;
    position: relative;
    justify-content: center;
    cursor: default;
    font-size: 11px;
    text-transform: uppercase;
`;


export const Step = styled.div`
    
    letter-spacing: 0.02rem;
`;


export const Description = styled.div`
    color: var(--purpleGrey);
    font-weight: 700;
`;

export const Button =  styled(Link)`
    all: unset;
    font-family: var(--defaultFont);
    color: #ffffff;
    cursor: pointer;
    right: 30px;
    position: absolute;
`;