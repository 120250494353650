import React from 'react';

import {
    OuterWrapper,
    Wrapper,
    LogoWrapper,
    Icon,
    Header,
    Row,
    Col
} from './ContactUs.styles'

import Logo from '../../images/azaaLogo.svg';
import EmailIcon from '../../images/email-icon.svg';
import PhoneIcon from '../../images/phone-icon.svg';

import Footer from '../Footer'

const ContactUs = () => {
    return (
        <OuterWrapper>
            <Wrapper>
                <Row>
                    <LogoWrapper>
                        <img src={Logo} alt="logo" />
                    </LogoWrapper>
                    <Col>
                        <Header>Contact Us</Header>
                        <Row margin="10px 0 0">
                            <a href="mailto:info@azaamusic.com"><Icon>
                                <img src={EmailIcon} alt="email" />
                            </Icon>info@azaamusic.com</a>
                        </Row>
                    </Col>
                </Row>
            </Wrapper>
            <Footer />
        </OuterWrapper>
    );
};

export default ContactUs;
