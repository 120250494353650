import styled from 'styled-components'

export const Wrapper = styled.div`
background: ${props => props.background ? props.background : ''};
letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : ''};
z-index: ${props => props.zIndex ? props.zIndex : ''};
width: ${props => props.width ? props.width : '100%'};
max-width: ${props => props.maxWidth ? props.maxWidth : '100%'};
height: ${props => props.height ? props.height : ''};
margin: ${props => props.margin ? props.margin : ''};
box-shadow: ${props => props.boxShadow ? props.boxShadow : ''};
left: ${props => props.left ? props.left : ''};
right: ${props => props.right ? props.right : ''};
top: ${props => props.top ? props.top : ''};
bottom: ${props => props.bottom ? props.bottom : ''};
position: ${props => props.position ? props.position : ''};
flex-direction: ${props => props.flexDirection ? props.flexDirection : 'column'}; 
flex-wrap: ${props => props.flexWrap ? props.flexWrap : ''};
display: ${props => props.display ? props.display : props.gridTempCols || props.gridTempRows ? 'grid':'flex'};
grid-template-columns: ${props => props.gridTempCols ? props.gridTempCols : ''};
grid-template-rows: ${props => props.gridTempRows ? props.gridTempRows : ''};
grid-auto-rows: ${props => props.gridAutoRows ? props.gridAutoRows : ''};
grid-auto-columns: ${props => props.gridAutoCols ? props.gridAutoCols : ''};
grid-auto-flow: ${props => props.gridAutoFlow ? props.gridAutoFlow : ''};
grid-column-gap: ${props => props.gridColGap ? props.gridColGap : ''};
grid-row-gap: ${props => props.gridRowGap ? props.gridRowGap : ''};
white-space: ${props => props.whiteSpace ? props.whiteSpace : ''};
grid-area: ${props => props.gridArea ? props.gridArea : ''};
grid-column: ${props => props.gridCol ? props.gridCol : ''};
grid-row: ${props => props.gridRow ? props.gridRow : ''};
text-align: ${props => props.alignText ? props.alignText : 'left'};
text-transform: ${props => props.textTransform ? props.textTransform : ''};
justify-content: ${props => props.justifyContent ? props.justifyContent : 'start'};
justify-items: ${props => props.justifyItems ? props.justifyItems : 'start'};
align-content: ${props => props.alignContent ? props.alignContent : 'start'};
align-items: ${props => props.alignItems ? props.alignItems : 'end'};
color: ${props => props.color ? props.color : 'var(--fontColor)'};
font-size: ${props => props.fontSize ? props.fontSize : '12px'};
font-weight: ${props => props.fontWeight ? props.fontWeight : 'inherit'};
border-bottom: ${props => props.borderBottom ? props.borderBottom : ''};
border-radius: ${props => props.borderRadius ? props.borderRadius : ''};
padding: ${props => props.padding ? props.padding : ''};
overflow: ${props => props.overflow ? props.overflow : ''};
justify-self: ${props => props.justifySelf ? props.justifySelf : ''};
align-self: ${props => props.alignSelf ? props.alignSelf : ''};
cursor: ${props => props.cursor ? props.cursor : 'default'};
opacity: ${props => props.opacity ? props.opacity : '1'};
transition: ${props => props.transition ? props.transition :''};
`;