import React from 'react'
import { Wrapper, InnerWrapper } from './PrivacyPolicy.styles'
const PrivacyPolicy = () => {
    return (
        <Wrapper>
            <InnerWrapper>
                <object data="https://app.termly.io/document/privacy-policy/57787f71-e04d-458c-a6cf-3c4931eb57b6" type="text/html" >
                    alt : <a href="https://app.termly.io/document/privacy-policy/57787f71-e04d-458c-a6cf-3c4931eb57b6">Privacy Policy</a>
                </object>
            </InnerWrapper>
        </Wrapper>
    )
}

export default PrivacyPolicy