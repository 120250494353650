import styled from "styled-components";

export const Wrapper = styled.div`
display: none;
text-align: center;
grid-template-columns: 1fr;
height: 100vh;
grid-template-rows: min-content 1fr min-content 1fr;
@media screen and (max-width: 600px){
        display: ${props => props.isMobile ? 'grid' : 'none'};
        
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            display: ${props => props.isMobile ? 'grid' : 'none'};
            
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            display: ${props => props.isMobile ? 'grid' : 'none'};
            
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            display: ${props => props.isMobile ? 'grid' : 'none'};
            
    }
`;

export const LogoWrapper = styled.div`
height: 100px;
width: 100%;
display: flex;
align-self: end;
justify-content: center;
img{
    width: 100px;
    height: 100%;
}
`;

export const CloseButton = styled.div`
height: 30px;
width: 30px;
position: relative;
justify-self: end;
display: flex;
img{
    position: relative;
    height: 100%;
    width: 100%;
}
`;