import React, { useState, useRef, useEffect } from 'react'
import dayjs from 'dayjs'
import { adjustPrice, calculateCut } from '../../helpers'

//hooks
import { useTransactionHook } from '../../hooks/useTransactionHook'
import { usePageHook } from '../../hooks/usePageHook'

//styling
import { Date, H2, Tile, TileWrapper, Wrapper } from './TransactionHistory.styles'

// components
import PageNav from '../PageNav'
import Flexbox from '../Flexbox'
import GridItem from '../GridItem'
import Table from '../Table2'
import TableCell from '../TableCell'
import TableRow from '../TableRow'
import Button from '../Button'

const RowItem = ({ c, selectedSong, getDotMenu, handleSelect, showSideBar, selected }) => {

    const dotMenuRef = useRef(null)
    const [hover, setHover] = useState(false)

    return (
        <TableRow
            content={c.data}
            // cellProps={{ verticalAlign: 'start' }}
            selectedSong={selectedSong} key={c.song_id} handleSelect={() => handleSelect(c)}
            onMouseEnter={() => { setHover(true) }}
            onMouseLeave={() => { setHover(false) }}
            onClick={(e) => {

                // if (e.target !== dotMenuRef.current && e.target !== dotMenuRefWrapper.current && !e.target.classList.contains('dotmenu') && e.target.classList.contains('row')) {
                //     console.log("TRIGGERED BY", e, e.target)
                //     onCheck(column.id, song.id, e)
                // }
            }}>
        </TableRow>)
}


const TransactionHistory = () => {
    const props = useTransactionHook({ songs: null })

    //{ page, setPage, totalResults, fetchPage }
    const { tableData,
        // selectedSong,
        getDotMenu,
        // handleSelect,
        showSideBar,
        closeSideBar,
        secondColumnSize,
        selected,
        sideBarDetails,
        filters,
        setFilters,
        onSearchChange,
        totalValue,
        filterOptions,
        nbHits,
        columns,
        onPageChange,
        resultsPerPage,
        totalResults,
        makeCSV,
        getTableDataForExport,
        pageNavProps,
        // searchValue,
    } = props
    // const pageNavProps = usePageHook(onPageChange, resultsPerPage)
    const { data, buyerHeader, subHeader, headerPadding, subHeaderPadding } = tableData
    const { status } = filters
    const headerRow1Ref = useRef(null)
    const headerRow2Ref = useRef(null)

    const [top, setTop] = useState({
        row1: 0,
        row2: 0
    })

    const rowRef = useRef(null)
    useEffect(() => {
        if (headerRow1Ref.current) {
            const client1 = headerRow1Ref.current.getBoundingClientRect()
            setTop({
                row1: 0,
                row2: client1.height + 'px'
            })

        }
    }, [headerRow2Ref, headerRow1Ref])

    const [searchValue, setSearchValue] = useState('')
    // const [filters, setFilters] = useState([])

    const [showModal, setShowModal] = useState(false)
    const selectedSong = useRef(null)
    // const { data, header, buyerHeader } = content

    const handleSelect = (c) => {
        selectedSong.current = c
    }
    if (data.length > 0)
        return (
            <>
                <Flexbox column height="calc(100vh - var(--navBarHeight))" width="100%" margin="var(--navBarHeight) 0 0" justifyContent="start" alignItems="start">
                    <GridItem alignItems="start" position="relative" gridTempCols="2fr min-content" gridTempRows="1fr min-content" height="100%" width="100%" display="grid" style={{ flexGrow: '1' }}>
                        <GridItem gap="10px" column overflow="hidden" height="100%" flexGrow="1" justifyContent="start" alignItems="start" padding="0 30px 10px " background="#1c1c1c">
                            <Flexbox className="" gap="10px" uppercaseOff={true} alignItems="end"><H2 >Transaction History</H2>
                                {/* <Date>{dayjs().format('ddd, MMMM D, YYYY')}</Date> */}
                            </Flexbox>
                            {(nbHits || totalValue) && <TileWrapper>

                                {
                                    nbHits &&
                                    <Tile >
                                        <h4>{nbHits.title}</h4>
                                        <h2>{nbHits.value}</h2>
                                    </Tile>
                                }
                                {
                                    totalValue &&
                                    <Tile >
                                        <h4>{totalValue.title}</h4>
                                        <h2>{totalValue.value}</h2>
                                    </Tile>
                                }
                            </TileWrapper>}
                            <Flexbox gap="15px">
                                {
                                    filterOptions.current.status.map(it => {
                                        return (
                                            <Flexbox opacity={filters.status === it.value ? '1' : '0.25'} nowrap width="min-content" key={it.value} onClick={() => { setFilters(prev => ({ ...prev, status: it.value })) }}>
                                                <h3>{it.title}</h3>
                                            </Flexbox>)
                                    })
                                }
                            </Flexbox>
                            <GridItem margin="1px 0 0" gridCol="1 / span 2" width="100%" flexDirection="row" justifyContent="start" alignItems="start">
                                <PageNav searchBarFirst pageGridGap="5px" shadowOff={true} {...pageNavProps} totalResults={totalResults} resultsPerPage={resultsPerPage}></PageNav>
                                <Button
                                    height="49px"
                                    margin="5px 0"
                                    padding="0 10px"
                                    // border='1px solid #fff'
                                    boxShadow="0 0 5px #000"
                                    background='#1C1C1C'
                                    text="Download As CSV" onClick={() => {
                                        makeCSV(getTableDataForExport(data, columns), `AzaaTransactionHistory.csv`)
                                    }} />
                            </GridItem>
                            <div style={{ borderTop: '2px solid rgba(255,255,255,0.15)', width: "100%" }}></div>

                            <Flexbox relative row justifyContent="start" alignItems="start" overflow="auto">
                                <Table oddHighlight>
                                    <TableRow headerRowIndex={1} headerRowRef={headerRow1Ref} fontSize="11px" className="tableHeader row" background="#1c1c1c" zIndex="1">
                                        {
                                            Object.keys(data[0].data).reduce((res, el, index) => {
                                                const props = data[0].data[el].headerProps
                                                const _data = data[0].data[el]
                                                const title = Object.keys(_data).includes('major') ? _data.major : el
                                                if (!res.find(it => it.title === title)) {
                                                    const trueSpan = Object.keys(data[0].data).reduce((a, b) => {
                                                        const _data = data[0].data[b]

                                                        if (!Object.keys(_data).includes('major'))
                                                            return a
                                                        if (_data.major !== title)
                                                            return a
                                                        console.log(a, b, _data.major, title, Object.keys(_data))
                                                        a = a + 1;
                                                        return a
                                                    }, 0)
                                                    console.log(trueSpan)
                                                    res.push({
                                                        title: title, el:
                                                            <TableCell key={index} isHeader className="tableHeader" id={index} content={{ title: title, props: { ...props, width: props.width ? props.width : 'auto', colSpan: trueSpan !== 0 ? trueSpan : 1, padding: props.padding ? props.padding : '0' } }}></TableCell>
                                                    })
                                                }
                                                return res
                                            }
                                                , []).map(it => it.el)
                                        }

                                    </TableRow>
                                    <TableRow headerRowIndex={2} headerRowRef={headerRow2Ref} className="tableHeader row" fontSize="12px" background="#1c1c1c" zIndex="1">
                                        {/* {
                                    subHeader.map((it, index) => <TableCell key={index} isHeader fontSize="10px" className="tableHeader" id={index} content={{ ...it, props: { padding: '0' } }}></TableCell>)
                                } */}
                                        {
                                            Object.keys(data[0].data).reduce((res, el, index) => {
                                                const props = data[0].data[el].headerProps
                                                const _data = data[0].data[el]
                                                const title = Object.keys(_data).includes('major') ? el : undefined
                                                if (title !== undefined && !res.find(it => it.title === title)) {

                                                    res.push({
                                                        title: title, el: <TableCell key={index} isHeader className="tableHeader" id={index} content={{
                                                            title: title,
                                                            props: { ...props.minorProps, width: props.minorProps.width ? props.minorProps.width : 'auto', },
                                                        }}></TableCell>
                                                    })
                                                }
                                                else res.push({ el: <TableCell key={index} isHeader className="tableHeader" id={index} content={{ props: { padding: '0' } }}></TableCell> })
                                                return res
                                            }
                                                , []).map(it => it.el)
                                        }
                                    </TableRow>
                                    {
                                        data.map((c) => (
                                            <RowItem key={c.id} c={c} {...props} />
                                        ))
                                    }
                                </Table>
                            </Flexbox>
                        </GridItem>
                    </GridItem>
                </Flexbox>
            </>

        )
    else return <></>
}

export default TransactionHistory