import React, { useEffect, useRef } from 'react';

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback])

    useEffect(() => {
        if (typeof delay === 'number') {
            const tick = () => {
                savedCallback.current();
            }
            let id = setInterval(tick, delay)
            // Clear if the components is unmounted or the delay changes:
            return () => clearInterval(id)
        }
    }, [delay])

}