import React, { useState, useEffect, useRef } from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import { Wrapper, InnerWrapper, ScrollWrapper } from './Table.styles'

import TableEntry from '../TableEntry'
import Flexbox from '../Flexbox'
import LoadingWheel from '../LoadingWheel'

import formNavArrow from '../../images/formNavArrow.svg'

const Table = ({ offset, resultsPerPage, scrollRef, isOpen, maxOpen, nbHits, fetchData, useInfScroll = false, _classNames, flexGrow, tableMarginBottom, rowMargin, _onHover, overflowX, rowWidth, expandedContainerHeight, scrollContainerPaddingBottom, rowBorder, setHoverActive, indexesToHideOffHover, rowHeight, rowHighlight, hideHeader, headerFontSize, contentFontSize, padding, content, gridTemplateColumns, gridColGap, gridRowGap, tableHeader, rowAlignmentArray, handleSelect, noHover, tableHeaderTextStyling }) => {
    const [_data, _setData] = useState(Array.from({ length: 20 }, (_, i) => i + 1))

    const fetchMas = () => {
        if (typeof (fetchData) === 'function' && nbHits > content.length) {
            console.log("fetching")
            fetchData()
        } else {
            console.log("fetching1")
        }

    }

    if (useInfScroll) {
        return (
            <Flexbox column padding="0" dimensions={{ w: '100%', h: '100%' }} relative>
                {(hideHeader === undefined || !hideHeader) && <TableEntry
                    content={tableHeader}
                    rowHeight={rowHeight}
                    minHeight={rowHeight}
                    headerFontSize={headerFontSize}
                    width={rowWidth}
                    tableHeaderTextStyling={tableHeaderTextStyling}
                    gridTemplateColumns={gridTemplateColumns}
                    gridColGap={gridColGap}
                    rowAlignmentArray={rowAlignmentArray}
                    hideHeader={hideHeader}
                    header
                    padding={padding}
                />}
                <Flexbox column relative overflow="hidden" dimensions={{
                    w: '100%',
                    h: '100%'
                    // h: !isOpen ? '100%' : 'calc(100% - 65px)'
                }} >
                    <InfiniteScroll
                        ref={scrollRef}
                        // dataLength={_data.length}
                        dataLength={content.length}
                        next={fetchMas}
                        hasMore={nbHits > content.length}
                        loader={<Flexbox column justifyContent="center" textTransform="uppercase" alignItems="center" fontSize="10px">
                            <LoadingWheel position="relative" radius="25px" height="28px" width="28px" />
                        </Flexbox>}
                        style={{ overflow: !isOpen ? 'hidden hidden' : 'hidden auto', }}
                        // style={{ overflow: !isOpen ? 'hidden hidden' : 'hidden auto', paddingBottom: '30px' }}
                        // height={!isOpen ? 60 : 5 * 60}
                        height={!isOpen ? 60 : maxOpen ? 'calc(100vh - 120px)' : 'calc(50vh - 35px - 65px - 65px)'}
                    // endMessage={
                    //     <p style={{ textAlign: "center" }}>
                    //         <b>Yay! You have seen it all</b>
                    //     </p>
                    // }
                    >
                        {/* {_data.map((i, index) => (
                        <div style={{ position: 'relative', width: '400px', padding: '20px', height: '50px' }} key={index}>
                            div - #{index}
                        </div>
                    ))} */}
                        {
                            content.map((row, index) => (
                                <TableEntry
                                    rowHeight={rowHeight}
                                    _classNames={_classNames}
                                    border={rowBorder}
                                    onClick={handleSelect ? handleSelect(row.contentID) : undefined}
                                    noHover={noHover}
                                    rowHighlight={row.rowHighlight}
                                    _onHover={row._onHover ? row._onHover : undefined}
                                    padding={padding}
                                    margin={rowMargin}
                                    indexesToHideOffHover={indexesToHideOffHover}
                                    width={rowWidth}
                                    setHoverActive={setHoverActive}
                                    contentFontSize={contentFontSize}
                                    content={row.contentArr}
                                    id={row.contentID}
                                    key={index}
                                    gridTemplateColumns={gridTemplateColumns}
                                    gridColGap={gridColGap}
                                    gridRowGap={gridRowGap}
                                    rowAlignmentArray={rowAlignmentArray}
                                />
                            ))
                        }
                        {
                            (offset.current * resultsPerPage >= nbHits - resultsPerPage) &&
                            <Flexbox relative height="192px" justifyContent="center" alignItems="start">
                                <Flexbox onClick={() => {scrollRef.current.el.scrollTop = 0}} fontWeight="700" column fontSize="16px" border="1px solid #ffffff" width="min-content" padding="5px" whiteSpace="nowrap" borderRadius="5px">
                                    <img src={formNavArrow} style={{transform: 'rotate(-90deg)',height: '10px', width: '10px'}} alt="to-top" />
                                    {/* <span>&#8593;</span> */}
                                </Flexbox>
                            </Flexbox>
                        }
                    </InfiniteScroll>
                </Flexbox>
            </Flexbox>
        )
    }
    else return (
        <Wrapper flexGrow={flexGrow}>
            {(hideHeader === undefined || !hideHeader) && <TableEntry
                content={tableHeader}
                rowHeight={rowHeight}
                headerFontSize={headerFontSize}
                width={rowWidth}
                tableHeaderTextStyling={tableHeaderTextStyling}
                gridTemplateColumns={gridTemplateColumns}
                gridColGap={gridColGap}
                rowAlignmentArray={rowAlignmentArray}
                hideHeader={hideHeader}
                header
                padding={padding}
            />}
            <InnerWrapper flexGrow={flexGrow}>
                <ScrollWrapper tableMarginBottom={tableMarginBottom} paddingBottom={scrollContainerPaddingBottom} expandedContainerHeight={expandedContainerHeight} overflowX={overflowX}>
                    {
                        content.map((row, index) => (
                            <TableEntry
                                rowHeight={rowHeight}
                                _classNames={_classNames}
                                border={rowBorder}
                                onClick={handleSelect ? handleSelect(row.contentID) : undefined}
                                noHover={noHover}
                                rowHighlight={row.rowHighlight}
                                _onHover={row._onHover ? row._onHover : undefined}
                                padding={padding}
                                margin={rowMargin}
                                indexesToHideOffHover={indexesToHideOffHover}
                                width={rowWidth}
                                setHoverActive={setHoverActive}
                                contentFontSize={contentFontSize}
                                content={row.contentArr}
                                id={row.contentID}
                                key={index}
                                gridTemplateColumns={gridTemplateColumns}
                                gridColGap={gridColGap}
                                gridRowGap={gridRowGap}
                                rowAlignmentArray={rowAlignmentArray}
                            />
                        ))
                    }

                </ScrollWrapper>
            </InnerWrapper>
        </Wrapper>
    )
}

export default Table
