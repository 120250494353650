import styled from "styled-components";

export const Wrapper = styled.div`
display: grid;
text-align: center;
grid-template-columns: 1fr;
height: 100vh;
grid-template-rows: min-content 1fr min-content 1fr;
position: fixed;
width: 100%;
z-index: 15;
background: rgba(0,0,0,0.95);
padding: 20px 20px;
top: 0;
left: 0;
`;

export const LogoWrapper = styled.div`
height: 100px;
width: 100%;
display: flex;
align-self: end;
justify-content: center;
img{
    width: 100px;
    height: 100%;
}
`;

export const CloseButton = styled.div`
height: 30px;
width: 30px;
position: relative;
justify-self: end;
display: flex;
img{
    position: relative;
    height: 100%;
    width: 100%;
}
`;