import React from 'react'

import { PlayButtonContainer } from './PlayButton.styles'

const PlayButton = ({ onClick, playing, isCurrent, fill }) => {
    if (playing && isCurrent) {
        return (
            <PlayButtonContainer onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.34 50">
                    <rect width="8.82" height="50" rx="1" style={{ fill: fill }} />
                    <rect x="21.52" width="8.82" height="50" rx="1" style={{ fill: fill }} />
                </svg>
            </PlayButtonContainer>
        )
    }
    else {
        return (
            <PlayButtonContainer onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.69 50.14">
                    <path className="cls-1" d="M43.19,24.2,1.5.14A1,1,0,0,0,0,1V49.14A1,1,0,0,0,1.5,50L43.19,25.94A1,1,0,0,0,43.19,24.2Z" style={{ fill: fill }} />
                </svg>
            </PlayButtonContainer>
        )
    }
}

export default PlayButton