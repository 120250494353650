import styled from 'styled-components'

export const Wrapper = styled.div`
flex-grow: 1;
height: 100vh;
padding-top: var(--navBarHeight);
display: flex;
flex-direction: column;
justify-content:flex-start;
align-items: center ;
object{
    height: 100%;
    position: relative;
    width: 100%;
}
`;

export const InnerWrapper = styled.div`
flex-grow: 1;
display: flex;
flex-direction: column;
justify-content:flex-start;
align-items: center ;
height: 100%;
width: 90%;
max-width: 900px;
background: #ffffff;
object{
    height: 100%;
    position: relative;
    width: 100%;
}
`;