import AlertIcon from './images/alertYellow.svg'
const VALID_FILE_TYPES = [
    "audio/mp3",
    "audio/mpeg",
    "audio/mpeg3",
    "audio/mpg",
    "audio/x-mpeg-3",
    "audio/wav",
    "audio/x-wav",
    "audio/wave",
    "audio-x-pn-wav"];
const MAX_SIZE = 500000000;
const ACCEPTED_SONGS = 1;
const SONG_ID_SPECIFIED = 2;
const USER_ID_SPECIFIED = 3;
const UNASSIGNED_SONGS = 4;
const LOGIN_POPUP = 'Log In';
const SIGNUP_POPUP = 'Sign Up';
const BUYER_SIGNUP = 'Buyer Sign Up';
const LOGOUT = 'Log Out';
const SET_PASSWORD_POPUP = 'Set Password';
const ACCOUNT_ACTIVITY = 1;
const ACCOUNT_SETTINGS = 2;
const DASHBOARD = 3;
const ACCEPT = 1;
const REJECT = 0;
const AZAA_PLATFORM_FEE_PERCENT = 15;
// zero means no limit
const MAX_UPLOADS =
{
    BASIC: 1,
    PLUS: 0,
    ULTRA: 0
};
const BASIC_TIER = 'BASIC';
const PLUS_TIER = 'PLUS';
const ULTRA_TIER = 'ÜLTRA'
const BUYER_TIER = 'BUYER'
const MEMBERSHIP_TIERS = { BASIC_TIER, PLUS_TIER, ULTRA_TIER, BUYER_TIER }
const TOP_100 = 'Top 100'
const FAVORITES = 'Favorites'
const PURCHASES = 'Purchases'
const HOLDS = 'Holds'
const BUYER_PLAYLIST_TYPES = { TOP_100, FAVORITES, PURCHASES }
// const BUYER_PLAYLIST_TYPES = { TOP_100, FAVORITES, PURCHASES, HOLDS }



const ENGAGE_ACTION_REQ = 1
const ADD_TO_CART = 'Add to Cart'
const PURCHASE_SONG = 'Purchase Song'
const PLACE_ON_HOLD = 'Place On Hold'
const MESSAGE_ADMIN = 'Message Azaa'
const DOWNLOAD = 'Download'
const CONFIRM = 'Confirm'
const VIEW_DETAILS = 'View Details'
const UNSOLD_SONG_ACTIONS = { ADD_TO_CART, PURCHASE_SONG }
const SOLD_SONG_ACTIONS = { DOWNLOAD, CONFIRM }
const MUTUAL_ACTIONS = { MESSAGE_ADMIN }
const BUYER_DASH_ACTIONS = { ...UNSOLD_SONG_ACTIONS, ...SOLD_SONG_ACTIONS, ...MUTUAL_ACTIONS }
const getBuyerDashActions = (playlistType, _requiredActionBuyer = null) => {
    if (playlistType === BUYER_PLAYLIST_TYPES.PURCHASES) {
        if (_requiredActionBuyer === -1 || _requiredActionBuyer === null)
            return { DOWNLOAD, ...MUTUAL_ACTIONS }
        else return { ...SOLD_SONG_ACTIONS, ...MUTUAL_ACTIONS }
    }
    else return { ...UNSOLD_SONG_ACTIONS, ...MUTUAL_ACTIONS }
}
export {
    getBuyerDashActions,
    UNSOLD_SONG_ACTIONS,
    SOLD_SONG_ACTIONS,
    VALID_FILE_TYPES,
    BUYER_DASH_ACTIONS,
    MEMBERSHIP_TIERS,
    MAX_SIZE,
    MAX_UPLOADS,
    ACCEPTED_SONGS,
    SONG_ID_SPECIFIED,
    USER_ID_SPECIFIED,
    LOGIN_POPUP,
    SIGNUP_POPUP,
    BUYER_SIGNUP,
    SET_PASSWORD_POPUP,
    AZAA_PLATFORM_FEE_PERCENT,
    LOGOUT,
    ACCOUNT_ACTIVITY,
    ACCOUNT_SETTINGS,
    ACCEPT,
    REJECT,
    DASHBOARD,
    BUYER_PLAYLIST_TYPES
};