import React, { useState, useEffect, useRef, useMemo, Suspense, forwardRef } from 'react'
import * as THREE from 'three'
import axios from 'axios'
import { Canvas, extend, useFrame, useLoader } from '@react-three/fiber'
// import { TextureLoader } from "three/src/loaders/TextureLoader";
import { Sphere as Sphere2, PositionalAudio, useTexture, useGLTF, Environment, Loader, PerspectiveCamera } from '@react-three/drei';
import { EffectComposer, DepthOfField, Bloom, Noise, Vignette } from '@react-three/postprocessing'
import SimplexNoise from 'simplex-noise';
import glsl from 'babel-plugin-glsl/macro'

import vertexShader3 from '../../shaders/vertex3.js'
import fragmentShader3 from '../../shaders/fragment3.js'

import SpriteWarmTone from '../../textures/spark1.png'
import SpriteCoolTone from '../../textures/spark3.png'
import Sprite from '../../textures/spark5.png'


import { roundValue } from '../../helpers'


//initialise simplex noise instance
var noise = new SimplexNoise();
var noise_level = 0.00001;

let fHighThresh = 20;
let fLowThresh = 105;

const avg = (array) => array.reduce((a, b) => a + b) / array.length;

const filterOutliers = (array) => {
    // Copy the values, rather than operating on references to existing values
    var values = array;
    if (values.length < 4)
        return array
    // Then sort
    values.sort(function (a, b) {
        return a - b;
    });


    /* Then find a generous IQR. This is generous because if (values.length / 4) 
     * is not an int, then really you should average the two elements on either 
     * side to find q1.
     */
    var q1 = values[Math.floor((values.length / 4))];
    // Likewise for q3. 
    var q3 = values[Math.ceil((values.length * (3 / 4)))];
    var iqr = q3 - q1;

    // Then find min and max values
    var maxValue = q3 + iqr * 1.5;
    var minValue = q1 - iqr * 1.5;

    // Then filter anything beyond or beneath these values.
    var filteredValues = values.filter(function (x) {
        return (x <= maxValue) && (x >= minValue);
    });

    // Then return
    return filteredValues;
}

// const Audio = forwardRef(({ listener, track, volume, ...props }, ref) => {

//     // const setLoaded = useMusicStore((state) => state.setLoaded);
//     // const init = useMusicStore((state) => state.init);

//     // const buffer = useLoader(AudioLoader, urls[track], null, (xhr) => {
//     //     if (xhr.loaded === xhr.total) {
//     //         setLoaded(track, true);
//     //     }
//     // });

//     useEffect(() => {
//         const sound = ref.current;
//         if (sound ) {
//             sound.setBuffer(buffer);
//             sound.setLoop(false);
//             sound.setVolume(volume);
//             sound.play();
//         }

//         return () => {
//             if (sound && init) {
//                 sound.stop();
//                 sound.disconnect();
//             }
//         };
//     }, [buffer, listener]);

//     return <audio ref={ref} args={[listener]} {...props} />;
// });


const Points = ({ sound, positionArray, sphere, controls, canvasRef }) => {


    const [positions, colors] = useMemo(() => {

        let vertices = [];
        //let colorCoords = [];
        let colors = []

        for (let i = 0; i < 10000; i++) {

            const x = THREE.MathUtils.randFloatSpread(2000);
            const y = THREE.MathUtils.randFloatSpread(2000);
            const z = THREE.MathUtils.randFloatSpread(2000);

            vertices.push(x, y, z);
            colors.push(1.0, 0.0, 0.0)

        }

        let p_geometry = new THREE.BufferGeometry(); //change to const later
        p_geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(vertices), 3))

        const positions = p_geometry.attributes.position.array

        return [new Float32Array(positions), new Float32Array(colors)]
    }, [])
    const [texture1, texture2, texture3] = useTexture([SpriteWarmTone, SpriteCoolTone, Sprite])
    const starAlphaTexture = new THREE.TextureLoader().load(Sprite);
    const shaderMaterialProps = useMemo(() => {

        return ({
            uniforms: {
                time: { value: 0.0 },
                pointTexture: { value: texture1 },
                color: { value: 0xffffff },
                alpha: { value: 1.0 },
                size: { value: 10.0 },
                isRainbow: { value: 1.0 },
                // iResolution: { value: [window.innerWidth, window.innerHeight, 1.0] },
                iResolution: { value: { x: window.innerWidth, y: window.innerHeight, z: 1.0 } },
            },
            color: 0xffffff,
            depthTest: false,
            transparent: true,
            blending: THREE.AdditiveBlending,
            fragmentShader: fragmentShader3,
            vertexShader: vertexShader3,
        })


    }, [])


    // Returning to render on canvas in index file
    return <points>
        <bufferGeometry attach="geometry">
            <bufferAttribute
                attach="attributes-position"
                count={positions.length / 3}
                array={positions}
                itemSize={3}
                usage={THREE.DynamicDrawUsage}
            />
            {/* <bufferAttribute
                attach="attributes-color"
                count={colors.length / 3}
                array={colors}
                itemSize={3}
                usage={THREE.DynamicDrawUsage}
            /> */}

        </bufferGeometry>
        <pointsMaterial attach="material"
            size={35}
            sizeAttenuation={true}
            // color={0xffffff}
            map={starAlphaTexture}
            depthTest={false}
            opacity={1.0}
            blending={THREE.AdditiveBlending}
            // alphaTest={0.5}
            transparent={true} />
    </points>;
}


const Sphere = ({ sphere, sound, controls, ...props }) => {
    // const { currentUser } = useAuth()
    const canvasRef = useRef(null)
    return (
        <div style={{ display: 'flex', height: '100vh', width: '100vw' }}>
            <Canvas
                ref={canvasRef}
                dpr={Math.max(window.devicePixelRatio, 2)}
                // gl={{ antialias: false }}
                camera={{ fov: 75, aspect: window.innerWidth / window.innerHeight, near: 0.01, far: 5000, position: [0, 0, 50] }}>
                <Points canvasRef={canvasRef} />
            </Canvas>
        </div>

    )
}

export default Sphere
