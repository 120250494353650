import styled from 'styled-components'

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
height: 100vh;
flex-grow: 1;
justify-content: center;
`;

export const OuterWrapper = styled.div`
height: 100%;
display: flex;
flex-direction: column;
`;

export const LogoWrapper = styled.div`
position: relative;
width: 200px;
@media only screen and (max-width: 600px){
    width: 50px;
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            width: 50px;
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            width: 50px;
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            width: 50px;
    }
`;

export const Header = styled.h1`
/* margin: 0;
user-select: none;
text-transform: uppercase;
height: min-content;
line-height: 2.2rem;
letter-spacing: 0.07rem;
font-size: 55px;
font-weight: 500;
margin-bottom: 25px; */
margin-top: 0;
user-select: none;
`;

export const Col = styled.div`
display: flex;
flex-direction: column;
margin-left: 20px;
justify-content: center;
@media only screen and (max-width: 600px){
    margin-left: 0;
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            margin-left: 0;
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            margin-left: 0;
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            margin-left: 0;
    }
`;

export const Row = styled.div`
display: flex;
font-weight: var(--boldSmall);
letter-spacing: var(--boldSmallLetterSpacing);
align-items: center;
margin: ${props=> props.margin ? props.margin : ''};
@media only screen and (max-width: 600px){
        flex-direction: column;
        row-gap: 20px;
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            flex-direction: column;
            row-gap: 20px;
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            flex-direction: column;
            row-gap: 20px;
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            flex-direction: column;
            row-gap: 20px;
    }
`;

export const Icon = styled.div`
height: 20px;
width: 20px;
margin-right: 10px;
`;