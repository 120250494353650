import { useState, useEffect, useRef } from 'react';
import API from '../API';
import dayjs from 'dayjs'
import { useAuth } from '../contexts/AuthContext'
import { updateRelTime } from '../utils'
import { roundValue } from '../helpers'

export const useVisualizerHook = (args) => {
    const [loading, setLoading] = useState(false)
    const canvasRef = useRef(null)
    const directionLightRef = useRef(null)
    const directionLightRef2 = useRef(null)
    const [sceneWidth, setSceneWidth] = useState(window.innerWidth)

    const backlightRef = useRef(null)

    const visualizerControls = {
        'Orb': {
            detail: {
                name: 'Particle Density',
                value: 26, //default
                range: [10, 26],
                step: 1
            },
            radius: {
                name: 'Orb Radius',
                value: 5,
                range: [3, 20],
                step: 1
            }
        },
        'Orb Distortion': {
            waves: {
                name: 'Waviness',
                value: 1,
                range: [1, 20],
                step: 1
            },
        },
        'Particles': {
            pointSize: {
                name: 'Size',
                value: 1.5 * window.devicePixelRatio,
                range: [roundValue(1.5 * window.devicePixelRatio), roundValue(10.5 * window.devicePixelRatio)],
                step: 0.25
            },
            sprites: {
                name: 'Hue',
                value: 'texture1',
                options: [{ name: 'Warm White', value: 'texture1' }, { name: 'Rainbow', value: 'texture2' }],
                onClick: () => { }
            },
        },
        'Sound': {
            trebleInfluence: {
                name: 'Treble Influence',
                value: 5,
                range: [1, 10],
                step: 1
            },
            bassInfluence: {
                name: 'Bass Influence',
                value: 8,
                range: [2, 10],
                step: 1
            },
            fftSize: {
                name: 'Audio Samples [2^x]',
                value: 11,
                range: [5, 11],
                step: 1
            },
        },
    }
    const [controls, setControls] = useState(visualizerControls)
    const [showControls, setShowControls] = useState(false)
    const sound = useRef();
    return {
        directionLightRef,
        directionLightRef2,
        backlightRef,
        showControls,
        setShowControls,
        controls,
        setControls,
        sound,
        canvasRef
    }
}