import styled from 'styled-components'

export const DropDownContainerBox = styled.div`
z-index: 7;
/* right: 30px;
position: absolute;
margin-top: 30px; */
`;

export const Overlay = styled.div`
width: 100%;
height: 100vh;
z-index: 6;
position: absolute;
top: 0;
left: 0;
visibility: ${props=> props.showOverlay ? 'visible' : 'hidden'};
background: transparent;
@media only screen and (max-width: 600px){
    display: none;
}
@media only screen 
    and (max-device-width: 812px) 
    and (-webkit-min-device-pixel-ratio: 3) {
        display: none;
}
@media only screen 
    and (device-width: 360px) 
    and (-webkit-device-pixel-ratio: 4) {
        display: none;
}
@media only screen 
    and (max-device-width: 667px) 
    and (-webkit-min-device-pixel-ratio: 2) {
        display: none;
}
`;

export const DropDownNavBtn = styled.div`
width: 25px;
height: 25px;
display: flex;
align-items: center;
position: relative;
background: transparent;
display: flex;
cursor: pointer;

svg{

    position: relative;
    visibility: visible;
}
    span{
        color: #000;
    white-space: nowrap;
    height: 100%;
    position: relative;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    font-size: inherit;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: .02rem;
    background: var(--white);
    margin-left: 2px;
    border-radius: 2px;
    font-weight: 400; 
    }


`

export const DropdownList = styled.ul`
margin: 0;
margin-top: ${props => props.marginTop ? props.marginTop : ''};
padding: 0;
background: ${props => props.listBackground ? props.listBackground : 'var(--dropdownBackground)'};
position: absolute;
border: ${props => props.border ? props.border : ''};
width: 100%;
z-index: 2;
box-shadow: 0 0 10px rgb(0,0,0);
height: min-content;

`;

export const DropdownListContainer = styled.div`
    
`;

export const DropdownContainer = styled.div`
    height: 100%;
    width: ${props => props.width ? props.width : '100%'};
    position: relative;
    background: ${props => props.background ? props.background : 'var(--grey)'};
    text-transform: uppercase;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    border: ${props => props.border ? props.border : ''};
font-size: 10px;
font-weight: 500;
letter-spacing: 0.1rem;
position: relative;
    
`;

export const ListItem = styled.li`
    height: 40px;
    list-style: none;
    display: flex;
    position: relative;
    align-items: center;
    margin: 0;
    padding: 0 20px;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-end'};
    user-select: none;
    cursor: pointer;
    background: ${props => props.isSelected || props.isActive? 'rgba(255,255,255,0.2)' : 'transparent'};
    color: ${props => props.isSelected ? props.colorOnSelect ? props.colorOnSelect : 'rgb(255,255,255)' : props.color ? props.color : 'var(--purpleGrey)'};
    :hover{
        background: rgba(255,255,255,0.2);
    }
`;