import React from 'react'

import { NextUpArrowWrapper } from './NextUpArrow.styles'

const NextUpArrow = ({ isOpen, isAlert }) => {
    const fillColor = isAlert ? 'var(--yellow)' : '#ffffff'
    return (
        <NextUpArrowWrapper isOpen={isOpen}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.4 11.8" width="100%" height="100%">
                <g style={{ fill: fillColor, strokeWidth: isAlert ? '5%' : '0', stroke: isAlert ? 'var(--yellow)' : '' }}>
                    <polygon className="cls-1" points="0.71 6.91 0 6.2 6.2 0 12.4 6.2 11.69 6.91 6.2 1.41 0.71 6.91" />
                    <polygon className="cls-1" points="0.71 11.8 0 11.09 6.2 4.89 12.4 11.09 11.69 11.8 6.2 6.31 0.71 11.8" />
                </g>
            </svg>
        </NextUpArrowWrapper>)
}

export default NextUpArrow