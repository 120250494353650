import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Wrapper, NavLink, BananaWrapper, ButtonWrapper } from './Success.styles'
import LoadingWheel from '../../LoadingWheel'
import Button from '../../Button'
import Bananas from '../../../images/bananas.svg'
import axios from 'axios'

const Success = ({ onSubmit, values, handleClose }) => {
    const navigate = useNavigate()
    const submitting = useRef(false)
    const splitsLeft = useRef(0)
    // const [splitsLeft, setSplitsLeft] = useState(0)
    const { state, loading, error, submitted } = values;
    const [data, setData] = useState(0)

    useEffect(() => {

        const handleSubmit = async () => {
                submitting.current = true
                splitsLeft.current = state.recentlyApproved.filter(s => (parseInt(s.status) === 2 && s.isPrimary)).length - 1
                setData({ contributors: state.selectedSong.contributors.length, songTitle: state.selectedSong.song_title })
                await onSubmit()
                submitting.current = false
        }
        if (!submitted && !submitting.current && state.selectedSong.id !== undefined)
            handleSubmit()

    }, [state])

    if (loading) {
        return (
            <Wrapper>
                <h1 style={{ textTransform: 'none' }}>Submitting</h1>
                <h2 style={{ textTransform: 'none' }}>This may take a few minutes.</h2>
                <h2 style={{ textTransform: 'none' }}>If you have any trouble submitting, please <NavLink href="mailto:info@azaamusic.com">contact us</NavLink>.</h2>
                <LoadingWheel hideWrapper></LoadingWheel>
            </Wrapper>
        )
    }
    else if (error) {
        return (
            <Wrapper>
                <h1 style={{ textTransform: 'none' }}>There has been an error.</h1>
                <h2 style={{ textTransform: 'none' }}>Please try again later.</h2>
                <h2 style={{ textTransform: 'none' }}>If you have further trouble submitting, please <NavLink href="mailto:info@azaamusic.com">contact us</NavLink>.</h2>
            </Wrapper>

        )
    }
    else if (submitted && state.tableData !== undefined) {

        //const s_splits_not_set = state.tableData.filter(s => parseInt(s.status) === 2).length - 1;
        return (
            <Wrapper>
                <BananaWrapper><img src={Bananas} alt="banana-split" /></BananaWrapper>
                <h1>Success!</h1>
                <h2>{`You’ve set pricing & splits for your song, '${data.songTitle}'!`}</h2>
                {splitsLeft.current > 0 && <h2>You still have <strong>{splitsLeft.current} song{splitsLeft.current > 1 ? 's' : ''} to go</strong>. Make sure you visit Recently Approved to complete your splits and pricing within <strong>5 days</strong> of approval.</h2>}
                {data.contributors > 1 && <h2>Your song will be placed on the market following your contributors' approval.</h2>}
                <ButtonWrapper splitsLeft={splitsLeft.current > 0}>
                    {splitsLeft.current > 0 && <Button
                        onClick={() => {
                            // navigate('/recently-approved')
                            handleClose()
                        }}
                        text={"Let's Do it!"}
                        width="198px"
                        background="transparent"
                        border="2px solid rgba(255,255,255,0.25)"
                    ></Button>}
                    <Button
                        onClick={() => { navigate('/dashboard') }}
                        text={splitsLeft.current > 0 ? "Procrastinate" : 'Dashboard'}
                        width="198px"
                        background="transparent"
                        border="2px solid rgba(255,255,255,0.25)"
                    ></Button>
                </ButtonWrapper>
            </Wrapper>
        )
    }
    else {
        return <Wrapper></Wrapper>
    }

}

export default Success
