import React, { useState, useEffect } from 'react';

import {
    OuterWrapper,
    TextWrapper,
    Wrapper,
    SideBar,
    GridWrapper,
    ColumnWrapper,
    RowWrapper,
    Header
} from './FAQ.styles'

import LoadingWheel from '../LoadingWheel'
import QAModule from './QAModule'
import Button from '../Button'

import API from '../../API'

const FAQ = () => {
    const text = "If there is a question you have that is not answered here, please reach out to us! We are here 24/7 and committed to our fellow songwriters and A&R’s."
    const [loading, setLoading] = useState(true)
    const [faq, setFaq] = useState([])
    useEffect(() => {
        setLoading(true)
        API.fetchFAQ().then((res) => {
            setFaq([...res])
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }, [])

    if (loading) {
        return (<Wrapper isLoading={loading}><LoadingWheel /></Wrapper>)
    } else {
        return (
            <OuterWrapper>
                <Wrapper>
                    <SideBar>
                        <Header>FAQ</Header>
                        <TextWrapper>{text}</TextWrapper>
                        <form action="mailto:info@azaamusic.com">
                            <Button
                                maxWidth="500px"
                                width="100%"
                                border="1px solid #ffffff"
                                text="Contact Us" />
                        </form>

                    </SideBar>
                    <RowWrapper>

                        <ColumnWrapper>
                            {faq.map((qa, i) => (
                                <QAModule
                                    key={i}
                                    question={qa.question}
                                    answer={qa.answer}
                                />
                            ))}
                        </ColumnWrapper>
                    </RowWrapper>
                    {/* </GridWrapper> */}
                </Wrapper>
            </OuterWrapper>
        )
    }
};

export default FAQ;
