import styled from "styled-components";

export const BackgroundWrapper = styled.div`
position: fixed;
top: 0;
right: 0;
width: 100%;
height: 100%;
overflow: hidden;
`;

export const Wrapper = styled.div`
width: 100%;
display: grid;
grid-template-columns: ${props => props.showTile ? 'repeat(3, 1fr)' : '1fr'};
text-transform: uppercase;
font-size: var(--fontSmall);
font-weight: 700;
letter-spacing: 0.1rem;
justify-content: center;
align-items: center;
grid-column-gap: 10px;
`;

export const DropdownListContainer = styled.div`
    
`;

export const DropdownContainer = styled.div`
    height: var(--defaultInputHeight);
    width: 100%;
    position: relative;
    background: var(--greyBackground);
   
    
`;

export const DropdownHeader = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 20px;
    user-select: none;
    img{
        width: 12px;
        position: absolute;
        right: 20px;
    }
    cursor: pointer;
    
`;

export const DropdownList = styled.ul`
margin: 0;
padding: 0;
position: relative;
z-index: 1;
box-shadow: 0 5px 3px -1px rgb(0,0,0);
background: var(--greyBackground);

`;

export const ListItem = styled.li`
    height: var(--defaultInputHeight);
    list-style: none;
    display: flex;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'space-between'};
    padding: 0 20px;
    margin: 0;
    align-items: center;
    user-select: none;
    cursor: pointer;
    width: 100%;
    background: ${props => props.isSelected? 'rgba(255,255,255,0.2)' : 'transparent'};
    color: ${props => props.isSelected? 'rgb(255,255,255)' : 'var(--purpleGrey)'};
    
`;

export const OptionTile = styled.div`
border: 1px solid rgb(255,255,255);
display: flex;
align-items: center;
padding: 0 20px;
justify-content: space-between;
user-select: none;
height: 90%;

`;

export const RemoveButton = styled.div`
    cursor: pointer;
    position: ${props => props.isDash ? 'absolute' : ''};
    right: ${props=> props.isDash ? '20px' : ''};

`;

export const DropdownArrow = styled.img`
    transform: ${props => props.shouldRotate? 'rotate(180deg)' : '0'};
`;

export const LabelStyled = styled.label`
    position: relative;
    font-size:  1em;
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;