import styled from "styled-components";

export const Wrapper = styled.div`
display: grid;
flex-direction: column;
align-items: center;
justify-content: space-between;
text-align: center;
justify-items: center;
flex-basis: 33%;
white-space: nowrap;
flex-grow: 1;
grid-row-gap: 20px;
grid-template-rows: min-content 1fr;
align-items: start;
`;

export const Image = styled.img`
width: auto;
height: 68px;
`;

export const Text = styled.div``;