import React, { useState, useRef, useEffect } from 'react'

import DropdownIcon from '../../../images/dropdownArrow.svg'

import { DropdownContainer, DropdownHeader, DropdownListContainer, DropdownList, ListItem, DropdownArrow, LabelStyled } from './Dropdown.styles'

const CloseDropdown = ({ shouldRotate }) => (

    <DropdownArrow src={DropdownIcon} alt="dropdown-arrow" shouldRotate={shouldRotate} />
)

const Label = ({ name, label, marginBottom }) => (
    <LabelStyled htmlFor={name} marginBottom={marginBottom}>{label}</LabelStyled>

)

const Option = ({ value, defaultSelect, innerText, onClick, isSelected }) => (
    <ListItem defaultSelect={defaultSelect} value={value}
        isSelected={isSelected}
        onClick={onClick}>
        {innerText}</ListItem>
);

const Dropdown = ({ label, name, marginBottom, options, setState, handleStateChange, valueX, key, id }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(valueX)

    const [currentVal, setCurrentVal] = useState(valueX)

    const initial = useRef(true);

    const handleChange = (event) => {
        setIsOpen(!isOpen)

    }

    const selectNew = (event) => {

        setValue(event.target.innerHTML);
        // setState(event.target.innerHTML);
        //handleStateChange('songList')
        handleStateChange()
        setIsOpen(!isOpen)
    }

    const selectNew2 = (event) => {
        event.preventDefault();
        handleStateChange(event)
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Label
                name={name}
                label={label}
                marginBottom={marginBottom}
            ></Label>
            <DropdownContainer id={id}>
                <DropdownHeader
                    onClick={handleChange}
                >{valueX}
                    <CloseDropdown shouldRotate={isOpen} />
                </DropdownHeader>
                {isOpen && (
                    <DropdownListContainer>
                        <DropdownList>
                            {
                                options.map(option => (
                                    <Option
                                        value={option.value}
                                        innerText={option.innerText}
                                        key={option.value}
                                        defaultSelect={option.isDefault}
                                        onClick={selectNew2} //was selectNew
                                        isSelected={valueX.includes(option.value)}
                                    />

                                ))
                            }
                        </DropdownList>
                    </DropdownListContainer>
                )}


            </DropdownContainer>
        </>
    )
}

export default Dropdown
