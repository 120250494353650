import React, { useState, useEffect, useRef, useMemo, Suspense } from 'react'
import * as THREE from 'three'
import { Canvas, extend, useLoader, useFrame } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { PositionalAudio, PointMaterial, useTexture, useGLTF, Environment, Loader, PerspectiveCamera } from '@react-three/drei';

import Sphere from './Sphere'
import VisualizerControls from './VisualizerControls'

import {
    Wrapper,
    H4
} from './Visualizer.styles'

import LoadingWheel from '../LoadingWheel'
import Toggle from '../Toggle'
import Flexbox from '../Flexbox'
import GridItem from '../GridItem'
import Slider from '../Slider'
// import VertexShader from '../../shaders/vertex.glsl'
// import FragmentShader from '../../shaders/fragment.glsl'

import { useVisualizerHook } from '../../hooks/useVisualizerHook'


import CloseIcon from '../../images/close.svg'

import { roundValue } from '../../helpers'


const Visualizer = ({
    sound,
    toggleControls,
    ...props }) => {

    const [loading, setLoading] = useState(false)

    // const [sceneWidth, setSceneWidth] = useState(window.innerWidth)

    const {
        directionLightRef,
        directionLightRef2,
        backlightRef,
        canvasRef
    } = useVisualizerHook()
    // if (loading) return <Wrapper><LoadingWheel height="100vh" top="0px" /></Wrapper>
    // else 
    return (
        <>
            <Flexbox position="absolute" width="100vw" height="100vh">
                <Canvas
                    ref={canvasRef}
                    dpr={Math.max(window.devicePixelRatio, 2)}
                    // gl={{ antialias: false }}
                    camera={{ fov: 75, aspect: window.innerWidth / window.innerHeight, near: 0.01, far: 5000, position: [0, 0, 50] }}>
                    <ambientLight intensity={0.1} />
                    <directionalLight intensity={1.0} position={[0, 1, 1]} ref={directionLightRef} />
                    <directionalLight intensity={1.0} position={[0, -1, 1]} ref={directionLightRef2} />
                    <directionalLight intensity={1.0} position={[0, 0, 1]} ref={backlightRef} />
                    <pointLight intensity={1.0} position={[0, 0, 20]} color="#ffffff" />
                    <pointLight intensity={0.5} position={[20, 20, -20]} color="#ffffff" />
                    <pointLight intensity={0.9} position={[-10, -10, 10]} color="#ffffff" />
                    <Sphere sound={sound} canvasRef={canvasRef} {...props} />
                </Canvas>
            </Flexbox >
        </>
    )
}

export default Visualizer
