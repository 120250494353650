import styled from "styled-components";

export const Wrapper = styled.div`
    background: transparent;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(255,255,255, 0.25);
    background: ${props => (props.itemIndex + 1) === (props.currentIndex + 1) && props.step != 1 ?  'rgba(255,255,255,1)' : 'transparent' };
    color: ${props => (props.itemIndex + 1) === (props.currentIndex + 1) && props.step != 1 ?  'rgba(0,0,0,1)' : '#fff' };
    font-weight: ${props => (props.itemIndex + 1) === (props.currentIndex + 1) && props.step != 1 ?  '700' : '400' };
    
    border-radius: 20px;
    position: relative;
    width: 180px;
    height: 40px;
    letter-spacing: 0.1rem;
    font-size: 10px;

    >div{
        width: calc(100% - 60px);
        text-overflow: ellipsis;
        white-space: nowrap;
        left: 20px;
        position: relative;
        overflow: hidden;
    }

`;

export const Button = styled.button`
    all: unset;
    font-family: var(--defaultFont);
    color: ${props => (props.itemIndex + 1) === (props.currentIndex + 1) && props.step != 1 ?  'rgba(0,0,0,1)' : '#fff' };
    position: ${({ buttonPosition }) => buttonPosition || 'absolute'};
    right: ${({ buttonRight }) => buttonRight || '20px'};
    opacity: ${({ buttonOpacity }) => buttonOpacity};
    cursor: pointer;

`;