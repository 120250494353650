import React, { useState, useEffect, useRef } from 'react'

import InputField from '../../InputField';
import FormNavArrow from '../FormNavArrow';
import Dropdown from '../Dropdown';
import DropdownMultiSelect from '../DropdownMultiSelect';
import LoadingWheel from '../../LoadingWheel';
import API from '../../../API'

import NextArrow from '../../../images/formNavArrow.svg'

import { validateEmail } from '../../../helpers.js'

import { Wrapper, QuestionWrapper, NavWrapper } from './UserDetails.styles'


import Button from '../../Button'
import GridItem from '../../GridItem'
import { useAuth } from '../../../contexts/AuthContext'

const UserDetails = ({
    vettedEmail,
    setBadEmails,
    badEmails,
    setVettedEmail,
    setLoading,
    loading,
    error, setError, nextButtonText, value, prevStep, nextStep, currStep, fieldName, handleChange, handleKeyPress }) => {
    const { sendResetPasswordEmail } = useAuth()

    const validateInstagram = (handle) => {
        const re = /^(?!.*\.\.)(?!.*\.$)[^\W](?![0-9]*$)[a-zA-Z0-9][\w.]{0,29}[\w](?!.*?\.{2})$/;
        const handleStripped = handle.split('@').length > 1 ? handle.split('@')[1] : handle.split('@')[0];
        return re.test(String(handleStripped));
    }




    let placeholder, label;
    switch (fieldName) {
        case 'name':
            placeholder = 'Enter your name'.toUpperCase();
            label = "What's your name?";
            break;
        case 'email':
            placeholder = 'Email address'.toUpperCase();
            label = "What's your email address?";
            break;
        case 'spotify':
            placeholder = 'Spotify link';
            label = "What's your Spotify? (Optional)"
            break;
        case 'instagram':
            placeholder = '@';
            label = "What's your Instagram Handle? (Optional)"
            break;
        case 'publisher':
            placeholder = 'Affiliation';
            label = "Publisher/Label/Mgmt Affiliation (Optional)"
            break;

    }

    const [validContinue, setValidContinue] = useState(false);
    const [sending, setSending] = useState(false)
    const buttonOptions = {
        'user-accepted': {
            text: 'Resend Registration Email',
            onClick: async () => {
                console.log("value: ", value)
                await API.resendUserAcceptanceEmail(value)}
        },
        'account-exists': {
            text: 'Send Password Reset Email',
            onClick: async () => {
                console.log("value: ", value)
                await sendResetPasswordEmail(value)
            }
        }
    }




    const [button, setButton] = useState({
        showButton: false,
        ...buttonOptions['user-accepted']
    })

    const buttonOnClick = async (e) => {
        e.preventDefault()
        try {
            setSending(true)
            await button.onClick()
            setError('Email sent')
            setSending(false)
        } catch (error) {
            setSending(false)
            setError('An error occurred. Please try again later.')
        }
    }

    const initial = useRef(true);

    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            validateStep()
            return;
        }
        validateStep()
        checkAgainstBadEmailsList()
    }, [value])

    const validateStep = () => {

        if (fieldName === 'email') {
            if (validateEmail(value)) {
                setValidContinue(true)
                return true
            }
            setValidContinue(false)
            return false
        }
        else if (fieldName === 'publisher') {
            setValidContinue(true)
            return true
        }
        else if (fieldName !== 'instagram' && value !== '' || fieldName === 'spotify') {
            setValidContinue(true)
            return true
        }
        else if (fieldName === 'instagram') {
            if (value === null || value === '' || validateInstagram(value)) {
                setValidContinue(true)
                return true
            }
            else {
                setValidContinue(false)
                return false
            }

        }
        else {
            setValidContinue(false)
            return false
        }
    }

    const checkAgainstBadEmailsList = () => {
        const _checkIfTried = badEmails.find(it => it.email === value)
        if (_checkIfTried) {
            setButton({
                showButton: true,
                ...buttonOptions[_checkIfTried.type]
            })
            setError(_checkIfTried.message)
        }
    }

    const checkEmail = async () => {
        try {
            setError(false)
            setLoading(true)
            setButton(prev => ({
                ...prev,
                showButton: false,
            }))
            const _checkIfTried = badEmails.find(it => it.email === value)
            if (_checkIfTried)
                throw _checkIfTried.type
            if (value !== vettedEmail) {
                await API.authNewSignup(value)
            }
            setError(false)
            nextStep(currStep);
            setVettedEmail(value)
            setLoading(false)

        } catch (err) {
            let errMessage, errText;
            if (err.response !== undefined) {
                errMessage = err.response.data.message
                if (errMessage.includes('buyer')){
                    errMessage = errMessage + ' If you would like you sign up for a songwriter account, please use a different email.'
                }
                errText = err.response.data.text
                setBadEmails(prev => ([...prev, { email: value, type: errText, message: errMessage }]))
            } else {
                errText = err
                errMessage = badEmails.find(it => it.email === value).message
            }
            setError(errMessage)
            setButton({
                showButton: true,
                ...buttonOptions[errText]
            })
            setValidContinue(false)
            setLoading(false)
        }
    }

    const handleStepChange = () => {
        if (fieldName === 'email') {
            checkEmail()
        }
        else {
            nextStep(currStep);
        }
    }

    const continueStep = (event) => {
        event.preventDefault();

        if (validateStep()) {
            handleStepChange()
        }
    }


    const previousStep = (event) => {
        event.preventDefault();
        prevStep()
    }

    const continueKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleKeyPress(event)
            if (validateStep()) {
                handleStepChange()
            }
        }

    }

    return (
        <NavWrapper>
            <FormNavArrow
                direction='back'
                text='back'
                onClick={previousStep}
                continueValid={currStep} />
            <GridItem
                width="446px"
                height="374px"
                justifyContent="center"
                alignItems="center"
                fontSize="1rem"
            >
                {!loading &&
                    <QuestionWrapper>
                        <InputField
                            onKeyPress={continueKey}
                            type={fieldName === 'email' ? "email" : 'text'}
                            onChange={handleChange(`${fieldName}`)}
                            name={fieldName}
                            // label={fieldName !== 'email' ? label : fieldName === 'email' && !error ? label : error}
                            label={label}
                            placeholder={placeholder}
                            marginBottom={'20px'}
                            value={value}
                            uppercaseOff={true}
                        />
                    </QuestionWrapper>
                }
                {(button.showButton && fieldName === 'email' && badEmails.findIndex(it => it.email === value) >= 0) &&
                    <GridItem position="absolute" margin="200px 0 0" width="400px" display="grid" gridTempCols="1fr">
                        <Button
                            hoverColor="#ffffff"
                            color="var(--background)"
                            background="#ffffff"
                            disabled={loading || sending}
                            border={!loading && !sending ? "1px solid #ffffff" : '1px solid rgba(255,255,255,0.1)'}
                            validContinue={!loading && !sending}
                            width="100%"
                            text={button.text}
                            onClick={buttonOnClick}
                        />
                        <div style={{ height: '45px', marginTop: '20px' }}>{error}</div>
                    </GridItem>

                }
                {
                    loading && <LoadingWheel />
                }

            </GridItem>

            <FormNavArrow
                direction='next'
                text={'next'}
                onClick={continueStep}

                continueValid={validContinue}
            />
        </NavWrapper>
    )
}

export default UserDetails
