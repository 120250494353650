import styled from "styled-components";

export const OuterWrapper = styled.div`
overflow: hidden;
z-index: 3; //check
width: 100%;
height: ${props => props.isOpen ? props.maxOpen ? '100%' : 'calc(50% - 35px)' : '60px'};
background: ${props => props.isOpen && props.maxOpen ? 'var(--dropdownBackground)' : 'rgba(0, 0, 0, 0.75)' };
border-top: ${props => props.isOpen ? '1px solid var(--dropdownBackground)' : '1px solid var(--dropdownBackground)'};
transition: height 0.5s, bottom 0.5s;
box-shadow: 0 0 10px 2px rgba(0,0,0,0.5);
position: absolute;
bottom: ${props => props.showControls ? '-100%' : '0'};
`;

export const Wrapper = styled.div`
width: 100%;
height: ${props => props.isOpen ? '100%' : ''};
padding-bottom: ${props => props.isOpen ? '65px' : ''};
position: relative;
letter-spacing: 0.03rem;
bottom: ${props => props.offset && !props.isOpen ? props.offset : props.isOpen ? '0' : ''};

`;

export const Action = styled.div`
width: 20px;
height: 20px;
padding: 3px;
position: relative;
display: flex;
align-items: center;
justify-content: center;
opacity: ${props => props.opacity ? props.opacity : 1};
svg{
    height: 90%;
    width: 90%;
}
`;

export const AlertIcon = styled(Action)`
position: ${props => props.position ? props.position : 'relative'};
padding: 3px;
right: ${props => props.right ? props.right : ''};
`;

export const NextUp = styled.div`
font-size: 12px;
background: ${props => props.isOpen && props.maxOpen ? 'var(--dropdownBackground)' : ' #000000' };
outline: ${props => props.isOpen && props.maxOpen ? ' 5px solid var(--dropdownBackground)' : '5px solid  #000000' };
height: 60px;
user-select: none;
text-transform: uppercase;
display: flex;
align-items: center;
position: absolute;
right: 3.2vw;
bottom: 0;
color: ${props => props.newApproval ? 'var(--yellow)' : '#ffffff'};
font-weight: ${props => props.newApproval ? '600' : '300'};
`;

export const InnerWrapper1 = styled.div`
display: flex;
position: relative;
width: 100%;
height: 100%;
flex-direction: column;
`;

export const InnerWrapper2 = styled.div`
overflow: auto;
display: flex;
position: relative;
width: 100%;
height: 100%;
flex-direction: column;
`;

export const ScrollWrapper = styled.div`
position: relative;
scroll-behavior: smooth;
overflow-x: ${props => props.overflowX ? props.overflowX : 'hidden'};
height: ${props => props.expandedContainerHeight ? props.expandedContainerHeight : ''};
padding-bottom: ${props => props.paddingBottom ? props.paddingBottom : '0'};
`;