
export class Genre {
    country = 'COUNTRY'
    dance = 'DANCE'
    indie = 'INDIE'
    pop = 'POP'
    rap = 'RAP'
    genre = []
    constructor(data) {
        if (data && data.genre) {
            if (data.genre.length > 0)
                if (data.genre.reduce((a, b) => { return ![this.country, this.dance, this.indie, this.pop, this.rap].includes(b.toUpperCase()) ? false : a }, true))
                    this.genre = [...data.genre]
                else
                    this.genre = [this.pop]
        }
        else
            this.genre = [this.pop]
    }

    validGenres() {
        return [this.country, this.dance, this.indie, this.pop, this.rap]
    }

}

export class Tempo {
    downtempo = 'Downtempo (50-80BPM)'
    midtempo = 'Midtempo (80-105BPM)'
    uptempo = 'Uptempo (+105BPM)'

    constructor(data) {
        if (data && data.tempo) {
            if ([this.downtempo, this.midtempo, this.uptempo].includes(data.tempo))
                this.tempo = data.tempo
            else
                this.tempo = this.uptempo
        }
        else
            this.tempo = this.uptempo
    }
    validTempos() {
        return [this.downtempo, this.midtempo, this.uptempo]
    }
}

export class Song {
    constructor(data) {
        this.genre = new Genre(data.genre)
    }
}

export class BuyerDashSongRow {

    constructor(data) {

    }
}