import React, { useState, useEffect, useRef } from 'react'
import { keyframes } from 'styled-components'
import {
    Wrapper,
    SkipWrapper,
    MusicToggleButton,
    ControlsWrapper,
    LogoWrapper,
    SongDetailsHeader,
    SongStatusNote,
    PricingTable
} from './Dashboard.styles'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { adjustPrice, calculateCut, calculatePriceWithFee } from '../../helpers'
import LoadingWheel from '../LoadingWheel'
import { useBuyerDash } from '../../hooks/useBuyerDash'
import { useAudioHook } from '../../hooks/useAudioHook'
import { useBuyerResponseHook } from '../../hooks/useBuyerResponseHook'
import { useVisualizerHook } from '../../hooks/useVisualizerHook'

import API from '../../API'

import DashBarBuyer from './DashBarBuyer'
import Arrow from '../../images/formNavArrow.svg'
import Play from '../../images/playBordered.svg'
import Pause from '../../images/pauseBordered.svg'
import AzaaLogo from '../../images/azaaLogo.svg'
import Close from '../../images/close.svg'

import SongDetailsTable from '../SongDetailsTable'
import Button from '../Button'
import GridItem from '../GridItem'
import Modal from '../Modal'
import Visualizer from '../Visualizer'
import DashFilters from './DashFilters'
import BufferPlaylist from './BufferPlaylist'
import BuyerDashModal from './BuyerDashModal'
import Sphere from '../Visualizer/Sphere'
import VisualizerControls from '../Visualizer/VisualizerControls'

const PlayButton = ({ onClick, playing, setPlaying }) => {

    return (
        <MusicToggleButton onClick={onClick} src={playing ? Pause : Play} alt={playing ? "pause-button" : "play-button"} />
    )

}

const SkipSong = ({ skipBackValid, direction, gridArea, onSkip }) => {
    return (
        <SkipWrapper opacity={direction === 'next' || skipBackValid ? '1' : '0.25'} direction={direction} gridArea={gridArea} onClick={() => onSkip(direction === 'next' ? 1 : -1)}>
            <img src={Arrow} alt={direction} />
        </SkipWrapper >
    )
}

const Controls = ({ audioRef, skipBackValid, setOpacity, transition, nowPlaying, handleSongChange, setPlaying, playing, onSkip }) => {

    return (
        <ControlsWrapper setOpacity={setOpacity} transition={transition}>
            <SkipSong skipBackValid={skipBackValid} direction="last" gridArea="last" onSkip={onSkip} />
            <PlayButton playing={playing}
                onClick={() => {
                    setPlaying(!playing)
                    // if (audioRef.current) {
                    //     if (audioRef.current.isPlaying)
                    //         audioRef.current.pause()
                    //     else
                    //         audioRef.current.play()
                    // }

                }} />
            <SkipSong direction="next" gridArea="next" onSkip={onSkip} />
        </ControlsWrapper>
    )
}

const BuyerDashboard = () => {

    const {
        payload,
        initialRender,
        modalType,
        closeModal,
        setShowModal,
        prepPayload,
        showModal,
        setPayload,
        isOpen,
        setIsOpen,
        songs,
        songBuffer,
        setSongBuffer,
        setSongs,
        selectedSong,
        setSelectedSong,
        showBufferPlaylist,
        setShowBufferPlaylist,
        changeCurrPlaylistHeader,
        closeBufferPlaylist,
        pullSongs,
        loading,
        setLoading,
        error,
        setError,
        page,
        setPage,
        totalResults,
        resultsPerPage,
        filterDropdowns,
        filter,
        playlist,
        setShowFilterDropdown,
        showFilterDropdown,
        handleGenreChange,
        nbHits,
        fetchMore } = useBuyerDash()

    const {
        file,
        audioRef,
        skipBackValid,
        playing,
        togglePlayPause,
        nowPlaying,
        handleSongChange,
        handlePlaylistChange,
        cleanupPlayingSong,
        onSkip,
        listener,
        loader
    } = useAudioHook({ changeCurrPlaylistHeader, setSongs, songs, songBuffer, setSongBuffer, payload })

    const { handleFavorites } = useBuyerResponseHook({ playlist, setShowModal, initialRender, setPayload, setSongs, setSongBuffer, songBuffer, cleanupPlayingSong, songs, pullSongs, filter })

    const {
        showControls,
        setShowControls,
        controls,
        setControls,
    } = useVisualizerHook()
    console.log("playing", playing, nowPlaying)

    // if (loading) return <Wrapper><LoadingWheel height="100vh" top="0px" /></Wrapper>
    // else 
    return (
        <Wrapper>
            <Visualizer
                onSkip={onSkip}
                nowPlayingSongId={nowPlaying}
                sound={audioRef}
                listener={listener}
                loader={loader}
                audioFile={file}
                showControls={showControls}
                setShowControls={setShowControls}
                controls={controls}
                setControls={setControls}
            >
                {/* <Sphere sound={audioRef} controls={controls} /> */}
            </Visualizer>
            <DashFilters
                toggleControls={() => {
                    setShowControls(!showControls)
                }}
                filterDropdowns={filterDropdowns}
                filter={filter}
                playlist={playlist}
                showBufferPlaylist={showBufferPlaylist}
                setShowFilterDropdown={setShowFilterDropdown}
                showFilterDropdown={showFilterDropdown}
                handleGenreChange={handleGenreChange}
            />
            <LogoWrapper><img src={AzaaLogo} alt="logo" /></LogoWrapper>
            <Controls
                audioRef={audioRef}
                skipBackValid={skipBackValid}
                playing={playing}
                setOpacity={'1'}
                transition="opacity 0.25s linear"
                onSkip={onSkip}
                setPlaying={togglePlayPause}
                handleSongChange={handleSongChange}
            />
            <GridItem width="200px" bottom="20vh" position="fixed" transition="opacity 0.5s linear">
                <Button
                    text="Purchase"
                    setOpacity={isOpen ? '0' : '1'}
                    transition="opacity 0.25s linear"
                    disabled={isOpen}
                    onClick={() => { }}
                    width="100%"
                    hoverColor='rgba(255,255,255,0.15)'
                    border="1px solid #ffffff"
                    background="rgba(0,0,0,0.55)"
                />
            </GridItem>
            <DashBarBuyer
                // newApproval={newApproval}
                showControls={showControls}
                isOpen={isOpen}
                hideNextUp={showBufferPlaylist}
                prepPayload={prepPayload}
                payload={payload}
                setShowBufferPlaylist={setShowBufferPlaylist}
                setIsOpen={setIsOpen}
                setShowModal={setShowModal}
                content={songs}
                handleFavorites={handleFavorites}
                handleSongChange={handleSongChange}
                setSelectedSong={setSelectedSong}
                playlistType={playlist.currPlaylist}
                nowPlaying={nowPlaying}
                playing={playing}
                nbHits={nbHits}
                fetchMore={fetchMore} />
            <BufferPlaylist
                handlePlaylistChange={handlePlaylistChange}
                showBufferPlaylist={showBufferPlaylist}
                nowPlaying={nowPlaying}
                prepPayload={prepPayload}
                closeBufferPlaylist={closeBufferPlaylist}
                setShowBufferPlaylist={setShowBufferPlaylist}
                playlistType={playlist.bufferPlaylist}
                currPlaylist={playlist.currPlaylist}
                setShowModal={setShowModal}
                bufferPlaylist={songBuffer}
                handleSongChange={handleSongChange}
                handleFavorites={handleFavorites}
                setSelectedSong={setSelectedSong}
                nbHits={nbHits}
                fetchMore={fetchMore}
            />
            <BuyerDashModal
                payload={payload}
                modalType={modalType}
                closeModal={closeModal}
                showModal={showModal}
            />
            <VisualizerControls showControls={showControls} controls={controls} setControls={setControls} setShowControls={setShowControls} />
        </Wrapper >
    )
}

export default BuyerDashboard
