import styled from 'styled-components'
import { Wrapper } from '../Flexbox/Flexbox.styles'

export const OpenEmailButton = styled(Wrapper)`
cursor: pointer;
border-radius: 5px;
padding: 5px;
gap: 10px;
/* border: 1px solid #fff; */
box-shadow: 0 0 5px #000;
margin: 5px;
`;

export const NavLink = styled.a`
text-decoration: none;
color: var(--yellow);
align-self: flex-start;
font-size: 16px;
`;

export const EmailLink = styled.div`
text-decoration: none;
color: var(--yellow);

font-size: 16px;
user-select: all;
`;

export const EmailIcon = styled.img`
height: 20px;
width: 20px;
object-fit: contain;
`;

export const CheckboxStyle = styled.label`
margin-bottom: 2px;
display: flex;
align-items: start;
justify-content: start;
/* font-size: var(--fontSmall); */
/* color: var(--purpleGrey); */
font-weight: 500;
letter-spacing: 0.02rem;
white-space: nowrap;
position: relative;
width: min-content;
span{
    margin-left: 5px;
    display: inline-block;
    width: 15px;
    height: 15px;
    background: rgba(255,255,255,0.5);
    position: relative;
    box-shadow: 0 0 1px 1px inset #000;
    cursor: pointer;
}

strong{
    margin-left: 0.2rem;
    color: #ffffff;
    font-weight: 700;
}

input{
    position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

input:checked ~ span{
    background: rgb(255,255,255);
    box-shadow: 0 0 1px 1px inset #000;
}

input:checked ~ span:after{
    content: "";
    position: relative;
    display: block;
 top: 1px;
  width: 3px;
  height: 8px;
  margin: auto auto;
  border: solid rgb(0,0,0);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`;

