import styled from "styled-components";

export const Wrapper = styled.div`

`;

export const Innercontainer = styled.div`
width: min-content;
height: 100%;
height: min-content;
padding: ${props => props.padding ? props.padding : ''};
position: relative;
`;


export const Row = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
grid-template-rows: min-content min-content;
row-gap: 10px;
width: 100%;
margin-bottom: 10px;
text-transform: uppercase;
`;

export const NavArrow = styled.div`
height: 15px;
width: 15px;
opacity: ${props => props.isValid ? '1' : '0.25'};
transform: ${props => props.back ? 'rotate(180deg)' : ''};
display: flex;
align-items: center;
justify-content: center;
object-fit: contain;
img{
    height: 10px;
    width: 10px;
    object-fit: contain;
}
`;

export const DateWrapper = styled.div`
font-size: 10px;
display: flex;
text-align: right;
justify-content: flex-end;
align-items: center;
`;

export const LoadWrapper = styled.div`
width: 100%;
height: 100%;
max-height: calc(100% - 50px);
position: relative;
display: flex;
justify-content: center;
align-items: center;
border: 1px solid var(--greyBackground);
    margin-top: 2px;
`;