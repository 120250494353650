import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'

export const Wrapper = styled.div`
    background: var(--background);
    width: 100%;
    height: 100vh;
    max-height: 56.25vw;
    position: relative;
    display: flex;
    overflow: visible;
    align-items: center;


    @media screen and (max-width: 600px){
        max-height: 80vh;
    }
    @media only screen 
    and (max-device-width: 812px) 
    and (-webkit-min-device-pixel-ratio: 3) {
        max-height: 80vh;
    }
    @media only screen 
    and (max-device-width: 360px) 
    and (-webkit-device-pixel-ratio: 4) {
        max-height: 80vh;
    }
    @media only screen 
    and (max-device-width: 667px) 
    and (-webkit-min-device-pixel-ratio: 2) {
        max-height: 80vh;
    }
    
    
`;


export const VideoHeader = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    top: 0;
`;

export const ImageHeader = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    visibility: ${props => props.visibility ? props.visibility : 'hidden'};
    top: 0;
    /* opacity: ${props => props.opacity ? props.opacity : '1'}; */
    
    /* transition: opacity 0.5s; */
    /* animation: fadein 5s ease-in alternate infinite;

    @keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
    }

    @keyframes fadein2 {
    from { opacity: 1; }
    to { opacity: 0; }
    }

    #image1{
        animation: fadein 5s ease-in alternate infinite;
    }
    #image2{
        animation: fadein2 5s ease-in alternate infinite;
    } */

`;

export const OverlayWrapper = styled.div`
    display: grid;
    position: absolute;
    width: 32.75%;
    width: 28%;
    max-width: 470px;
    /* top: var(--navBarHeight); */
    left: 3.1%;
    grid-template-columns: 3fr 1fr 3fr;
    grid-template-rows: min-content 60px;
    grid-template-areas:
        "img img img"
        "L2 . R2";

        grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    /* padding: 100px 0 50px; */
    height: calc(100% - var(--navBarHeight) - 6.2vw);
    max-height: 56.25vw;
    margin-top: var(--navBarHeight);
    grid-template-rows: 1fr 60px;
    grid-template-areas:
        "img img"
        "L2 R2";



        @media screen and (max-width: 600px){
        width: 100%;
        padding: 0 30px 30px;
        top: 0;
        height: calc(80vh - 50px);
        max-height: calc(80vh - 50px);
        left: 0;
        }
        @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            width: 100%;
            top: 0;
            height: calc(80vh - 50px);
            max-height: calc(80vh - 50px);
            padding: 0 30px 30px;
            left: 0;
        }
        @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            width: 100%;
            top: 0;
            height: calc(80vh - 50px);
            max-height: calc(80vh - 50px);
            padding: 0 30px 30px;
            left: 0;
        }
        @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            width: 100%;
            top: 0;
            height: calc(80vh - 50px);
            max-height: calc(80vh - 50px);
            padding: 0 30px 30px;
            left: 0;
        }
`;

export const LogoGlassWrapper = styled.div`
display: none;
height: 100%;
width: 100%;
svg{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
@media screen and (max-width: 600px){
        display: flex;
        }
        @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            display: flex;
        }
        @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            display: flex;
        }
        @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            display: flex;
        }
`;

export const LogoWrapper = styled.div`
    position: relative;
    grid-area: img;
    background-image: url(${(props) => props.backgroundIMG});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    
    /* min-height: 28vw; */
    height: 100%;
    /* height: 100%; */
    width: 100%;
    /* display: flex;
    width: 100%;
    height: 100%; */
    @media screen and (max-width: 600px){
        background-image: none;
        }
        @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            background-image: none;
        }
        @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            background-image: none;
        }
        @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            background-image: none;
        }
    
`;

export const LogoOverlay = styled.img`
    /* max-width: 100%; */
    position: relative;
    object-fit: contain;
    height: auto;
    max-width: 100%;
    /* max-width: 100%; */
    @media screen and (max-width: 500px){
        width: 80px;
    }
`;
// isSellButtonDesktop
export const ButtonStyle = styled.div`
    display: flex;
    background: #ffffff;
    color: #000000;
    justify-content: center;
    align-items: center;
    height: 60px;
    font-size: 1.625em;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-align: center;
    cursor: pointer;
    font-family: var(--defaultFont);
    border: none;
    width: 100%;
    grid-area: ${props => props.position === 'left' ? 'L2' : 'R2'};
    display: ${props => props.isSellButtonMobile ? 'none' : 'flex'};
    :hover{
        background: rgba(0, 0, 0, .5);
        border: 1px solid var(--white);
        color: var(--white);
    }

    @media screen and (max-width: 600px){
        display: ${props => props.isSellButtonDesktop ? 'none' : 'flex'};
        }
        @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            display: ${props => props.isSellButtonDesktop ? 'none' : 'flex'};
        }
        @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            display: ${props => props.isSellButtonDesktop ? 'none' : 'flex'};
        }
        @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            display: ${props => props.isSellButtonDesktop ? 'none' : 'flex'};
        }

`;

export const GridItem = styled.div`
grid-area: L2;
`;

export const ButtonNoNav = styled.div`
    display: flex;
    background: #ffffff;
    color: #000000;
    justify-content: center;
    align-items: center;
    height: 60px;
    font-size: 1.625em;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-align: center;
    cursor: pointer;
    font-family: var(--defaultFont);
    border: none;
    width: 100%;
    grid-area: ${props => props.position === 'left' ? 'L2' : 'R2'};

    :hover{
        background: rgba(0, 0, 0, .5);
        border: 1px solid var(--white);
        color: var(--white);
    }
`;