import React, { useState, useEffect, useRef } from 'react'
import {
    DropDownContainerBox,
    DropDownNavBtn,
    DropdownListContainer,
    BackgroundWrapper,
    DropdownList,
    DropdownContainer,
    ListItem
} from './Dropdown.styles'


import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'

import Logo from '../../images/headerLogo.svg'

import {
    ACCOUNT_ACTIVITY,
    LOGOUT,
    ACCOUNT_SETTINGS,
    DASHBOARD
} from '../../config'

const Option = ({ className, itemPadding, itemHeight, letterSpacing, fontSize, fontWeight, textAlign, color, colorOnSelect, value, defaultSelect, innerText, onClick, isSelected, justifyContent }) => (
    <ListItem height={itemHeight} itemPadding={itemPadding} defaultSelect={defaultSelect} value={value}
        isSelected={isSelected}
        color={color}
        className={className}
        textAlign={textAlign}
        colorOnSelect={colorOnSelect}
        justifyContent={justifyContent}
        letterSpacing={letterSpacing}
        fontSize={fontSize}
        fontWeight={fontWeight}
        onClick={onClick}>


        {innerText}</ListItem>
);

const Dropdown = ({
    className,
    textAlign,
    justifyContent,
    label,
    maxHeight,
    height,
    itemHeight,
    itemPadding,
    width,
    boxShadow,
    _handleChange,
    border,
    background,
    listBackground,
    letterSpacing,
    fontSize,
    fontWeight,
    name,
    marginBottom,
    color,
    menuState,
    setMenuState,
    colorOnSelect,
    dropdownListMarginTop,
    options, setState, handleStateChange, valueX, key, id, handleOrderChange, order }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(valueX)
    const [currentVal, setCurrentVal] = useState(valueX)
    const bgdRef = useRef(null)
    const initial = useRef(true);
    const handleChange = (event) => {

        setIsOpen(!isOpen)

    }

    const selectNew = (event) => {

        setValue(event.target.innerHTML);
        handleStateChange()
        setIsOpen(!isOpen)
    }

    const selectNew2 = (event) => {
        event.preventDefault();
        handleStateChange(event)

        setIsOpen(!isOpen)
        if (menuState !== undefined && setMenuState !== undefined) {
            setMenuState(!menuState)
        }
    }

    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return;
        }
        console.log("change initiated")
        setIsOpen(!isOpen)
    }, [
        // valueX
    ])

    return (<>
        {/* {isOpen && <BackgroundWrapper ref={bgdRef} onClick={(e) => { if (e.target === bgdRef.current && typeof (_handleChange) === 'function') _handleChange() }} />} */}
        <DropdownContainer
            textAlign={textAlign}
            width={width}
            id={id}>
            {menuState && (
                <DropdownListContainer
                    border={border}>
                    <DropdownList
                        boxShadow={boxShadow}
                        listBackground={listBackground}
                        maxHeight={maxHeight}
                        border={border}
                        marginTop={dropdownListMarginTop}
                    >
                        {
                            options.map(option => (
                                <Option
                                    className={className}
                                    itemHeight={itemHeight}
                                    itemPadding={itemPadding}
                                    color={color}
                                    letterSpacing={letterSpacing}
                                    fontSize={fontSize}
                                    fontWeight={fontWeight}
                                    textAlign={textAlign}
                                    colorOnSelect={colorOnSelect}
                                    justifyContent={justifyContent}
                                    value={option.value}
                                    innerText={option.innerText}
                                    key={option.value}
                                    defaultSelect={option.isDefault}
                                    onClick={option.onClick} //was selectNew
                                    isSelected={valueX.includes(option.value)}
                                />

                            ))
                        }
                    </DropdownList>
                </DropdownListContainer>
            )}


        </DropdownContainer>
    </>)
}

export default Dropdown
