import React, { useState, useEffect, useRef } from 'react'

import { validatePassword, validateEmail, varNameToString, validateName } from '../../helpers'

// contexts
import { useAuth } from '../../contexts/AuthContext'
import { useAccountSettingsHook } from '../../hooks/useAccountSettingsHook'

import API from '../../API'

// style
import {
    Wrapper,
    Header,
    ScrollWrapper,
    ContentWrapper,
    GridItem,
    Label,
    QuestionWrapper,
    EditPasswordButton,
    Small,
    ModalOverlay,
    ModalWrapper,
    ModalTitle,
    SubText,
    PasswordReset,
    CloseButtonContainer,
    ErrorWrapper,
    LoadingWrapper
} from './AccountSettings.styles'

// images
import EditIcon from '../../images/editIcon.svg'
import Close from '../../images/close.svg'

// components
import InputField from '../InputField'
import Footer from '../Footer'
import Button from '../Button'
import LoadingWheel from '../LoadingWheel'
import ReAuthModal from './ReAuthModal'
import { StaticDrawUsage } from 'three'

const ClosePopup = ({ closeModal }) => (
    <CloseButtonContainer >
        <img src={Close} alt="close-popup" onClick={closeModal} />
    </CloseButtonContainer>
)

const Content = ({ loading, state, userReauthed, handleChange, setShowModal, authItem, setSubmissionAttempt, setServerResponse, continueKey, validContinue, handleSubmit, submissionAttempt }) => {
    // if (loading) return <ContentWrapper><LoadingWheel radius={"30px"} /></ContentWrapper>
    // else
    return (
        <ContentWrapper>
            <Header>Account Settings</Header>
            <SubText>Your account information is not viewable to other artists or buyers on Azaa.</SubText>
            <QuestionWrapper gridTemplateColumns="1fr 1fr" gridColGap="10px">
                <InputField
                    onChange={handleChange}
                    onKeyPress={continueKey}
                    type="text"
                    label="First Name"
                    labelFontSize="12px"
                    labelTextTransform="uppercase"
                    name='first_name'
                    placeholder='First Name'
                    marginBottom={'20px'}
                    value={state.first_name}
                    uppercaseOff={true}
                />
                <InputField
                    onChange={handleChange}
                    onKeyPress={continueKey}
                    type="text"
                    label="Last Name"
                    labelFontSize="12px"
                    labelTextTransform="uppercase"
                    name='last_name'
                    placeholder='Last Name'
                    marginBottom={'20px'}
                    value={state.last_name}
                    uppercaseOff={true}
                />
            </QuestionWrapper>
            {/* <QuestionWrapper>
                <InputField
                    type="email"
                    disabled={true}
                    name='email'
                    placeholder='Email Address'
                    marginBottom={'20px'}
                    value={state.email}
                />
            </QuestionWrapper> */}
            <QuestionWrapper gridColGap="10px" gridTemplateColumns="1fr min-content" gridTemplateAreas="'label label''subtext subtext''input button''txt txt'">
                <GridItem gridArea="label"><Label>Change Email</Label></GridItem>
                <SubText color="#ffffff" marginBottom="0.5rem">
                    Click the edit button to change your email.
                    {/* {userReauthed ? "Enter a new password below" : "Click the edit button to change your password."} */}
                </SubText>
                <GridItem gridArea="input">
                    <InputField
                        type="email"
                        name="email"
                        value={state.currEmail}
                        placeholder={state.currEmail}
                        marginBottom={'20px'}
                        disabled={true}
                        uppercaseOff={true}
                    />
                </GridItem>
                {/* <GridItem gridArea="txt"><Small>Min 8 characters, at least one letter and at least one number.</Small></GridItem> */}
                <GridItem gridArea="button">
                    <EditPasswordButton onClick={() => { setServerResponse(''); authItem.current = 'emailChanged'; setSubmissionAttempt(''); setShowModal(true); }}><img src={EditIcon} /></EditPasswordButton>
                </GridItem>
            </QuestionWrapper>
            <QuestionWrapper gridColGap="10px" gridTemplateColumns="1fr min-content" gridTemplateAreas="'label label''subtext subtext''input button''txt txt'">
                <GridItem gridArea="label"><Label>New Password</Label></GridItem>
                <SubText color="#ffffff" marginBottom="0.5rem">
                    Click the edit button to change your password.
                    {/* {userReauthed ? "Enter a new password below" : "Click the edit button to change your password."} */}
                </SubText>
                <GridItem gridArea="input">
                    <InputField
                        type="password"
                        subText={<>Min 8 characters<br></br>At least one letter<br />at least one number</>}
                        name="dummyField"
                        placeholder='●●●●●●●●●●'
                        marginBottom={'20px'}
                        disabled={true}
                        uppercaseOff={true}
                    />
                </GridItem>
                {/* <GridItem gridArea="txt"><Small>Min 8 characters, at least one letter and at least one number.</Small></GridItem> */}
                <GridItem gridArea="button">
                    <EditPasswordButton onClick={() => { setServerResponse(''); authItem.current = 'pwChanged'; setSubmissionAttempt(''); setShowModal(true); }}><img src={EditIcon} /></EditPasswordButton>
                </GridItem>
            </QuestionWrapper>
            <QuestionWrapper >
                <InputField
                    type="text"
                    label="Spotify Profile (optional)"
                    labelFontSize="12px"
                    labelTextTransform="uppercase"
                    onChange={handleChange}
                    onKeyPress={continueKey}
                    name='spotify'
                    placeholder='Spotify'
                    marginBottom={'20px'}
                    value={state.spotify}
                    uppercaseOff={true}
                />
            </QuestionWrapper>
            <QuestionWrapper >
                <InputField
                    type="email"
                    label="PayPal Email Address"
                    labelFontSize="12px"
                    labelTextTransform="uppercase"
                    // labelFontSize="10px"
                    // labelTextTransform="uppercase"
                    // label="PayPal Email Address"
                    onChange={handleChange}
                    onKeyPress={continueKey}
                    name='paypal'
                    placeholder='PayPal Email Address'
                    marginBottom={'20px'}
                    value={state.paypal}
                    uppercaseOff={true}
                />
            </QuestionWrapper>
            <QuestionWrapper >
                <InputField
                    type="text"
                    label="Instagram Handle"
                    labelFontSize="12px"
                    labelTextTransform="uppercase"
                    onChange={handleChange}
                    onKeyPress={continueKey}
                    name='instagram_handle'
                    placeholder='Instagram Handle'
                    marginBottom={'20px'}
                    value={state.instagram_handle}
                    uppercaseOff={true}
                />
            </QuestionWrapper>
            <QuestionWrapper >
                <InputField
                    type="text"
                    uppercaseOff
                    label="Publisher/Label/Mgmt Affiliation"
                    labelFontSize="12px"
                    labelTextTransform="uppercase"
                    onChange={handleChange}
                    onKeyPress={continueKey}
                    name='publisher'
                    placeholder='Affiliation'
                    marginBottom={'20px'}
                    value={state.publisher}
                />
            </QuestionWrapper>
            <QuestionWrapper >
                <InputField
                    type="text"
                    disabled
                    label="Current Plan"
                    labelFontSize="12px"
                    labelTextTransform="uppercase"
                    onChange={handleChange}
                    onKeyPress={continueKey}
                    name='membership'
                    placeholder='Basic'
                    marginBottom={'20px'}
                    value={state.membership}
                />
            </QuestionWrapper>
            <QuestionWrapper border={loading ? "2px solid var(--greyBackground)" : ''} >
                {loading && <LoadingWheel top="0px" background="var(--greyBackground)" radius={"20px"} width="100%" height="var(--buttonHeight)" position="relative" />}
                {!loading && <Button
                    width="100%"
                    validContinue={validContinue}
                    disabled={!validContinue}
                    onClick={handleSubmit}
                    text="Save Changes"
                    color={'#000000'}
                    hoverColor={validContinue ? '#ffffff' : "rgba(255,255,255,0.15)"}
                    border={validContinue ? "2px solid #ffffff" : '2px solid rgba(255,255,255,0.1)'}
                    background={validContinue ? '#ffffff' : "rgba(255,255,255,0.15)"}
                />}
            </QuestionWrapper>
            <QuestionWrapper height="14px"><SubText>{submissionAttempt}</SubText></QuestionWrapper>
        </ContentWrapper>
    )
}

const AccountSettings = () => {

    const { authItem,
        closeModal,
        closeModalOverlay,
        continueKey,
        emailChanged,
        handleChange,
        handleSubmit,
        initialLoading,
        getReAuthFunctions,
        loading,
        modalOverlay,
        pwChanged,
        serverResponse,
        setError,
        setServerResponse,
        setSubmissionAttempt,
        setShowModal,
        showModal,
        state,
        submissionAttempt,
        userReauthed,
        validContinue } = useAccountSettingsHook()
    console.log("loading", loading, validContinue)

    if (initialLoading)
        return (<LoadingWheel />)
    else
        return (
            <Wrapper showModal={showModal}>
                <Content
                    authItem={authItem}
                    loading={loading}
                    state={state}
                    userReauthed={userReauthed}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    setServerResponse={setServerResponse}
                    setSubmissionAttempt={setSubmissionAttempt}
                    setShowModal={setShowModal}
                    validContinue={validContinue}
                    continueKey={continueKey}
                    submissionAttempt={submissionAttempt}
                />
                <ModalOverlay ref={modalOverlay} showModal={showModal} onClick={closeModalOverlay}>
                    <ReAuthModal
                        closeModal={closeModal}
                        showModal={showModal}
                        userReauthed={userReauthed}
                        itemChanged={authItem.current === 'pwChanged' ? pwChanged : emailChanged}
                        onKeyPress={getReAuthFunctions(authItem.current === 'pwChanged' ? { pwChanged } : { emailChanged }).onKeyPress}
                        title={authItem.current === 'pwChanged' ? "Change Password" : 'Change Email'}
                        serverResponse={serverResponse}
                        setServerResponse={setServerResponse}
                        handleChange={handleChange}
                        setError={setError}
                        loading={loading}
                        values={getReAuthFunctions(authItem.current === 'pwChanged' ? { pwChanged } : { emailChanged }).values}
                        closeAfterSubmit={getReAuthFunctions(authItem.current === 'pwChanged' ? { pwChanged } : { emailChanged }).closeAfterSubmit}
                        handleSubmit={getReAuthFunctions(authItem.current === 'pwChanged' ? { pwChanged } : { emailChanged }).handleSubmit}
                        disabled={getReAuthFunctions(authItem.current === 'pwChanged' ? { pwChanged } : { emailChanged }).validContinue()}
                    />
                </ModalOverlay>
                <Footer />
            </Wrapper >
        )
}

export default AccountSettings
