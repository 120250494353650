import React, { useState, useEffect } from 'react'
import { validatePassword, validateEmail } from '../../../helpers'
// contexts
import { useAuth } from '../../../contexts/AuthContext'
import API from '../../../API'

import {
    QuestionWrapper,
    GridItem,
    ModalWrapper,
    LoadingWrapper,
    ErrorWrapper,
    ModalTitle,
    SubText,
    PasswordReset,
    CloseButtonContainer
} from './ReAuthModal.styles'

import InputField from '../../InputField'
import Button from '../../Button'
import LoadingWheel from '../../LoadingWheel'

import Close from '../../../images/close.svg'

const ClosePopup = ({ closeModal }) => (
    <CloseButtonContainer >
        <img src={Close} alt="close-popup" onClick={closeModal} />
    </CloseButtonContainer>
)

const Validating = ({ showModal, closeModal }) => {
    return (
        <ModalWrapper showModal={showModal}>
            <GridItem gridArea="title">
                <ModalTitle>Validating</ModalTitle>
            </GridItem>
            <GridItem gridArea="close"><ClosePopup closeModal={closeModal} /></GridItem>
            <GridItem gridArea="loading">
                <LoadingWrapper><LoadingWheel radius={"30px"} border={"2px solid rgba(0,0,0,0.25)"}></LoadingWheel></LoadingWrapper>
            </GridItem>
        </ModalWrapper>
    )
}

const Complete = ({ showModal, title, closeAfterSubmit, type }) => {
    return (
        <ModalWrapper showModal={showModal} gridAreas={"'title close''subtext subtext''button button'"}>
            <GridItem gridArea="title">
                <ModalTitle>{title}</ModalTitle>
            </GridItem>
            <GridItem gridArea="close"><ClosePopup
                closeModal={closeAfterSubmit} /></GridItem>
            <GridItem gridArea="subtext"><h5>You have successfully changed your {type}.</h5></GridItem>
            <GridItem gridArea="button">
                <Button
                    width="100%"
                    text="Close"
                    onClick={closeAfterSubmit}
                    hoverColor='#ffffff'
                    color={'#000000'}
                    border="2px solid #ffffff"
                    background='#ffffff'
                />
            </GridItem>
        </ModalWrapper>
    )
}

const ReAuth = ({
    title,
    disabled,
    userReauthed,
    handleChange,
    serverResponse,
    setServerResponse,
    itemChanged,
    closeAfterSubmit,
    showModal,
    closeModal,
    handleSubmit,
    values,
    onKeyPress }) => {
    const { sendResetPasswordEmail, currentUser } = useAuth()
    return (<ModalWrapper showModal={showModal}

        gridAreas={!userReauthed || values.type === 'email' ? "'title close''subtext subtext''input input''button button''err err'" : "'title close''subtext subtext''input input''pwcheck pwcheck''button button''err err'"}>
        <GridItem gridArea="title">
            <ModalTitle>{title}</ModalTitle>
        </GridItem>
        <GridItem gridArea="close"><ClosePopup closeModal={closeModal} /></GridItem>
        <GridItem gridArea="subtext">
            {title === 'Change Password' &&
                <SubText fontSize="15px">
                    {!userReauthed
                        ? 'To update your password, please reenter your current password below or'
                        : 'Enter a new password below or'
                    }
                    <PasswordReset
                        onClick={() => sendResetPasswordEmail(currentUser.email).then(() => { setServerResponse('Password reset email sent') })}>
                        request password reset email.</PasswordReset>
                </SubText>
            }
            {title !== 'Change Password' &&
                <SubText fontSize="15px">
                    {!userReauthed
                        ? 'To update your email, please reenter your current password below.'
                        : 'Enter a new email below.'}
                </SubText>
            }
        </GridItem>
        <GridItem gridArea="input">
            <InputField
                labelFontSize="12px"
                labelTextTransform="uppercase"
                label={values.label}
                type={values.type}
                onKeyPress={onKeyPress}
                onChange={handleChange}
                value={values.value}
                name={values.name}
                placeholder={values.placeholder}
                marginBottom={'20px'}
                uppercaseOff={true}
            />
        </GridItem>
        <GridItem gridArea="button">
            <Button
                width="100%"
                // background="transparent"
                // border="1px solid white"
                text="Submit"
                onClick={handleSubmit}
                disabled={disabled}
                hoverColor={disabled ? '#ffffff' : "rgba(255,255,255,0.15)"}
                validContinue={disabled}
                color={'#000000'}
                border={disabled ? "2px solid #ffffff" : '2px solid rgba(255,255,255,0.1)'}
                background={disabled ? '#ffffff' : "rgba(255,255,255,0.15)"}
            />
        </GridItem>
        {
            (userReauthed && values.type === 'password') &&
            <GridItem gridArea="pwcheck">
                <SubText fontSize="12px" color={/^[a-zA-Z\d\w\W]{8,}.*$/.test(values.value) ? 'var(--green)' : 'var(--red)'}>Min 8 characters</SubText>
                <SubText fontSize="12px" color={/^(?=.*[A-Z]).*$/.test(values.value) ? 'var(--green)' : 'var(--red)'}>At least one uppercase letter</SubText>
                <SubText fontSize="12px" color={/^(?=.*[a-z]).*$/.test(values.value) ? 'var(--green)' : 'var(--red)'}>At least one lowercase letter</SubText>
                <SubText fontSize="12px" color={/\d/.test(values.value) ? 'var(--green)' : 'var(--red)'}>At least one number</SubText>
                <SubText fontSize="12px" color={values.value !== '' && values.value !== values.curr ? 'var(--green)' : 'var(--red)'}>Must not match previous password</SubText>
            </GridItem>
        }
        <GridItem gridArea="err"><ErrorWrapper>{serverResponse}</ErrorWrapper></GridItem>
    </ModalWrapper>)
}

const ReAuthModal = ({
    closeModal,
    showModal,
    userReauthed,
    closeAfterSubmit,
    itemChanged,
    onKeyPress,
    title,
    serverResponse,
    setServerResponse,
    handleChange,
    values,
    setError,
    loading,
    handleSubmit,
    disabled
}) => {
    if (loading) return (
        <Validating
            title={title}
            showModal={showModal}
            closeModal={closeModal} />
    )
    else if (!itemChanged) return (
        <ReAuth
            title={title}
            itemChanged={itemChanged}
            userReauthed={userReauthed}
            disabled={disabled}
            values={values}
            handleChange={handleChange}
            serverResponse={serverResponse}
            setServerResponse={setServerResponse}
            showModal={showModal}
            closeModal={closeModal}
            handleSubmit={handleSubmit}
            onKeyPress={onKeyPress} />
    )
    else return (
        <Complete
            type={values.type}
            showModal={showModal}
            title={title}
            closeAfterSubmit={closeAfterSubmit}
        />)
}

export default ReAuthModal