import styled from 'styled-components';

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
height: 100vh;

h1{
    margin: 0;
    font-weight: 400;
    font-size: 2em;
    text-transform: none;
}

h2{
    margin-top: 0.5rem;
    text-transform: none;
    font-size: 1.3em;
    font-weight: 300;
    color: var(--purpleGrey);
    
}

strong{
    color: #ffffff;
}
`;

export const LoadingWheel = styled.div`
    border: 10px solid var(--greyBackground);
    border-top: 10px solid #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }
`;

export const ButtonWrapper = styled.div`
display: grid;
grid-template-columns: ${props=> props.splitsLeft ? '1fr 1fr' : '1fr'};
grid-column-gap: 20px;
`;

export const BananaWrapper = styled.div`
position: relative;
width: 100px;
height: 100px;
`;

export const NavLink = styled.a`
text-decoration: none;
display: inline-block;
color: #ffffff;
`;