import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Dropdown from '../../Dropdown'
import SongDetailsTable from '../../SongDetailsTable'
import DotMenuIcon from '../../../images/verticalDotMenu.svg'

import { BUYER_PLAYLIST_TYPES, BUYER_DASH_ACTIONS, getBuyerDashActions } from '../../../config'
import { _requiredActionBuyer } from '../../../helpers'
import { useAuth } from '../../../contexts/AuthContext'
import {
    DropDownContainerBox,
    DotMenuWrapper
} from './DotMenu.styles'


const DotMenu = ({ prepPayload, position, setShowModal, setSelectedSong, status, playlistOpen, hoverActive, song, fill }) => {
    const { currentUser } = useAuth()
    const [menuOpen, setMenuOpen] = useState(false)
    const navigate = useNavigate()
    const handleChange = () => {
        console.log("change")
    }
    console.log("song", song)
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (!hoverActive)
            setMenuOpen(false)
    }, [hoverActive])

    const getOptions = (status) => {
        const baseOptions = []
        switch (parseInt(status)) {
            case 1:
                return [{ value: 3, innerText: 'View Details', onClick: () => { setShowModal(true); } }]
            case 2:
                if (song.isPrimary)
                    return [{
                        value: 2,
                        innerText: 'Set Splits',
                        onClick: () => { navigate('/recently-approved') }
                    }]
                else
                    return [{
                        value: 2,
                        innerText: 'View Details',
                        onClick: () => { setShowModal(true); }
                    }]
            case 3:
                if (song.actionRequired === 2) {
                    return [
                        { value: 3, innerText: 'View Details', onClick: () => { setShowModal(true); } },
                        { value: 31, innerText: song.action, onClick: () => { navigate('/recently-approved') } }
                    ]
                }
                else {
                    return [
                        { value: 3, innerText: 'View Details', onClick: () => { setShowModal(true); } }
                    ]
                }
            case 4:
                return [...Object.keys(getBuyerDashActions()).map(it => {
                    console.log("it is", it)
                    if (BUYER_DASH_ACTIONS[it] !== BUYER_DASH_ACTIONS.ADD_TO_CART)
                        return ({
                            value: it,
                            innerText: BUYER_DASH_ACTIONS[it],
                            onClick: (song) => { prepPayload(BUYER_DASH_ACTIONS[it]) }
                        })
                    return ({
                        value: !song['inCart'] ? it : 'Remove From Cart',
                        innerText: !song['inCart'] ? BUYER_DASH_ACTIONS[it] : 'Remove From Cart',
                        onClick: (song) => { prepPayload(BUYER_DASH_ACTIONS[it]) }
                    })
                })]
            case 5:
                return [...Object.keys(getBuyerDashActions(BUYER_PLAYLIST_TYPES.PURCHASES, _requiredActionBuyer(currentUser, song).value)).map(it => ({
                    value: it,
                    innerText: BUYER_DASH_ACTIONS[it],
                    onClick: () => { console.log("BUYER_DASH_ACTIONS[it]", BUYER_DASH_ACTIONS[it]); prepPayload(BUYER_DASH_ACTIONS[it]) }
                }))]
            case 6:
                return [...Object.keys(getBuyerDashActions(BUYER_PLAYLIST_TYPES.PURCHASES)).map(it => ({
                    value: it,
                    innerText: BUYER_DASH_ACTIONS[it],
                    onClick: () => { prepPayload(BUYER_DASH_ACTIONS[it]) }
                }))]
            default:
                return []
        }
    }

    useEffect(() => {
        setOptions(getOptions(status))
    }, [status])

    return (
        <>
            <DropDownContainerBox position={position}>
                <Dropdown
                    name='header'
                    width="200px"
                    textAlign="right"
                    headerWidth="100px"
                    dropdownWidth="200px"
                    marginBottom={'0'}
                    listBackground="var(--dropdownBackground)"
                    color="#ffffff"
                    boxShadow="0 0 10px rgb(0,0,0)"
                    colorOnSelect="var(--goldSolid)"
                    dropdownListMarginTop="20px"
                    justifyContent="flex-end"
                    background="var(--dropdownBackground)"
                    options={options}
                    valueX=""
                    setMenuState={setMenuOpen}
                    menuState={menuOpen}
                    handleStateChange={handleChange}
                    index={0}
                    id='filter'
                    setValidContinue={true}
                ></Dropdown>
            </DropDownContainerBox>
            <DotMenuWrapper

                onClick={() => { if (playlistOpen) { setMenuOpen(!menuOpen); setSelectedSong(song); } }}
                onMouseOver={() => { if (playlistOpen) { setMenuOpen(true); setSelectedSong(song); } }}>
                {/* <img src={DotMenuIcon} alt="menu" onClick={() => { setMenuOpen(!menuOpen); setSelectedSong(song); }} /> */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 13">
                    <g style={{ fill: fill }}>
                        <circle cx="1.5" cy="1.5" r="1.5" />
                        <circle cx="1.5" cy="6.5" r="1.5" />
                        <circle cx="1.5" cy="11.5" r="1.5" />
                    </g>
                </svg>
            </DotMenuWrapper>
        </>
    )
}

export default DotMenu
