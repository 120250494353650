import styled from "styled-components";

export const Wrapper = styled.div`

display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

input{
    height: 49px;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'} !important;
    padding: ${props => props.padding ? props.padding : props.name === 'price' ? '0 20px 0 40px' : '0 20px 0 20px'} !important;
    font-weight: 600;
    text-transform: ${props => props.type === 'password' || props.uppercaseOff ? '' : 'uppercase'} !important;
    color: ${props => props.disabled === true ? props.colorDisabled ? props.colorDisabled : 'var(--greyBackground)' : '#ffffff'} !important;
    color: ${props => props.disabled === true ? props.colorDisabled ? props.colorDisabled : '#696980' : '#ffffff'} !important;
    width: 100%;
    background: ${props => props.disabled === true ? props.backgroundDisabled ? props.backgroundDisabled : '#121212' : props.background ? props.background : 'var(--greyBackground)'} !important;
    border: ${props => props.border ? props.border : 'none'} !important;
    border-bottom: ${props => props.borderBottom ? props.borderBottom : ''} !important;
    border-top: ${props => props.borderTop ? props.borderTop : ''} !important;
    border-left: ${props => props.borderLeft ? props.borderLeft : ''} !important;
    border-right: ${props => props.borderRight ? props.borderRight : ''} !important;
    position: relative;
    letter-spacing: .1rem;
    box-shadow: ${props => props.boxShadow ? props.boxShadow : 'inset 0 0 5px 1px rgb(0, 0, 0)'};
}
`;

export const Content = styled.div`
margin: 60px 0;
display: flex;
width: 300px;
flex-direction: column;
align-items: center;
justify-content: center;
img{
    width: 100%;
}

/* input{
    
    height: 49px;
    padding: 0 20px 0 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    width: 100%;
    background: var(--greyBackground);
    border: none;
    letter-spacing: .1rem;
    box-shadow: inset 0 0 5px 1px rgb(0, 0, 0);

    ::placeholder{
        color: #696980;
    }

    :focus{
        outline: 1px solid white;
        box-shadow: inset 0 0 5px rgba(255, 255, 255, .5);
        
    }


} */

button{
        width: 100%;
        margin-top: 20px;
        text-transform: uppercase;
        letter-spacing: .1rem;
        color: white;
        height: var(--buttonHeight);
        font-size: 11px;
        background: transparent;
        border: 1px solid var(--white);
        cursor: pointer;

        :hover{
            background: rgba(255,255,255,0.15)
        }
    }
    
    form{
        width: 100%;
    }

`;

export const Text = styled.div`
    margin-top: 60px;
    font-size: var(--fontMedium);
`;

export const InputFieldWrapper = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    position: relative;
    height: 49px;
    img{
        right: 20px;
        height: 22px;
        width: 33px;
        position: absolute;

    }

`;

export const ResponseFeedback = styled.div`
    margin-top: 10px;
    user-select: none;
    font-size: 10px;
    height: 14px;
    width: 100%;
    position: relative;
`;