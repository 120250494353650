import { AZAA_PLATFORM_FEE_PERCENT } from './config'

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const validateInstagram = (handle) => {
    const re = /^(?!.*\.\.)(?!.*\.$)[^\W](?![0-9]*$)[a-zA-Z0-9][\w.]{0,29}[\w](?!.*?\.{2})$/;
    const handleStripped = handle.split('@').length > 1 ? handle.split('@')[1] : handle.split('@')[0];
    return re.test(String(handleStripped));
}

export const validatePassword = (pw) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
    return re.test(String(pw));
}

export const validateName = (str) => {
    return /^[a-z][a-z\s]*$/.test(str)
}

export const returnMonth = (date, abbreviated = false) => {
    const month = date.split('/')[0]
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthsAbbreviated = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    var month_index = month - 1;
    return abbreviated ? monthsAbbreviated[month_index] : months[month_index];
}

export const timeElapsed = (date) => {
    const dateReceived = new Date(date).getTime(); //new Date(date).getTime();
    const now = new Date();
    const dateFormatted = new Date(date).toLocaleString().split(' ')[0]
    const monthReceived = new Date(date).getMonth(); //now.getMonth() - 
    const monthsElapsed = Math.abs(now.getMonth() - new Date(dateReceived).getMonth());
    const nowTime = now.getTime();
    const delta = nowTime - dateReceived;
    const days = Math.floor(delta / (1000 * 60 * 60 * 24))
    const hours = Math.floor((delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((delta % (1000 * 60)) / 1000);

    // const str = `${days}D ${hours}H ${minutes}M ${seconds}S`.replace(/0D /,"").replace(/0H /,"")

    // const str = monthsElapsed > 0 ?
    //     days > 0 ? `${monthsElapsed}M ${days}d`
    //         : `${monthsElapsed}`
    //     : days > 0 ? `${days}d` : hours > 0 ? `${hours}h` : minutes > 0 ? `${minutes}m` : 'now';
    const str = monthsElapsed > 0 ? `${returnMonth(dateFormatted, true) + ' ' + dateFormatted.split('/')[1]}` : days > 0 ? `${days}d` : hours > 0 ? `${hours}h` : minutes > 0 ? `${minutes}m` : 'now';

    const result = delta < 0 ? "now" : str !== "now" && monthsElapsed <= 0 ? str : str;



    return result

}

export const showTrailing = (price, contributors) => {
    return price === '-' || price === '' || price === null || price === undefined || contributors.length === 0 ? false
        : parseFloat(price.replace(/,/g, '')) % 1 ? true
            : (parseFloat(price.replace(/,/g, '')) * (AZAA_PLATFORM_FEE_PERCENT) / 100.00) % 1 ? true
                : contributors.map(c => parseFloat(price.replace(/,/g, '')) * parseFloat(c.split) / 100.00).reduce((a, b) => { return b % 1 ? true : a }, false)
}

export const timeElapsedFromCreatedAt = (createdAt) => {
    const date = createdAt.split(' ')[0]
    const dateMMDDYYYY = date.split('-')[1] + '/' + date.split('-')[2] + '/' + date.split('-')[0] + ' ' + createdAt.split(' ')[1];
    const result = timeElapsed(dateMMDDYYYY);
    return result
}
export const setCookie = (name, value, days = 7, path = '/') => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString()
    document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path
}
export const getCookie = (cname) => {
    // return document.cookie.split('; ').reduce((r, v) => {
    //     const parts = v.split('=')
    //     return parts[0] === name ? decodeURIComponent(parts[1]) : r
    // }, '')
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
export const deleteCookie = (name, path) => {
    setCookie(name, '', -1, path)
}
export const calculateCut = (my_split = null, total_price, showTrailing = false) => {
    const myCut = total_price !== undefined && total_price !== null && total_price !== '-' && my_split !== '-' ?
        (parseFloat(typeof (total_price) === 'number' ? total_price : total_price.replace(/,/g, '')) * my_split / 100.00).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : '-';
    return adjustPrice(myCut, showTrailing)
}
export const calculatePriceWithFee = (total_price, showTrailing = false) => {
    const myCut = total_price !== undefined && total_price !== '-' && total_price !== null ?
        {
            fee: (parseFloat(typeof (total_price) === 'number' ? total_price : total_price.replace(/,/g, '')) * (AZAA_PLATFORM_FEE_PERCENT) / 100.00).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            total: (parseFloat(typeof (total_price) === 'number' ? total_price : total_price.replace(/,/g, '')) * (100 + AZAA_PLATFORM_FEE_PERCENT) / 100.00).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        : { fee: '-', total: '-' };
    return { fee: adjustPrice(myCut.fee, showTrailing), total: adjustPrice(myCut.total, showTrailing) }
}
export const adjustPrice = (price, showTrailing = false) => {
    const priceAdj = price !== undefined && price !== null ?
        price.split('.').length > 1 ?
            price.split('.')[0] + `${price.split('.')[1] !== '00' ? '.' + (price.split('.')[1] + '0').slice(0, 2) : showTrailing ? '.00' : ''}` :
            showTrailing ? price + '.00' :
                price : '-';
    return priceAdj !== '-' ? '$' + priceAdj : priceAdj
}

export const toTitleCase = (str) => {
    return str
        .toLowerCase()
        .split(' ')
        .map((word) => {
            return word[0].toUpperCase() + word.substr(1);
        })
        .join(' ');
}

export const statusText = (currentUser, song) => {
    if (isBuyer(currentUser)) {
        const text = _requiredActionBuyer(currentUser, song).text
        if (text !== '-')
            return text
        if (parseInt(song.status) > 4)
            return 'Purchased'
        else return song.status_text
    } else return song.status_text
}

export const _requiredAction = (currentUser, song) => {
    if (currentUser.userDetails.tier === 'BUYER')
        return _requiredActionBuyer(currentUser, song)

    const agreementStatus = song.contributors !== undefined && song.contributors.length > 0 ? song.contributors.filter((c) => c.email === currentUser.email)[0].agreement : null;
    if (parseInt(song.status) === 2 && song.isPrimary) {
        return { text: 'Set Price & Splits', value: 1 }
    }
    else if (parseInt(song.status) === 3) {
        return agreementStatus === '1' ? { text: '-', value: -1 }
            : { text: 'Review & Respond', value: 2 };
    }
    else if (parseInt(song.status) === 5 && song.isPrimary) {
        const didSend = song.stemTransfer !== undefined && song.stemTransfer.length > 0 ? song.stemTransfer.filter((c) => c.email === currentUser.email)[0].agreement : null;
        return didSend === '1' ? { text: '-', value: -1 }
            : { text: 'Confirm Stems Transfer', value: 3 };
    }
    else return { text: '-', value: -1 }
}

export const _requiredActionBuyer = (currentUser, song) => {
    const agreementStatus = song.stemTransfer !== undefined && song.stemTransfer.length > 0 ? song.stemTransfer.find((c) => c.email === currentUser.email).agreement : null;
    if (parseInt(song.status) === 5) {
        return agreementStatus === '1' ? { text: '', value: -1 }
            : { text: 'Confirm Stems Transfer', value: 3 } // songwriter did send, buyer did receive
    }
    else return { text: '-', value: -1 }
}

export const isBuyer = (currentUser) => {
    return currentUser.userDetails.tier === 'BUYER'
}


export const varNameToString = (varObj) => Object.keys(varObj)

export const roundValue = (value, decimalPlaces = 2) => {
    return Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)
}

export const numToFormattedPriceString = (val) => {
    let value = String(roundValue(val)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    value = value.includes('.') ? value.split('.')[1].length > 1 ? value : value + '0' : value + '.00'
    return( '$' + value)
}