import React from 'react'

import { Wrapper, Content, GridWrapper } from './HomeBenefitsSection.styles'
import SongwritersImg1 from "../../images/songwriter_benefits_1.svg";
import SongwritersImg2 from "../../images/songwriter_benefits_2.svg";
import SongwritersImg3 from "../../images/songwriter_benefits_3.svg";

import BuyerImg1 from "../../images/buyer_benefits_1.svg";
import BuyerImg2 from "../../images/buyer_benefits_2.svg";
import BuyerImg3 from "../../images/buyer_benefits_3.svg";


const HomeBenefitsSection = ({ background, title, children }) => {
    // "Songwriters" : [
    //     {
    //         "imgUrl": SongwritersImg1,
    //         "description": <>{'Earn money from your'}<br/>{'unreleased songs'}</>
    //     },
    //     {
    //         "imgUrl": SongwritersImg2,
    //         "description": <>{'Secure placements with'}<br/>{'established industry buyers'}</>
    //     },
    //     {
    //         "imgUrl": SongwritersImg3,
    //         "description": <>{'Keep your'}<br/>{'publishing'}</>
    //     }
    //     ],
    //     "Buyers" : [
    //         {
    //             "imgUrl": BuyerImg1,
    //             "description": "Discover your artist's next great song"
    //         },
    //         {
    //             "imgUrl": BuyerImg2,
    //             "description": <>{'Obtain exclusive'} <br /> {'release rights'}</>
    //         },
    //         {
    //             "imgUrl": BuyerImg3,
    //             "description": "Gain private access to superior quality songs"
    //         }
    //         ]

    return (
        <>
            <Wrapper>
                <h1>Songwriters</h1>
                <GridWrapper>
                    <img src={SongwritersImg1} />
                    <div>Earn money from your unreleased songs</div>
                    <img src={SongwritersImg2} />
                    <div>Secure placements with established industry buyers</div>
                    <img src={SongwritersImg3} />
                    <div>Keep your publishing</div>
                </GridWrapper>
            </Wrapper>
            <Wrapper background={'var(--greyBackground)'}>
                <h1>A&R's</h1>
                <GridWrapper>
                    <img src={BuyerImg1} />
                    <div>Discover your artist's next great song</div>
                    <img src={BuyerImg2} />
                    <div>Obtain exclusive release rights</div>
                    <img src={BuyerImg3} />
                    <div>Gain private access to superior quality songs</div>
                </GridWrapper>
            </Wrapper>
        </>
    )

    // return (
    //     <Wrapper background={background} >
    //         <h1>{title}</h1>
    //         <Content>
    //             {children}
    //         </Content>
    //     </Wrapper>
    // )
}

export default HomeBenefitsSection
