import React, { useState, useEffect, useRef } from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import { _requiredActionBuyer, calculatePriceWithFee } from '../../../helpers'
// import { usePlaylistHook } from '../../../hooks/usePlaylistHook'
import dayjs from 'dayjs'
import YellowAlertIcon from '../../../images/alertYellow.svg'
import BlackAlertIcon from '../../../images/alertBlack.svg'

import API from '../../../API'

import AcceptAndReject from '../../SetSplits/AcceptAndReject'
import Table from '../../Table'

import Flexbox from '../../Flexbox'
import DotMenu from '../DotMenu'
import GridItem from '../../GridItem'
import DashBarBuyerEntry from '../DashBarBuyerEntry'
import PlayButton from '../../PlayButton'
import NextUpArrow from '../../NextUpArrow'
import clock from '../../../images/clockIcon.svg'
import heartIcon from '../../../images/favoriteIcon.svg'
import expand from '../../../images/expandQueue.svg'
import cart from '../../../images/cartIcon.svg'

import { useAuth } from '../../../contexts/AuthContext'

import { NextUp, OuterWrapper, Wrapper, AlertIcon, Action, ScrollWrapper, InnerWrapper2, InnerWrapper1 } from './DashBarBuyer.styles'

const ExpandIcon = ({ setMaxOpen, onClick, isOpen }) => {
    return (
        <GridItem isOpen={isOpen} onClick={() => { setMaxOpen(prev => !prev) }} width="30px" height="100%" padding="0" justifyContent="center" alignItems="center">
            <img src={expand} alt="expand to full screen" style={{ width: "13px", height: '13px', objectFit: 'contain' }} />
        </GridItem>)
}

const DashBarBuyer = ({ fetchMore, nbHits, showControls, prepPayload, setShowBufferPlaylist, hideNextUp, playlistType, isOpen, setIsOpen, handleFavorites, newApproval, setShowModal, setSelectedSong, content, handleSelect, playing, nowPlaying, handleSongChange }) => {
    const infScrollRef = useRef(null)

    const fetchMas = () => {
        if (typeof (fetchMore) === 'function' && nbHits > content.length) {
            console.log("fetching")
            fetchMore()
        } else {
            console.log("fetching1")
        }

    }

    const [maxOpen, setMaxOpen] = useState(false)
    const [hoverActive, setHoverActive] = useState(false)
    const [hoverItem, setHoverItem] = useState(null)
    const { currentUser } = useAuth()
    const indexesToHideOffHover = [9]
    const rowAlignmentArray = ["center", "center", "flex-start", "flex-end", "flex-end", "flex-end", "flex-end", "center", "center", "center"];
    const indexNowPlaying = content.findIndex((c) => c['song_id'] === nowPlaying)
    const offset = (indexNowPlaying > 0 ? indexNowPlaying : 0) * 60 + 'px';

    const adjustScrollPositionOnClose = () => {
        if (infScrollRef.current && infScrollRef.current.el) {
            infScrollRef.current.el.scrollTop = (indexNowPlaying > 0 ? indexNowPlaying : 0) * 60

            // const currPosition = infScrollRef.current.el.scrollTop
            // console.log("infScrollRef.current.el.scrollTop", currPosition, currPosition + currPosition % 60, infScrollRef.current.el.scrollTopMax)
            // if (currPosition % 60 <= 30)
            //     infScrollRef.current.el.scrollTop = currPosition - currPosition % 60
            // else {
            //     const scrollGoal = 60 + currPosition - currPosition % 60
            //     if (scrollGoal > (infScrollRef.current.el.scrollTopMax - 60))
            //         infScrollRef.current.el.scrollTop = currPosition - currPosition % 60
            //     else
            //         infScrollRef.current.el.scrollTop = 60 + currPosition - currPosition % 60
            // }
        }
    }
    useEffect(() => {
        adjustScrollPositionOnClose()
    }, [nowPlaying])
    return (
        <OuterWrapper
            isOpen={isOpen} maxOpen={maxOpen} showControls={showControls}>
            <Wrapper
                isOpen={isOpen}
            // offset={offset}
            >
                {isOpen &&
                    <GridItem padding="0 calc(3.22vw - 15px ) 0 3.22vw" height="45px" justifyContent="center">
                        <ExpandIcon setMaxOpen={setMaxOpen} />
                    </GridItem>
                }
                <InnerWrapper1>
                    <InnerWrapper2>
                        <ScrollWrapper expandedContainerHeight={isOpen ? "100%" : undefined}>
                            <InfiniteScroll
                                ref={infScrollRef}
                                // dataLength={_data.length}
                                dataLength={content.length}
                                next={fetchMas}
                                hasMore={nbHits > content.length}
                                loader={<Flexbox column justifyContent="center" textTransform="uppercase" alignItems="center" fontSize="10px">
                                    {/* <LoadingWheel position="relative" radius="25px" height="28px" width="28px" /> */}
                                </Flexbox>}
                                style={{ overflow: !isOpen ? 'hidden hidden' : 'hidden auto', }}
                                height={!isOpen ? 60 : 'calc(50vh - 35px - 65px - 65px)'}>
                                {
                                    content.map(it => (<DashBarBuyerEntry
                                        setShowModal={setShowModal}
                                        prepPayload={(action) => prepPayload(it.song_id, action)}
                                        setHoverItem={setHoverItem}
                                        playlistType={playlistType}
                                        key={it.song_id}
                                        setSelectedSong={setSelectedSong}
                                        handleFavorites={handleFavorites}
                                        handleSongChange={handleSongChange}
                                        hoverItem={hoverItem}
                                        noHover={!isOpen ? true : undefined}
                                        hoverActive={hoverActive}
                                        nowPlaying={nowPlaying}
                                        item={it}
                                        isOpen={isOpen}
                                        setHoverActive={setHoverActive}
                                        handleSelect={handleSelect}
                                        playing={playing}
                                    ></DashBarBuyerEntry>))
                                }
                            </InfiniteScroll>
                        </ScrollWrapper>
                    </InnerWrapper2>
                </InnerWrapper1>
            </Wrapper>
            <NextUp hideNextUp={hideNextUp}
                onClick={() => {
                    if (isOpen)
                        adjustScrollPositionOnClose()
                    setShowBufferPlaylist(false);
                    setIsOpen(!isOpen);
                    setMaxOpen(false)
                }} isOpen={isOpen} maxOpen={maxOpen}>
                <div>Next Up</div>
                <NextUpArrow isAlert={newApproval} isOpen={isOpen} maxOpen={maxOpen} />
                {newApproval && <AlertIcon position="absolute" right="-25px"><img src={YellowAlertIcon} /></AlertIcon>}
            </NextUp>

        </OuterWrapper>
    )
}

export default DashBarBuyer