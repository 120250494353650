import React, { useState, useEffect } from 'react'

import { useAuth } from '../../contexts/AuthContext'

import API from '../../API'

import AccountSettings from '../AccountSettings'
import TransactionHistory from '../TransactionHistory'
import GridItem from '../GridItem'
import Flexbox from '../Flexbox'


import {
    Wrapper,
    SideBar,
    SideBarLink,
    RightContainer,
    SideBarLinkHeader,
    Row,
    Innercontainer,
    DateWrapper,
    SiteHeader,
    Spacer,
    MessagesLink
} from './Account.styles'

// import {


// } from '../../config'

const Account = () => {
    const { currentUser } = useAuth();
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const options = ["Account Information", "Payment and Billing", "Transaction History", "Messages"]
    const [selected, setSelected] = useState(options[0])
    return (
        // <GridItem display="grid" gridTempCols="min-content 1fr" relative height="calc(100vh - var(--navBarHeight))" margin="var(--navBarHeight) 0 0" justifyContent="start">
        <Flexbox row height="calc(100vh - var(--navBarHeight))" margin="var(--navBarHeight) 0 0" justifyContent="start">
            <Flexbox column height="100%" flexBasis="210px" relative justifyContent="start" alignItems="start" background="#262626" boxShadow={'0 0 8px #000000'} zIndex="6">
                <h3 style={{ paddingLeft: '3.22vw' }}>My Account</h3>
                {
                    options.map(option => <Flexbox onClick={() => setSelected(option)} borderRight={selected === option ? 'var(--borderRightActiveLi)' : undefined} padding="10px 20px 10px 3.22vw" background={selected === option ? 'var(--backgroundActiveLi)' : undefined}>{option}</Flexbox>)
                }
            </Flexbox>


            <Flexbox column height="100%" relative justifyContent="start" alignItems="start" overflow="hidden">
                <Flexbox column height="100%" relative justifyContent="start" alignItems="start" overflow="auto">
                    {selected === "Account Information" &&
                        <AccountSettings />}
                    {selected === "Transaction History" && <TransactionHistory />}
                </Flexbox>
            </Flexbox>

        </Flexbox>
    )
}

export default Account
