import styled from "styled-components";

export const Wrapper = styled.div`
display: grid;
position: relative;
margin-bottom: ${props => props.marginBottom ? props.marginBottom : '20px'};
/* padding-right: 3.22vw; */
padding-top: 20px;
grid-template-columns: ${props => props.gridTemplateColumns ? props.gridTemplateColumns : ''};
grid-template-rows: ${props => props.gridTemplateRows ? props.gridTemplateRows : ''};
grid-column-gap: 0;
grid-row-gap: ${props => props.step === 0 ? '' : '10px'};
grid-template-areas: ${props => props.gridTemplateAreas ? props.gridTemplateAreas : ''};
`;

export const Spacer = styled.div`
grid-area: spacer;
border-bottom: 1px solid #ffffff;
`;


export const SplitSymbol = styled.div`
grid-area: splitSymbol;
border-bottom: 1px solid #ffffff;
display: flex;
padding-bottom: 10px;
width: 100%;
position: relative;
height: 100%;
img{
    width: 90%;
    max-width: 60px;
}
`;
export const CloseButton = styled.div`
grid-area: close;
justify-self: end;
user-select: none;
border-bottom: 1px solid #ffffff;
cursor: pointer;
width: 100%;
display: flex;
justify-content: flex-end;

color: #ffffff;
`;
export const Title = styled.div`
grid-area: title;
height: 100%;
padding-bottom: 10px;
border-bottom: 1px solid #ffffff;
display: flex;
flex-direction: column;
justify-content: center;
`;

export const SubText = styled.div`
user-select: none;
color: var(--purpleGrey);
display: flex;
justify-content: ${props => props.align ? props.align : 'flex-start'};
font-size: 12px;
letter-spacing: 0.03rem;
`;

export const NextStepTextWrapper = styled.div`
white-space: nowrap;
user-select: none;
cursor: pointer;
display: flex;
flex-direction: column;
height: 100%;
position: relative;
justify-content: space-between;
`;

export const PrevStep = styled.div`
user-select: none;
cursor: pointer;
justify-content: flex-start;
height: 100%;
align-items: center;
display: flex;
grid-area: prevStep;
img{
    height: 15px;
    margin-right: 5px;
    transform: rotate(180deg);
}
`;
export const PrevStepTextWrapper = styled.div`
white-space: nowrap;
user-select: none;
cursor: pointer;
display: flex;
flex-direction: column;
height: 100%;
position: relative;
justify-content: space-between;
`;

export const NextStep = styled.div`
user-select: none;
justify-content: flex-end;
height: 100%;
align-items: center;
display: flex;
grid-area: nextStep;
img{
    height: 15px;
    margin-left: 5px;
}
`;

export const Prev = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
`;

export const Next = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
`;