import React from 'react'

import {
    Wrapper,
    QuestionWrapper,
    SectionWrapper,
    SectionWrapperHeader,
    PriceWrapper,
    AddIconWrapper,
    PricingTable,
    ContentWrapper,
    RemoveContributorWrapper
} from './SplitsAndPricing.styles'

import InputField from '../../InputField'
import Table from '../Table'
import GridItem from '../../GridItem'

import AddIcon from '../../../images/addIcon.svg'
import trashIcon from '../../../images/trashIcon.svg'
import { useAuth } from '../../../contexts/AuthContext'
import { calculateCut, calculatePriceWithFee } from '../../../helpers'

const Trash = ({ onClick, visibility }) => (
    <RemoveContributorWrapper visibility={visibility} onClick={onClick}>
        <img src={trashIcon} alt="remove" />
    </RemoveContributorWrapper>
)

const SplitsAndPricing = ({ fieldInFocus, buyerEmails, validateStep, priceOnBlur, values, handleChange, addContributor, removeContributor, handleCollabFieldChange }) => {
    const { currentUser } = useAuth();

    const handleRemoval = (input) => (event) => {
        removeContributor(input);
    }

    const alertField = (item) => {
        // return buyerEmails.includes(item.email) || (item.emailCheck && item.emailCheck.isBuyer)
        return true
    }

    const getAlertType = (item) => {
        if (!item.emailCheck)
            return { type: undefined, focus: '#ffffff', color: 'var(--greyBackground)', onClick: undefined }
        const { emailCheck } = item
        if (emailCheck.isBuyer)
            return { type: 'BUYER', focus: 'var(--yellow)', color: 'var(--yellow)', onClick: undefined }
        else if (emailCheck.exists && emailCheck.registered)
            return { type: 'EXISTS', focus: 'var(--accountExistsBlue)', color: 'var(--accountExistsBlue)', onClick: undefined}
        else return { type: 'ADD_NEW', focus: 'var(--green)', color: 'var(--green)', onClick: undefined }
    }


    const tableContent = values.contributors.map((contributor, index) => (
        {
            contentID: index,
            contentArr: [
                <InputField
                    type="text"
                    onChange={handleCollabFieldChange(index, 'first_name')}
                    name='first_name'
                    padding="0"
                    placeholder='First'
                    marginBottom={'0.8rem'}
                    value={contributor.first_name}
                    disabled={index === 0 ? true : false}
                    colorDisabled="#545454"//#363636
                    boxShadow="none"
                    background='transparent'
                    backgroundDisabled='transparent'
                    outlineFocus="none"
                    borderBottom={index === 0 ? "3px solid #000000" : "3px solid var(--greyBackground)"}
                    borderBottomFocus="3px solid #ffffff"
                    boxShadowFocus="none"
                    uppercaseOff={true}
                />,
                <InputField
                    type="text"
                    onChange={handleCollabFieldChange(index, 'last_name')}
                    name='last_name'
                    placeholder='Last'
                    marginBottom={'0.8rem'}
                    padding="0"
                    value={contributor.last_name}
                    disabled={index === 0 ? true : false}
                    colorDisabled="#545454"
                    boxShadow="none"
                    background='transparent'
                    backgroundDisabled='transparent'
                    outlineFocus="none"
                    borderBottom={index === 0 ? "3px solid #000000" : "3px solid var(--greyBackground)"}
                    borderBottomFocus="3px solid #ffffff"
                    boxShadowFocus="none"
                    uppercaseOff={true}
                />,
                <InputField
                    type="text"
                    id={"cl" + index}
                    onChange={handleCollabFieldChange(index, 'email')}
                    name='email'
                    uppercaseOff={true}
                    alertThisField={index === 0 ? false : alertField(contributor)}
                    placeholder='Email Address'
                    marginBottom={'0.8rem'}
                    value={contributor.email}
                    alertType={getAlertType(contributor).type}
                    disabled={index === 0 ? true : false}
                    colorDisabled="#545454"
                    boxShadow="none"
                    padding="0"
                    background='transparent'
                    transition="border 0.5s linear"
                    backgroundDisabled='transparent'
                    outlineFocus="none"
                    borderBottom={index === 0 ? "3px solid #000000" : alertField(contributor) ? `3px solid ${getAlertType(contributor).color}` : "3px solid var(--greyBackground)"}
                    boxShadowFocus="none"
                    borderBottomFocus={alertField(contributor) ? `3px solid ${getAlertType(contributor).focus}` : "3px solid #fff" }
                />,
                <InputField
                    type="text"
                    onChange={handleCollabFieldChange(index, 'split')}
                    name='split'
                    placeholder='%'
                    marginBottom={'0.8rem'}
                    value={contributor.split}
                    boxShadow="none"
                    padding="0"
                    background='transparent'
                    colorDisabled="#545454"
                    backgroundDisabled='transparent'
                    outlineFocus="none"
                    borderBottom="3px solid var(--greyBackground)"
                    borderBottomFocus="3px solid #ffffff"
                    boxShadowFocus="none"
                />,
                <p style={{ color: '#545454', fontWeight: '600' }}>{values.price !== '' && values.price !== null ? calculateCut(contributor.split, values.price) : '-'}</p>,
                <Trash visibility={index !== 0 ? 'visible' : 'hidden'} onClick={handleRemoval(index)} />
            ]
        }))
    const rowAlignmentArray = ["flex-start", "flex-start", "flex-start", "flex-start", "flex-start", "center"];
    return (
        <Wrapper>
            <ContentWrapper>
                <QuestionWrapper
                    style={{ height: 'min-content' }}
                // style={{ overflow: 'visible', height: 'min-content', minHeight: 'min-content' }}
                >
                    <h5 style={{ marginBottom: '0' }}>{values.song_title}</h5>
                    <p style={{ margin: '5px 0 0', fontSize: '10px', textTransform: "uppercase", fontWeight: '600', color: 'var(--purpleGrey)' }}>Song Title</p>
                    <SectionWrapperHeader style={{ marginBottom: '10px' }}>
                        Price
                    </SectionWrapperHeader>
                    <p>The price and splits can be changed up until this song hits the marketplace (after all contributors have accepted the price & terms), after which the asking price is final.</p><br />
                    <PriceWrapper>
                        <PricingTable>
                            <div><InputField
                                width="175px"
                                type="text"
                                textAlign="right"
                                onChange={handleChange('price')}
                                // onKeyPress={continueKey}
                                name='price'
                                priceOnBlur={priceOnBlur}
                                // label="Name your price."
                                placeholder=''
                                // marginTop="5px"
                                marginBottom={'0.8rem'}
                                value={values.price}
                            // setValidContinue={setValidContinue}
                            /></div><div>Your Price</div>
                            <div> {values.price !== '' && values.price !== null ? `+ ${calculatePriceWithFee(values.price).fee}` : ''}</div><div>+ 15% Azaa Platform Fee</div>
                            <div>{values.price !== '' && values.price !== null ? `+ ${calculatePriceWithFee(values.price).total}` : ''}</div><div>Listing Price</div>
                        </PricingTable>
                        <div style={{ marginTop: '5px', color: 'var(--purpleGrey)', fontWeight: '500', fontStyle: 'italic', fontSize: '10px', display: 'flex' }}>
                            <span>Note: Azaa will <span style={{ textDecoration: 'underline' }}>add</span> a 15% commision to your listing price before it goes live so you can keep your full asking price.</span>
                        </div>
                    </PriceWrapper>

                </QuestionWrapper>
                <QuestionWrapper
                    style={{ minHeight: '205px' }} >
                    <GridItem borderBottom="1px solid #ffffff" alignItems="center" gridTempCols="1fr min-content" gridTempRows="40px" margin="20px 0 10px">
                        <GridItem fontSize="1rem" gridCol="1" alignItems="start" textTransform="uppercase">Contributors</GridItem>
                        <GridItem gridCol="2"><AddIconWrapper onClick={addContributor}>
                            <img src={AddIcon} alt="add-btn" />
                        </AddIconWrapper>
                        </GridItem>
                    </GridItem>
                    <p>Enter the contact information for this song's contributors.<br />
                        If the user doesn't have an Azaa account for the listed email address, they will receive a registration email to create one.<br />You can change this information up until all contributors have agreed to the set terms.</p>
                    <QuestionWrapper style={{ height: 'min-content', paddingBottom: '20px' }}>
                        <Table
                            noHover
                            padding="10px 0"
                            content={[]}
                            tableHeader={["First Name", "Last Name", "Email Address", "Split [%]", "Split [$]"]}
                            gridTemplateColumns='repeat(2, 1fr) 2fr 80px 100px 30px'
                            rowAlignmentArray={rowAlignmentArray}
                            gridColGap="10px"
                            gridRowGap="10px"
                        />
                    </QuestionWrapper>
                    <QuestionWrapper
                    // style={{ overflowY: 'auto', height: 'calc(100% - 20px)' }}
                    >
                        <QuestionWrapper
                        // style={{ height: 'min-content' }}
                        >
                            <Table
                                noHover
                                padding="10px 0"
                                content={tableContent}
                                hideHeader
                                tableHeader={["First Name", "Last Name", "Email Address", "Split [%]", "Split [$]"]}
                                gridTemplateColumns='repeat(2, 1fr) 2fr 80px 100px 30px'
                                rowAlignmentArray={rowAlignmentArray}
                                gridColGap="10px"
                                gridRowGap="10px"
                            />
                        </QuestionWrapper>
                    </QuestionWrapper>
                </QuestionWrapper>
            </ContentWrapper>
        </Wrapper>
    )
}

export default SplitsAndPricing
