import React from 'react'

import {
    OuterWrapper,
    Wrapper,
    Header,
    Quote,
    Attribute,
    Bio,
    PictureContainer,
    QuoteBox
} from './TheFounders.styles'

import  William  from '../../images/williamVanderheydenPic@4x.png'
import  Chelsea  from '../../images/chelseaDavenportPic@4x.png'

const TheFounders = () => {

    const founders = [
        {
            img:  William ,
            name: "William Vanderheyden",
            quote: "“Azaa is a bold step forward towards a healthier industry where songwriters are compensated at point of placement.”",
            attribute: '–William vanderheyden, CEO & Founder',
            bio: 'William Vanderheyden, also known as multi-platinum award-winning producer Felix Snow, is the founder and CEO of Azaa Music Group. William has over 10 years of experience in the music industry under his belt. From running his own record label, Effess Records, to developing artists like Kiiara and Terror Jr, to producing for the likes of Katy Perry, Tinashe, Young Thug, Christina Aguilera, Lil Uzi Vert and Bebe Rexha, Felix is a hardcore seasoned music veteran.'
        },
        {
            img:  Chelsea ,
            name: "Chelsea Davenport",
            quote: "“We started Azaa with a simple idea that quickly developed into a movement - a new, fairer standard that will hopefully be further embraced and adopted by all songwriters and their industry counterparts.”",
            attribute: "–Chelsea Davenport, COO & Co-Founder",
            bio: "Chelsea Davenport, also known under the moniker TYSM and as 1/2 of country duo 17 Memphis, is the co-founder and COO of Azaa Music Group. Chelsea has been working as a singer, songwriter and producer in the music industry for 7+ years. Collaborating with the likes of Felix Snow, R3hab, Gucci Mane, Like Mike & Dimitri Vegas, Tinashe and Ben Burgess, Chelsea knows her way around a studio. Chelsea has been signed with a major label and has extensive experience as both an artist and as a leading business woman within the music industry."
        }
    ]

    return (
        <OuterWrapper>
            <Header>The Founders</Header>
            {
                founders.map((founder, index) => (
                    <Wrapper key={index} index={index}>
                        <PictureContainer>
                            <img src={founder.img} alt={founder.name} />
                        </PictureContainer>
                        <QuoteBox>
                            <Quote>{founder.quote}</Quote>
                            <Attribute>{founder.attribute}</Attribute>
                        </QuoteBox>
                        <Bio>{founder.bio}</Bio>
                    </Wrapper>
                ))
            }

        </OuterWrapper>
    )
}

export default TheFounders
