import styled from 'styled-components'

export const Wrapper = styled.div`
display: grid;
flex-grow: ${({ flexGrow }) => flexGrow};
background: ${({ background }) => background};
width: ${({ width }) => width};
flex-basis: ${({ flexBasis }) => flexBasis};
max-width: ${({ maxWidth }) => maxWidth};
height: ${({ height }) => height};
gap: ${({ gap }) => gap};
padding: ${({ padding }) => padding};
margin: ${({ margin }) => margin};
grid-template-rows: ${({ gridTemp }) => gridTemp ? gridTemp['rows'] : ''};
grid-template-columns: ${({ gridTemp }) => gridTemp ? gridTemp['columns'] : ''};
grid-template-areas: ${({ gridTempAreas }) => gridTempAreas};
justify-content: ${({ justify }) => justify};
justify-items: ${({ justifyItems }) => justifyItems};
align-items: ${({ align }) => align};
align-content: ${({ alignContent }) => alignContent};
left: ${props => props.left ? props.left : ''};
right: ${props => props.right ? props.right : ''};
top: ${props => props.top ? props.top : ''};
bottom: ${props => props.bottom ? props.bottom : ''};
position: ${props => props.position ? props.position : ''};
scroll-snap-type: ${({ scrollSnapType }) => scrollSnapType};
scroll-snap-align: ${({ scrollSnapAlign }) => scrollSnapAlign};
color: ${({ color }) => color};
border: ${({ border }) => border};
font-size: ${({ fontSize }) => fontSize};
overflow: ${({ overflow }) => overflow};
>div{
    padding: ${({ itemPadding }) => itemPadding};
}
${({ itemsPerRow = 2, highlightOff = false }) => {
        if (highlightOff)
            return
        let arr = Array(parseInt(itemsPerRow)).fill(0)
        console.log("arr is", arr, itemsPerRow)
        return arr.map((it, index) => {
            console.log("items per row", itemsPerRow)
            const str = `>div:not(.header):nth-of-type(${itemsPerRow * 2}n+${index + 1})` + (index < itemsPerRow - 1 ? ',' : '')
            console.log("string is", str)
            return str
        })
    }}{
    background: var(--nthRowBackground);
}
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
            width: ${({ mobileWidth }) => mobileWidth};
            height: ${({ mobileHeight }) => mobileHeight};
            flex-basis: ${({ mobileFlexBasis }) => mobileFlexBasis};

        }
`;