import React from 'react'

import { TableCellWrapper, TableCellHeaderWrapper } from './TableCell.styles'


const TableCell = ({ props, children, className, index, content, isHeader, ...remainingProps }) => {
    if (content) {
        const columnSpan = !content.props ? undefined : content.props.colSpan ? content.props.colSpan : undefined
        if (isHeader)
            return (<TableCellHeaderWrapper colSpan={columnSpan} className={className} id={index} {...content.props} {...remainingProps}>{content.title}</TableCellHeaderWrapper>)
        return (
            <TableCellWrapper colSpan={columnSpan} className={className} id={index} {...content.props}  {...remainingProps}  {...remainingProps}>{content.title}</TableCellWrapper>
        )
    } else {
        if (isHeader)
            return (<TableCellHeaderWrapper className={className} id={index} {...props}  {...remainingProps}>{children}</TableCellHeaderWrapper>)
        return (
            <TableCellWrapper className={className} id={index} {...props}  {...remainingProps}>{children}</TableCellWrapper>
        )
    }

}

export default TableCell