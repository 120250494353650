import React, { useState, useRef, useEffect } from 'react'

import { Genre, Tempo } from '../../../objectPrototypes'
import { Wrapper, DropDownContainerBox, BackgroundWrapper } from './DashFilters.styles'

import GridItem from '../../GridItem'
import Flexbox from '../../Flexbox'
import DropdownMultiSelect from '../../UploadFormSteps/DropdownMultiSelect'
import Dropdown from '../../Dropdown'

import ControlsIcon from '../../../images/controls.svg'

const Label = ({ index, labelRef, label, setShowFilterDropdown }) => {
    if (index === 0) {
        return (<GridItem cursor="pointer" position='relative' whiteSpace="nowrap" letterSpacing="0.1rem" fontSize="var(--fontSmall)" fontWeight="700" height="49px"
            // justifySelf="end" alignItems="end" 
            justifySelf="center" alignItems="center"
            onClick={setShowFilterDropdown}
            justifyContent="center"
            refID={labelRef}>{label}</GridItem>)
    } else if (index === 1)
        return (<GridItem cursor="pointer" onClick={setShowFilterDropdown} position='relative' letterSpacing="0.1rem" alignText="center" fontSize="var(--fontSmall)" fontWeight="700" height="49px" alignItems="center" justifyContent="center" refID={labelRef}>{label}</GridItem>)
    else
        return (<GridItem cursor="pointer" onClick={setShowFilterDropdown} position='relative' letterSpacing="0.1rem" alignText="center" fontSize="var(--fontSmall)" fontWeight="700" height="49px" alignItems="start" width="min-content" justifyContent="center" refID={labelRef}>{label}</GridItem>)
}

const Filter = ({ showBufferPlaylist, children, label, genreFilters, showFilterDropdown, setShowFilterDropdown, width, index }) => {
    const labelRef = useRef(null)
    return (
        <GridItem zIndex={index !== 1 && showBufferPlaylist ? '-1' : undefined} gridCol={index + 1} position='relative' alignItems='center' display="flex" width={width}
        // onClick={(e) => {
        //     if (e.target === labelRef.current) setShowFilterDropdown()
        // }}
        >
            <Label index={index} label={label} labelRef={labelRef} setShowFilterDropdown={setShowFilterDropdown} />
            {
                showFilterDropdown && children
            }
        </GridItem>)
}

const DashFilters = ({ toggleControls, showBufferPlaylist, playlist, filter, handleGenreChange, filterDropdowns, setShowFilterDropdown, showFilterDropdown }) => {

    const bgdRef = useRef(null)
    if (filterDropdowns.genreDropdown === undefined) return <></>
    return (
        <>
            {showFilterDropdown.reduce((a, b) => (b ? b : a), false) && <BackgroundWrapper ref={bgdRef} onClick={(e) => { if (e.target === bgdRef.current) setShowFilterDropdown(Array(3).fill(false)) }} />}
            <GridItem display="grid"
                position='fixed'
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                top='0'
                width="min-content" height="var(--navBarHeight)" zIndex="20" gridTempCols="1fr 1fr 1fr" gridColGap="50px">
                {!showBufferPlaylist &&
                    <Filter
                        showBufferPlaylist={showBufferPlaylist}
                        index={0}
                        setShowFilterDropdown={() => { setShowFilterDropdown(prev => prev.map((it, idx) => idx === 0 ? !it : false)) }}
                        showFilterDropdown={showFilterDropdown[0]} display="flex" width="140px" genreFilters={filter.genre} label={filter.genre.length > 0 ? filter.genre.map((genre, idx) => (idx > 0 ? ", " + genre.toUpperCase() : genre.toUpperCase())) : 'GENRES'}>
                        <DropDownContainerBox>
                            <DropdownMultiSelect
                                label={filter.genre.length > 0 ? filter.genre.map((genre, idx) => (idx > 0 ? ", " + genre : genre)) : 'GENRES'}
                                headerText={filter.genre.length > 0 ? filter.genre.map((genre, idx) => (idx > 0 ? ", " + genre : genre)) : 'GENRES'}
                                showTile={false}
                                _isOpen={true}
                                isDash={true}
                                _handleChange={() => { setShowFilterDropdown(prev => [false, prev[1], prev[2]]) }}
                                hideLabel={true}
                                name='tempo'
                                marginBottom={'1rem'}
                                options={filterDropdowns.genreDropdown}
                                handleStateChange={handleGenreChange}
                                valueX={filter.genre}
                                setValidContinue={() => { }}
                            ></DropdownMultiSelect>
                        </DropDownContainerBox>
                    </Filter>}
                {/* <Filter index={1} label={filter.playlist.toUpperCase()} width="130px" setShowFilterDropdown={() => { setShowFilterDropdown(prev => prev.map((it, idx) => idx === 1 ? !it : false)) }} showFilterDropdown={showFilterDropdown[1]}>
                    <Dropdown
                        setMenuState={(val) => { setShowFilterDropdown(prev => prev.map((it, idx) => idx === 1 ? !it : false)) }}
                        menuState={showFilterDropdown[1]}
                        // handleStateChange={(e) => { console.log(e.target); setPlaylistFilters(e.target.attributes.value.value) }}
                        valueX={filter.playlist}
                        index={0}
                        _handleChange={() => { setShowFilterDropdown(prev => prev.map((it, idx) => idx === 1 ? !it : false)) }}
                        listBackground="var(--greyBackground)"
                        justifyContent="center"
                        textAlign="center"
                        letterSpacing="0.1rem"
                        fontSize="var(--fontSmall)"
                        itemHeight="49px"
                        fontWeight="700"
                        width="130px"
                        name='playlist'
                        options={filterDropdowns.playlistDropdown}
                        setValidContinue={true} />
                </Filter> */}
                <Filter showBufferPlaylist={showBufferPlaylist} index={1} label={(showBufferPlaylist ? playlist.bufferPlaylist : playlist.currPlaylist).toUpperCase()} width="130px" setShowFilterDropdown={() => { setShowFilterDropdown(prev => prev.map((it, idx) => idx === 1 ? !it : false)) }} showFilterDropdown={showFilterDropdown[1]}>
                    <Dropdown
                        setMenuState={(val) => { setShowFilterDropdown(prev => prev.map((it, idx) => idx === 1 ? !it : false)) }}
                        menuState={showFilterDropdown[1]}
                        // handleStateChange={(e) => { console.log(e.target); setPlaylistFilters(e.target.attributes.value.value) }}
                        valueX={showBufferPlaylist ? playlist.bufferPlaylist : playlist.currPlaylist}
                        index={0}
                        _handleChange={() => { setShowFilterDropdown(prev => prev.map((it, idx) => idx === 1 ? !it : false)) }}
                        listBackground="var(--greyBackground)"
                        justifyContent="center"
                        textAlign="center"
                        letterSpacing="0.1rem"
                        fontSize="var(--fontSmall)"
                        itemHeight="49px"
                        fontWeight="700"
                        width="130px"
                        name='playlist'
                        options={filterDropdowns.playlistDropdown}
                        setValidContinue={true} />
                </Filter>
                {!showBufferPlaylist && <Filter showBufferPlaylist={showBufferPlaylist} index={2} label={filter.tempo.toUpperCase()} width="130px" setShowFilterDropdown={() => { setShowFilterDropdown(prev => prev.map((it, idx) => idx === 2 ? !it : false)) }} showFilterDropdown={showFilterDropdown[2]}>
                    <GridItem position='absolute' top="49px" alignItems="center">
                        <Dropdown
                            setMenuState={(val) => { setShowFilterDropdown(prev => prev.map((it, idx) => idx === 2 ? !it : false)) }}
                            menuState={showFilterDropdown[2]}
                            // handleStateChange={(e) => { console.log(e.target); setTempo(e.target.attributes.value.value) }}
                            valueX={filter.tempo}
                            index={0}
                            itemPadding="17.75px"
                            _handleChange={() => { setShowFilterDropdown(prev => prev.map((it, idx) => idx === 2 ? !it : false)) }}
                            listBackground="var(--greyBackground)"
                            justifyContent="center"
                            textAlign="center"
                            letterSpacing="0.1rem"
                            itemHeight="min-content"
                            fontSize="var(--fontSmall)"
                            fontWeight="700"
                            width="130px"
                            name='playlist'
                            options={filterDropdowns.tempoDropdown}
                            setValidContinue={true} />
                    </GridItem>
                </Filter>}
            </GridItem>
            <Flexbox cursor="pointer" zIndex="3" height="var(--navBarHeight)" width="20px" fixed top="0" right="calc(3.22vw + 60px)" onClick={toggleControls}><img src={ControlsIcon} style={{ position: 'relative', height: '100%', width: '100%' }} /></Flexbox>
        </>

    )
}

export default DashFilters