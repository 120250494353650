import React from 'react'
import {useNavigate} from 'react-router-dom'
import { Wrapper, CloseButton, LogoWrapper } from './MobileVisitor.styles'

import Button from '../Button'

import Close from '../../images/close.svg'
import Logo from '../../images/headerLogo.svg'

const MobileVisitor = ({isMobile, setShowMobileModal}) => {
    const navigate = useNavigate()
    return (
        <Wrapper isMobile={isMobile} onClick={() => {setShowMobileModal(false)}}>
            <CloseButton onClick={() => {setShowMobileModal(false)}}><img src={Close} alt="closebutton"/></CloseButton>
            <LogoWrapper><img src={Logo} alt="logo"/></LogoWrapper>
            <h3>Thanks for your interest in Azaa.<br/>Please visit us on desktop to apply.</h3>
            <Button width="100%" border="1px solid #ffffff" onClick={() => {setShowMobileModal(false)}}>Home</Button>
        </Wrapper>
    )
}

export default MobileVisitor