
import React, { useContext, useState, useEffect, useReducer, useRef } from 'react'
import { useInterval } from '../hooks/useInterval'
import { useAuth } from './AuthContext'
import API from '../API'

const MessengerContext = React.createContext()

export const useMessenger = () => {
    return useContext(MessengerContext)
}

//we need to programtically set the interval
export const MessengerProvider = ({ children }) => {
    const messagesInitialized = useRef(false);
    const { currentUser } = useAuth();
    const [ticker, setTicker] = useState(0);
    const [songID, setSongID] = useState(null);
    const [error, setError] = useState('');
    const messagesReducer = (state, { type, payload }) => {
        switch (type) {
            case 'push':
                return !state.map(msg => msg.id).includes(payload.id) ? [...state, payload] : state;
            // case 'update':
            //     return state.map(evt => evt.id === payload.id ? payload : evt);
            case 'pop':
                return state.filter(msg => msg.id !== payload.id);
            default:
                throw new Error();
        }
    }




    const fetchMessages = (songID = null) => {
        if (currentUser != null) {
            API.fetchMessages2(currentUser.accessToken, songID).then((res) => {

            }).catch((e) => {
                setError(e)
                return e
            })
        }
    }
    const initMessages = () => {
        fetchMessages()
    }
    // useEffect(() => {
    //     if (currentUser != null) {
    //         fetchMessages()
    //     }
    // }, [currentUser])
    const [messages, dispatchMessages] = useReducer(messagesReducer, [])
    useInterval(() => {
        //fetchMessages()
    }, currentUser !== null && currentUser.accessToken !== undefined && messagesInitialized.current ? 3000 : null)

    const value = { ticker, messages, songID, setSongID }
    return (
        <MessengerContext.Provider value={value}>
            {children}
        </MessengerContext.Provider>
    )
}

