import React, { useState, useEffect, useRef } from 'react';
import { _requiredAction } from '../helpers'
import { AZAA_PLATFORM_FEE_PERCENT, BUYER_PLAYLIST_TYPES, BUYER_DASH_ACTIONS } from '../config'
import API from '../API'
import { useAuth } from '../contexts/AuthContext'
import { Genre, Tempo } from '../objectPrototypes'

export const useBuyerDash = () => {
    const { currentUser } = useAuth()
    const [buyerID, setBuyerID] = useState('1')
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const initialRender = useRef(true)
    const payloadNew = useRef(null)

    const [payload, setPayload] =
        useState({
            songID: null,
            buyerID: null,
            songTitle: null,
        })

    const initialState = {
        song_title: '',
        tableData: [],
        selectedSong: {},
        actionRequired: 1
    }

    const [songs, setSongs] = useState([])
    const [songBuffer, setSongBuffer] = useState([])
    const [nbHits, setNbHits] = useState(0)

    const [selectedSong, setSelectedSong] = useState(null)
    const [showBufferPlaylist, setShowBufferPlaylist] = useState(false)

    const [showModal, setShowModal] = useState(false)

    const payloadModalOptions = [
        {
            title: 'Place Hold',
            subText: 'You can place a hold on this song for 5 days to lock it down from other buyers.',
            onClick: () => { console.log('placing a hold') }
        }
    ]

    // hold, etc
    const [modalType, setModalType] = useState(2)

    const downloadSong = async (songID, filename) => {
        // const res = await fetch(_href, { headers: new Headers({ 'Origin': document.location.origin }), mode: 'cors' })
        const res = await API.downloadSong(currentUser, songID)
        console.log("download res", res)
        // const _blob = URL.createObjectURL(new Blob([_href], { type: _mimeType }));
        // const link = document.createElement("a");
        // link.href = _blob
        // link.download = filename.replaceAll(' ', '-') + '.' + _fileTypeExtension;
        // console.log("link.href", _dataurl, link.href, link.download)
        // link.addEventListener('click', (e) => {
        //     setTimeout(() => { URL.revokeObjectURL(_href) }, 30 * 1000)
        // })
        // link.click()

        const url = URL.createObjectURL(new Blob([res.data]), { type: res.data.type });
        const _fileTypeExtension = res.data.type.includes('mpeg') ? 'mp3' : 'wav'
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename.replaceAll(' ', '-') + '.' + _fileTypeExtension);
        document.body.appendChild(link);
        link.addEventListener('click', (e) => {
            setTimeout(() => { URL.revokeObjectURL(res.data) }, 30 * 1000)
        })
        link.click();
    }

    const prepPayload = async (song_id, _modalType = 2) => {
        let _song = songs.find(it => it.song_id === song_id)
        if (!_song)
            _song = songBuffer.find(it => it.song_id === song_id)
        setPayload(_song)
        payloadNew.current = _song
        console.log("modal type", _modalType, BUYER_DASH_ACTIONS, BUYER_DASH_ACTIONS.ADD_TO_CART)
        if (_modalType === BUYER_DASH_ACTIONS.ADD_TO_CART) {
            const res = await API.updateCart(currentUser, _song.song_id)
            console.log("res", res)
        } 
        else if (_modalType !== BUYER_DASH_ACTIONS.DOWNLOAD) {
            setModalType(_modalType)
            setShowModal(true)
        } else if (_modalType === BUYER_DASH_ACTIONS.DOWNLOAD) {
            await downloadSong(_song.song_id, _song.song_title)
        }
    }

    // FILTERS
    const initialFilterState = {
        genre: [],
        playlist: 'Top 100',
        tempo: 'TEMPO'
    }
    const [filter, setFilter] = useState(initialFilterState)
    const [playlist, setPlaylist] = useState({ currPlaylist: 'Top 100', bufferPlaylist: 'Top 100' })
    const [showFilterDropdown, setShowFilterDropdown] = useState([false, false, false])
    const pageRef = useRef(1)
    const closeBufferPlaylist = () => {
        setShowBufferPlaylist(false)
        pageRef.current = 1
    }
    const closeModal = () => setShowModal(false)

    const validTempos = (new Tempo()).validTempos()
    const validGenres = (new Genre()).validGenres()
    const validPlaylists = Object.keys(BUYER_PLAYLIST_TYPES).map(it => BUYER_PLAYLIST_TYPES[it])

    const tempoDropdown = validTempos.map(it => ({
        value: it, innerText: it, onClick: () => {
            if (filter.tempo === it) {
                setFilter(prev => ({ ...prev, tempo: 'TEMPO' }))
            } else {
                setFilter(prev => ({ ...prev, tempo: it }))
            }
            setShowFilterDropdown(Array(3).fill(false))
        }
    }))

    const changeCurrPlaylistHeader = () => {
        setPlaylist(prev => ({ ...prev, currPlaylist: prev.bufferPlaylist }))
        setShowBufferPlaylist(false)
    }


    const playlistDropdown = validPlaylists.map(it => ({
        value: it, innerText: it,
        onClick: async () => {
            // setFilter(prev => ({ ...prev, playlist: it }));
            setPlaylist(prev => ({ ...prev, bufferPlaylist: it }))
            if (!Object.entries(playlist).find(_it => _it[1] === it)) {
                const res = await pullSongs(pageRef.current, { ...filter, playlist: it }, true)
                setIsOpen(false)
                setShowBufferPlaylist(true)
            } else if (playlist.bufferPlaylist === it && it !== playlist.currPlaylist) {
                //setSongBuffer([])
                setIsOpen(false)
                setShowBufferPlaylist(true)
            } else {
                setShowBufferPlaylist(false)
            }
            setShowFilterDropdown(Array(3).fill(false))

        }
    }))

    const genreDropdown = validGenres.map(it => ({ value: it, innerText: it }))
    //await API.editFavorites(currentUser, it.song_id)
    const filterDropdowns = { genreDropdown, playlistDropdown, tempoDropdown }

    const handleGenreChange = (event) => {
        if (filter.genre.includes(event.target.attributes.value.value)) {
            setFilter(prev => ({ ...prev, genre: prev.genre.filter((n) => { return n != event.target.attributes.value.value }) }))
        }
        else if (!filter.genre.includes(event.target.attributes.value.value) && filter.genre.length < 2) {
            if (filter.genre.length === 1) {
                setShowFilterDropdown(Array(3).fill(false))
            }
            setFilter(prev => ({ ...prev, genre: [...prev.genre, event.target.attributes.value.value] }))
        }
    }

    //

    const resultsPerPage = 20
    const [page, setPage] = useState(1)
    const [totalResults, setTotalResults] = useState(0)

    const [newApproval, setNewApproval] = useState(false)



    const fetchMore = async () => {
        console.log("fetching more")
        pageRef.current = pageRef.current + 1;
        await pullSongs(pageRef.current, { ...filter, playlist: showBufferPlaylist ? playlist.bufferPlaylist : playlist.currPlaylist }, showBufferPlaylist, true)
    }

    const pullSongs =
        async (_page = pageRef.current, _filter = { ...filter, playlist: playlist.currPlaylist }, isPlaylistSwitch = false, retainValues = false) => {

            setError(false)
            try {
                const response = await API.getSongsBuyer(currentUser, _page, _filter, resultsPerPage)
                if (response.songs !== undefined) {
                    console.log("res songs", response.songs)
                    if (isPlaylistSwitch) {
                        if (retainValues)
                            setSongBuffer(prev => [...prev, ...response.songs])
                        else
                            setSongBuffer(response.songs)
                    }
                    else {
                        if (retainValues)
                            setSongs(prev => [...prev, ...response.songs])
                        else
                            setSongs(response.songs)
                    }
                }
                if (response['nbHits']) {
                    setNbHits(response['nbHits'])
                } else {
                    setNbHits(0)
                }
                setError(false)
                if (response.songs !== undefined)
                    return response.songs
                else return []
            } catch (error) {
                setLoading(false)
                setError(true)
            }
        }

    useEffect(() => {
        const init = async () => {
            setLoading(true)
            await pullSongs()
            setLoading(false)
            setError(false)
            initialRender.current = false
        }
        init()
    }, [])

    return {
        payload,
        initialRender,
        setShowModal,
        modalType,
        closeModal,
        prepPayload,
        showModal,
        setPayload,
        isOpen,
        setIsOpen,
        songs,
        songBuffer,
        setSongBuffer,
        changeCurrPlaylistHeader,
        showBufferPlaylist,
        closeBufferPlaylist,
        setShowBufferPlaylist,
        setSongs,
        selectedSong,
        setSelectedSong,
        pullSongs,
        loading,
        setLoading,
        error,
        setError,
        page,
        setPage,
        totalResults,
        resultsPerPage,
        filterDropdowns,
        filter,
        playlist,
        setShowFilterDropdown,
        showFilterDropdown,
        handleGenreChange,
        nbHits,
        fetchMore
    };
}