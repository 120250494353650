import styled from "styled-components";

export const TableContainer = styled.div`
margin: ${props => props.margin ? props.margin : '20px 0'};
padding: ${props => props.padding ? props.padding : 'undefined'};
border: ${props => props.border ? props.border : '1px solid #ffffff'};
box-shadow: ${props => props.boxShadow ? props.boxShadow : 'undefined'};
border-bottom: ${props => props.borderBottom ? props.borderBottom : '1px solid #ffffff'};
width: 100%;
`;

export const Email = styled.div`
display: flex;
flex-direction: column-reverse;
.emailShortened{
    max-width: calc(360px - 45px - 3.5rem - 63px - 0.75rem*3);
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltip{
    background: var(--background);
    border: 2px solid var(--yellow);
    padding: 10px 20px;
    position: fixed;
    visibility: hidden;
    z-index: 1;
    margin-left: -20px;
    margin-bottom: 30px;
}
&:hover .tooltip{
    visibility: visible;
}
`;

export const PricingTable = styled.div`
font-size: 14px;
width: 100%;
display: grid;
padding-right: 20px;
grid-column-gap: 20px;
grid-template-columns: 1fr 5rem 5rem;
grid-auto-rows: min-content;
border: 1px solid #fff;

>div{
    white-space: nowrap;
    text-align: right;
    height: 30px;
    display: flex;
    align-items: center;
    color: var(--purpleGrey);
    /* padding: 5px; */
    /* padding: 1px 0; */
}

>div:nth-of-type(2n + 1){
    /* padding: 5px;// 5px 0 5px; */
    border-bottom: 2px solid var(--greyBackground);
    /* letter-spacing: 0.03rem; */
    font-size: 12px;
    padding-left: 20px;
    grid-column: 1 / span 1;
    
    font-weight: 600;
    justify-content: flex-end;
}
>div:nth-of-type(2n){
    font-weight: 600;
    /* padding-right: 20px; */
    grid-column: 2 / span 1;
    /* letter-spacing: .1rem; */
    /* padding: 5px 25px 5px; */
    justify-content: flex-end;
    background: var(--greyBackground);
    border-bottom: 2px solid var(--greyBackground);
    
}
/* &:last-child{
    border-bottom: 0;
} */
/* >div:nth-of-type(4), >div:nth-of-type(3){
    border-bottom: 2px solid var(--greyBackground);
} */
`;