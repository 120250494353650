import React, { useState, useEffect, useRef } from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import Flexbox from '../Flexbox'
const Test = () => {
    const [_data, _setData] = useState(Array.from({ length: 20 }, (_, i) => i + 1))

    const fetchMas = () => {
        setTimeout(() => {
            const newData = _data
            _setData(newData.concat(Array.from({ length: 20 }, (_, i) => i + 1)));
        }, 1500);

    }

    return (
        <div>
            <h1>demo: react-infinite-scroll-component</h1>
            <hr />
            <InfiniteScroll
                dataLength={_data.length}
                next={fetchMas}
                hasMore={true}
                loader={<h4>Loading...</h4>}
                height={400}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
            >
                {_data.map((i, index) => (
                    <div style={{ position: 'relative', width: '400px',padding: '20px',height: '50px' }} key={index}>
                        div - #{index}
                    </div>
                ))}
            </InfiniteScroll>
        </div>
    )
}


export default Test