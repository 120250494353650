import styled from "styled-components";

export const Wrapper = styled.div`
    /* background: var(--greyBackground); */
    background: ${props => props.background};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    h1{
        margin-top: 60px;
        font-size: var(--fontBig);
        font-weight: 300;
    }
    @media screen and (max-width: 660px){
        padding: 0 50px 10px;
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            padding: 0 50px 10px;
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            padding: 0 50px 10px;
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            padding: 0 50px 10px;
    }
`;

export const ButtonContainer = styled.div`
width: 198px;
position: relative;
display: flex;
        @media screen and (max-width: 800px){

        width: 100%;
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {

            width: 100%;
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {

            width: 100%;
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {

            width: 100%;
    }
`;

export const Content = styled.div`
/* max-width: 1193px; */
margin: 0 60px;
display: flex;
justify-content: space-between;
flex-direction: column;

@media screen and (min-width: 720px){
            flex-direction: row;
        }
@media screen and (max-width: 600px){
        padding: 0;
        row-gap: 20px;
        width: 100%;
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            padding: 0;
            row-gap: 20px;
            width: 100%;
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            padding: 0;
            row-gap: 20px;
            width: 100%;
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            padding: 0;
            row-gap: 20px;
            width: 100%;
    }


/* img{
    width: 67%;
    height: auto;
    object-fit: contain;
    max-width: 1100px;
} */
`;

export const Computercontainer = styled.div`
    width: 60vw;
    /* height: auto; */
    object-fit: contain;
    max-width: 1100px;
    display: flex;
    position: relative;
    img {
        max-height: 100%;
        max-width: 100%;
    }
    @media only screen and (max-width: 720px){
        margin: 0 auto;
        width: 100%;
    }
    /* @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            margin: 0 auto;
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            margin: 0 auto;
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            margin: 0 auto;
    } */
`;

export const InnerContent = styled.div`
display: flex;
flex-direction: column;
justify-content: center;

button{
    margin-top: 60px;
}
@media screen and (min-width: 720px){
            width: 100%;
        }
    @media screen and (max-width: 600px){
            width: 100%;
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            width: 100%;
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            width: 100%;
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            width: 100%;
    }

`;

export const H1 = styled.h1`
text-transform: none;
text-align: center;

`;

export const Text = styled.div`
font-size: var(--fontBig);
font-weight: 300;

strong{
    font-weight: 700;
}
`;

export const Video = styled.video`
min-width: 100%;
min-height: 100%;
object-fit: cover;
`;

export const StillFrame = styled.img`
min-width: 100%;
min-height: 100%;
object-fit: cover;
position: absolute;
z-index: 1;
top: 0;
left: 0;
`;

export const VidContainer = styled.div`
position: absolute;
width: ${props => props.largeMonitor ? '61.5%' : '70.25%'};
overflow: hidden;
left: ${props => props.largeMonitor ? '12.25%' : '15%'};
top: ${props => props.largeMonitor ? '5.5%' : '15.5%'};
height: ${props => props.largeMonitor ? '56.5%' : '64%'};
`;

export const MonitorLargeFrame = styled.div`
    width: 50vw;
    /* height: auto; */
    height: auto;
    object-fit: contain;
    max-width: 950px;
    display: flex;
    position: relative;
    img {
        max-height: 100%;
        max-width: 100%;
    }
    @media only screen and (max-width: 720px){
        margin: 0 auto;
        width: 100%;
    }
`;

export const MonitorSmallFrame = styled.div`
    width: 27vw;
    /* height: auto; */
    object-fit: contain;
    height: auto;
    max-width: 500px;
    display: flex;
    position: absolute;
    bottom: 1.8vw;
    right: 5%;
    img {
        max-height: 100%;
        max-width: 100%;
    }
`;
