import styled from "styled-components";

export const OuterWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;

width: 100%;
height: 100%;
position: relative;
padding: 50px;
top: 0px;
`;

export const Header = styled.h1`
user-select: none;
@media only screen and (max-width: 700px){
    line-height: 1em;
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            line-height: 1em;
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            line-height: 1em;
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            line-height: 1em;
    }
`;

export const Wrapper = styled.div`
display: grid;
max-width: 800px;
grid-auto-rows: min-content;
position: relative;
grid-column-gap: 20px;
grid-row-gap: 20px;
grid-template-areas:
${props => props.index === 0 ? 
`'image quote'
'image bio'` : 
`'quote image'
'bio image'`
} ;
grid-template-columns: 
${props => props.index === 0 ? '400px 1fr' : '1fr 400px' };
@media only screen and (max-width: 700px){
    grid-template-areas:
    'image''quote''bio';
    grid-template-columns: 1fr;
    width: 100%;
    margin-top: ${props => props.index === 1 ? '20px' : '0'};
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            grid-template-areas:
    'image''quote''bio';
    grid-template-columns: 1fr;
    width: 100%;
    margin-top: ${props => props.index === 1 ? '20px' : '0'};
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            grid-template-areas:
    'image''quote''bio';
    grid-template-columns: 1fr;
    width: 100%;
    margin-top: ${props => props.index === 1 ? '20px' : '0'};
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            grid-template-areas:
    'image''quote''bio';
    grid-template-columns: 1fr;
    width: 100%;
            margin-top: ${props => props.index === 1 ? '20px' : '0'};
    }
`;

export const Quote = styled.div`
font-style: italic;
font-size: 21px;
font-weight: 200;
`;

export const Attribute = styled.div`
font-size: 12px;
text-transform: uppercase;
margin-top: 1rem;
`;

export const Bio = styled.p`
text-align: justify;
margin: 0;
`;

export const PictureContainer = styled.div`
grid-area: image;
position: relative;
display: flex;

overflow: hidden;
img{
    min-width: 100%;
    min-height: 100%;
    object-fit: contain;
}
`;

export const QuoteBox = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-end;
`;



