import React, { useState, useEffect, useRef } from 'react'

import {
    Wrapper,
    FormWrapper,
    Content,
    LinkButton,
    SubmitButton,
    CloseButtonStyle,
    CheckboxStyle,
    QuestionWrapper,
    NavLink,
    SwitchField,
    Error,
    TermsLink,
    Overlay,
    SubText,
    H2,
    PwdReset
} from './LoginOverlay.styles'
import InputField from '../InputField'
import LoadingWheel from '../LoadingWheel'
import MobileLogin from '../MobileLogin'
// import Button from '../Button'

import { validateEmail } from '../../helpers.js'

import API from '../../API'

import { useNavigate, useLocation } from 'react-router-dom'

// // Hook
// import { useAuth } from '../../hooks/useAuth';

import { useAuth } from '../../contexts/AuthContext';


import {
    LOGIN_POPUP,
    SIGNUP_POPUP,
    BUYER_SIGNUP,
    SET_PASSWORD_POPUP
} from '../../config.js'

import Logo from '../../images/azaaLogo.svg';

const Checkbox = ({ onCheck, userAgreement }) => {
    return (
        <CheckboxStyle >By registering, I agree to the site's <TermsLink to={{ pathname: "/terms" }} target="_blank">Terms and Conditions</TermsLink> and the Azaa <TermsLink to={{ pathname: "/azaa-seller-agreement" }} target="_blank">Seller Agreement.</TermsLink> <input type="checkbox" onChange={onCheck} checked={userAgreement} /><span></span></CheckboxStyle>
    )
}

const CheckboxSubscribe = ({ onCheck, userAgreement }) => {
    return (
        <CheckboxStyle >Sign me up to receive news and updates from Azaa Music. <input type="checkbox" onChange={onCheck} /><span></span></CheckboxStyle>
    )
}

const FormContent = ({ popupType, handleChange, continueKey, state, user }) => {
    const firstRef = useRef(null);
    const lastRef = useRef(null);

    if (popupType === SIGNUP_POPUP) {
        return (
            <>
                <QuestionWrapper
                //marginTop={"100px"}
                >
                    <InputField

                        type="text"
                        onChange={handleChange('name')}
                        onKeyPress={continueKey}
                        name='name'
                        placeholder={'Enter your name'.toUpperCase()}
                        marginBottom={'20px'}
                        value={state.name}
                        uppercaseOff={true}
                    />
                </QuestionWrapper>
                <QuestionWrapper>
                    <InputField

                        type="email"
                        onChange={handleChange('email')}
                        onKeyPress={continueKey}
                        name='email'
                        placeholder={'Email Address'.toUpperCase()}
                        marginBottom={'20px'}
                        value={state.email}
                        uppercaseOff={true}
                    />
                </QuestionWrapper>

            </>

        )
    }
    else if (popupType === BUYER_SIGNUP) {
        return (
            <>
                <QuestionWrapper>
                    <H2>Catalog will be live Spring 2022.</H2>
                </QuestionWrapper>
                <QuestionWrapper>
                    <SubText>Sign up below to stay updated on our official launch!</SubText>
                </QuestionWrapper>
                <QuestionWrapper gridTemplateColumns="1fr 1fr">
                    <InputField
                        type="text"
                        labelFontSize="10px"
                        labelTextTransform="uppercase"
                        onKeyPress={continueKey}
                        onChange={handleChange('first_name')}
                        name='first_name'
                        placeholder={'First Name'.toUpperCase()}
                        marginBottom={'20px'}
                        value={state.first_name}
                        uppercaseOff={true}
                    />
                    <InputField
                        type="text"
                        labelFontSize="10px"
                        labelTextTransform="uppercase"
                        onKeyPress={continueKey}
                        onChange={handleChange('last_name')}
                        name='last_name'
                        placeholder={'Last Name'.toUpperCase()}
                        marginBottom={'20px'}
                        value={state.last_name}
                        uppercaseOff={true}
                    />
                </QuestionWrapper>
                <QuestionWrapper>
                    <InputField
                        type="email"
                        onChange={handleChange('email')}
                        onKeyPress={continueKey}
                        name='email'
                        placeholder={'Email Address'.toUpperCase()}
                        marginBottom={'20px'}
                        value={state.email}
                        uppercaseOff={true}
                    />
                </QuestionWrapper>

            </>

        )
    }
    else if (popupType === LOGIN_POPUP) {
        return (
            <>
                <QuestionWrapper >
                    <InputField
                        type="email"
                        onChange={handleChange('email')}
                        name='email'
                        placeholder={'Email Address'.toUpperCase()}
                        marginBottom={'20px'}
                        onKeyPress={continueKey}
                        value={state.email}
                        uppercaseOff={true}
                    />
                </QuestionWrapper>
                <QuestionWrapper>
                    <InputField
                        type="password"
                        onChange={handleChange('password')}
                        name='password'
                        placeholder={'Password'.toUpperCase()}
                        onKeyPress={continueKey}
                        marginBottom={'20px'}
                        value={state.password}
                        uppercaseOff={true}
                    />
                </QuestionWrapper>
            </>
        )
    }
    else if (popupType === SET_PASSWORD_POPUP) {
        return (
            <>
                <QuestionWrapper>
                    <H2>Congratulations on your acceptance and welcome to Azaa!</H2>
                </QuestionWrapper>
                <QuestionWrapper>
                    <SubText>You'll use the email you applied with to login after completing the fields below.</SubText>
                </QuestionWrapper>
                <QuestionWrapper >
                    <InputField
                        type="email"
                        labelFontSize="10px"
                        labelTextTransform="uppercase"
                        label="login email"
                        onChange={handleChange('email')}
                        name='email'
                        placeholder={'Email Address'.toUpperCase()}
                        marginBottom={'20px'}
                        value={state.email}
                        uppercaseOff={true}
                        disabled
                    />
                </QuestionWrapper>

                <QuestionWrapper gridTemplateColumns="1fr 1fr">
                    <InputField
                        type="text"
                        labelFontSize="10px"
                        labelTextTransform="uppercase"
                        label="First Name"
                        onChange={handleChange('first_name')}
                        name='first_name'
                        placeholder={'First Name'.toUpperCase()}
                        marginBottom={'20px'}
                        value={state.first_name}
                        uppercaseOff={true}

                    />
                    <InputField
                        type="text"
                        labelFontSize="10px"
                        labelTextTransform="uppercase"
                        label="Last Name"
                        onChange={handleChange('last_name')}
                        name='last_name'
                        placeholder={'Last Name'.toUpperCase()}
                        marginBottom={'20px'}
                        value={state.last_name}
                        uppercaseOff={true}

                    />
                </QuestionWrapper>
                <QuestionWrapper>
                    <SubText>You'll be paid via PayPal after your song sells on the Azaa marketplace.</SubText>
                </QuestionWrapper>
                <QuestionWrapper >
                    <InputField
                        type="email"
                        labelFontSize="10px"
                        labelTextTransform="uppercase"
                        label="PayPal Email Address"
                        onChange={handleChange('paypal')}
                        name='paypal'
                        placeholder={'PayPal Email Address'.toUpperCase()}
                        marginBottom={'20px'}
                        value={state.paypal}
                        uppercaseOff={true}
                    />
                </QuestionWrapper>
                <QuestionWrapper gridTemplateColumns="1fr 1fr">
                    <InputField
                        labelFontSize="10px"
                        labelTextTransform="uppercase"
                        subText={<>Min 8 characters<br></br>At least one letter<br />at least one number</>}
                        label="Set a password"
                        type="password"
                        onChange={handleChange('password')}
                        name='password'
                        placeholder={'Password'.toUpperCase()}
                        uppercaseOff={true}
                        marginBottom={'20px'}
                    />
                    <InputField
                        labelFontSize="10px"
                        labelTextTransform="uppercase"
                        label="Reenter password"
                        type="password"
                        onChange={handleChange('passwordV')}
                        name='passwordV'
                        placeholder={'Confirm Password'.toUpperCase()}
                        uppercaseOff={true}
                        marginBottom={'20px'}
                    />
                </QuestionWrapper>
                <QuestionWrapper>
                    <SubText fontSize="12px" color={/^[a-zA-Z\d\w\W]{8,}.*$/.test(state.password) ? 'var(--green)' : 'var(--red)'}>Min 8 characters</SubText>
                    <SubText fontSize="12px" color={/^(?=.*[A-Z]).*$/.test(state.password) ? 'var(--green)' : 'var(--red)'}>At least one uppercase letter</SubText>
                    <SubText fontSize="12px" color={/^(?=.*[a-z]).*$/.test(state.password) ? 'var(--green)' : 'var(--red)'}>At least one lowercase letter</SubText>
                    <SubText fontSize="12px" color={/\d/.test(state.password) ? 'var(--green)' : 'var(--red)'}>At least one number</SubText>
                    <SubText fontSize="12px" color={state.password !== '' && state.password === state.passwordV ? 'var(--green)' : 'var(--red)'}>Passwords must match</SubText>
                </QuestionWrapper>
                <QuestionWrapper>
                    <Checkbox onCheck={handleChange('checkbox')} />
                    <CheckboxSubscribe onCheck={handleChange('checkboxSubscribe')} />
                </QuestionWrapper>
            </>
        )
    }
    else return <></>
}

const CloseButton = ({ setShowLogin }) => {

    const onClick = (event) => {
        event.preventDefault()
        setShowLogin(false)
    }
    return (
        <CloseButtonStyle
            onClick={onClick}
        >
            &#10006;</CloseButtonStyle>)
}

const LoginOverlay = ({ onChange, setShowLogin, popupType, setPopupType, user, setUser, onClick, regKey, directAccessedLogin = false }) => {
    //const { login } = useAuth();
    const submitted = useRef(false)
    const { initUser, loadingUser, signup, login, currentUser, _retrieveUserDetails, sendResetPasswordEmail, setAccessToken } = useAuth();
    const navigate = useNavigate()
    const location = useLocation();
    useEffect(() => {

        if (popupType !== SET_PASSWORD_POPUP)
            document.querySelector("body").style.overflowY = 'hidden'

        return () => {
            document.querySelector("body").style.overflowY = 'visible'
            document.querySelector("body").style.overflowX = 'hidden'
        }
    }, [])




    const initialState = {
        email: user !== undefined ? user.email !== undefined ? user.email : "" : "",
        paypal: "",
        password: "",
        passwordV: "",
        name: "",
        checkboxSubscribe: false
    }
    const validatePassword = (pw) => {
        const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
        return re.test(String(pw));
    }
    const [state, setState] = useState(initialState);
    const continueKey = async (event) => {
        console.log("handling from here", popupType === LOGIN_POPUP)
        if (event.key === 'Enter') {
            event.preventDefault();
            // handleKeyPress(event)
            if (popupType === LOGIN_POPUP) {
                await handleLogin(event)
            } else if (popupType === SET_PASSWORD_POPUP) {
                await handleSubmit(event)
            } else if (popupType === BUYER_SIGNUP) {
                await buyerSignup()
            } else if (validateStep() && popupType === SIGNUP_POPUP) {
                API.authNewSignup(state.email).then((res) => {
                    setShowLogin(false)
                    navigate('/get-started', { state: { name: state.name, email: state.email } })
                }).catch(err => {
                    setError(err.response.data.message)
                })
            }
        }


        else if (event.key === 'Tab' && event.shiftKey) {
            const inputs = [...document.querySelectorAll('.modal input')]
            if (event.target === inputs[0] && event.shiftKey) {
                event.preventDefault()
                inputs[inputs.length - 1].focus()
            }
        } else if (event.key === 'Tab') {
            const inputs = [...document.querySelectorAll('.modal input')]

            if (event.target === inputs[inputs.length - 1]) {
                event.preventDefault()
                inputs[0].focus()
            }
        }



    }
    const [error, setError] = useState(false);
    const [showResendEmailOption, setShowResendEmailOption] = useState(false);
    const [loading, setLoading] = useState(loadingUser || (loadingUser && location.state !== null && location.state.isRerouted !== undefined) ? true : false)
    // CHANGED
    const buyerSignup = async () => {
        if (validateStep()) {
            try {
                setError('')
                setLoading(true)
                const res = await API.mailChimpSignup('Buyer', state.email, state.first_name, state.last_name)
                setLoading(false)
                setValidContinue(false)
                submitted.current = true;
                setError('Success!')
            } catch (error) {
                console.log(error)
                setLoading(false)
                setError('An error has occurred. Please try again later.')
            }
            // setShowLogin(false)
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log("handling")
        if (validateStep()) {
            try {
                setError(false)
                setLoading(true)
                const userCred = await signup(state.email, state.password)
                const res2 = await API.registerAccount(state.first_name, state.last_name, state.email, state.paypal, regKey, state.checkboxSubscribe)
                const res5 = await login(state.email, state.password)
                const { user } = res5
                const res4 = await initUser(user)

                setLoading(false)
                setShowLogin(false)
                navigate('/')
            } catch (error) {
                const errCode = error.code !== undefined ? error.code.includes("email-already-in-use") ? <>You have already registered your account.
                    <div onClick={() => {

                        navigate('/login')
                    }} >Sign in</div></> : <>Unknown</> : <>Unknown</>;
                setError(errCode)
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        if (currentUser && currentUser.userDetails !== undefined) {
            console.log("currentUser", currentUser, currentUser.userDetails)
            const navTo =
                location.state !== undefined && location.state !== null && location.state.from !== undefined ?
                    location.state.from :
                    directAccessedLogin ? '/'
                        : '/dashboard';
            setShowLogin(false)
            if (navTo !== '/dashboard')
                navigate(navTo)
        } else if (currentUser === null && loadingUser === false) {
            setLoading(false)
        }
    }, [currentUser, loadingUser])

    const handleLogin = async (e) => {

        e.preventDefault()
        if (validateStep()) {
            try {
                setLoading(true)
                const userCred = await login(state.email, state.password)
                setShowLogin(false)
            } catch (e) {
                setShowLogin(true)
                const errCode = e.message !== undefined ? e.message.includes("wrong-password") ? "Incorrect password."
                    : e.message.includes("user-not-found") ? "Account not found."
                        : e.message.includes("too-many-requests") ? "Too many attempts. Try again later or reset your password."
                            : "Unknown" : "Error";
                setError(errCode)
                setLoading(false)
            }
        }
        else {
            console.log("invalid")
        }
    }

    const [validContinue, setValidContinue] = useState(false);

    const validateStep = () => {
        if (popupType === LOGIN_POPUP && validateEmail(state.email) && state.password !== '') {
            setValidContinue(true)
            return true;
        }
        else if (popupType === SIGNUP_POPUP && validateEmail(state.email) && state.name !== '' && /^[a-zA-Z\s]*$/.test(state.name)) {
            setValidContinue(true)
            return true;
        }
        else if (popupType === BUYER_SIGNUP && validateEmail(state.email) && /^[a-zA-Z\s]*$/.test(state.first_name) && !submitted.current
            && /^[a-zA-Z\s]*$/.test(state.last_name)) {
            setValidContinue(true)
            return true;
        }
        else if (popupType === SET_PASSWORD_POPUP
            && validateEmail(state.email)
            && /^[a-zA-Z\s]*$/.test(state.first_name)
            && /^[a-zA-Z\s]*$/.test(state.last_name)
            && validateEmail(state.paypal)
            && state.checkbox
            && validatePassword(state.password)
            && state.password === state.passwordV) {
            setValidContinue(true)
            return true;
        }
        else {
            setValidContinue(false)
            return false
        }
    }
    const handleChange = (input) => (event) => {
        setState(prev => ({
            ...prev,
            [input]: event.target.type !== 'checkbox' ? event.target.value : event.target.checked
        }))
        if (popupType === SET_PASSWORD_POPUP && setUser !== undefined) {
            setUser(prev => ({ ...prev, password: event.target.value }))
        }
    }
    const onClickButton = (event) => {
        if (validContinue) {
            setShowLogin(false)
        }
        else {

        }
    }
    useEffect(() => {
        validateStep()
    }, [state])
    const wrapperRef = useRef(null)

    if (loading) {
        return <LoadingWheel height="100vh" top="0px" position="fixed" zIndex="20" />

    }
    else return (
        <>
            <Wrapper ref={wrapperRef} isRegister={popupType === SET_PASSWORD_POPUP} onClick={(e) => { if (e.target === wrapperRef.current) setShowLogin(false); }}>
                <MobileLogin isMobile={popupType === SET_PASSWORD_POPUP || popupType === LOGIN_POPUP} />
                <Content isMobile={popupType === SET_PASSWORD_POPUP || popupType === LOGIN_POPUP} className="modal" isLoading={loading} maxWidth={popupType === SET_PASSWORD_POPUP ? '600px' : '426px'}>
                    <img src={Logo} alt="logo" />
                    <FormWrapper>
                        <FormContent
                            user={user}
                            popupType={popupType}
                            handleChange={handleChange}
                            continueKey={continueKey}
                            state={state} />
                        <QuestionWrapper>
                            {popupType === SIGNUP_POPUP &&
                                <SubmitButton
                                    color={validContinue ? '#000000' : '#ffffff'}
                                    width="100%"
                                    disabled={!loading}
                                    background={validContinue ? '#ffffff' : "rgba(255,255,255,0.15)"}
                                    fontWeight={validContinue ? '700' : '700'}
                                    border={validContinue ? "2px solid #ffffff" : '2px solid rgba(255,255,255,0.1)'}
                                    onClick={async () => {
                                        if (validateStep()) {
                                            try {
                                                setError('')
                                                if (!showResendEmailOption) {
                                                    const res = await API.authNewSignup(state.email)
                                                    setShowLogin(false)
                                                    navigate('/get-started', { state: { name: state.name, email: state.email } })
                                                } else if (showResendEmailOption) {
                                                    const res = await API.resendUserAcceptanceEmail(state.email)
                                                    console.log(res)
                                                    setError('Email sent')
                                                }
                                            } catch (err) {
                                                if (!showResendEmailOption) {
                                                    let _errMessage = err.response.data.message
                                                    if (_errMessage.includes('buyer')) {
                                                        _errMessage = _errMessage + ' If you would like you sign up for a songwriter account, please use a different email.'
                                                    }
                                                    setError(_errMessage)

                                                    if (err.response.data.text.includes('user-accepted')) {
                                                        setShowResendEmailOption(true)

                                                    }
                                                }
                                                else
                                                    setError('An error occurred. Please try again later.')
                                            }
                                        }
                                    }
                                    }
                                    state={{ name: state.name, email: state.email }}
                                    // to={popupType === SIGNUP_POPUP ? "/get-started" : "/"}
                                    cursor={validContinue ? 'pointer' : 'default'}
                                >{showResendEmailOption ? 'Resend Registration Email' : popupType}
                                </SubmitButton>
                            }
                            {popupType === BUYER_SIGNUP &&
                                <SubmitButton
                                    color={validContinue ? '#000000' : '#ffffff'}
                                    width="100%"
                                    disabled={loading || submitted.current}
                                    background={validContinue ? '#ffffff' : "rgba(255,255,255,0.15)"}
                                    fontWeight={validContinue ? '700' : '700'}
                                    border={validContinue ? "2px solid #ffffff" : '2px solid rgba(255,255,255,0.1)'}
                                    onClick={buyerSignup}
                                    state={{ name: state.name, email: state.email }}
                                    // to={popupType === SIGNUP_POPUP ? "/get-started" : "/"}
                                    cursor={validContinue ? 'pointer' : 'default'}
                                >Sign Up
                                </SubmitButton>
                            }
                            {popupType === SET_PASSWORD_POPUP &&
                                <SubmitButton
                                    color={validContinue ? '#000000' : '#ffffff'}
                                    width="100%"
                                    background={validContinue ? '#ffffff' : "rgba(255,255,255,0.15)"}
                                    fontWeight={validContinue ? '700' : '700'}
                                    border={validContinue ? "2px solid #ffffff" : '2px solid rgba(255,255,255,0.1)'}
                                    // onClick={onClick}
                                    onClick={handleSubmit}
                                    disabled={loading}
                                    cursor={validContinue ? 'pointer' : 'default'}
                                >Submit
                                </SubmitButton>
                            }
                            {popupType === LOGIN_POPUP &&
                                <SubmitButton
                                    width="100%"
                                    color={validContinue ? '#000000' : '#ffffff'}
                                    border={validContinue ? "2px solid #ffffff" : '2px solid rgba(255,255,255,0.1)'}
                                    background={validContinue ? '#ffffff' : "rgba(255,255,255,0.15)"}
                                    fontWeight={validContinue ? '700' : '700'}
                                    onClick={handleLogin}
                                    //onClick={() => login(state.email, state.password).then(() => { setShowLogin(false); navigate("/dashboard"); })}
                                    cursor={validContinue ? 'pointer' : 'default'}
                                >{popupType}
                                </SubmitButton>
                            }
                        </QuestionWrapper>
                        {(popupType !== SET_PASSWORD_POPUP && popupType !== BUYER_SIGNUP) && <>
                            <PwdReset to="/forgot-password" onClick={() => sendResetPasswordEmail(state.email).then(() => { setError('Password reset email sent') })}>Reset Password</PwdReset>
                            <SwitchField
                                onClick={() => { setPopupType(popupType === SIGNUP_POPUP ? 'Log In' : 'Sign Up') }}>
                                {popupType === SIGNUP_POPUP ? 'Log In' : 'Register'}
                            </SwitchField>
                        </>
                        }
                        <QuestionWrapper marginTop="5px">
                            <Error>{error}</Error>
                        </QuestionWrapper>
                    </FormWrapper>
                    {popupType !== SET_PASSWORD_POPUP && <CloseButton setShowLogin={setShowLogin} />}
                </Content>

            </Wrapper>
            <Overlay onClick={(e) => { e.stopPropagation(); setShowLogin(false); }} />
        </>
    )

}

export default LoginOverlay
