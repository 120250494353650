import axios from 'axios'
//http://nfttest.xyz/api/index.php/home/
import {
  ACCEPTED_SONGS,
  SONG_ID_SPECIFIED,
  USER_ID_SPECIFIED,
} from './config';

const defaultConfig = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  }
};

const apiSettings = {
  fetchUser: async (email) => {

    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_user?email=${email}`
    });
    return res.data.userDetails
  },
  authNewSignup: async (email) => {
    //auth_signup
    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/auth_signup?email=${email}`
    });
    return res.data
  },
  resendUserAcceptanceEmail: async (email) => {
    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/resendUserAcceptanceEmail?email=${email}`
    });
    return res.data
  },
  validateContributorEmailsAreNotBuyers: async (currentUser, emailsArray) => {
    // var data = JSON.stringify({ email: currentUser.email, accessToken: currentUser.accessToken, emails: emailsArray});
    var qs = require('qs');
    var data = qs.stringify({
      'accessToken': currentUser.accessToken,
      'email': currentUser.email,
      'emails': emailsArray,
    });

    let res = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/validateContributorEmailsAreNotBuyers`, data,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });


    return res
  },
  fetchUserAccountSettings: async (access_token, email) => {

    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_user_account_settings?access_token=${access_token}&email=${email}`
    });
    return res.data
  },
  updateUser: async (user) => {
    if (!Object.keys(user).includes("email")) {
      return;
    }
    const formData = new FormData();

    formData.append("email", user.email)
    formData.append("access_token", user.access_token)

    if (Object.keys(user).includes("publisher")) {
      formData.append("publisher", user.publisher);
    }
    if (Object.keys(user).includes("instagram_handle")) {
      formData.append("instagram_handle", user.instagram_handle);
    }
    if (Object.keys(user).includes("spotify")) {
      formData.append("spotify", user.spotify);
    }
    if (Object.keys(user).includes("paypal")) {
      formData.append("paypal", user.paypal);
    }
    if (Object.keys(user).includes("first_name")) {
      formData.append("first_name", user.first_name);
    }
    if (Object.keys(user).includes("last_name")) {
      formData.append("last_name", user.last_name);
    }
    const res = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_DOMAIN}/update_user`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData
    })
    return res
  },
  fetchSongStatusKey: async () => {

    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_song_status_keys`
    });
    return res.data.valid_statuses
  },
  fetchSongs: async (accessToken, email, page = undefined, resultsPerPage = undefined, filter = undefined) => {

    const params = {
      email: email,
      access_token: accessToken,
      c: resultsPerPage,
      p: page,
      filter: filter !== undefined ? JSON.stringify(filter) : undefined
    }

    let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_user_songs2`,
      { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined)) } })


    // return res.data.songs !== undefined ? res.data.songs : [];
    return [res.data.songs, res.data.nbHits, res.data.resultRevised];

  },
  fetchSongs2: async (accessToken, email, page = undefined, resultsPerPage = undefined, filter = undefined, search = null) => {

    const params = {
      email: email,
      access_token: accessToken,
      c: resultsPerPage,
      p: page,
      filter: filter !== undefined ? JSON.stringify(filter) : undefined,
      search
    }

    let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_user_songs3`,
      { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) } })


    // return res.data.songs !== undefined ? res.data.songs : [];
    return [res.data.songs, res.data.nbHits, res.data.resultRevised];

  },
  fetchFAQ: async () => {

    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_faq`
    });

    return res.data.faq;

  },
  fetchSongsWithContributorStatus: async (accessToken) => {

    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_contributor_songs?access_token=${accessToken}`
    });

    let data = res.data.songs.reverse();
    return data;

  },
  fetchMessages: async (accessToken, email, songID = null) => {
    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_user_messages?access_token=${accessToken}&email=${email}&song_id=${songID}`
    });
    return res.data

  },
  fetchMessageStreamTabs: async (accessToken, email, page = 1, resultsPerPage = 5, userID = null, songID = null) => {

    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_user_message_stream_tabs2?access_token=${accessToken}&email=${email}&song_id=${songID}&p=${page}&c=${resultsPerPage}`
    });
    return [res.data.message_streams, res.data.total]
  },
  fetchMessages2: async (accessToken, songID) => {
    if (songID) {
      let res = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_DOMAIN}/get_user_messages_new?access_token=${accessToken}&song_id=${songID}`
      });
      if (res.data.messages !== undefined)
        return res.data.messages.reverse()
      else return []
    }
    else {
      let res = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_DOMAIN}/get_user_messages_new?access_token=${accessToken}`
      });
      if (res.data.messages !== undefined)
        return res.data.messages.reverse()
      else return []
    }

  },
  submitSongs: async (values, userDetails = null, currentUser = null) => {
    const formData = new FormData();
    if (currentUser === null && userDetails !== null) {
      formData.append("user_agreement", values.userAgreement === true ? 1 : 0);
      formData.append("name", userDetails.name);
      formData.append("email", userDetails.email);
      formData.append("instagram_handle", userDetails.instagram);
      formData.append("spotify", userDetails.spotify);
      formData.append("publisher", userDetails.publisher);
    }
    else if (currentUser !== null) {
      formData.append('access_token', currentUser.accessToken)
      formData.append("email", currentUser.email);
    }
    else {
      return 'error'
    }
    if (values.dropbox !== '') {
      formData.append("dropbox", values.dropbox);
    }
    formData.append("song_list", JSON.stringify(values.songList));
    values.songList.forEach((song, index) => {
      formData.append('song-' + index, song.file);
    });
    let res = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_DOMAIN}/save`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData

    });

    return res
  },
  submitBuyerApplication: async (values) => {
    const formData = new FormData();

    formData.append("artists", values.artists);
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("instagram_handle", values.instagram);
    formData.append("website", values.website);
    formData.append("label", values.label);
    formData.append("useConcierge", values.useConcierge);

    let res = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_DOMAIN}/putNewBuyer`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData

    });

    return res
  },
  setSplits: async (selectedSong, currentUser) => {
    const formData = new FormData();
    formData.append("song_id", selectedSong.id);
    formData.append("price", selectedSong.price);
    formData.append("contributors", JSON.stringify(selectedSong.contributors));
    // formData.append("total_files", selectedSong.stems.length);
    formData.append("access_token", currentUser.accessToken);
    formData.append("email", currentUser.email);
    // selectedSong.stems.forEach((song, index) => {
    //   formData.append('file-' + index, song.file);
    // })
    let res = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_DOMAIN}/update_additional_info`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData

    });

    return res
  },
  sendMessage: async (currentUser, song_id, message) => {
    var qs = require('qs');
    var data = qs.stringify({
      'access_token': currentUser.accessToken,
      'email': currentUser.email,
      'song_id': song_id,
      'message': message
    });
    let res = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_DOMAIN}/save_user_messages`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data

    });

    return res
  },
  sendSongAcceptanceEmail: async (userID, name, email, songTitle) => {
    var qs = require('qs');
    var data = qs.stringify({
      'fname': name,
      'email': email,
      'song_title': songTitle,
      'user_id': userID
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/send_user_email2`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res

  },
  sendSongAcceptanceEmail: async (userID, name, email, songTitle) => {
    var qs = require('qs');
    var data = qs.stringify({
      'fname': name,
      'email': email,
      'song_title': songTitle,
      'user_id': userID
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/send_user_email2`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res

  },
  authenticateAcceptance: async (registration_key) => {

    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/auth_acceptance?registration_key=${registration_key}`
    });

    return res.data.user

  },
  registerAccount: async (first_name, last_name, email, paypal, activation_key, isSubscribed) => {
    const qs = require('qs');
    const data = qs.stringify({
      'first_name': first_name,
      'last_name': last_name,
      'email': email,
      'paypal': paypal,
      'activation_key': activation_key,
      'subscribed': isSubscribed ? '1' : '0'
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/register`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res

  },
  signIn: async (email, password) => {
    const qs = require('qs');
    const data = qs.stringify({
      'email': email,
      'password': password
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/login`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res
  },
  setAccessToken: async (email, token) => {
    console.log("settings access token", token)
    const qs = require('qs');
    const data = qs.stringify({
      'email': email,
      'token': token
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/set_access_token`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res.data.userDetails !== undefined ? res.data.userDetails : null
  },
  updateNewSongTags: async (songs) => {
    const formData = new FormData();
    formData.append("songs", JSON.stringify(songs));
    const res = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_DOMAIN}/update_new_song_tags`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData
    })
    return res
  },
  updateMessageReadStatus: async (song_id, receiver_id, user_id) => { //we only want new tags when current user === receiver
    let res = await axios({ //user_id is songwriter_user_id field
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/update_message_read_status?song_id=${song_id}&receiver_id=${receiver_id}&user_id=${user_id}`
    });
    return res
  },
  submitContributorResponse: async (accessToken, email, songID, response) => {

    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/submit_contributor_response?email=${email}&access_token=${accessToken}&song_id=${songID}&response=${response}`
    });

    return res
  },
  checkIfUserExists: async (email) => {

    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/checkIfUserExists?email=${email}`
    });

    return res.data
  },
  verifyIdToken: async (email, idToken) => {
    const qs = require('qs');
    const data = qs.stringify({
      'email': email,
      'idToken': idToken
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/php_login`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res.data
  },
  mailChimpSignup: async (tag, email, firstName = '', lastName = '') => {
    const qs = require('qs');
    const data = qs.stringify({
      'tag': tag,
      'email': email,
      'first_name': firstName,
      'last_name': lastName
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/mailchimp_signup`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res.data
  },//getSongsBuyer
  getSales: async (currentUser, page = 1, filter = null, resultsPerPage = undefined, search = undefined) => {
    const params = {
      email: currentUser.email,
      access_token: currentUser.accessToken,
      filter,
      c: resultsPerPage,
      p: page,
      search
      // [{ type, value}, {artist, artist_id},{collection, collection_id}]
    }

    let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/getSales`,
      { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) } })

    return res.data
  },
  getSongsBuyer: async (currentUser, page = 1, filter = null, resultsPerPage = undefined, search = undefined) => {
    const params = {
      email: currentUser.email,
      access_token: currentUser.accessToken,
      filter,
      c: resultsPerPage,
      p: page,
      search
      // [{ type, value}, {artist, artist_id},{collection, collection_id}]
    }

    let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/getSongsBuyer`,
      { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) } })

    return res.data
  },
  confirmDeliverablesTransfer: async (currentUser, songId, agreement) => {
    const params = { //song id 1561 user id 1002 sold item id 70
      email: currentUser.email,
      access_token: currentUser.accessToken,
      songId,
      agreement
      // [{ type, value}, {artist, artist_id},{collection, collection_id}]
    }

    let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/confirmDeliverablesTransfer`,
      { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) } })

    return res.data
  },
  updateCart: async (currentUser, songId, agreement) => {
    const params = { //song id 1561 user id 1002 sold item id 70
      email: currentUser.email,
      access_token: currentUser.accessToken,
      songID: songId,
      // [{ type, value}, {artist, artist_id},{collection, collection_id}]
    }

    let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/updateCart`,
      { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) } })

    return res.data
  },
  getCart: async (currentUser) => {
    const params = { //song id 1561 user id 1002 sold item id 70
      email: currentUser.email,
      access_token: currentUser.accessToken,
      // songID: songId,
      // [{ type, value}, {artist, artist_id},{collection, collection_id}]
    }

    let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/getCart`,
      { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) } })

    return res.data
  },
  downloadSong: async (currentUser, songID) => {
    let res = await axios({
      method: 'get', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/downloadSong?email=${currentUser.email}&accessToken=${currentUser.accessToken}&songID=${songID}`, //api/config/database.php
      responseType: 'blob'
    });
    return res
    // const _url = window.URL.createObjectURL(new Blob([res.data]));
    // const link = document.createElement('a');
    // link.href = _url;
    // link.setAttribute('download', title);
    // document.body.appendChild(link);
    // link.click();
    // window.URL.revokeObjectURL(_url)
  },
  getSong: async (currentUser, filePath) => {
    let res = await axios({
      method: 'get', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/downloadSong?email=${currentUser.email}&accessToken=${currentUser.accessToken}&filePath=${filePath}`, //api/config/database.php
      responseType: 'blob'
    });
    return res
    // const _url = window.URL.createObjectURL(new Blob([res.data]));
    // const link = document.createElement('a');
    // link.href = _url;
    // link.setAttribute('download', title);
    // document.body.appendChild(link);
    // link.click();
    // window.URL.revokeObjectURL(_url)
  },
  editFavorites: async (currentUser, song_id) => {
    const qs = require('qs');
    const data = qs.stringify({
      'email': currentUser.email,
      'buyer_id': currentUser.userDetails.buyer_id,
      'access_token': currentUser.accessToken,
      'song_id': song_id
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/editFavorites`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res.data
  },
  updateEmail: async (currentEmail, accessToken, newEmail) => {
    const qs = require('qs');
    const data = qs.stringify({
      'currentEmail': currentEmail,
      'accessToken': accessToken,
      'newEmail': newEmail
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/updateEmail`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res.data
  },
  validateEmailUnused: async (email) => {
    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/validateEmailUnused?email=${email}`
    });

    return res.data
  }
};



export default apiSettings;