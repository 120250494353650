import React from 'react'

import {
    OuterWrapper,
    TextWrapper,
    Wrapper,
    SideBar,
    GridWrapper,
    ColumnWrapper,
    RowWrapper,
    Header
} from '../FAQ/FAQ.styles'

const Help = () => {
  return (
    <div>Help</div>
  )
}

export default Help