import React from 'react'
import { Wrapper } from './SellerAgreement.styles'
import AzaaSellerAgreement from '../../images/AzaaSellerAgreementFinal31022v4.pdf'
const SellerAgreement = () => {
    return (
        <Wrapper>
            <object data={AzaaSellerAgreement} type="application/pdf" >
                alt : <a href={AzaaSellerAgreement}>Azaa Seller Agreement.pdf</a>
            </object>
        </Wrapper>
    )
}

export default SellerAgreement