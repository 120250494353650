import React, { useRef, useEffect, useState } from 'react'

import { Wrapper, RowElementWrapper } from './TableEntry.styles'

const Row = ({ align, column, hideOffHover, showMenu, classNames }) => (
    <RowElementWrapper
        className={classNames}
        align={align}
        showMenu={showMenu}
        hideOffHover={hideOffHover}
    >{column}</RowElementWrapper>

)


const TableEntry = ({ adjustScrollPositionOnClose, _classNames, margin, _offHover, _onHover, width, border, setHoverActive, indexesToHideOffHover, rowHeight, rowHighlight, headerFontSize, contentFontSize, padding, gridTemplateAreas, gridTemplateColumns, gridColGap, content, rowAlignmentArray, id, onClick, header, noHover, tableHeaderTextStyling, ...props }) => {
    const refID = useRef(id)
    const [showMenu, setShowMenu] = useState(false)
    const wrapperRef = useRef(null)
    const mouseOver = (e) => {
        e.stopPropagation();
        if (setHoverActive) {
            setHoverActive(true)
            if (typeof (_onHover) === 'function')
                _onHover()
        }
        setShowMenu(true)
    }
    const mouseOut = (e) => {
        e.stopPropagation();
        if (setHoverActive) {
            setHoverActive(false)
            if (typeof (_offHover) === 'function')
                _offHover()

        }
        setShowMenu(false)
    }

    return (
        <Wrapper
            // onClick={ (e) => { e.preventDefault(); handleSelect('input is')}}
            onClick={onClick}
            margin={margin}
            height={rowHeight}
            border={border}
            rowHighlight={rowHighlight}
            onMouseEnter={(e) => mouseOver(e)}
            onMouseLeave={(e) => mouseOut(e)}
            ref={wrapperRef}
            width={width}
            fontSize={header !== undefined ? headerFontSize : contentFontSize}
            tableHeaderTextStyling={tableHeaderTextStyling}
            padding={padding}
            noHover={noHover !== undefined ? true : false}
            isHeader={header !== undefined ? true : undefined}
            gridTemplateAreas={gridTemplateAreas}
            gridColGap={gridColGap}
            gridTemplateColumns={gridTemplateColumns}
            {...props}>
            {
                content.map((column, index) => {

                    return (
                        <Row
                            classNames={_classNames !== undefined && _classNames.length > 0 ? _classNames[index] : undefined}
                            showMenu={showMenu}
                            hideOffHover={indexesToHideOffHover !== undefined && indexesToHideOffHover.includes(index) ? true : false
                            }
                            align={rowAlignmentArray !== undefined ? rowAlignmentArray.length === content.length ? rowAlignmentArray[index] : undefined : undefined}
                            column={column}
                            key={index}
                        />
                    )
                })
            }
        </Wrapper>
    )
}

export default TableEntry
