import { createGlobalStyle } from 'styled-components';
import CanoOtf from './fonts/Cano.otf';
export const GlobalStyle = createGlobalStyle`
    :root{
        --maxWidth: 1280px;
        --background: #000;
        --backgroundActiveLi: rgba(255,255,255,0.25);
        --borderRightActiveLi: 2px solid rgb(255,255,255);
        --yellow: #F2BD00;
        --white: #fff;
        --red: #9e0000;
        --green: #03cc00;
        --defaultFont: 'Raleway', sans-serif;
        --greyBackground: #262626;
        --dropdownBackground: #1A1A1A;
        --fontMicro: 10px;
        --navBarHeight: 50px;
        --fontSmall: 11px;
        --headerWeight: 500;
        --fontMedium: 1.1em;
        --fontBig: 1.5em;
        --bold: 500;
        --boldSmall: 600;
        --boldSmallLetterSpacing: 0.07rem;
        --buttonFontSize: 11px;
        --buttonHeight: 56px;
        --buttonBorder: 1px solid white;
        --purpleGrey: #696980;
        --defaultInputHeight: 49px;
        --accountExistsBlue: #00C5F2;
        --paypalBlueLight: #0079C1;
        --paypalBlueDark: #002b45;
        --paypalYellow: #ffc43a;
        --paypalYellowHover: #ffb70f;
        --nthRowBackground: rgba(255,255,255,0.1);
    }
    

    * {
        box-sizing: border-box;
        font-family: 'Raleway', sans-serif;
    }
    #root{
        max-width: 100%;
        height: 100%;
    }

    body{
        margin: 0;
        padding: 0;
        background: var(--background);
        color: var(--white);
        height: 100%;
    }
    @font-face {
        font-family: 'Cano';
        src: local('Cano'), url({CanoOtf}) format('opentype');
        font-weight: 300;
        font-style: normal;
    }
    html{
        margin: 0;
        font-size: 15px;
        font-family: 'Colombia';
        height: 100%;
        background: #000000;
        h1{
            user-select: none;
            text-transform: uppercase;
            /* margin: 0; */
            font-size: 3em;
            line-height: 2.2rem;
            font-weight: var(--bold);
            letter-spacing: 0.07rem;
            /* margin-bottom: 25px; */
        }
        h2{
            user-select: none;
            text-transform: uppercase;
            font-size: 2em;
            line-height: 2.2rem;
            font-weight: var(--bold);
            letter-spacing: 0.07rem;
        }
        h4{
            font-weight: var(--boldSmall);
            font-size: .9em;
            line-height: 1.2rem;
            letter-spacing: 0.07rem;
            margin: 0;
            text-transform: uppercase; 
            /* font-weight: var(--bold);
font-size: 16px;
line-height: 1.2rem;
letter-spacing: 0.07rem;
text-transform: uppercase; */
        }
        p{
            font-weight: 300;
            
        }
        a{
            display: flex;
            text-decoration: none;
            color: var(--fontColor);
        }
    }
    @media screen and (min-width:768px) {
    html {
        font-size: 16px;
    }
    }

    @media screen and (min-width:1024px) {
    html {
        font-size: 16px;
    }
    }

    @media screen and (min-width:1300px) {
    html {
        font-size: 18px;
    }
}
`;