import React, { useEffect, useRef, useState } from 'react'

import { AlertIconWrapper, Wrapper, TextAreaStyled, LabelStyled, InnerWrapper, DollarSign } from './TextArea.styles'

import AlertIcon from '../../images/alertYellow.svg'

const Label = ({ labelWeight, name, marginBottom, label, labelFontSize, labelTextTransform }) => (


    <LabelStyled
        htmlFor={name}
        labelWeight={labelWeight}
        labelFontSize={labelFontSize}
        labelTextTransform={labelTextTransform}
        marginBottom={marginBottom}
    >{label}</LabelStyled>
)




const TextArea = ({ margin, rows, maxLength, labelWeight, alertThisField, padding, colorDisabled, backgroundDisabled, outlineFocus, boxShadowFocus, borderFocus, borderBottomFocus, borderRightFocus, borderLeftFocus, borderTopFocus, marginTop, textAlign, width, uppercaseOff,
    boxShadow, background, border, borderTop, borderBottom, borderRight, borderLeft, disabled, labelFontSize, labelTextTransform, type, image, onChange, placeholder, value, label, name, marginBottom, onKeyPress, priceOnBlur }) => {
    const initial = useRef(true);
    const inputRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [initialLength, setInitialLength] = useState(0);
    const handleCursor = (event) => {

        event.preventDefault();

        setCursorPosition(inputRef.current.selectionStart)
        setInitialLength(inputRef.current.value.length)
        onChange(event);
    }
    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            if (inputRef.current !== null) {
                inputRef.current.focus()
            }
            return; // if initial render, skip useEffect
        }
        if (name === 'price') {
            let length = inputRef.current.value.length
            let caretPos = length - initialLength + cursorPosition;
            console.log(length, initialLength, cursorPosition);
            inputRef.current.setSelectionRange(caretPos, caretPos);



        }
    }, [value])

    if (label !== undefined) {

        return (
            <Wrapper width={width} marginTop={marginTop} margin={margin}>
                <Label htmlFor={name}
                    labelWeight={labelWeight}
                    marginBottom={marginBottom}
                    label={label}
                    labelFontSize={labelFontSize}
                    labelTextTransform={labelTextTransform}
                ></Label>
                <InnerWrapper >
                    {name === 'price' && <DollarSign>$</DollarSign>}
                    <TextAreaStyled
                        type={type}
                        uppercaseOff={uppercaseOff}
                        padding={padding}
                        textAlign={textAlign}
                        background={background}
                        border={border}
                        borderBottom={borderBottom}
                        borderTop={borderTop}
                        borderLeft={borderLeft}
                        backgroundDisabled={backgroundDisabled}
                        colorDisabled={colorDisabled}
                        rows={rows !== undefined ? rows : 3}
                        maxLength={maxLength !== undefined ? maxLength : 1000}
                        borderRight={borderRight}
                        boxShadow={boxShadow}
                        placeholder={placeholder}
                        disabled={disabled ? true : false}
                        value={value}
                        onChange={handleCursor}
                        name={name}
                        onKeyPress={onKeyPress}
                        ref={inputRef}
                        onBlur={priceOnBlur !== undefined ? priceOnBlur : undefined}
                    />
                    {alertThisField && <AlertIconWrapper><img src={AlertIcon} alt="alert-icon" style={{ width: '15px', height: '15px' }} /></AlertIconWrapper>}
                </InnerWrapper>
            </Wrapper >

        )
    }
    else {
        return (
            <InnerWrapper width={width} marginTop={marginTop} margin={margin}>
                {name === 'price' && <DollarSign>$</DollarSign>}
                <TextAreaStyled
                    type={type}
                    padding={padding}
                    background={background}
                    backgroundDisabled={backgroundDisabled}
                    colorDisabled={colorDisabled}
                    outlineFocus={outlineFocus}
                    borderFocus={borderFocus}
                    borderBottomFocus={borderBottomFocus}
                    borderRightFocus={borderRightFocus}
                    borderTopFocus={borderTopFocus}
                    borderLeftFocus={borderLeftFocus}
                    boxShadowFocus={boxShadowFocus}
                    border={border}
                    borderBottom={borderBottom}
                    borderTop={borderTop}
                    borderLeft={borderLeft}
                    borderRight={borderRight}
                    uppercaseOff={uppercaseOff}
                    boxShadow={boxShadow}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled ? true : false}
                    textAlign={textAlign}
                    onChange={handleCursor}
                    name={name}
                    rows={rows !== undefined ? rows : 3}
                    maxLength={maxLength !== undefined ? maxLength : 1000}
                    onKeyPress={onKeyPress}
                    ref={inputRef}
                    onBlur={priceOnBlur !== undefined ? priceOnBlur : undefined}
                />
                {alertThisField && <AlertIconWrapper><img src={AlertIcon} alt="alert-icon" style={{ width: '15px', height: '15px' }} /></AlertIconWrapper>}
            </InnerWrapper>
        )
    }



}

export default TextArea
