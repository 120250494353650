import React, { useRef, useEffect, useState } from 'react'
import { _requiredActionBuyer, calculatePriceWithFee } from '../../../helpers'
import { BUYER_PLAYLIST_TYPES } from '../../../config'
import dayjs from 'dayjs'
import { useAuth } from '../../../contexts/AuthContext'

import API from '../../../API'

import { Wrapper, ColumnElement, AlertIcon, Action } from './DashBarBuyerEntry.styles'

import GridItem from '../../GridItem'
import DashIcon from '../../DashIcon'
import Flexbox from '../../Flexbox'
import PlayButton from '../../PlayButton'
import DotMenu from '../DotMenu'
import NextUpArrow from '../../NextUpArrow'

import cart from '../../../images/cartIcon.svg'
import YellowAlertIcon from '../../../images/alertYellow.svg'
import BlackAlertIcon from '../../../images/alertBlack.svg'

const FavoriteIcon = ({ isFavorited, onClick, isOpen, spot, hoverActive, fill }) => {
    const [favorite, setFavorite] = useState(isFavorited)

    useEffect(() => {
        setFavorite(isFavorited)
    }, [isFavorited])
    return (
        <Action isOpen={isOpen} onClick={async () => { const res = !favorite; setFavorite(res); await onClick(res); }} opacity={favorite || hoverActive ? '1' : '0.25'}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12.66" width="100%" height="100%">
                <g style={{ fill: fill }}>
                    <path className="d" d="M10.05,0c-1.2,0-2.31,.61-3.05,1.66-.74-1.05-1.85-1.66-3.05-1.66C1.77,0,0,2.06,0,4.58c0,1.42,.58,2.78,1.56,3.64l4.93,4.25c.15,.13,.34,.19,.52,.19,.19,0,.38-.06,.53-.19l5.32-4.69,.12-.13c.66-.84,1.02-1.93,1.02-3.07,0-2.52-1.77-4.58-3.95-4.58Zm2.06,6.91l-5.1,4.5L2.31,7.36c-.73-.64-1.17-1.68-1.17-2.78,0-1.89,1.26-3.43,2.81-3.43,.95,0,1.83,.58,2.35,1.56,.14,.25,.41,.41,.7,.41s.56-.16,.7-.41c.52-.98,1.4-1.56,2.35-1.56,1.55,0,2.81,1.54,2.81,3.43,0,.89-.28,1.73-.75,2.33Z" />
                </g>
                <g style={{ fill: favorite ? fill : 'none', stroke: favorite ? fill : 'none', strokeWidth: favorite ? '5%' : '0' }}>
                    <path className="d" d="M12.86,4.58c0,.89-.28,1.73-.75,2.33l-5.1,4.5L2.31,7.36c-.73-.64-1.17-1.68-1.17-2.78,0-1.89,1.26-3.43,2.81-3.43,.95,0,1.83,.58,2.35,1.56,.14,.25,.41,.41,.7,.41s.56-.16,.7-.41c.52-.98,1.4-1.56,2.35-1.56,1.55,0,2.81,1.54,2.81,3.43Z" />
                </g>
            </svg>

        </Action>)
    // else return (<Action>{spot}</Action>)
}

const CartIcon = ({ onClick, isOpen, hoverActive }) => {
    return (
        <GridItem alignItems="center" isOpen={isOpen} opacity={hoverActive ? '1' : '0.25'} onClick={() => { }}>
            <img src={cart} alt="add-to-cart" style={{ width: "15px" }} />
        </GridItem>)
}

const DashBarBuyerEntry = ({ prepPayload, playlistType, isBuffer = false, setHoverActive, setShowModal, setSelectedSong, handleSongChange, handleFavorites, playing, nowPlaying, item, hoverActive, hoverItem, setHoverItem, handleSelect, noHover, isOpen }) => {
    const validPlaylists = ['Top 100', 'Favorites', 'Holds', 'Purchases']
    const getGridCols = () => {
        if (isBuffer) {
            if (playlistType !== BUYER_PLAYLIST_TYPES.PURCHASES)
                return '22px 2fr 1fr 7rem 5rem 2rem 30px'
            else return '22px 2fr 1fr 4rem 7rem 5rem 2rem 30px 30px'
        }
        switch (playlistType) {
            case BUYER_PLAYLIST_TYPES.TOP_100:
                return `22px 22px 2fr 1fr 7rem 5rem 5rem 2rem 30px`;
            case BUYER_PLAYLIST_TYPES.FAVORITES:
                return `22px 22px 2fr 1fr 7rem 5rem 5rem 2rem 30px`;
            case BUYER_PLAYLIST_TYPES.PURCHASES:
                return `22px 2fr 1fr 4rem 7rem 5rem 2rem 30px 30px`;
            default:
                return ``;
        }
    }

    const { currentUser } = useAuth()
    const [showMenu, setShowMenu] = useState(false)
    const wrapperRef = useRef(null)
    const mouseOver = (e) => {
        e.stopPropagation();
        if (setHoverActive) {
            setHoverActive(true)
            setHoverItem(item.song_id)
        }
        setShowMenu(true)
    }
    const mouseOut = (e) => {
        e.stopPropagation();
        if (setHoverActive) {
            setHoverActive(false)
            setHoverItem(null)
        }
        setShowMenu(false)
    }

    const getRowHighlight = () => {
        if (isOpen) {
            if (item.userHold !== undefined) {
                // check if hold is expiring soon if true return alert
                // or if price change ocurred (equivalent of "is new")
                return { background: 'var(--yellow)', textColor: '#000000', fill: '#000000', fontWeight: '600', border: '1px solid #000000' }
            } else if (_requiredActionBuyer(currentUser, item).value !== -1 && isOpen) {
                return { background: 'var(--yellow)', textColor: '#000000', fill: '#000000', fontWeight: '600', border: '1px solid #000000' }
            } else if (nowPlaying === item.song_id && !noHover)
                return { background: 'rgba(255,255,255,0.15)', textColor: '#ffffff', fill: '#ffffff', fontWeight: '400' }
            else return { background: 'none', textColor: '#ffffff', fill: '#ffffff', fill: '#ffffff', fontWeight: '400' }
        } else
            return { background: 'none', textColor: '#ffffff', fill: '#ffffff', fill: '#ffffff', fontWeight: '400' }
    }
    const getActionRequired = () => {
        if (_requiredActionBuyer(currentUser, item).value !== -1)
            return <Flexbox justifyContent="flex-end">{_requiredActionBuyer(currentUser, item).text}<AlertIcon ><img src={BlackAlertIcon} /></AlertIcon></Flexbox>
        else return _requiredActionBuyer(currentUser, item).text
    }

    const getPrice = () => {
        return item.price
        // return calculatePriceWithFee(item.price).total
    }

    return (
        <Wrapper
            playlistType={playlistType}
            gridTemplateColumns={getGridCols()}
            rowHighlight={getRowHighlight()}
            noHover={noHover}
            onClick={() => console.log("YES")}
            onMouseEnter={(e) => mouseOver(e)}
            onMouseLeave={(e) => mouseOut(e)}
            ref={wrapperRef}>
            <GridItem>{
                hoverItem === item.song_id || item.spot === -1 ?
                    <PlayButton
                        fill={isOpen && !(hoverItem === item.song_id) ? 'none' : getRowHighlight().fill}
                        // fill={getRowHighlight().fill}
                        onClick={() => handleSongChange(item)}
                        playing={playing}
                        isCurrent={nowPlaying === item.song_id ? true : false} />
                    : <Action>{item.spot}</Action>
            }
            </GridItem>
            {(!isBuffer && item.playlist !== BUYER_PLAYLIST_TYPES.PURCHASES) && <GridItem >
                <FavoriteIcon fill={getRowHighlight().fill} isFavorited={item.isFavorite} isOpen={isOpen} spot={item.spot} hoverActive={hoverItem === item.song_id}
                    onClick={async (res) => {
                        //addToFavorites
                        // await API.editFavorites(currentUser, item.song_id)
                        await handleFavorites(item.song_id, res)
                    }} />
            </GridItem>}
            <GridItem alignItems="start">{item.song_title}</GridItem>
            {item.playlist === BUYER_PLAYLIST_TYPES.PURCHASES && <GridItem >{getActionRequired()}</GridItem>}
            {(!isBuffer && item.playlist !== BUYER_PLAYLIST_TYPES.PURCHASES) && <GridItem>{isOpen && !isBuffer ? item.playlist : ''}</GridItem>}
            <GridItem>{eval(item.genre).map((g, idx) => (idx > 0 ? ", " + g : g))}</GridItem>
            <GridItem>{item.tempo.split(' (')[0]}</GridItem>
            <GridItem>{getPrice(item)}</GridItem>
            {
                item.playlist === BUYER_PLAYLIST_TYPES.PURCHASES &&
                <GridItem>{dayjs.unix(item.updated_at).fromNow()}</GridItem>}
            < GridItem >
                {/* <CartIcon hoverActive={hoverActive && hoverItem === item.song_id} fill={getRowHighlight().fill}/> */}
                <DashIcon
                    cart={playlistType === BUYER_PLAYLIST_TYPES.PURCHASES ? undefined : true}
                    download={playlistType === BUYER_PLAYLIST_TYPES.PURCHASES ? true : undefined}
                    hoverActive={hoverActive && hoverItem === item.song_id} fill={getRowHighlight().fill} />
            </GridItem>
            {/* {
                !isBuffer && <ColumnElement>
                    <DotMenu
                        prepPayload={prepPayload}
                        fill={isOpen && !(hoverItem === item.song_id) ? 'none' : getRowHighlight().fill}
                        setShowModal={setShowModal} position="fixed" setSelectedSong={setSelectedSong} song={item} hoverActive={hoverActive && hoverItem === item.song_id} status={item.status} playlistOpen={isOpen}></DotMenu>
                </ColumnElement>
            } */}
            <ColumnElement width="30px" height="50px">
                {isOpen && (hoverItem === item.song_id) &&
                    <DotMenu
                        prepPayload={prepPayload}
                        fill={isOpen && !(hoverItem === item.song_id) ? 'none' : getRowHighlight().fill}
                        setShowModal={setShowModal} position="absolute"
                        setSelectedSong={setSelectedSong} song={item} hoverActive={hoverActive && hoverItem === item.song_id} status={item.status} playlistOpen={isOpen}></DotMenu>}
            </ColumnElement>
        </Wrapper >
    )
}

export default DashBarBuyerEntry