import React, { useState, useRef } from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import { Wrapper } from './BufferPlaylist.styles'

import { AZAA_PLATFORM_FEE_PERCENT, BUYER_PLAYLIST_TYPES } from '../../../config'

import DashBarBuyerEntry from '../DashBarBuyerEntry'
import GridItem from '../../GridItem'
import Flexbox from '../../Flexbox'
import Button from '../../Button'

import close from '../../../images/close.svg'
import playButton from '../../../images/playPlaylist.svg'
import pauseButton from '../../../images/pausePlaylist.svg'

const BufferPlaylist = ({
    fetchMore,
    useInfScroll = true,
    handlePlaylistChange,
    currPlaylist,
    bufferPlaylist,
    prepPayload,
    setShowModal,
    playlistType,
    showBufferPlaylist,
    closeBufferPlaylist,
    nowPlaying,
    handleSongChange,
    handleFavorites,
    setSelectedSong,
    nbHits }) => {
    const [hoverActive, setHoverActive] = useState(false)
    const [hoverItem, setHoverItem] = useState(null)
    const scrollRef = useRef(null)
    const getButtonText = () => {
        switch (playlistType) {
            case BUYER_PLAYLIST_TYPES.FAVORITES:
                return "You haven't added any songs to your favorites yet.";
            case BUYER_PLAYLIST_TYPES.PURCHASES:
                return "You haven't purchased any songs yet.";
            default:
                return "Looks like this playlist is empty";
        }
    }

    const fetchMas = () => {
        if (typeof (fetchMore) === 'function' && nbHits > bufferPlaylist.length) {
            console.log("fetching")
            fetchMore()
        } else {
            console.log("fetching1")
        }

    }
    if (useInfScroll)
        return (
            <Wrapper showBufferPlaylist={showBufferPlaylist}>
                <GridItem height="60px" width="25px" position="absolute" right="calc(3.22vw - 15px )" top="0" onClick={closeBufferPlaylist}>
                    <img src={close} alt="close" style={{ width: "100%", height: '100%', objectFit: 'contain' }} />
                </GridItem>
                <GridItem alignItems="center" width="100%" display="grid" gridColGap="20px" gridTempCols="min-content 1fr 1fr min-content" padding="var(--navBarHeight) calc(3.22vw - 15px ) 0 3.22vw">
                    <GridItem height="22px" width="22px">
                        <img src={currPlaylist === playlistType ? pauseButton : playButton} alt="play" style={{ opacity: bufferPlaylist.length > 0 ? '1' : '0.25', width: "100%", height: '100%', objectFit: 'contain' }}
                            onClick={() => {
                                if (bufferPlaylist.length > 0)
                                    handlePlaylistChange()
                            }} />
                    </GridItem>

                    <h2 style={{ fontWeight: '700' }}>{playlistType}</h2>

                </GridItem>
                {bufferPlaylist.length === 0 &&
                    <Flexbox column relative center height="calc(100% - 160px)"><h2 style={{ textTransform: 'none' }}>
                        {getButtonText()}</h2>
                        <Button onClick={closeBufferPlaylist} text="Close" width="200px" border="1px solid #ffffff" />
                    </Flexbox>}
                <InfiniteScroll
                    ref={scrollRef}
                    // dataLength={_data.length}
                    dataLength={bufferPlaylist.length}
                    next={fetchMas}
                    hasMore={nbHits > bufferPlaylist.length}
                    loader={
                    <Flexbox column justifyContent="center" textTransform="uppercase" alignItems="center" fontSize="10px">
                        {/* <LoadingWheel position="relative" radius="25px" height="28px" width="28px" /> */}
                    </Flexbox>
                    }
                    style={{ overflow: !showBufferPlaylist ? 'hidden hidden' : 'hidden auto', }}
                    // style={{ overflow: !showBufferPlaylist ? 'hidden hidden' : 'hidden auto', paddingBottom: '30px' }}
                    // height={!showBufferPlaylist ? 60 : 5 * 60}
                    height={'calc(100vh - 125px - 60px)'}
                    // height={'calc(60px * 4 + 125px)'}
                // endMessage={
                //     <p style={{ textAlign: "center" }}>
                //         <b>Yay! You have seen it all</b>
                //     </p>
                // }
                >
                    {
                        bufferPlaylist.length > 0 && bufferPlaylist.map(it => (
                            <DashBarBuyerEntry
                                playlistType={playlistType}
                                prepPayload={(action) => prepPayload(it.song_id, action)}
                                setShowModal={setShowModal}
                                setHoverItem={setHoverItem}
                                key={it.song_id}
                                setSelectedSong={setSelectedSong}
                                handleFavorites={handleFavorites}
                                handleSongChange={(song = null) => { handlePlaylistChange(song) }}
                                hoverItem={hoverItem}
                                noHover={true}
                                hoverActive={false}
                                nowPlaying={nowPlaying}
                                item={it}
                                isBuffer={true}
                                isOpen={true}
                                setHoverActive={setHoverActive}
                                handleSelect={() => { }}
                                playing={false}
                            ></DashBarBuyerEntry>))
                    }
                </InfiniteScroll>

            </Wrapper>
        )
    return (
        <Wrapper showBufferPlaylist={showBufferPlaylist}>
            <GridItem height="60px" width="25px" position="absolute" right="calc(3.22vw - 15px )" top="0" onClick={closeBufferPlaylist}>
                <img src={close} alt="close" style={{ width: "100%", height: '100%', objectFit: 'contain' }} />
            </GridItem>
            <GridItem alignItems="center" width="100%" display="grid" gridColGap="20px" gridTempCols="min-content 1fr 1fr min-content" padding="var(--navBarHeight) calc(3.22vw - 15px ) 0 3.22vw">
                <GridItem height="22px" width="22px">
                    <img src={currPlaylist === playlistType ? pauseButton : playButton} alt="play" style={{ opacity: bufferPlaylist.length > 0 ? '1' : '0.25', width: "100%", height: '100%', objectFit: 'contain' }}
                        onClick={() => {
                            if (bufferPlaylist.length > 0)
                                handlePlaylistChange()
                        }} />
                </GridItem>

                <h2 style={{ fontWeight: '700' }}>{playlistType}</h2>

            </GridItem>
            {bufferPlaylist.length === 0 &&
                <Flexbox column relative center height="calc(100% - 160px)"><h2 style={{ textTransform: 'none' }}>
                    {getButtonText()}</h2>
                    <Button onClick={closeBufferPlaylist} text="Close" width="200px" border="1px solid #ffffff" />
                </Flexbox>}
            {
                bufferPlaylist.length > 0 && bufferPlaylist.map(it => (
                    <DashBarBuyerEntry
                        playlistType={playlistType}
                        prepPayload={(action) => prepPayload(it.song_id, action)}
                        setShowModal={setShowModal}
                        setHoverItem={setHoverItem}
                        key={it.song_id}
                        setSelectedSong={setSelectedSong}
                        handleFavorites={handleFavorites}
                        handleSongChange={(song = null) => { handlePlaylistChange(song) }}
                        hoverItem={hoverItem}
                        noHover={true}
                        hoverActive={false}
                        nowPlaying={nowPlaying}
                        item={it}
                        isBuffer={true}
                        isOpen={true}
                        setHoverActive={setHoverActive}
                        handleSelect={() => { }}
                        playing={false}
                    ></DashBarBuyerEntry>))
            }</Wrapper>
    )
}

export default BufferPlaylist