import styled from "styled-components";

export const Wrapper = styled.div`
display: grid;
padding-bottom: 20px;
grid-row-gap: .5rem;
position: relative;
width: 100%;
/* flex-basis: 5%; */
min-width: 340px;
max-width: 400px;
max-width: 40%;
grid-template-rows: min-content min-content;
text-align: left;
align-items: flex-start;
justify-content: flex-start;
@media (max-width: 1430px) {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: unset;
  }

  @media screen and (max-width: 600px){
    min-width: 100%;
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
          min-width: 100%;
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
          min-width: 100%;
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
          min-width: 100%;
    }
`;

export const Question = styled.h4`
/* font-weight: var(--bold);
font-size: 16px;
line-height: 1.2rem;
letter-spacing: 0.07rem;
text-transform: uppercase; */
`;

export const Answer = styled.div`
font-size: 14px;
font-weight: 200;
line-height: 1.2rem;
`;