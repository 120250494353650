// const fragmentShader = `uniform sampler2D pointTexture;
// varying vec3 vColor;


// void main() {
// gl_FragColor = vec4( vColor, 1.0 );
// gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );
// }
// `
const fragmentShader = `
uniform sampler2D pointTexture;
uniform vec3 iResolution;
varying vec3 vColor;

varying vec3 vertexNormal;

void main() {

vec2 uv = gl_PointCoord/iResolution.xy;
vec2 pos = 0.5 - uv;
// Adjust y by aspect for uniform transforms
pos.y /= iResolution.x/iResolution.y;
float dist = 1.0/length(pos);
dist *= 0.5;
dist = pow(dist, 0.8);
vec3 col = dist * vColor;
col = 1.0 - exp( -col );
gl_FragColor = vec4(col, 1.0) * texture2D( pointTexture, gl_PointCoord );


// gl_FragColor = vec4( vColor, 0.5) ;
// gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );
}
`

export default fragmentShader