import styled from "styled-components";

export const Wrapper = styled.div`
padding: 0 3.22vw;
display: flex;
flex-direction: column;
align-items: flex-start;
/* max-width: 1100px; */
height: 100%;
flex-grow: 1;
margin: 0 auto 50px;
border: 1px solid #ffffff;
max-width: 1100px;
padding-bottom: 50px;

`;

export const ContentWrapper = styled.div`
width: 800px;
width: 100%;
display: flex;
flex-direction: column;
height: 100%;
/* max-height: 700px; */
/* overflow: hidden; */
/* padding: 50px 0; */
/* background: var(--dropdownBackground); */
`;

export const QuestionWrapper = styled.div`
position: relative;
display: flex;
flex-direction: column;
padding: ${props => props.padding ? props.padding : ''};
height: 100%;
/* overflow: ${props => props.overflow ? props.overflow : 'hidden'}; */
`;

export const SectionWrapper = styled.div`

`;

export const SectionWrapperHeader = styled.div`
border-bottom: 1px solid #ffffff;
text-transform: uppercase;
margin-top: 20px;
height: 40px;
align-items: center;
display: flex;
justify-content: space-between;
`;

export const PriceWrapper = styled.div`
display: flex;
align-self: flex-start;
flex-direction: column;
`;

export const AddIconWrapper = styled.div`
    cursor: pointer;
    width: 40px;
    height: 40px;
`;

export const RemoveContributorWrapper = styled.div`
cursor: pointer;
display: flex;
position: relative;
justify-content: center;
visibility: ${props => props.visibility ? props.visibility : 'visible'};
align-items: center;
padding: 2px;
height: 40px;
width: 30px;
img{
    height: 100%;
    object-fit: contain;
    width: 100%;
}
`;

export const PricingTable = styled.div`
font-size: 14px;
width: min-content;
display: grid;
grid-template-columns: 1fr 1fr;
grid-auto-rows: min-content;
border: 2px solid var(--greyBackground);
border-bottom: unset;
>div{
    white-space: nowrap;
    text-align: right;
    height: 58px;
    display: flex;
    align-items: center;
    
    /* padding: 5px; */
    /* padding: 1px 0; */
}

>div:nth-of-type(2n){
    /* padding: 5px;// 5px 0 5px; */
    padding: 0 5px;
    border-bottom: 2px solid var(--greyBackground);
    letter-spacing: 0.03rem;
    font-size: 12px;
    font-weight: 600;
    justify-content: flex-end;
}
>div:nth-of-type(2n + 1){
    font-weight: 600;
    letter-spacing: .1rem;
    /* padding: 5px 25px 5px; */
    background: var(--greyBackground);
    padding: 0 25px 0 3px;
    border-bottom: 2px solid var(--greyBackground);
    justify-content: flex-end;
}
>div:nth-of-type(1){
    padding-right: 5px;
    /* padding-left: 5px;
    padding: 5px 5px 0 5px; */
}

&:last-child{
    border-bottom: 0;
}
/* >div:nth-of-type(4), >div:nth-of-type(3){
    border-bottom: 2px solid var(--greyBackground);
} */
`;