import React, { useState, useRef } from 'react'
import API from '../../API'
import { validateEmail } from '../../helpers'
import cassette from '../../images/cassette.svg';
import SignupIcon from '../../images/emailSignupIcon.svg';

import InputField from '../InputField';
import Cassette from '../Cassette'

import { Wrapper, Content, Text, InputFieldWrapper, ResponseFeedback } from './EmailSubscriptionSection.styles';

const EmailSubscriptionSection = () => {
    const [state, setState] = useState('');
    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(false);
    const submitted = useRef({ hasSubmitted: false, email: '' })
    const handleSubmit = (event) => {
        event.preventDefault();
        alert('An email was submitted: ' + state);

    }

    const handleChange = (event) => {
        setState(event.currentTarget.value)
    }

    const onClick = async (e) => {
        e.preventDefault();
        if (submitted.current) {
            setResponse('Success!')
        }
        if (validateEmail(state)) {
            try {
                setResponse('')
                setLoading(true)
                const res = await API.mailChimpSignup('stayintheloop', state)
                setLoading(false)
                submitted.current = { hasSubmitted: true, email: state }
                setResponse('Success!')
            } catch (error) {
                console.log(error)
                setLoading(false)
                setResponse('An error has occurred. Please try again later.')
            }
        }
        else {
            setResponse('Please enter a valid email address.')
        }
    }


    return (
        <Wrapper uppercaseOff={true}>
            <Content>
                <Cassette />
                <Text>Stay in the loop</Text>
                <form onSubmit={(e) => onClick(e)}>
                    <InputFieldWrapper>
                        <img src={SignupIcon} alt="email-icon" />
                        {/* <input
                            type="email"
                            placeholder="Email address"
                            value={state}
                            onChange={handleChange
                            }
                        /> */}
                        <InputField type="email"
                            placeholder="Email address"
                            value={state}
                            setInputState={setState}
                            uppercaseOff={true}
                            onChange={handleChange
                            } />
                    </InputFieldWrapper>

                    <button
                        // disabled={loading || submitted.current.hasSubmitted}
                        disabled={loading}
                        onClick={onClick} >Subscribe</button>
                    <ResponseFeedback>{response}</ResponseFeedback>
                </form>
            </Content>
        </Wrapper>
    )
}

export default EmailSubscriptionSection;
