import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import GridItem from '../GridItem'
import Flexbox from '../Flexbox'
import Button from '../Button'
import Table2 from '../Table2'
import TableRow from '../TableRow'
import TableCell from '../TableCell'

import paypalLogoText from '../../images/paypal-wordmark-color.svg'
import formNavArrow from '../../images/formNavArrow.svg'
import removeItem from '../../images/rejectIcon.svg'

import { GradientText } from './Cart.styles'

import API from '../../API'
import { formatPrice } from '../../utils'

import { useAuth } from '../../contexts/AuthContext'
import { async } from '@firebase/util'

const GridC = styled.div`
display: grid;
width: 100%;
grid-row-gap: 10px;
grid-template-columns: 1fr repeat(2,min-content);
align-items: center;
>div{
    padding: 20px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    height: 100%;
    display: flex;
    /* margin-top: 5px; */
}
>div:nth-of-type(3n + 1){
    border-left: 1px solid #fff;
}
>div:nth-of-type(3n){
    border-right: 1px solid #fff;
    padding-left: 0;
}
`;

const Cart = () => {
    const initialState = {
        buyer_id: '',  //mysql: buyer table, id column
        buyer_deliverables_email: '', //mysql: user table, email column
        songTitle: '',  //mysql: songs table, songTitle column
        song_id: '',  //mysql: songs table, id column
        songwriter_email: '', //use instead of name. mysql: user table, email column,
        cart: [],
        subtotal: '-'
    }
    const [state, setState] = useState(initialState)
    const { cart, subtotal } = state
    const song = { songTitle: 'Nail Tech', price: '$10,000.00' }
    const { currentUser } = useAuth()
    const buyer = { buyer_deliverables_email: 'lorem@ipsum.com' }

    useEffect(() => {
        const getCart = async () => {
            try {
                const data = await API.getCart(currentUser)
                setState(prev => ({
                    ...prev,
                    cart: data['cart'] || [],
                    subtotal: data['subtotal'] || []
                }))
            } catch (error) {

            }
        }
        if (currentUser)
            getCart()
    }, [currentUser])
    return (
        <Flexbox
            top="50px"
            padding="30px 0"
            background="#1A1A1A" justifyContent="center" alignItems="start" width="100vw" relative height="calc(100vh - var(--navBarHeight))" >
            <GridItem
                maxWidth="1200px"
                // padding="30px var(--navBarHeight) var(--navBarHeight)"
                height="min-content"
                width="100%"
                display="grid"
                // margin="var(--navBarHeight) 0 0"
                alignItems="center"
                gridColGap="var(--navBarHeight)"
                gridTempRows="1fr"
                gridTempCols="1fr 1fr">
                <GridItem
                    // padding="10px 20px"
                    alignItems="start"
                    justifyContent="start"
                    // boxShadow="0 0 10px #000"
                    borderRadius="10px"
                    height="100%"
                    gridRowGap="20px"
                    width="100%"
                    display="grid"
                // background="var(--greyBackground)"
                >
                    <Flexbox borderRadius="0.5rem" justifyContent="center" alignItems="cetner" border="1px solid #fff" padding="10px" width="min-content">
                        <img src={formNavArrow} style={{ transform: 'rotate(180deg)', height: '10px', width: '10px' }} />
                    </Flexbox>
                    <GradientText >Purchase<br />Song{cart.length > 0 ? 's' : ''}</GradientText>
                    <p> scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                        release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of </p>

                </GridItem>
                <GridItem
                    padding="20px 20px"
                    boxShadow="0 0 10px #000"
                    borderRadius="10px"
                    height="min-content"
                    alignItems="start"
                    gridRowGap="10px"
                    width="100%"
                    background="var(--greyBackground)">
                    <h2 style={{ margin: '0', textTransform: 'none', letterSpacing: '0.05em' }}>Summary</h2>

                    <h3 style={{ margin: '20px 0 0', textTransform: 'none', letterSpacing: '0.05em' }}>Song</h3>
                    <p style={{ margin: '0' }}>crambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                        release of Letraset sheets containing Lorem Ipsum passages, and more recently with d</p>
                    <GridC>
                        {
                            cart.map(cartItem => {
                                return (
                                    <>
                                        <div>{cartItem.song_title}</div>
                                        <div>{formatPrice(cartItem.price)}</div>
                                        <div><img src={removeItem} style={{ width: '15px', height: '15px' }} onClick={
                                            async () => {
                                                const data = await API.updateCart(currentUser, cartItem.song_id)
                                                setState(prev => ({
                                                    ...prev,
                                                    cart: data['cart'] || [],
                                                    subtotal: data['subtotal'] || []
                                                }))
                                            }
                                        } /></div>

                                    </>
                                )
                            })
                        }
                    </GridC>
                    {/* {
                        cart.map(cartItem => {
                            return (
                                <Flexbox fontWeight="700" justifyContent="space-between" border="1px solid rgba(255,255,255,0.25)" padding="30px" >
                                    <span>{cartItem.song_title}</span>
                                    <span>{formatPrice(cartItem.price)}</span>
                                    <img src={removeItem} style={{ width: '20px', height: '20px' }} />
                                </Flexbox>
                            )
                        })
                    } */}

                    <h3 style={{ margin: '20px 0 0', textTransform: 'none', letterSpacing: '0.05em' }}>Deliverables Transfer</h3>
                    <p style={{ margin: '0' }}>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                        release of Letraset sheets containing Lorem Ipsum passages, and more recently with d</p>
                    <Flexbox fontWeight="700" justifyContent="space-between" border="1px solid rgba(255,255,255,0.25)" padding="30px" >
                        <span>Contact Email</span>
                        <span>{buyer.buyer_deliverables_email}</span>
                    </Flexbox>
                    <Table2>
                        <TableRow hoverOff={true} className="tableHeader">
                            <TableCell>Subtotal</TableCell>
                            <TableCell textAlign="right">
                                {subtotal !== '-' ? formatPrice(subtotal) : '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                        </TableRow>
                    </Table2>
                    <Button
                        borderRadius="0.5rem"
                        background="var(--paypalYellow)"
                        hoverColor="var(--paypalYellowHover)"
                        height="40px"
                        padding="10px 0"
                        width="100%">
                        <img style={{ height: '1rem' }} src={paypalLogoText} alt="Paypal" />
                    </Button>

                </GridItem>
            </GridItem>
        </Flexbox>
    )
}

export default Cart