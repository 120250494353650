import React from 'react';

import { ButtonStyle } from './Button.styles';

const Button = ({ setOpacity, transition, fontSize, transform, validContinue, children, position, background, height, width, color, text, border, hoverColor, onClick, ...props }) => {
    return (


        <ButtonStyle
            transform={transform}
            setOpacity={setOpacity}
            transition={transition}
            fontSize={fontSize}
            validContinue={validContinue !== undefined ? validContinue : true}
            onClick={onClick}
            background={background} height={height} width={width} color={color} border={border} hoverColor={hoverColor} {...props}>
            {text}{children}
        </ButtonStyle>
    )
}
export default Button;