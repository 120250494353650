import React, { useState, useEffect } from 'react'
import anime from 'animejs'
const AnimatedInviteIcon = ({className}) => {
    
    useEffect(() => {
        const fixSvgElementCoordinates = (className, xFactor = 2, yFactor = 2) => {
            let elements;
            elements = document.querySelectorAll(className);
            Array.from(elements).forEach((element) => {
                let BBox = element.getBBox();
                let BBoxX2 = xFactor == 0 ? 0 : BBox["width"] / xFactor;
                let BBoxY2 = yFactor == 0 ? 0 : BBox["height"] / yFactor;
                let BBoxX = BBox["x"] + BBoxX2;
                let BBoxY = BBox["y"] + BBoxY2;
                BBox = BBoxX + "px" + " " + BBoxY + "px";
                element.style.transformOrigin = BBox;
            });
        }
        // fixSvgElementCoordinates('#env1')
        // fixSvgElementCoordinates('#env3')
        const animation = anime({
            targets: `.${className} #env1`,
            points: [
                { value:"17.5 6.85 9 11.38 .5 6.85 9 11.38 17.5 6.85"},
                { value:"17.5 7.35 9 11.88 .5 7.35 9 .5 17.5 7.35"},
            ],
            easing: 'easeOutSine',
            duration: 700,
            delay: 0,
            // direction: 'alternate',
            loop: false
        });
        const animation3 = anime({
            targets: `.${className} #env3`,
            points: [
                { value:"1.98 13.77 16.04 13.77 16.04 13.77 1.98 13.77"},
                { value:"1.98 2.44 16.04 2.44 16.04 13.77 1.98 13.77"},
            ],
            easing: 'easeOutSine',
            duration: 300,
            delay: 500,
            // direction: 'alternate',
            loop: false
        });
        // const animation2 = anime({
        //     targets: '#env2',
        //     points: [
        //         { value:"17.5 6.85 17.5 16.69 .5 16.69 .5 6.85 9 11.38 17.5 6.85"},
        //         { value:"17.5 7.35 17.5 17.19 .5 17.19 .5 7.35 9 11.88 17.5 7.35"},
        //     ],
        //     easing: 'easeOutSine',
        //     duration: 500,
        //     delay: 0,
        //     // direction: 'alternate',
        //     loop: false
        // });

        animation.play()
        // animation2.play()
        animation3.play()

    }, [])

    return (
        <div style={{ height: '15px', width: '15px', objectFit: 'contain', position: 'relative' }} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17.69">
                <g id="a" />
                <g id="b" style={{ fill: '#000000', stroke: 'var(--green)', strokeMiterlimit: '10', strokeLinecap: 'round', strokeLinejoin: 'round' }}>
                    <g id="c">
                        <line fill="none" x1=".5" y1="7.35" x2="17.5" y2="7.35" />
                        <polygon id="env1" points="17.5 6.85 9 11.38 .5 6.85 9 11.38 17.5 6.85" />
                        {/* <rect id="env2" class="f" x="1.98" y="2.44" width="14.04" height="13.77" rx="1.28" ry="1.28" /> */}
                        <polygon id="env3" points="1.98 13.77 16.04 13.77 16.04 13.77 1.98 13.77" />
                        <polygon id="env2" points="17.5 6.85 17.5 16.69 .5 16.69 .5 6.85 9 11.38 17.5 6.85" />
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default AnimatedInviteIcon