import styled from 'styled-components';


export const LabelStyled = styled.label`
    position: relative;
    font-weight: ${props => props.labelWeight ? props.labelWeight : ''};
    font-size:  ${props => props.labelFontSize ? props.labelFontSize : '1em'};
    text-transform:  ${props => props.labelTextTransform ? props.labelTextTransform : 'none'};
    letter-spacing: ${props => props.labelTextTransform ? '0.1rem' : ''};
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;


export const InputStyled = styled.input`
    height: ${props => props.height ? props.height : '49px'};
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    margin: 2px;
    padding: ${props => props.isSearch ? '0 40px 0 20px' : props.padding ? props.padding : props.name === 'price' ? '0 20px 0 40px' : '0 20px 0 20px'};
    font-weight: 600;
    text-transform: ${props => props.type === 'password' || props.uppercaseOff ? 'none' : 'uppercase'};
    color: ${props => props.disabled === true ? props.colorDisabled ? props.colorDisabled :  'var(--greyBackground)' : '#ffffff'};
    color: ${props => props.disabled === true ? props.colorDisabled ? props.colorDisabled :  '#696980' : '#ffffff'};
    width: 100%;
    background: ${props => props.disabled === true ? props.backgroundDisabled ? props.backgroundDisabled : '#121212' : props.background ? props.background : 'var(--greyBackground)'};
    border: ${props => props.border ? props.border : 'none'};
    border-bottom: ${props => props.borderBottom ? props.borderBottom : ''};
    border-top: ${props => props.borderTop ? props.borderTop : ''};
    border-left: ${props => props.borderLeft ? props.borderLeft : ''};
    border-right: ${props => props.borderRight ? props.borderRight : ''};
    border-radius: ${props => props.borderRadius ? props.borderRadius: ''};
    position: relative;
    letter-spacing: .1rem;
    box-shadow: ${props => props.boxShadow ? props.boxShadow : 'inset 0 0 5px 1px rgb(0, 0, 0)'};
    transition: ${props => props.transition ? props.transition : ''};

    ::placeholder{
        color: #696980;
    }

    :focus{
        outline: ${props => props.outlineFocus ? props.outlineFocus : '1px solid white'};
        box-shadow: ${props => props.boxShadowFocus ? props.boxShadowFocus : 'inset 0 0 5px rgba(255, 255, 255, .5)'};
        border: ${props => props.borderFocus ? props.borderFocus : ''};
        border-bottom: ${props => props.borderBottomFocus ? props.borderBottomFocus : ''};
        border-top: ${props => props.borderTopFocus ? props.borderTopFocus : ''};
        border-left: ${props => props.borderLeftFocus ? props.borderLeftFocus : ''};
        border-right: ${props => props.borderRightFocus ? props.borderRightFocus : ''};
        
    }

`;

export const Wrapper = styled.div`
    position: relative;
    width: ${props => props.width ? props.width : ''};
`;

export const InnerWrapper = styled.div`
  position: relative;
  margin-top: ${props => props.marginTop ? props.marginTop : '0'};
  width: ${props => props.width ? props.width : '100%'};
    display: flex;
    align-items: center;
`;

export const DollarSign = styled.div`
user-select: none;
position: absolute;
left: 1rem;
z-index: 1;
`;

export const AlertIconWrapper = styled.div`
position: absolute;
right: 5px;
`;

export const SearchIcon = styled.img`
height: 15px;
width: 15px;
cursor: pointer;
opacity: ${props => props.opacity ? props.opacity : '0.25'};
position: absolute;
right: 1rem;
user-select: none;
`;