import React, { useState, useEffect, useRef } from 'react'
import {
    DropDownContainerBox,
    DropDownNavBtn,
    DropdownListContainer,
    DropdownList,
    DropdownContainer,
    ListItem,
    Overlay
} from './HamburgerMenu.styles'


import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'

import Logo from '../../images/headerLogo.svg'

import {
    ACCOUNT_ACTIVITY,
    LOGOUT,
    ACCOUNT_SETTINGS,
    DASHBOARD,
    MEMBERSHIP_TIERS
} from '../../config'

const Option = ({ isActive, textAlign, color, colorOnSelect, value, defaultSelect, innerText, onClick, isSelected, justifyContent }) => (
    <ListItem defaultSelect={defaultSelect} value={value}
        isActive={isActive}
        isSelected={isSelected}
        color={color}
        textAlign={textAlign}
        colorOnSelect={colorOnSelect}
        justifyContent={justifyContent}
        onClick={onClick}>


        {innerText}</ListItem>
);

const Dropdown = ({
    textAlign,
    justifyContent,
    label,
    height,
    width,
    border,
    background,
    listBackground,
    name,
    marginBottom,
    color,
    menuState,
    setMenuState,
    colorOnSelect,
    dropdownListMarginTop,
    options, setState, handleStateChange, valueX, key, id, handleOrderChange, order }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(valueX)
    const location = useLocation();
    const [currentVal, setCurrentVal] = useState(valueX)

    const initial = useRef(true);
    const handleChange = (event) => {
        setIsOpen(!isOpen)

    }

    const selectNew = (event) => {
        setValue(event.target.innerHTML);
        handleStateChange()
        setIsOpen(!isOpen)
    }

    const selectNew2 = (event) => {
        event.preventDefault();
        handleStateChange(event)

        setIsOpen(!isOpen)
        if (menuState !== undefined && setMenuState !== undefined) {
            setMenuState(!menuState)
        }
    }

    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return;
        }
        setIsOpen(!isOpen)
    }, [
        // valueX
    ])

    return (<>
        <DropdownContainer
            textAlign={textAlign}
            width={width}
            id={id}>
            {menuState && (
                <DropdownListContainer
                    border={border}>
                    <DropdownList
                        listBackground={listBackground}
                        border={border}
                        marginTop={dropdownListMarginTop}
                    >
                        {
                            options.map(option => (
                                <Option
                                    color={color}
                                    textAlign={textAlign}
                                    colorOnSelect={colorOnSelect}
                                    justifyContent={justifyContent}
                                    value={option.value}
                                    innerText={option.innerText}
                                    key={option.value}
                                    defaultSelect={option.isDefault}
                                    onClick={selectNew2} //was selectNew
                                    isSelected={location.pathname.replace(/^\/+/g, '') === option.value}
                                    isActive={location.pathname.replace(/^\/+/g, '') === option.value}
                                />

                            ))
                        }
                    </DropdownList>
                </DropdownListContainer>
            )}


        </DropdownContainer>

    </>)
}

const HamburgerMenu = () => {
    const navigate = useNavigate()
    const { logout, currentUser } = useAuth();
    const [menuOpen, setMenuOpen] = useState(false);
    const [error, setError] = useState(false);


    const getOptions = () => {
        const { userDetails: { tier } } = currentUser
        let songwriterOptions = [
            {
                value: '',
                innerText: 'Dashboard'
            },
            {
                value: 'account',
                innerText: 'Account Settings'
            },
            {
                value: 'upload',
                innerText: 'Submit Songs'
            },
            {
                value: 'recently-approved',
                innerText: 'Recently Approved'
            },
            {
                value: 'messages',
                innerText: 'Messages'
            },
            {
                value: 'transactions',
                innerText: 'Transaction History'
            },
            {
                value: 'logout',
                innerText: 'Log out'
            }
        ]

        if (tier === MEMBERSHIP_TIERS.BUYER_TIER) {
            songwriterOptions = songwriterOptions.filter(it => it.value !== 'upload' && it.value !== 'recently-approved')
            songwriterOptions.splice(songwriterOptions.length - 1, 0, { value: 'cart', innerText: 'Cart' })
            console.log("new is", songwriterOptions)
            return songwriterOptions
            //songwriterOptions.filter(it => it.value !== 'upload' && it.value !== 'recently-approved').splice([{ value: 'cart', innerText: 'Cart' }])
        }
        else return songwriterOptions
    }

    const [options, setOptions] = useState(getOptions())
    const handleChange = (event) => {
        const value = event.target.attributes.value.value;
        if (value === 'logout') {
            setError('')
            logout()
                .then((userCred) => {
                    navigate('/')
                })
                .catch((error) => {
                    const errorCode = error.code;
                    setError(error)
                })
        }
        else {
            console.log(value)
            navigate(`/${value}`)
        }
        // else if (value === ACCOUNT_ACTIVITY) {
        //     navigate('/account-activity')
        // }
        // else if (value === ACCOUNT_SETTINGS) {
        //     navigate('/account')
        // }
        // else if (value === DASHBOARD) {
        //     navigate('/dashboard')
        // }
    }


    return (
        <>
            <DropDownNavBtn className="account" onClick={() => { setMenuOpen(!menuOpen) }}>
                <img src={Logo} alt="azaa-logo" />
            </DropDownNavBtn>
            <DropDownContainerBox>
                <Dropdown
                    name='header'
                    width="200px"
                    textAlign="right"
                    headerWidth="100px"
                    dropdownWidth="200px"
                    marginBottom={'0'}
                    color="#ffffff"
                    colorOnSelect="var(--goldSolid)"
                    dropdownListMarginTop="20px"
                    justifyContent="flex-end"
                    background="#000000"
                    options={options}
                    valueX=""
                    setMenuState={setMenuOpen}
                    menuState={menuOpen}
                    handleStateChange={handleChange}
                    index={0}
                    id='filter'
                    setValidContinue={true}
                ></Dropdown>
            </DropDownContainerBox>
            <Overlay showOverlay={menuOpen} onClick={(e) => { e.stopPropagation(); setMenuOpen(!menuOpen); }} />
        </>
    )
}

export default HamburgerMenu
