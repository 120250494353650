import React, { useState, useEffect } from 'react'

import {
    Wrapper,
    SplitSymbol,
    Title,
    SubText,
    NextStep,
    Prev,
    PrevStep,
    Spacer,
    PrevStepTextWrapper,
    Next,
    NextStepTextWrapper,
    CloseButton
} from './Header.styles'

import Split from '../../../images/splitsIcon.svg'
import NavArrow from '../../../images/formNavArrow.svg'

import PageNav from '../../PageNav'
import Flexbox from '../../Flexbox'
import GridItem from '../../GridItem'

const Header = ({ stepTitle, nextStepText, nextStep, prevStep, prevStepText, validContinue, handleClose, step, pageNavProps }) => {

    const click = () => {
        nextStep();
    }
    const back = () => {
        prevStep();
    }

    return (
        <Wrapper 
        step={step}
            marginBottom={step === 0 ? '0': undefined}
            gridTemplateColumns={step === 0 ? 'calc(3.22vw - 10px) 1fr' : '3.22vw 1fr 200px 3.22vw'}
            gridTemplateRows={step === 0 ? 'min-content 1fr' : 'min-content min-content'}
            gridTemplateAreas={step === 0 ? "'splitSymbol title'" : "'splitSymbol title close spacer''. prevStep nextStep .'"}
        >
            <SplitSymbol>
                <img src={Split} alt="splits-symbol" />
            </SplitSymbol>
            <Title>
                {stepTitle.title}
                <SubText>
                    {stepTitle.subText}
                </SubText>
            </Title>
            {
                step === 0 &&
                <GridItem background="#1A1A1A" margin="1px 0 0" padding="5px 3.22vw 5px calc(3.22vw - 10px)" gridCol="1 / span 2" width="100%" direction="row" justifyContent="start" alignItems="start">
                    <PageNav shadowOff={true} placeHolder={"Enter Search Term"} {...pageNavProps}></PageNav>
                </GridItem>
            }
            {
                step !== 0 &&
                <>
                    <CloseButton onClick={handleClose}>
                        &#10006;
                    </CloseButton>
                    <PrevStep>
                        <PrevStepTextWrapper onClick={() => prevStep()}>
                            <Prev><img src={NavArrow} /> Back</Prev>
                            <SubText align="flex-end">
                                {prevStepText}
                            </SubText>
                        </PrevStepTextWrapper>
                    </PrevStep>
                    <NextStep>
                        <NextStepTextWrapper onClick={() => click()}>
                            <Next> Next<img src={NavArrow} /></Next>
                            <SubText align="flex-end">
                                {nextStepText}
                            </SubText>
                        </NextStepTextWrapper>
                    </NextStep>
                    <Spacer></Spacer>
                </>
            }


        </Wrapper>
    )


}

export default Header
