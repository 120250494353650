import React from 'react'
import {useNavigate} from 'react-router-dom'
import { Wrapper, CloseButton, LogoWrapper } from './MobileLogin.styles'

import Button from '../Button'

import Close from '../../images/close.svg'
import Logo from '../../images/headerLogo.svg'

const MobileLogin = ({isMobile}) => {
    const navigate = useNavigate()
    return (
        <Wrapper isMobile={isMobile}>
            <CloseButton onClick={() => {navigate('/')}}><img src={Close} alt="closebutton"/></CloseButton>
            <LogoWrapper><img src={Logo} alt="logo"/></LogoWrapper>
            <h3>Please visit us on desktop to login.</h3>
            <Button width="100%" border="1px solid #ffffff" onClick={() => {navigate('/')}}>Home</Button>
        </Wrapper>
    )
}

export default MobileLogin