import styled from 'styled-components'

export const Wrapper = styled.div`
height: 100vh;
z-index: 1000;
padding: 100px;
background: #000000;
position: fixed;
top: 0;
right: 0;
width: 100%;
display: flex;
flex-direction: column;
align-items: start;
justify-content: center;
h2{
    margin-bottom: 0;
}
`;