import React, {useEffect, useCallback} from 'react'

import NextArrow from '../../../images/formNavArrow.svg'


import {Wrapper, InnerWrapper} from './FormNavArrow.styles'

const FormNavArrow = ({direction, onClick, continueValid, text}) => {



    return (
        <Wrapper direction={direction} onClick={onClick}
           
        validContinue={continueValid}>
            <img src={NextArrow} alt="arrow" /> <InnerWrapper>{text}</InnerWrapper>
        </Wrapper>
    )
}

export default FormNavArrow
