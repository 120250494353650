import styled from 'styled-components';


import { NavLink as Link } from 'react-router-dom'

export const Wrapper = styled.div`
    position: ${props => props.isRegister ? 'relative' : 'fixed'};
    width: 100%;
    overflow: auto;
    padding: ${props => props.isLoading ? '' : '50px 0'};
    top: 0;
    z-index: 5;
    min-height: 100vh;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 100%;


`;

export const Overlay = styled.div`
    position: fixed;
    width: 100%;
    overflow: auto;
    top: 0;
    z-index: 4;
    height: 100vh;
    background: rgba(0, 0, 0, .85);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Error = styled.div`
font-size: 10px;
min-height: 20px;
max-width: 100%;
user-select: none;
`;

export const Content = styled.div`
background: #000;
max-width: ${props => props.maxWidth ? props.maxWidth : '426px'};
width: 90%;
box-shadow: 0 0 10px 0 rgba(255,255,255,0.15);
min-height: 600px;
display: flex;
position: relative;
z-index: 6;
flex-direction: column;
justify-content: space-around;
align-items: center;
padding: 60px 20px;
img{
    height: 100px;
}

@media screen and (max-width: 600px){
        display: ${props => props.isMobile ? 'none' : 'flex'};
    }
    @media only screen 
        and (max-device-width: 812px) 
        and (-webkit-min-device-pixel-ratio: 3) {
            display: ${props => props.isMobile ? 'none' : 'flex'};
    }
    @media only screen 
        and (max-device-width: 360px) 
        and (-webkit-device-pixel-ratio: 4) {
            display: ${props => props.isMobile ? 'none' : 'flex'};
    }
    @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            display: ${props => props.isMobile ? 'none' : 'flex'};
    }

`;


export const FormWrapper = styled.div`
width: 100%;
position: relative;
display: flex;
flex-direction: column;
`;

export const QuestionWrapper = styled.div`
position: relative;
margin-top: ${props => props.marginTop ? props.marginTop : '20px'};
display: grid;
grid-column-gap: 10px;
grid-template-columns: ${props => props.gridTemplateColumns ? props.gridTemplateColumns : '1fr'};
width: 100%;
`;

export const CloseButtonStyle = styled.div`
 align-self: flex-end;
    top: 15px;
    cursor: pointer;
    user-select: none;
    position: absolute;
`;

export const NavContainerStyle = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
`;

export const NavLink = styled(Link)`
text-decoration: none;
font-size: var(--fontMicro);
letter-spacing: 0.07rem;
font-weight: 600;
margin-top: 0.5rem;
color: var(--purpleGrey);
text-transform: uppercase;
align-self: flex-start;

:hover{
    color: #fff;
}
`;


export const PwdReset = styled.div`
text-decoration: none;
user-select: none;
cursor: pointer;
font-size: var(--fontMicro);
letter-spacing: 0.07rem;
font-weight: 600;
margin-top: 0.5rem;
color: var(--purpleGrey);
text-transform: uppercase;
align-self: flex-start;

:hover{
    color: #fff;
}
`;
export const SwitchField = styled.div`
text-decoration: none;
user-select: none;
cursor: pointer;
font-size: var(--fontMicro);
letter-spacing: 0.07rem;
font-weight: 600;
margin-top: 0.5rem;
color: var(--purpleGrey);
text-transform: uppercase;
align-self: flex-start;

:hover{
    color: #fff;
}

`;




export const LinkButton = styled(Link)`
    all: unset;
    font-family: var(--defaultFont);
    color: #ffffff;
    height: ${props => props.height ? props.height : 'var(--buttonHeight)'};
    font-size: var(--buttonFontSize);
    color: ${({ color }) => color};
    width: ${({ width }) => width};
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    cursor: ${({ cursor }) => cursor};
    border: ${({ border }) => border};
    background: ${({ background }) => background};
    font-weight: ${({ fontWeight }) => fontWeight};
    /* border: ${props => props.validContinue ? props.border : '2px solid rgba(255,255,255,0.1)'};
    background: ${props => props.validContinue ? props.background : 'rgba(255,255,255,0.15)'}; */
    /* font-weight: ${props => props.validContinue ? '500' : '700'}; */
    letter-spacing: 0.08rem;
    :hover{
        background: ${({ background }) => background} ;

    }
`;

export const H2 = styled.h3`
    font-weight: 400;
    letter-spacing: 0.03rem;
    user-select: none;
    margin: 0;
    font-size: ${props => props.fontSize ? props.fontSize : '18px'};
`;


export const SubText = styled.h5`
    font-weight: 300;
    letter-spacing: 0.03rem;
    user-select: none;
    margin: 0;
    font-size: ${props => props.fontSize ? props.fontSize : '13px'};
    color: ${props => props.color ? props.color : 'var(--primary)'};
`;

export const SubmitButton = styled.div`
    all: unset;
    font-family: var(--defaultFont);
    color: #ffffff;
    height: ${props => props.height ? props.height : 'var(--buttonHeight)'};
    font-size: var(--buttonFontSize);
    color: ${({ color }) => color};
    width: ${({ width }) => width};
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    cursor: ${({ cursor }) => cursor};
    border: ${({ border }) => border};
    background: ${({ background }) => background};
    font-weight: ${({ fontWeight }) => fontWeight};
    /* border: ${props => props.validContinue ? props.border : '2px solid rgba(255,255,255,0.1)'};
    background: ${props => props.validContinue ? props.background : 'rgba(255,255,255,0.15)'}; */
    /* font-weight: ${props => props.validContinue ? '500' : '700'}; */
    letter-spacing: 0.08rem;
    :hover{
        background: ${({ background }) => background} ;

    }
`;
export const TermsLink = styled(Link)`
all: unset;
    font-family: var(--defaultFont);
    cursor: pointer;
    margin: 0 0.2rem;
    color: #ffffff;
    font-weight: 700;
`;
export const CheckboxStyle = styled.label`
margin-bottom: 2px;
display: flex;
align-items: center;
justify-content: flex-start;
font-size: var(--fontSmall);
color: var(--purpleGrey);
font-weight: 500;
letter-spacing: 0.02rem;
span{
    margin-left: 5px;
    display: inline-block;
    width: 15px;
    height: 15px;
    background: rgba(255,255,255,0.5);
    position: relative;
    box-shadow: 0 0 1px 1px inset #000;
    cursor: pointer;
}

strong{
    margin-left: 0.2rem;
    color: #ffffff;
    font-weight: 700;
}

input{
    position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

input:checked ~ span{
    background: rgb(255,255,255);
    box-shadow: 0 0 1px 1px inset #000;
}

input:checked ~ span:after{
    content: "";
    position: relative;
    display: block;
 top: 1px;
  width: 3px;
  height: 8px;
  margin: auto auto;
  border: solid rgb(0,0,0);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`;