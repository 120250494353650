import React, { useState } from 'react'

import { Wrapper } from './Toggle.styles'

const Toggle = ({ _checked, _setChecked, onChange, hideLabel = false, fontSize, options = [], splitPosition = false, ...props }) => {
    const [checked, setChecked] = useState(true)

    return (
        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }} onClick={(e) => {
            onChange(e);
            setChecked(!checked)
        }}>
            {(splitPosition && !hideLabel) &&
                <label for="checkbox" style={{ marginLeft: '5px', fontSize: fontSize ? fontSize : '1rem', whiteSpace: 'nowrap' }}>
                    {options.length ? options[0] : 'Yes'}
                </label>
            }
            <Wrapper {...props}>
                <input type="checkbox" checked={checked} name="checkbox" onChange={onChange} />
                <span className="slider"></span>
            </Wrapper>
            {(!splitPosition && !hideLabel) && <label for="checkbox" style={{ marginLeft: '5px', fontSize: '1rem' }}>
                {checked ? options.length ? options[0] : 'Yes' : options.length ? options[1] : 'No'}
            </label>}
            {(splitPosition && !hideLabel) && <label for="checkbox" style={{ marginLeft: '5px', fontSize: fontSize ? fontSize : '1rem', whiteSpace: 'nowrap' }}>
                {options.length ? options[1] : 'No'}
            </label>}
        </div>
    )
}

export default Toggle