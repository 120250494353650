import styled from "styled-components";

export const Wrapper = styled.div``;

export const TileWrapper = styled.div`
display: flex;
width: 100%;
gap: 25px;
>div:nth-of-type(2){
    flex-grow: 1;
}
`;

export const H2 = styled.h2`
text-transform: none;
margin: 10px 0 0;
`;

export const Date = styled.p`
color: #555555;
font-weight: 500;
margin: 0;
font-size: 14px;
`;

export const Tile = styled.div`
display: flex;

flex-direction: column;
gap: 5px;
/* width: 100%; */
padding: 20px;
box-shadow: 0 0 5px #000;
h2, h4{
margin: 0;
}
h4{
    opacity: 0.35;
}
`;

export const Span = styled.span`
font-size: 10px;
opacity: 0.35;
user-select: all;
margin-top: 5px;
`;

export const FlexCell = styled.div`
display: flex;
flex-direction: column;
align-items: ${props => props.end ? 'end' : 'start'};
`;