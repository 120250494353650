import { useState, useEffect, useRef } from 'react';
import API from '../../API';
import { useAuth } from '../../contexts/AuthContext'
import { updateRelTime } from '../../utils'
import dayjs from 'dayjs'
import { shortFormatPrice, showTrailing, calculatePriceWithFee, calculateCut } from '../../helpers'

import agreedIcon from '../../images/agreedIcon.svg'
import notAccepted from '../../images/close.svg'

import ToolTip from '../../components/ToolTip'

import Flexbox from '../Flexbox'
import Table from '../Table2'
import TableCell from '../TableCell'
import TableRow from '../TableRow'

export const getCompletionStatus = (payoutComplete = undefined, stemTransfer = undefined, index = 0, justifyContent = 'center') => {
    if (payoutComplete === undefined)
        return stemTransfer[index].updated_at ?
            <ToolTip justifyContent={justifyContent} boxShadow="0 0 10px rgb(0,0,0)" background="#000000" bottom="20px" border="#000000" relative toolTipPosition="absolute" toolTipText={`Confirmed ${dayjs.unix(stemTransfer[index].updated_at).format('M/D/YY')}`}>
                <img src={agreedIcon} style={{ width: '15px', height: '15px' }} /></ToolTip > : <img src={notAccepted} style={{ height: '15px', width: '15px' }} />
    else if (!stemTransfer[0].updated_at || !stemTransfer[1].updated_at)
        return '-'
    else return payoutComplete === '1' ? <img src={agreedIcon} style={{ height: '15px', width: '15px' }} /> : <img src={notAccepted} style={{ height: '15px', width: '15px' }} />
}


const BuyerDetails = ({ selectedSong }) => {
    const [data, setData] = useState(null)
    console.log("selectedSong", selectedSong)
    useEffect(() => {
        if (selectedSong) {

            setData(formatData())
        }
    }, [selectedSong])

    const formatData = () => {
        if (selectedSong) {
            if (selectedSong.saleDetails !== undefined) {
                const headerProps = { padding: '10px 20px', whiteSpace: 'nowrap', fontWeight: '700', fontSize: '10px' }
                const props = { padding: '10px 20px' }
                return (({ email, songwriter_email, buyer_email, buyerPaypal, stemTransfer,
                    payoutComplete, payoutDate, saleDetails, price, updated_at }) => ({
                        'Primary Songwriter [Contact Email]': { headerProps, title: <>Primary Songwriter<br />[Contact Email]</>, data: saleDetails.primaryEmail, props },
                        'Deliverables Confirmation [Songwriter]': { headerProps, title: <>Deliverables Confirmation<br />[Songwriter]</>, data: getCompletionStatus(undefined, stemTransfer, 0, 'start'), props: { ...props, color: !stemTransfer[0].updated_at ? 'var(--yellow)' : '' } },
                        'Buyer [Contact Email]': { headerProps, title: <>Buyer<br />[Contact Email]</>, data: saleDetails.buyerEmail, props },
                        'Deliverables Confirmation [Buyer]': { headerProps, title: <>Deliverables Confirmation<br />[Buyer]</>, data: getCompletionStatus(undefined, stemTransfer, 1, 'start'), props: { ...props, color: !stemTransfer[1].updated_at ? 'var(--yellow)' : '' } },
                    }))(selectedSong)
            }
            return null
        }
    }

    if (data === null || !selectedSong) {
        return <></>
    }
    return (
        <>
            <h3 style={{ margin: '20px 0 0' }}>Deliverables Transfer</h3>
            < Table noUppercase background="#1c1c1c" >
                {
                    Object.keys(data).map(it => {
                        console.log("IT", it, data)
                        return (
                            <TableRow >
                                <TableCell width="min-content" props={data[it].headerProps}>{data[it].title}</TableCell>
                                <TableCell width="max-content" props={data[it].props}>{data[it].data === '' || data[it].data === 0 ? '-' : data[it].data}</TableCell>
                            </TableRow>
                        )
                    })
                }
            </Table >
        </>)
}
const PaypalDetails = ({ selectedSong }) => {
    const [data, setData] = useState(null)
    console.log("selectedSong", selectedSong)
    useEffect(() => {
        if (selectedSong)
            setData(formatData())
    }, [selectedSong])

    const formatData = () => {
        if (selectedSong) {
            const props = { whiteSpace: 'nowrap', fontWeight: '700', background: 'var(--paypalBlueDark)', color: 'var(--paypalBlueLight)', padding: '10px 20px' }
            const propsData = { width: '100%', color: 'var(--paypalBlueDark)', background: 'var(--paypalBlueLight)', padding: '10px 20px' }
            if (selectedSong.saleDetails !== undefined)
                return (({ saleDetails, buyerOrderID, buyerInvoiceID }) => ({
                    'Date Purchased': { data: dayjs.unix(saleDetails.dateSold).format('MMM DD YYYY'), props, propsData },
                    'Order Id': { data: buyerOrderID, props, propsData },
                    'Invoice Id': { data: buyerInvoiceID, props, propsData },
                }))(selectedSong)
            return null
        }
    }

    if (data === null || !selectedSong) {
        return <></>
    }
    return (
        <>
            <h3 style={{ margin: '20px 0 0' }}>Paypal Details</h3>
            < Table noUppercase background="var(--paypalBlueDark)" borderCollapse="unset" >
                {
                    Object.keys(data).map(it => {
                        console.log("IT", it, data)
                        return (
                            <TableRow userSelect="text">
                                <TableCell width="min-content" props={data[it].props}>{it.replaceAll('_', " ")}</TableCell>
                                <TableCell width="max-content" props={data[it].propsData}>{data[it].data === '' || data[it].data === 0 ? '-' : data[it].data}</TableCell>
                            </TableRow>
                        )
                    })
                }
            </Table >
        </>)

}

const SongDetails = ({ selectedSong, isBuyer }) => {
    return (
        <Flexbox column alignItems="start" overflow="hidden">
            <Flexbox column gap="20px" alignItems="start" overflow="auto" height="100%">
                <BuyerDetails selectedSong={selectedSong} />
                {isBuyer && <PaypalDetails selectedSong={selectedSong} />}
            </Flexbox>
        </Flexbox>
    )
}

export default SongDetails