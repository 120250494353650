import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'
export const CloseButton =  styled(Link)`
    all: unset;
    font-family: var(--defaultFont);
    color: #ffffff;
    cursor: pointer;
    right: 30px;
    top: 30px;
    position: absolute;
`;

export const NavLink = styled.a`
text-decoration: none;
display: inline-block;
color: #ffffff;
`;

export const H1 = styled.h1`
    margin: 0;
    font-weight: 400;
    font-size: 2rem;
`;

export const H2 = styled.h1`
    margin-top: 0.5rem;
    font-size: 1.3rem;
    font-weight: 300;
    color: var(--purpleGrey);
`;

export const FormWrapper = styled.div`
display: grid;
grid-gap: 20px;
width: 100%;
height: 100%;
padding: 0;
justify-content: center;
align-items: center;
align-content: center;
position: relative;
grid-template-columns: min-content 442px min-content;
grid-template-rows: 1fr;
grid-template-areas: 'back content next';
@media screen and (max-width: 600px){
    grid-template-columns: 1fr 1fr;
    padding: 0 10% 10%;
    grid-template-rows: 1fr min-content;
    grid-template-areas: 'content content''back next';
    >div:nth-of-type(2n+1){
        align-items: start;
    }
}
`;