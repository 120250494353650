import React from 'react'

import { Wrapper, Button } from './FilterTile.styles';


const FilterTile = ({ onClick, name }) => {
    return (
        <Wrapper value={name} ><div>{name}</div><Button onClick={onClick}>&#10006;</Button></Wrapper>
    )
}

export default FilterTile