import styled from 'styled-components'

export const Wrapper = styled.div`
position: relative;
display: flex;
height: 10px
width: 200px;
min-height: 10px;
min-width: 200px;
background: var(--yellow);
align-items: center;
justify-content: start;
box-shadow: inset 0 0 2px #000;
`;

const makeLongShadow = (color, size) => {
  let i = 18;
  let shadow = `${i}px 0 0 ${size} ${color}`;

  for (; i < 706; i++) {
    shadow = `${shadow}, ${i}px 0 0 ${size} ${color}`;
  }

  return shadow;
};

export const StyledSlider = styled.input`
  overflow: visible;
  display: block;
  /* transform: ${props => props.rotate ? 'rotate(90deg)' : ''}; */
  appearance: none;
  background: ${props => props.background};
  max-width: 200px;
  width: 100%;
  margin: 0;
  height: 5px;
  cursor: pointer;
  border-radius: 5px;
  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${props => props.height};
    background: ${props => props.lowerBackground};
    border-radius: 5px;
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: ${props => props.thumbDiameter};
    width: ${props => props.thumbDiameter};
    background: black;
    background-size: cover;
    border-radius: 100%;
    border: 0;
    top: 50%;
    transform: translateY(-50%);
    /* box-shadow: ${makeLongShadow(props => props.upperColor, "-10px")}; */
    transition: background-color 150ms;
  }

  &::-moz-range-track,
  &::-moz-range-progress {
    width: 100%;
    height: 100%;
    background: ${props => props.upperBackground};
    border-radius: 5px;
  }

  &::-moz-range-progress {
    background: ${props => props.lowerBackground};

  }

  &::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: ${props => props.thumbHeight ? props.thumbHeight : props.thumbDiameter};
    width: ${props => props.thumbWidth ? props.thumbWidth : props.thumbDiameter};
    border-radius: ${props => props.borderRadius ? props.borderRadius : '100%'};
    background: #ffffff;
    background-color #000000;
    background-image linear-gradient(315deg, #000000 0%, #7f8c8d 74%);
    background-image linear-gradient(90deg, 
      #7f8c8d 0%, 
      #7f8c8d 10%, 
      #bcc7c7 20%, 
      #7f8c8d 25%, 
      #a8b2b3 30%, 
      #7f8c8d 35%, 
      #bcc7c7 40%, 
      #7f8c8d 45%, 
      #a8b2b3 50%, 
      #7f8c8d 55%, 
      #bcc7c7 60%,
      #7f8c8d 65%, 
      #a8b2b3 70%, 
      #7f8c8d 75%, 
      #bcc7c7 80%, 
      #7f8c8d 90%, 
      #7f8c8d 100%);
    box-shadow: 0 0 2px #000;
    border: 0;
    transition: background-color 150ms;
  }

  &::-ms-track {
    width: 100%;
    height: 100%;
    border: 0;
    /* color needed to hide track marks */
    color: transparent;
    background: var(--greyBackground);
    border-radius: 5px;
  }

  &::-ms-fill-lower {
    background: ${props => props.lowerBackground};
  }

  &::-ms-fill-upper {
    background: var(--greyBackground);
    border-radius: 5px;
  }

  &::-ms-thumb {
    appearance: none;
    height: ${props => props.thumbHeight ? props.thumbHeight : props.thumbDiameter};
    width: ${props => props.thumbWidth ? props.thumbWidth : props.thumbDiameter};
    border-radius: ${props => props.borderRadius ? props.borderRadius : '100%'};
    background: #ffffff;
    background-color #000000;
    background-image linear-gradient(315deg, #000000 0%, #7f8c8d 74%);
    box-shadow: 0 0 2px #000;
    border: 0;
    transition: background-color 150ms;
    /* IE Edge thinks it can support -webkit prefixes */
    top: 0;
    margin: 0;
    box-shadow: none;
  }

  &:hover,
  &:focus {
    &::-webkit-slider-thumb {
      background-color: ${props => props.thumbHoverColor};
    }
    &::-moz-range-thumb {
      background-color: ${props => props.thumbHoverColor};
    }
    &::-ms-thumb {
      background-color: ${props => props.thumbHoverColor};
    }
  }
`;