import React, { useState, useEffect } from 'react'

import { Wrapper } from './Login.styles'

import LoginOverlay from '../LoginOverlay'

import { useNavigate, Navigate } from 'react-router-dom'

import {
    LOGIN_POPUP,
    SIGNUP_POPUP,
    SET_PASSWORD_POPUP
} from '../../config.js'

import { useLocation } from 'react-router-dom';

const Login = ({ initPopupType }) => {
    let navigate = useNavigate();
    let location = useLocation();
    const [popupType, setPopupType] = useState(initPopupType ? initPopupType : LOGIN_POPUP)
    const [showLogin, setShowLogin] = useState(true)
    useEffect(() => {
        if (showLogin) {
            document.querySelector("body").style.overflowY = 'hidden'
        }
        else {
            document.querySelector("body").style.overflowY = 'visible'
        }

        return () => {
            document.querySelector("body").style.overflowY = 'visible'
        }

    }, [showLogin])

    // if (!showLogin) return (<Navigate to="/" />)
    // else {
    return (
        <LoginOverlay
            setShowLogin={setShowLogin}
            popupType={popupType}
            setPopupType={setPopupType}
            directAccessedLogin={location.pathname === '/login' && location.state === null}
        />
    )
    // }
}

export default Login
