import React from 'react'

import { Wrapper } from './Table.styles'

import TableEntry from '../TableEntry'

const Table = ({ hideHeader, rowBorder, rowMargin, rowHeight, rowHighlight, padding, content, gridTemplateColumns, gridColGap, gridRowGap, tableHeader, rowAlignmentArray, handleSelect, noHover, tableHeaderTextStyling }) => {
    return (
        <Wrapper>
            {(hideHeader === undefined || !hideHeader) && <TableEntry
                rowHeight={rowHeight}
                content={tableHeader}
                tableHeaderTextStyling={tableHeaderTextStyling}
                gridTemplateColumns={gridTemplateColumns}
                gridColGap={gridColGap}
                rowAlignmentArray={rowAlignmentArray}
                header
                padding={padding}
            />}
            {
                content.map((row, index) => (
                    <TableEntry
                        rowHeight={rowHeight}
                        rowBorder={rowBorder}
                        onClick={handleSelect ? handleSelect(row.contentID) : undefined}
                        noHover={noHover}
                        rowHighlight={row.rowHighlight}
                        padding={padding}
                        margin={rowMargin}
                        content={row.contentArr}
                        id={row.contentID}
                        key={index}
                        gridTemplateColumns={gridTemplateColumns}
                        gridColGap={gridColGap}
                        gridRowGap={gridRowGap}
                        rowAlignmentArray={rowAlignmentArray}
                    />
                ))
            }
        </Wrapper>
    )
}

export default Table
