import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
position: relative;
width: 100%;
height: 100%;
flex-direction: column;
flex-grow: ${props => props.flexGrow ? props.flexGrow : ''};
`;

export const InnerWrapper = styled.div`
overflow: auto;
display: flex;
position: relative;
width: 100%;
height: 100%;
flex-direction: column;
flex-grow: ${props => props.flexGrow ? props.flexGrow : ''};
padding-bottom: 50px;

`;

export const ScrollWrapper = styled.div`
position: relative;
scroll-behavior: smooth;
margin-bottom: ${props => props.tableMarginBottom ? props.tableMarginBottom : ""};
overflow-x: ${props=> props.overflowX ? props.overflowX : ''};
height: ${props=> props.expandedContainerHeight ? props.expandedContainerHeight : ''};
padding-bottom: ${props=> props.paddingBottom ? props.paddingBottom : '0'};
`;