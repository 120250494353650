import styled from "styled-components"; 

export const Wrapper = styled.div`
display: flex;
width: 100%;
position: relative;
height: min-content;
flex-direction: column-reverse;
padding: 20px;
scroll-behavior: smooth;
/* background: var(--greyBackground); */
`;

export const ScrollWrapper = styled.div`
display: flex;
width: 100%;
position: relative;
height: 100%;
overflow: auto;
`;

