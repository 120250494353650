import { useState, useEffect, useRef } from 'react';

import API from '../API';
import { useAuth } from '../contexts/AuthContext'

import { validatePassword, validateEmail, varNameToString, validateName } from '../helpers'

export const useAccountSettingsHook = () => {
    const { skipReset, currentUser, reAuth, changePassword, changeEmail, login } = useAuth()

    const initial = useRef(true)
    const modalOverlay = useRef(null)
    const [userReauthed, setUserReauthed] = useState(false)
    const [validContinue, setValidContinue] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [error, setError] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [submissionAttempt, setSubmissionAttempt] = useState('')
    const [loading, setLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(true)
    const [pwChanged, setPwChanged] = useState(false)
    const authItem = useRef('pwChanged')
    const [emailChanged, setEmailChanged] = useState(false)
    const [serverResponse, setServerResponse] = useState()

    const initialState = {
        first_name: '',
        last_name: '',
        currPassword: '',
        newPassword: '',
        currEmail: '',
        newEmail: '',
        paypal: '',
        spotify: '',
        instagram_handle: '',
        publisher: '',
        membership: '',
        initialState: { newPassword: '', newEmail: '' }
    }
    const [state, setState] = useState(initialState)

    const { spotify, instagram_handle, publisher, membership, paypal, first_name, last_name } = state
    const values = { spotify, instagram_handle, publisher, membership, paypal, first_name, last_name }

    const closeModal = () => { setShowModal(false) }

    const continueKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (validate()) {
            }
        }
    }

    const handleChange = (e) => {
        setState(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const diff = () => {
        return Object.keys(values).filter(v => { return state.initialState[v] !== undefined && values[v] !== state.initialState[v] })
    }

    const validate = () => {
        setValidContinue(false)
        const changes = diff()
        console.log("changes",changes)
        if (loading)
            return false
        else if (!(changes.length > 0))
            return false
        else if (changes.includes('instagram_handle') && state.instagram_handle.length !== 0 && !validateInstagram(state.instagram_handle))
            return false
        else if (changes.includes('paypal') && !validateEmail(state.paypal))
            return false
        else if (changes.includes('first_name') && validateName(state.first_name) === '')
            return false
        else if (changes.includes('last_name') && validateName(state.last_name) === '')
            return false
        else {
            setValidContinue(true)
            return true
        }

    }

    const validateInstagram = (handle) => {
        const re = /^(?!.*\.\.)(?!.*\.$)[^\W](?![0-9]*$)[a-zA-Z0-9][\w.]{0,29}[\w](?!.*?\.{2})$/;
        const handleStripped = handle.split('@').length > 1 ? handle.split('@')[1] : handle.split('@')[0];
        return re.test(String(handleStripped));
    }

    const validateAuthChange = (itemChanged, key, currentValue) => {
        if (!userReauthed)
            return false
        else if (itemChanged) //itemChanged or emailChanged
            return false
        else if (modalLoading)
            return false
        else if (state[key].length === 0)
            return false
        else if (!meetsFieldRequirements(key)) {
            return false
        }
        else if (state[key] === currentValue) // state.currPassword || currentUser.email
            return false
        else
            return true
    }

    const meetsFieldRequirements = (key) => {
        return key === 'newPassword'
            ? validatePassword(state.newPassword)
            : validateEmail(state.newEmail)
    }

    const getErrorCodeMessage = (e) => {
        if (e.code === undefined)
            return 'There was an error. Please try again later'
        else if (e.code.includes("wrong-password"))
            return "Incorrect password."
        else if (e.code.includes("user-not-found"))
            return "Account not found."
        else if (e.code.includes("too-many-requests"))
            return "Too many attempts. Try again later or reset your password."
        else return 'There was an error. Please try again later'
    }

    const reAuthUser = async () => {
        try {
            if (validatePassword(state.currPassword)) {
                setModalLoading(true)
                await reAuth(state.currPassword)
                setUserReauthed(true)
                setServerResponse('')
                setModalLoading(false)
            }
        } catch (e) {
            console.log(e)
            const errCode = getErrorCodeMessage(e)
            setError(errCode)
            setServerResponse(errCode)
            setModalLoading(false)
        }
    }

    const getReAuthFunctions = (itemChanged) => { // pwChanged or emailChanged
        let itemKeys = { new: '', curr: '' }
        const item = Object.entries(itemChanged)[0][1]
        if (Object.keys(itemChanged)[0] === 'pwChanged') {
            itemKeys = { new: 'newPassword', curr: 'currPassword' }
        } else {
            itemKeys = { new: 'newEmail', curr: 'currEmail' }
        }
        return {
            onKeyPress: async (event) => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    if (!userReauthed && !item) {
                        if (validatePassword(state.currPassword))
                            reAuthUser()
                    }
                    else if (!item) {
                        if (Object.keys(itemChanged)[0] === 'pwChanged' &&
                            validateAuthChange(item, state.newPassword, state.currPassword))
                            await submitNewPassword()
                        else if (validateAuthChange(item, state.newEmail, state.currEmail)) {
                            await submitNewEmail()
                        }
                    }
                }
            },
            validContinue: () => {
                if (!userReauthed && !item) {
                    return validatePassword(state.currPassword)
                }
                else if (!item) {

                    return validateAuthChange(item, itemKeys.new, state[itemKeys.curr])
                }
            },
            handleSubmit: async () => {
                if (!userReauthed && !item) {
                    reAuthUser()
                }
                else if (!item) {
                    if (Object.keys(itemChanged)[0] === 'pwChanged')
                        await submitNewPassword()
                    else {
                        await submitNewEmail()
                    }
                }
            },
            closeAfterSubmit: () => {
                console.log("submit", state)
                setState(prev => ({
                    ...prev,
                    [itemKeys.curr]: state[itemKeys.new],
                    [itemKeys.new]: '',
                    currEmail: currentUser.email
                }))
                setUserReauthed(false)
                setPwChanged(false)
                setEmailChanged(false)
                closeModal()
            },
            values: !userReauthed
                ? {
                    value: state.currPassword,
                    curr: '',
                    name: 'currPassword',
                    label: 'Current Password',
                    type: 'password',
                    placeholder: 'Enter Password'
                }
                : {
                    value: state[itemKeys.new],
                    curr: state[itemKeys.curr],
                    name: itemKeys.new,
                    label: itemKeys.new.replace('new', 'New '),
                    type: itemKeys.new.replace('new', '').toLowerCase(),
                    placeholder: `Enter ${itemKeys.new.replace('new', '')}`
                }
        }
    }

    const submitNewPassword = async () => {
        if (validateAuthChange(pwChanged, 'newPassword', state.currPassword)) {
            try {
                setServerResponse("")
                setModalLoading(true)
                const changePw = await reAuth(state.currPassword)
                const res2 = await changePassword(state.newPassword)
                setServerResponse("Password Changed!")
                setModalLoading(false)
                setPwChanged(true)
            } catch (e) {
                console.log(e);
                setModalLoading(false)
            }
        }
    }

    const handleSubmit = () => {
        console.log("valid", validContinue)
        try {
            if (validContinue) {
                setLoading(true)
                const updatedContentKeys = diff();
                setSubmissionAttempt('Submitting')
                if (updatedContentKeys.length > 0) {
                    const updatedContent = {
                        ...Object.fromEntries(Object.entries(state).filter(item => updatedContentKeys.includes(item[0]))),
                        email: currentUser.email,
                        access_token: currentUser.accessToken
                    }
                    API.updateUser(updatedContent).then(async () => {
                        API.fetchUserAccountSettings(currentUser.accessToken, currentUser.email).then(async (res) => {
                            console.log(Object.keys(res.user))
                            setState(prev => ({ ...prev, ...res.user, initialState: { ...prev.initialState, ...res.user } }))
                            setSubmissionAttempt('Success!')
                            setValidContinue(false)
                            setLoading(false)

                        }).catch(err => {
                            setError(err)
                            setLoading(false)
                        })
                    }).catch(err => { setError(err); setLoading(false) })
                }

            }
            else {
                console.log("invalid ")
            }
        } catch (error) {
            setSubmissionAttempt('Error. Please try again later')
            setLoading(false)
        }


    }

    const submitNewEmail = async () => {
        //itemChanged, key, currentValue

        if (validateAuthChange(emailChanged, 'newEmail', state.currEmail)) {
            try {
                setServerResponse("")
                setModalLoading(true)
                // this is required to keep auth function onIdTokenChanged from triggering until the end
                skipReset.current = true
                const currEmail = state.currEmail
                await API.validateEmailUnused(state.newEmail)
                await reAuth(state.currPassword)
                await changeEmail(state.newEmail)
                await API.updateEmail(currEmail, currentUser.accessToken, state.newEmail)
                setState(prev => ({ ...prev, currEmail: state.newEmail, newEmail: '' }))
                skipReset.current = false
                const res5 = await login(state.newEmail, state.currPassword)
                setServerResponse("Email Changed!")
                setModalLoading(false)
                setEmailChanged(true)
            } catch (e) {
                console.log("ERROR", e)
                if (e.response !== undefined) {
                    if (e.response.data !== undefined)
                        setServerResponse(e.response.data.message)
                }
                setModalLoading(false)
                setEmailChanged(false)
            }
        }
    }

    useEffect(() => {

        const init = async () => {
            try {
                const res = await API.fetchUserAccountSettings(currentUser.accessToken, currentUser.email)
                initial.current = true;
                let _obj = {}
                let _initialState = {}
                const _keys = Object.keys(res.user)

                for (let i = 0; i < _keys.length; i++) {
                    const key = _keys[i]
                    _obj = { ..._obj, [key]: res.user[key] !== null ? res.user[key] : '' }
                    _initialState = { ..._initialState, [key]: res.user[key] !== null ? res.user[key] : '' }
                }

                setState(prev => ({
                    ...prev,
                    currEmail: currentUser.email,
                    ..._obj,
                    initialState: { ...prev.initialState, ..._initialState }
                }))

                initial.current = false;
                setInitialLoading(false)
                validate()
            } catch (error) {
                setError(error)
                setInitialLoading(false)
            }

        }
        if (currentUser !== null) {
            init()
            // API.fetchUserAccountSettings(currentUser.accessToken, currentUser.email).then((res) => {
            //     setState(prev => ({
            //         ...prev,
            //         currEmail: currentUser.email
            //     }))
            //     initial.current = true;
            //     Object.keys(res.user).forEach((key, index) => {
            //         setState(prev => ({
            //             ...prev,
            //             [key]: res.user[key] !== null ? res.user[key] : '',
            //             initialState: { ...prev.initialState, [key]: res.user[key] !== null ? res.user[key] : '' }
            //         }))
            //         if (index + 1 === Object.keys(res.user).length) {
            //             initial.current = false;
            //         }
            //     })
            //     setInitialLoading(false)
            //     validate()

            // }).catch(err => {
            //     setError(err)
            //     setInitialLoading(false)
            // })

        }
    }, [currentUser])

    useEffect(() => {
        if (!loading)
            validate()
    }, [state])

    const closeModalOverlay = (e) => {
        if (e.target === modalOverlay.current)
            setShowModal(false)
    }

    return {
        authItem,
        closeModal,
        closeModalOverlay,
        continueKey,
        emailChanged,
        handleChange,
        handleSubmit,
        initialLoading,
        getReAuthFunctions,
        loading,
        modalOverlay,
        pwChanged,
        serverResponse,
        setError,
        setServerResponse,
        setSubmissionAttempt,
        setShowModal,
        showModal,
        state,
        submissionAttempt,
        userReauthed,
        validContinue,
    }
}