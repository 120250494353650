import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: start;
position: relative;
align-items: center;
height: 100%;
flex-grow: 1;
`;

export const Table = styled.div`
display: table;
padding-bottom: 100px;
width: 100%;
overflow: hidden auto;
/* tr{
    background: ${props => props.background ? props.background : ''};
} */
tr:nth-of-type(2n){
    background: ${props => props.isTop100 ? '#282828' : '#313131'};
    //#313131
    
}
>div:nth-of-type(2n){
    background: #0D0D0D;
    //#313131
    
}
tr.selected{
    background: var(--yellow);
    color: #000000;
    outline: inset 1px #1A1A1A;
    
}
tr:not(.tableHeader):not(.selected):hover{
    background: ${props => props.isTop100 ? '#282828' : '#313131'};
    
}
tr.tableHeader{
    text-transform: uppercase;
}
`;

export const TableRow = styled.div`
display: table-row;
font-size: 12px;
user-select: none;
position: relative;
transition: background 0.2s ease-out;
background: ${props => props.isDragging ? 'var(--yellow) !important' : props.background ? props.background : ''};
color: ${props => props.color ? props.color : props.isDragging ? '#000000 !important' : ''};
width: 100%;

:not(.tableHeader):hover{
    background: rgba(255,255,255,0.15);
    
}

>div:nth-of-type(1), >div:nth-of-type(2){
padding-left: 0;
}
>div:nth-last-of-type(1){
    padding: 0;  
}


`;

export const TableCell = styled.div`
display: table-cell;
color: ${props => props.color ? props.color : ''};
position: relative;
padding: 0 30px;
white-space: ${props => props.whiteSpace ? props.whiteSpace : ''};
font-size: ${props => props.fontSize ? props.fontSize : 'inherit'};
width: ${props => props.width ? props.width : 'auto'};
height: ${props => props.height ? props.height : 'auto'};
vertical-align: middle;
min-width: ${props => props.minWidth ? props.minWidth : ''};
text-align: ${props => props.textAlign ?  props.textAlign : 'left'};
/* padding: 0 30px; */


`;