import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'

export const throttle = (func, delay) => {
    let inProgress = false;
    return (...args) => {
        if (inProgress) {
            return
        }
        inProgress = true
        setTimeout(() => {
            func(...args)
            inProgress = false;
        }, delay)
    }
}
export const debounce = (callback, wait) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => { callback.apply(this, args) }, wait)
    }

}
export const updateRelTime = () => {
    dayjs.extend(RelativeTime)
    var updateLocale = require('dayjs/plugin/updateLocale')
    dayjs.extend(updateLocale)
    dayjs.updateLocale('en', {
        relativeTime: {
            future: "in %s",
            past: "%s",
            s: 'now',
            m: "1m",
            mm: "%dm",
            h: "1h",
            hh: "%dh",
            d: "1d",
            dd: "%dd",
            M: "1M",
            MM: "%dMs",
            y: "a year",
            yy: "%d years"
        }
    })
}
export const formatPrice = (_value) => {
    const MAX_DEC_PLACES = 2
    let value = _value.toString()
    let price = value.split('.')[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let decimal; //MAX_DEC_PLACES
    if (value.split('.').length > 1) {
        decimal = (value.split('.')[1] + '0').substring(0, MAX_DEC_PLACES).replace(/\D/g, "")
        price = price + '.' + decimal;
    }
    return '$' + price
}