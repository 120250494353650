import React, { useState, useEffect } from 'react'
import API from '../../API'
import Modal from '../Modal'
import Flexbox from '../Flexbox'
import emailIcon from '../../images/email-icon.svg'

// import { CheckboxStyle } from '../UploadFormSteps/UploadSongs/UploadSongs.styles'
import { OpenEmailButton, EmailIcon, NavLink, EmailLink, CheckboxStyle } from './StemsTransferModal.styles'

const Checkbox = ({ onCheck, userAgreement, userType, buyerEmail }) => {
    if (userType === 'BUYER')
        return (
            <CheckboxStyle ><input type="checkbox" onChange={onCheck} /><span></span>
                <div style={{ marginLeft: '5px' }}>I confirm that I have received the stems from the songwriter.</div>
            </CheckboxStyle>
        )
    else
        return (
            <CheckboxStyle ><input type="checkbox" onChange={onCheck} /><span></span>
                <div style={{ marginLeft: '5px' }}>I confirm that I have sent the stems to the buyer via the provided email address, {buyerEmail}.</div>
            </CheckboxStyle>
        )
}
const StemsTransferModal = ({ showModal, closePopup, selectedSong, currentUser }) => {
    console.log("selectedSong", selectedSong)
    const { saleDetails: { buyerName, buyerEmail, buyerLabel }, song_title } = selectedSong
    const [res, setRes] = useState({ msg: '', agreement: false, didSend: false })

    if (currentUser.userDetails === undefined || currentUser.userDetails.tier === undefined)
        return <></>
    const { userDetails: { tier } } = currentUser
    if (tier === 'BUYER')
        return (<Modal
            maxWidth="700px"
            fixed
            top="0"
            zIndex="10"
            width="700px"
            minWidth="min-content"
            closeModal={closePopup}
            showModal={showModal}
            title="Deliverables Transfer"
        >
            <Flexbox column gap="20px" start>
                <h3 style={{ fontSize: "16px" }}>Congratulations on the purchase of your song, '{selectedSong.song_title}'!</h3>
                <p style={{ fontSize: "13px", margin: '0' }}>
                    The songwriter will contact you from their listed email address, SONGWRITER_EMAIL_ADDRESS, to send you the stems to your listed email address below:</p>
                <NavLink>{buyerEmail}</NavLink>
                <p style={{ fontSize: "13px", margin: '0' }}>
                    The funds are held in escrow by Azaa until this exchange is complete.<br /><br />If the songwriter does not send you the within 5 days, funds ...."MESSAGE"....</p>
                <p style={{ fontSize: "13px", marginTop: '0' }}>After you confirm receipt of the stems, the funds will be released from escrow.</p>
                <Checkbox userType={tier} buyerEmail={buyerEmail} />
            </Flexbox>
        </Modal>)
    else {

        return (
            <Modal
                maxWidth="700px"
                fixed
                top="0"
                width="700px"
                minWidth="min-content"
                closeModal={closePopup}
                showModal={showModal}
                buttonText={res.didSend ? 'Close Modal' : 'Confirm'}
                onClick={ //song id 1561
                    res.didSend ? closePopup :
                        async () => {
                            if (res.agreement)
                                try {
                                    const _res = await API.confirmDeliverablesTransfer(currentUser, selectedSong.id, res.agreement === true ? 1 : 0)
                                    if (_res) {
                                        setRes(prev => ({
                                            ...prev,
                                            msg: 'Close',
                                            didSend: true
                                        }))
                                    }
                                } catch (error) {
                                    console.log("error", error)
                                    setRes(prev => ({
                                        ...prev,
                                        msg: 'An error occurred.'
                                    }))
                                }

                        }}
                        title="Deliverables Transfer"
            >
                <Flexbox column gap="20px" start>
                    <h3 style={{ fontSize: "16px" }}>Congratulations on the sale of your song, {selectedSong.song_title}!</h3>
                    <p style={{ fontSize: "13px", margin: '0' }}>
                        As the submitting songwriter, you are responsible for sending the stems to the buyer off platform, at their listed email address below:</p>

                    <Flexbox row gap="5px"><EmailLink
                    >{buyerEmail}</EmailLink>
                        <OpenEmailButton width="min-content" onClick={() => {
                            const subject = `Deliverables for your purchased song, %22${song_title}%22 on Azaa`.replaceAll(' ', '%20')
                            const emailBody = `Hey ${buyerName},\nI've attached the deliverables for %22${song_title}%22.\n`.replaceAll(' ', '%20').replaceAll(/"/g, '%22').replaceAll('\n', '%0D%0A')
                            window.open(`mailto:${buyerEmail}?subject=${subject}&body=${emailBody}`, '_self')
                        }}><EmailIcon src={emailIcon} />
                            {/* <span style={{whiteSpace: 'nowrap'}}>Open Email Draft</span> */}
                        </OpenEmailButton>
                    </Flexbox>
                    <p style={{ fontSize: "13px", margin: '0' }}>
                        The funds are held in escrow by Azaa until this exchange is complete.<br /><br />If you send the buyer the stems but they do not respond within 5 days, funds ...."MESSAGE"....</p>
                    <p style={{ fontSize: "13px", marginTop: '0' }}>After you and the buyer confirm the stems exchange, you and any contributors you listed for this song will receive payment.</p>
                    <Checkbox userType={tier} buyerEmail={buyerEmail} onCheck={() => {

                        setRes(prev => ({ ...prev, agreement: !res.agreement }))
                    }} />
                    {
                        res.msg.length > 0 && <p>Success!</p>
                    }
                </Flexbox>
            </Modal>
        )
    }
}

export default StemsTransferModal