import React, { useContext, useState, useEffect, useRef } from 'react'
import { auth } from '../firebase'
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    onIdTokenChanged,
    signInWithEmailAndPassword,
    EmailAuthProvider,
    reauthenticateWithCredential,
    sendPasswordResetEmail,
    updatePassword,
    updateEmail,
    signOut
} from "firebase/auth";

import API from '../API'

const AuthContext = React.createContext()

export const useAuth = () => {
    return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
    const pastLoginExists = window.localStorage.getItem("pastLogin") !== null && window.localStorage.getItem("pastLogin") !== undefined ? JSON.parse(window.localStorage.getItem("pastLogin")) : null;
    const [loadingUser, setLoadingUser] = useState(true)
    const [currentUser, setCurrentUser] = useState(null)
    const loginError = useRef(false)
    const skipReset = useRef(false)
    const responsePending = useRef(false)
    const isSignup = useRef(false)
    const [user, setUser] = useState(null)
    const signup = async (email, password) => {
        isSignup.current = true
        localStorage.setItem('pastLogin', JSON.stringify(true))
        return await createUserWithEmailAndPassword(auth, email, password)
    }
    const login = async (email, password) => {
        try {
            setLoadingUser(true)
            const res = await signInWithEmailAndPassword(auth, email, password)
            localStorage.setItem('pastLogin', JSON.stringify(true))
            return res
        } catch (error) {
            setLoadingUser(false)
            throw new Error(error.code)
        }

    }
    const sendResetPasswordEmail = (email) => {
        return sendPasswordResetEmail(auth, email)
    }
    const changePassword = async (password) => {
        return updatePassword(user, password)
    }
    const changeEmail = async (email) => {
        return updateEmail(user, email)
    }
    const logout = (email, password) => {
        return signOut(auth)
    }
    const reAuth = async (password) => {
        const { email } = currentUser;
        console.log("user", user, email)
        const credential = EmailAuthProvider.credential(email, password);
        return await reauthenticateWithCredential(user, credential);
    }
    const _retrieveUserDetails = async (email) => {
        try {
            const additionalDetails = await API.fetchUser(email)
            setCurrentUser(prev => ({ ...prev, userDetails: additionalDetails }))
            return additionalDetails;
            // .then((additionalDetails) => {
            //         setCurrentUser(prev => ({ ...prev, userDetails: additionalDetails }))
            //     })
        }
        catch (e) {
            console.log(e)
            return 'error'
        }
    }
    const setAccessToken = async (email, accessToken) => {
        try {
            setLoadingUser(true)
            const userDetails = await API.setAccessToken(email, accessToken)
            console.log("herehere")
            if (userDetails !== null)
                setCurrentUser(prev => ({ ...prev, userDetails: userDetails }))
            setLoadingUser(false)
        }
        catch (e) {
            console.log(e)
            setCurrentUser(null)
            setLoadingUser(false)
            setUser(null)
        }
    }
    const initUser = async (user) => {
        try {
            loginError.current = false
            const userDetails = await API.setAccessToken(user.email, user.accessToken)
            if (userDetails !== null)
                setCurrentUser(prev => ({ ...user, userDetails: userDetails }))
            setLoadingUser(false)
            setUser(user)
            isSignup.current = false
        }
        catch (e) {
            console.log("an error occurred", e)
            loginError.current = true
            isSignup.current = false
            responsePending.current = false
            setLoadingUser(false)
        }
    }
    useEffect(() => {
        const unsubscribe2 = onIdTokenChanged(auth, async (u) => {
            if (u && !skipReset.current && !isSignup.current) {
                // u is signed in or token was refreshed.
                if (!responsePending.current) {
                    console.log("token was refreshed")
                    responsePending.current = true
                    setLoadingUser(true)
                    await initUser(u)
                    responsePending.current = false
                }
            }
        })
        const unsubscribe = onAuthStateChanged(auth, async (u) => {
            if (!isSignup.current)
                if (u) {
                    if ((currentUser === null || currentUser.accessToken === undefined) && !responsePending.current) {
                        console.log("auth change occurred")
                        setLoadingUser(true)
                        await initUser(u)
                    }
                }
                else {
                    console.log("user has logged out")
                    setCurrentUser(null)
                    setLoadingUser(false)
                    setUser(null)
                }

        })
        return () => {
            unsubscribe()
            unsubscribe2()
        } // unsubscribe from listener on unmount
    }, [])
    //const value = { pastLoginExists, reAuth, currentUser, signup, login, logout, _retrieveUserDetails, sendResetPasswordEmail, changePassword }
    const value = { loginError, skipReset, initUser, setAccessToken, loadingUser, pastLoginExists, reAuth, currentUser, signup, login, logout, sendResetPasswordEmail, changePassword, changeEmail }
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

