import React, { useEffect, useState, useRef } from 'react'
import { timeElapsedFromCreatedAt, adjustPrice, calculateCut } from '../../../helpers'

import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import {
    OuterWrapper,
    Wrapper,
    PlayButtonContainer,
    NextUp,
    // NextUpArrow,
    AlertIcon
} from './DashBar.styles'

import clock from '../../../images/clockIcon.svg'
import expand from '../../../images/expandQueue.svg'
import Play from '../../../images/play.svg'
import Pause from '../../../images/pause.svg'
import NextUpIcon from '../../../images/next-up.svg'
import YellowAlertIcon from '../../../images/alertYellow.svg'
import BlackAlertIcon from '../../../images/alertBlack.svg'


import AcceptAndReject from '../../SetSplits/AcceptAndReject'
import Table from '../../Table'
import Flexbox from '../../Flexbox'

import DotMenu from '../DotMenu'
import PlayButton from '../../PlayButton'
import NextUpArrow from '../../NextUpArrow'


// const NextUpSvg = ({ newApproval }) => {
//     const fillColor = newApproval ? 'var(--yellow)' : '#ffffff'
//     return (
//         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.4 11.8" width="100%" height="100%">
//             <g style={{ fill: fillColor, strokeWidth: newApproval ? '5%' : '0', stroke: newApproval ? 'var(--yellow)' : '' }}>
//                 <polygon className="cls-1" points="0.71 6.91 0 6.2 6.2 0 12.4 6.2 11.69 6.91 6.2 1.41 0.71 6.91" />
//                 <polygon className="cls-1" points="0.71 11.8 0 11.09 6.2 4.89 12.4 11.09 11.69 11.8 6.2 6.31 0.71 11.8" />
//             </g>
//         </svg>)
// }

// const PlayButton = ({ onClick, playing, isCurrent, fill }) => {
//     if (playing && isCurrent) {
//         return (
//             <PlayButtonContainer onClick={onClick}>
//                 {/* <img src={Pause} alt="play-button" /> */}
//                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.34 50">
//                     <rect width="8.82" height="50" rx="1" style={{ fill: fill }} />
//                     <rect x="21.52" width="8.82" height="50" rx="1" style={{ fill: fill }} />
//                 </svg>
//             </PlayButtonContainer>
//         )
//     }
//     else {
//         return (
//             <PlayButtonContainer onClick={onClick}>
//                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.69 50.14">
//                     <path className="cls-1" d="M43.19,24.2,1.5.14A1,1,0,0,0,0,1V49.14A1,1,0,0,0,1.5,50L43.19,25.94A1,1,0,0,0,43.19,24.2Z" style={{ fill: fill }} />
//                 </svg>
//             </PlayButtonContainer>
//         )
//     }

// }

const ExpandIcon = ({ setMaxOpen, onClick, isOpen }) => {
    return (
        <Flexbox isOpen={isOpen} onClick={() => { setMaxOpen(prev => !prev) }} width="30px" height="100%" padding="0" justifyContent="center" alignItems="center">
            <img src={expand} alt="expand to full screen" style={{ width: "13px", height: '13px', objectFit: 'contain' }} />
        </Flexbox>)
}

const DashBar = ({ showControls, page, resultsPerPage, nbHits, fetchMore, newApproval, setShowModal, setSelectedSong, content, handleSelect, playing, nowPlaying, handleSongChange }) => {
    const [hideHeader, setHideHeader] = useState(true)
    const [maxOpen, setMaxOpen] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [hoverActive, setHoverActive] = useState(false)
    const infScrollRef = useRef(null)
    const indexNowPlaying = content.findIndex((c) => c['song_id'] === nowPlaying)
    const adjustScrollPositionOnClose = () => {
        if (infScrollRef.current && infScrollRef.current.el) {
            infScrollRef.current.el.scrollTop = (indexNowPlaying > 0 ? indexNowPlaying : 0) * 60
            // const currPosition = infScrollRef.current.el.scrollTop
            // console.log("infScrollRef.current.el.scrollTop", currPosition, currPosition + currPosition % 60, infScrollRef.current.el.scrollTopMax)
            // if (currPosition % 60 <= 30)
            //     infScrollRef.current.el.scrollTop = currPosition - currPosition % 60
            // else {
            //     const scrollGoal = 60 + currPosition - currPosition % 60
            //     if (scrollGoal > (infScrollRef.current.el.scrollTopMax - 60 ))
            //         infScrollRef.current.el.scrollTop = currPosition - currPosition % 60
            //     else
            //         infScrollRef.current.el.scrollTop = 60 + currPosition - currPosition % 60
            // }
        }
    }

    const tableContent = content.map((c) => (
        {
            contentID: c.song_id,
            rowHighlight:
                ((parseInt(c.status) === 2 && c.isPrimary) || c.actionRequired !== -1) && isOpen ? { background: 'var(--yellow)', textColor: '#000000', fontWeight: '600' } :
                    nowPlaying === c.song_id && isOpen ? { background: 'rgba(255,255,255,0.15)', textColor: '#ffffff', fontWeight: '400' }
                        : undefined,
            contentArr: [
                <PlayButton
                    fill={c.actionRequired !== -1 && isOpen ? '#000000' : '#ffffff'}
                    onClick={() => handleSongChange(c)} playing={playing} isCurrent={nowPlaying === c.song_id ? true : false} />,
                // <div style={{ border: content.is_new ? "1px solid white" : "", padding: '2px', fontSize: '10px' }}> {content.is_new ? "NEW" : ""} </div>,
                c.song_title,
                c.actionRequired !== -1 ? <>{c.status_text}<AlertIcon ><img src={BlackAlertIcon} /></AlertIcon></> : c.status_text,
                c.genres.map((genre, idx) => (idx > 0 ? ", " + genre : genre)),
                (parseInt(c.status) === 2 && c.contributors !== undefined) ? '-' : `${adjustPrice(c.price)}`,
                c.my_split_percent,
                c.my_split_value,
                dayjs.unix(c.created_at).fromNow(),
                // content.action,
                parseInt(c.status) !== 1 && <DotMenu
                    fill={c.actionRequired !== -1 && isOpen ? '#000000' : '#ffffff'}
                    setShowModal={setShowModal} setSelectedSong={setSelectedSong} song={c} hoverActive={hoverActive} status={c.status} playlistOpen={isOpen}></DotMenu>
            ]
        })
    )
    useEffect(() => {
        adjustScrollPositionOnClose()
    }, [nowPlaying])
    // const tableContent = content.map((content) => (
    //     {
    //         contentID: content.song_id,
    //         rowHighlight:
    //             ((parseInt(content.status) === 2 && content.isPrimary) || content.actionRequired !== -1) && isOpen ? { background: 'var(--yellow)', textColor: '#000000', fontWeight: '600' } :
    //                 nowPlaying === content.song_id && isOpen ? { background: 'rgba(255,255,255,0.15)', textColor: '#ffffff', fontWeight: '400' }
    //                     : undefined,
    //         contentArr: [
    //             <PlayButton
    //                 fill={content.actionRequired !== -1 && isOpen ? '#000000' : '#ffffff'}
    //                 onClick={() => handleSongChange(`${content.file_path}`, `${content.song_id}`)} playing={playing} isCurrent={nowPlaying === content.song_id ? true : false} />,
    //             // <div style={{ border: content.is_new ? "1px solid white" : "", padding: '2px', fontSize: '10px' }}> {content.is_new ? "NEW" : ""} </div>,
    //             content.song_title,
    //             content.actionRequired !== -1 ? <>{content.status_text}<AlertIcon ><img src={BlackAlertIcon} /></AlertIcon></> : content.status_text,
    //             content.genres.map((genre, idx) => (idx > 0 ? ", " + genre : genre)),
    //             (parseInt(content.status) === 2 && content.contributors !== undefined) ? '-' : `${adjustPrice(content.price)}`,
    //             content.my_split.includes('-') || (parseInt(content.status) === 2 && content.contributors !== undefined) ? '-' : `${content.my_split}%`,
    //             (parseInt(content.status) === 2 && content.contributors !== undefined) ? '-' : `${calculateCut(content.my_split, content.price)}`,
    //             dayjs.unix(content.created_at).fromNow(),
    //             // content.action,
    //             parseInt(content.status) !== 1 && <DotMenu
    //                 fill={content.actionRequired !== -1 && isOpen ? '#000000' : '#ffffff'}
    //                 setShowModal={setShowModal} setSelectedSong={setSelectedSong} song={content} hoverActive={hoverActive} status={content.status} playlistOpen={isOpen}></DotMenu>
    //         ]
    //     })
    // )
    const indexesToHideOffHover = [8]
    const rowAlignmentArray = ["center", "flex-start", "flex-end", "flex-end", "flex-end", "flex-end", "flex-end", "center", "center"];
    const offset = content.filter(c => c.song_id === nowPlaying) !== undefined && content.filter(c => c.song_id === nowPlaying).length > 0 ? (content.filter(c => c.song_id === nowPlaying)[0].index) * 60 + 'px' : 0;
    //
    return (
        <OuterWrapper
            maxOpen={maxOpen} showControls={showControls}
            isOpen={isOpen}>
            <Wrapper
                isOpen={isOpen}
            // offset={offset}
            >
                <Table
                    offset={page}
                    isOpen={isOpen}
                    maxOpen={maxOpen}
                    resultsPerPage={resultsPerPage}
                    nbHits={nbHits}
                    fetchData={fetchMore}
                    useInfScroll={true}
                    indexesToHideOffHover={indexesToHideOffHover}
                    scrollContainerPaddingBottom="100px"
                    expandedContainerHeight={isOpen ? "100%" : undefined}
                    rowHeight="60px"
                    rowWidth="100vw"
                    rowBorder="1px solid #000000"
                    setHoverActive={setHoverActive}
                    // handleSelect={handleSelect}
                    setShowModal={setShowModal}
                    noHover={!isOpen ? true : undefined}
                    headerFontSize="10px"
                    overflowX="hidden"
                    contentFontSize="12px"
                    content={tableContent}
                    scrollRef={infScrollRef}
                    hideHeader={!isOpen}
                    tableHeaderTextStyling={{ textTransform: 'uppercase' }}
                    tableHeader={["",
                        "Song",
                        "Status",
                        "Genre",
                        "Price",
                        "My Split [%]",
                        "My Split [$]",
                        <img src={clock} alt="clockIcon" style={{ width: "15px" }} />,
                        <ExpandIcon setMaxOpen={setMaxOpen} />
                        // ""
                    ]}
                    // gridTemplateColumns='calc(3.22vw - 20px) 2fr 5rem 1fr 5rem 5rem 5rem 2rem 8rem 30px' // with action row
                    gridTemplateColumns='calc(3.22vw - 20px) 2fr 1fr 5rem 5rem 5rem 5rem 2rem 30px'
                    rowAlignmentArray={rowAlignmentArray}
                    gridColGap="20px"
                    padding="0 calc(3.22vw - 15px ) 0 3.22vw"
                />

            </Wrapper>
            <NextUp onClick={() => {
                if (isOpen)
                    adjustScrollPositionOnClose()
                setIsOpen(!isOpen);
                setMaxOpen(false)
            }} newApproval={newApproval} isOpen={isOpen} maxOpen={maxOpen}>
                <div>Next Up</div>
                {/* <NextUpArrow isOpen={isOpen} src={NextUpIcon} alt="next-up" /> */}
                {/* <NextUpArrow >
                    <NextUpSvg newApproval={newApproval} />
                </NextUpArrow> */}
                <NextUpArrow isAlert={newApproval} isOpen={isOpen} maxOpen={maxOpen} />
                {newApproval && <AlertIcon position="absolute" right="-25px"><img src={YellowAlertIcon} /></AlertIcon>}
            </NextUp>

        </OuterWrapper>
    )
}

export default DashBar
