import { useEffect, useRef } from 'react';
import { _requiredAction } from '../helpers'

import API from '../API'
import { BUYER_PLAYLIST_TYPES } from '../config'
import { useAuth } from '../contexts/AuthContext'


export const useBuyerResponseHook = (args) => {
    const { currentUser } = useAuth()
    // const initialRender = useRef(true)
    const { playlist, initialRender, setPayload, setSongs, songBuffer, setSongBuffer, cleanupPlayingSong, songs, pullSongs, filter } = args

    const handleFavorites = async (song_id, action) => { //add or remove
        setPayload(songs.find(it => it.song_id === song_id))
        await API.editFavorites(currentUser, song_id)
        console.log("action is", action, filter.playlist)
        if (action === false && filter.playlist === 'Favorites') {
            const editedSongs = songs.filter(it => it.song_id !== song_id)
            cleanupPlayingSong()
            setSongs(editedSongs)
        }
        else if (action === true) {
            const editedSongs = songs.map(it => {
                if (it.song_id === song_id)
                    return { ...it, isFavorite: action }
                else return it
            })
            setSongs(editedSongs)
            if (playlist.bufferPlaylist === BUYER_PLAYLIST_TYPES.FAVORITES) {
                const _song = songs.find(it => it.song_id)
                setSongBuffer(prev => ([...prev, { ..._song, isFavorite: action }]))
            }
        }
    }

    useEffect(() => {
        if (initialRender.current) {

            return
        }
        const onFilterChange = async () => {
            console.log('onFilterChange')
            await pullSongs()
        }
        onFilterChange()
    }, [filter])



    return {
        handleFavorites
    };
}