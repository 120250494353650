import styled from "styled-components";

export const TableRowContainer = styled.tr`
/* display: table-row; */
font-size: ${props => props.fontSize ? props.fontSize : '12px'};
user-select: ${props => props.userSelect ? props.userSelect : 'none'};
position: ${props => props.position ? props.position : 'relative'};
top: ${props => props.top ? props.top : props.position === 'sticky' ? '0' : ''};
z-index: ${props => props.zIndex ? props.zIndex : ''};
transition: background 0.2s ease-out;
background: ${props => props.isDragging ? 'var(--yellow) !important' : props.background ? props.background : ''};
color: ${props => props.color ? props.color : props.isDragging ? '#000000 !important' : ''};
width: 100%;
border: ${props => props.border ? props.border : ''};
:not(.tableHeader):hover{
    background: ${props => props.hoverOff ? '' : 'rgba(255,255,255,0.15)'};
    
}

>div:nth-of-type(1), >div:nth-of-type(2){
padding-left: 0;
}
>div:nth-last-of-type(1){
    padding: 0;  
}


`;

export const TableRowHeaderContainer = styled.th`
/* display: table-row; */
font-size: ${props => props.fontSize ? props.fontSize : '12px'};
user-select: none;
position: ${props => props.position ? props.position : 'relative'};
top: ${props => props.top ? props.top : props.position === 'sticky' ? '0' : ''};
transition: background 0.2s ease-out;
background: ${props => props.background ? props.background : ''};
z-index: ${props => props.zIndex ? props.zIndex : ''};
color: ${props => props.color ? props.color : props.isDragging ? '#000000 !important' : ''};
width: 100%;
border: ${props => props.border ? props.border : ''};
>div:nth-of-type(1), >div:nth-of-type(2){
padding-left: 0;
}
>div:nth-last-of-type(1){
    padding: 0;  
}
`;

export const TableGroup = styled.div`
display: table-row-group;
`;
