import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
// Components
import HeroVideo from './HeroVideo';
import HomeBenefitsSection from './HomeBenefitsSection';
import HomeBenefitsSubsection from './HomeBenefitsSubsection';
import EmailSubscriptionSection from './EmailSubscriptionSection';
import HomeIntro from './HomeIntro';
import Footer from './Footer';
import Button from './Button';
import Dashboard from './Dashboard';
import BuyerDashboard from './BuyerDashboard';
import LoadingWheel from './LoadingWheel'
import Cassette from './Cassette';
import { useAuth } from '../contexts/AuthContext'

import { MEMBERSHIP_TIERS } from '../config'

import benefitsSectionContent from '../benefitsSection.js'

const LoadWrapper = styled.div`
width: 100vw;
z-index: 20;
height: 100vh;
display: flex;
top: 0;
flex-direction: column;
position: fixed;
align-items: center;
justify-content: center;

h1{
    margin: 0;
    font-weight: 400;
    font-size: 2em;
    text-transform: none;
}

p{
    /* margin-top: 0.5rem;
    font-size: 1.3em;
    font-weight: 300; */
    color: var(--purpleGrey);
    
}
`;

const Home = () => {
    const { currentUser, loadingUser, loginError } = useAuth();
    const navigate = useNavigate()


    if (loginError.current)
        return (<LoadWrapper>
            <h1>An error occurred while trying to log you in.</h1>
            <p>Please <span style={{ cursor: 'pointer', fontWeight: 'bold', color: 'var(--yellow)' }}
                onClick={() => navigate('/contact-us')}>contact us</span> if this error persists.</p>
            <Button
                width="200px"
                border="1px solid #ffffff"
                text="Home"
                onClick={() => {
                    loginError.current = false
                    navigate('/')
                }} />
        </LoadWrapper>)
    if (currentUser === null && loadingUser) {
        return <LoadWrapper><LoadingWheel height="100vh" width="100vw" top="0" text="Logging In..." /></LoadWrapper>
    }
    else if (currentUser !== null && currentUser.userDetails) {
        const { userDetails: { tier } } = currentUser
        return tier === MEMBERSHIP_TIERS.BUYER_TIER ? <BuyerDashboard /> : <Dashboard />
    }
    else return (
        <>
            <HeroVideo />
            <HomeIntro background='var(--greyBackground)' />
            <HomeBenefitsSection />
            <EmailSubscriptionSection></EmailSubscriptionSection>
            <Footer />
        </>
    )
}

export default Home;

