import React, { useEffect, useState } from 'react'

import { Action } from './DashIcon.styles'

const Favorite = ({ fill, hoverActive, isFavorited, isOpen, onClick }) => {
    const [favorite, setFavorite] = useState(isFavorited)

    useEffect(() => {
        setFavorite(isFavorited)
    }, [isFavorited])
    return (
        <Action isOpen={isOpen} onClick={async () => { const res = !favorite; setFavorite(res); await onClick(res); }} opacity={favorite || hoverActive ? '1' : '0.25'}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12.66" width="100%" height="100%">
                <g style={{ fill: fill }}>
                    <path className="d" d="M10.05,0c-1.2,0-2.31,.61-3.05,1.66-.74-1.05-1.85-1.66-3.05-1.66C1.77,0,0,2.06,0,4.58c0,1.42,.58,2.78,1.56,3.64l4.93,4.25c.15,.13,.34,.19,.52,.19,.19,0,.38-.06,.53-.19l5.32-4.69,.12-.13c.66-.84,1.02-1.93,1.02-3.07,0-2.52-1.77-4.58-3.95-4.58Zm2.06,6.91l-5.1,4.5L2.31,7.36c-.73-.64-1.17-1.68-1.17-2.78,0-1.89,1.26-3.43,2.81-3.43,.95,0,1.83,.58,2.35,1.56,.14,.25,.41,.41,.7,.41s.56-.16,.7-.41c.52-.98,1.4-1.56,2.35-1.56,1.55,0,2.81,1.54,2.81,3.43,0,.89-.28,1.73-.75,2.33Z" />
                </g>
                <g style={{ fill: favorite ? fill : 'none', stroke: favorite ? fill : 'none', strokeWidth: favorite ? '5%' : '0' }}>
                    <path className="d" d="M12.86,4.58c0,.89-.28,1.73-.75,2.33l-5.1,4.5L2.31,7.36c-.73-.64-1.17-1.68-1.17-2.78,0-1.89,1.26-3.43,2.81-3.43,.95,0,1.83,.58,2.35,1.56,.14,.25,.41,.41,.7,.41s.56-.16,.7-.41c.52-.98,1.4-1.56,2.35-1.56,1.55,0,2.81,1.54,2.81,3.43Z" />
                </g>
            </svg>
        </Action>)
}

const Cart = ({ fill }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.71 16.25">
            <g style={{ fill: fill }}>
                <path className="d" d="M3.49,13.92c-.64,0-1.16,.52-1.16,1.16s.52,1.16,1.16,1.16,1.16-.52,1.16-1.16-.52-1.16-1.16-1.16Z" />
                <path className="d" d="M9.32,13.92c-.64,0-1.16,.52-1.16,1.16s.52,1.16,1.16,1.16,1.16-.52,1.16-1.16-.52-1.16-1.16-1.16Z" />
                <path className="d" d="M8.48,11.01c.42,0,.82-.23,1.02-.6l1.11-2.01c-1.73-.08-3.21-1.13-3.89-2.63H2.45l-.55-1.16H0v1.16H1.16l2.1,4.42-.79,1.42c-.31,.56-.1,1.27,.46,1.59,.17,.09,.37,.14,.56,.14h6.99v-1.16H3.49l.64-1.16h4.34Z" />
                <path className="d" d="M10.82,0c-2.15,0-3.88,1.74-3.88,3.88,0,.69,.17,1.33,.49,1.89,.66,1.19,1.93,1.99,3.39,1.99,.05,0,.09,0,.14,0,2.08-.07,3.75-1.77,3.75-3.88s-1.74-3.88-3.88-3.88Zm2.44,4.53h-1.79v1.79h-1.29v-1.79h-1.79v-1.29h1.79V1.44h1.29v1.79h1.79v1.29Z" />

            </g>
        </svg>
    )
}

const Download = ({ fill }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.55 17">
            <g style={{ fill: fill }}>
                <path className="d" d="M11.77,6.76c-.29-.29-.77-.29-1.06,0l-3.68,3.68V.75c0-.41-.34-.75-.75-.75s-.75,.34-.75,.75V10.44L1.82,6.74c-.29-.29-.77-.29-1.06,0s-.29,.77,0,1.06l3.99,3.99c.42,.42,.97,.63,1.52,.63s1.1-.21,1.52-.63l3.97-3.97c.29-.29,.29-.77,0-1.06Z" />
                <g >
                    <path className="d" d="M11.7,16.08H.86c-.41,0-.75-.34-.75-.75s.34-.75,.75-.75H11.7c.41,0,.75,.34,.75,.75s-.34,.75-.75,.75Z" />
                </g>
            </g>
        </svg>
    )
}

const Alert = ({ fill }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.54 12.86">
            <g style={{ fill: fill }}>
                <path d="M13.54,12.86H1a1,1,0,0,1-.86-1.5L6.41.49a1,1,0,0,1,1.73,0l6.27,10.87A1,1,0,0,1,13.54,12.86Z" />
                <path d="M7.28,11.28a.73.73,0,0,1-.74-.75.73.73,0,1,1,.74.75ZM6.65,2.84H7.82l-.13,6H6.78Z" />
            </g>
        </svg>
    )
}

const DashIcon = ({ alert, favorite, cart, download, isFavorited, onClick, isOpen, spot, hoverActive, fill }) => {
    if (favorite)
        return <Favorite fill={fill} hoverActive={hoverActive} onClick={onClick} isOpen={isOpen} isFavorited={isFavorited} />
    else return (
        <Action isOpen={isOpen} onClick={onClick} opacity={hoverActive || (download && fill === '#000000') ? '1' : '0.25'} size={cart ? '25px' : undefined}>
            {
                cart && <Cart fill={fill} />
            }
            {
                download && <Download fill={fill} />
            }
            {
                alert && <Alert fill={fill} />
            }
        </Action>
    )
}

export default DashIcon