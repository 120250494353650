import styled from 'styled-components';

export const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    height: 100%;
    max-height: 250px;
    margin: 60px 0;
    user-select: none;
    
    
`;

export const Wrapper = styled.div`
    width: 100%;
    max-width: ${props => props.width ? props.width : '500px'};
    height: ${props => props.height ? props.height : '347px'};
    border: 2px solid rgba(255,255,255,0.25);
    outline: 2px dashed rgba(255,255,255,0.25);
    outline-offset: -10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.05rem;
    background: ${props => props.dragOverEventLive ? 'rgba(255,255,255,0.25)' : 'transparent'};

    button{
        visibility: ${props => props.dragOverEventLive ? 'hidden' : 'visible'};

        input {
            display: none;
        }
    }

`;

export const Title = styled.div`
    font-size: 2em;
    font-weight: 400;
`;

export const SubText = styled.div`
    
    text-transform: uppercase;
    font-size: var(--fontSmall);
    visibility: ${props => props.dragOverEventLive ? 'hidden' : 'visible'};
`;

export const Note = styled.div`
    color: var(--purpleGrey);
    font-weight: 500;
    font-size: var(--fontSmall);
`;

export const ButtonStyle = styled.div`
    height: ${props => props.height ? props.height : 'var(--buttonHeight)'};
    font-size: var(--buttonFontSize);
    color: ${props => props.color? props.color : 'var(--white)'};
    width: ${ ({width}) => width};
    border: ${ ({border}) => border};
    background: ${props => props.background};
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    :hover{
        background: ${props => props.hoverColor? props.hoverColor : 'rgba(255,255,255,0.15)'} ;

    }

    input{
        height: 0;
        width: 0;
        visibility: hidden;
    }
`;