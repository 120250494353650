import React from 'react'

import Agreed from '../../images/agreedIcon.svg'
import Disagreed from '../../images/close.svg'
import Table from '../Table'
import GridContainer from '../GridContainer'
import GridItem2 from '../GridItem2'
import { adjustPrice, calculateCut } from '../../helpers'
import dayjs from 'dayjs'
import { formatPrice } from '../../utils'
import {
    TableContainer,
    Email

} from './SongDetailsTable.styles'

const SongDetailsTable = ({ margin, selectedSong, border, boxShadow, borderBottom, padding }) => {
    console.log("selectedSongselectedSongselectedSong", selectedSong)
    const tableContent = selectedSong.contributors.map((content) => (
        {
            contentID: selectedSong.id,
            rowHighlight: undefined,
            contentArr: [
                <Email><div className="emailShortened">{content.email}</div><div className="tooltip" title="t">{content.email}</div></Email>,
                `${content.split}%`,
                `${calculateCut(content.split, selectedSong.price, selectedSong.showTrailing)}`,
                content.agreement !== null ? <><img src={content.agreement === '1' ? Agreed : Disagreed} alt={content.agreement === '1' ? "agreed" : "disagreed"} style={{ width: "15px" }} /></> : '-'
            ]
        })
    )
    const rowAlignmentArray = ["flex-start", "center", "center", "center"];
    return (
        <>
            {
                selectedSong['saleDetails'] &&
                <>
                    <h3>Sale Details</h3>
                    <GridContainer
                        itemsPerRow="2"
                        className="isTable"
                        gridTemp={{ columns: '1fr 1fr' }}
                        border="1px solid #8f8f8f"
                        fontSize="12px"
                        align="center"
                        alignContent="start"
                        nthHighlight="rgba(255,255,255,0.5)"
                        justify="start"
                        itemPadding="20px" >
                        <GridItem2 justify="start">Primary Songwriter</GridItem2>
                        <GridItem2 justify="end">{selectedSong['saleDetails'].primaryEmail}</GridItem2>
                        <GridItem2 justify="start">Buyer Email</GridItem2>
                        <GridItem2 justify="end">{selectedSong.buyerEmail}</GridItem2>
                        <GridItem2 justify="start">Buyer Name</GridItem2>
                        <GridItem2 justify="end">{selectedSong.buyerName}</GridItem2>
                        <GridItem2 justify="start">Buyer Label</GridItem2>
                        <GridItem2 justify="end">{selectedSong.buyerLabel}</GridItem2>
                        <GridItem2 justify="start">Price [Inc. Fee]</GridItem2>
                        <GridItem2 justify="end">{formatPrice(Math.round(selectedSong.priceWithFee * 100) / 100)}</GridItem2>
                        <GridItem2 justify="start">Date Sold</GridItem2>
                        <GridItem2 justify="end">{dayjs.unix(selectedSong.dateSold).format('M/D/YYYY')}</GridItem2>

                    </GridContainer>
                </>
            }
            {
                selectedSong['stemTransfers'] &&
                <>
                    <h3>Deliverables Transfer</h3>
                    <GridContainer
                        itemsPerRow="2"
                        className="isTable"
                        gridTemp={{ columns: '1fr 1fr' }}
                        border="1px solid #8f8f8f"
                        fontSize="12px"
                        align="center"
                        alignContent="start"
                        nthHighlight="rgba(255,255,255,0.5)"
                        justify="start"
                        itemPadding="20px" >
                        {
                            selectedSong.stemTransfers.map((user) => {
                                return (
                                    <>
                                        <GridItem2 justify="start">{user.email}</GridItem2>
                                        <GridItem2 justify="end"><img src={user.agreement === '1' ? Agreed : Disagreed} alt={user.agreement === '1' ? "agreed" : "disagreed"} style={{ width: "15px" }} /></GridItem2>
                                    </>
                                )
                            })
                        }

                    </GridContainer>
                </>

            }
            <TableContainer border={border} boxShadow={boxShadow} borderBottom={borderBottom} padding={padding} margin={margin}>
                <Table
                    content={tableContent}
                    noHover={true}
                    headerFontSize="10px"
                    contentFontSize="12px"
                    tableHeaderTextStyling={{ textTransform: 'uppercase', fontSize: '10px' }}
                    tableHeader={["Contributor", "Split [%]", "Split [$]", "Agreement"]}
                    gridTemplateColumns='1fr 45px 3.5rem 63px'
                    rowAlignmentArray={rowAlignmentArray}
                    gridColGap=".75rem"
                    padding="10px 0"
                />
            </TableContainer>

        </>
    )
}

export default SongDetailsTable
