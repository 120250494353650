import React, { useState, useEffect, useCallback, useRef } from 'react'

import { Wrapper, SubText, Note, Title, Content, ButtonStyle } from './DragAndDrop.styles'




const Button = ({ position, background, height, width, color, text, border, hoverColor, handleSongUpload }) => {
    const inputFile = useRef(null);
    const onButtonClick = (event) => {

        inputFile.current.click();
    }

    const handleFileSelection = (event) => {
        event.preventDefault();
        console.log(event.target.files)
        const files = event.target.files;
        handleSongUpload(files)
    }
    return (

        <ButtonStyle
            onClick={onButtonClick}
            background={background} height={height} width={width} color={color} border={border} hoverColor={hoverColor} >
            {text}
            <input type="file" ref={inputFile} onChange={handleFileSelection} />
        </ButtonStyle>
    )

}

const DragAndDrop = ({ membership, values, handleSongUpload, title, isRegistrationForm, subtext, height, width }) => {

    const titleText = title ? title : <>Drag & Drop<br />
        your songs here</>;


    const handleFileDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        console.log('Files dropped: ', files)
        handleSongUpload(files);
    }

    const [fileList, setFileList] = useState('');

    const [isVisible, setIsVisible] = useState(false);



    const onDragEnter = useCallback((e) => {
        setIsVisible(true);
        e.stopPropagation();
        e.preventDefault();
        return false;
    }, []);
    const onDragOver = useCallback((e) => {
        setIsVisible(true);
        e.preventDefault();
        e.stopPropagation();
        return false;
    }, []);
    const onDragLeave = useCallback((e) => {
        setIsVisible(false);
        e.stopPropagation();
        e.preventDefault();
        return false;
    }, []);
    const onDrop = useCallback((e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        console.log('Files dropped: ', files);
        handleSongUpload();
        // Upload files
        setIsVisible(false);
        return false;
    }, []);

    useEffect(() => {
        window.addEventListener('mouseup', onDragLeave);
        window.addEventListener('dragleave', onDragLeave);
        window.addEventListener('dragenter', onDragEnter);
        window.addEventListener('dragover', onDragOver);
        window.addEventListener('drop', handleFileDrop);
        return () => {
            window.removeEventListener('mouseup', onDragLeave);
            window.removeEventListener('dragleave', onDragLeave);
            window.removeEventListener('dragenter', onDragEnter);
            window.removeEventListener('dragover', onDragOver);
            window.removeEventListener('drop', handleFileDrop);
        };
    }, [onDragEnter, onDragLeave, onDragOver, handleFileDrop]);

    var note;

    let membershipNote = membership !== undefined ? membership : '';

    switch (membershipNote) {
        case 'ultra':
            note = <>Thanks for being an Ültra member.<br></br>
                Enjoy unlimited uploads.</>;
        default:
            note = <>You can submit one song with your application.</>
    }
    note = subtext !== undefined ? subtext : note;

    return (
        <Wrapper dragOverEventLive={isVisible} width={width} height={height}>
            <Content >
                <Title>{titleText}</Title>
                <SubText dragOverEventLive={isVisible}>or</SubText>
                <Button
                    width="198px"
                    background="transparent"
                    border="2px solid rgba(255,255,255,0.25)"
                    dragOverEventLive={isVisible}
                    text="Browse"
                    handleSongUpload={handleSongUpload}

                > </Button>
                <div style={{display: 'flex', flexDirection: 'column',rowGap: '5px'}}>
                    <SubText>MP3 & WAV formats accepted</SubText>
                    {/* <SubText>Max Upload Size: 50MB</SubText> */}
                    <Note>{note}</Note>
                </div>
            </Content>

        </Wrapper>
    )
}

export default DragAndDrop
