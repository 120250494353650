import React, { useState, useEffect, useRef } from 'react'
import { AlertNote, CloseButtonWrapper, Wrapper, WrapperOverlay, CloseButtonWrapperText, InnerWrapper } from './SetSplits.styles'
import {
    SongDetailsHeader,
    SongStatusNote,
    PricingTable
} from '../Dashboard/Dashboard.styles'
import Header from './Header'
import RecentlyApproved from './RecentlyApproved'
import StemsTransferModal from '../StemsTransferModal'
import SplitsAndPricing from './SplitsAndPricing'
import Success from './Success'
import { useAuth } from '../../contexts/AuthContext'
import { _requiredAction, validateEmail, calculatePriceWithFee, adjustPrice } from '../../helpers'
import { debounce } from '../../utils'
import LoadingWheel from '../LoadingWheel'
import Modal from '../Modal'
import SongDetailsTable from '../SongDetailsTable'


import {
    VALID_FILE_TYPES,
    MAX_SIZE,
    MAX_UPLOADS,
    AZAA_PLATFORM_FEE_PERCENT
} from '../../config'

import axios from 'axios'

import API from '../../API'
import AcceptAndReject from './AcceptAndReject'

const FeedbackModal = ({ setErrorMessage, errorMessage, showErrorMessage, setShowErrorMessage }) => {
    const onClick = () => {
        setShowErrorMessage(false);
    }

    return (
        <AlertNote showMessage={showErrorMessage}>
            <span>
                {errorMessage}
            </span>
            <button
                onClick={onClick}
            >&#10006;
            </button>
        </AlertNote>
    )
}


const SetSplits = () => {
    const modalType = useRef(null)
    const { currentUser } = useAuth();
    const [errorMessage, setErrorMessage] = useState('')
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const resultsPerPage = 5
    const totalResults = useRef(0);
    const pageRef = useRef(1)
    const maxPageResult = useRef(1);
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({})
    const initialFilterState = {
        genre: [],
        playlist: 'Top 100',
        tempo: ''
    }
    const [filter, setFilter] = useState(initialFilterState)

    const [showModal, setShowModal] = useState(false)
    const initialStepTitleState = {
        title: '',
        subText: ''
    }
    const initialState = {
        step: 0,
        stepTitle: initialStepTitleState,
        songTitle: '',
        recentlyApproved: [],
        tableData: [],
        history: {},
        selectedSong: {
            price: ''
        },
        actionRequired: 1
    }
    const [searchValue, setSearchValue] = useState(null)
    const [state, setState] = useState(initialState);
    const [buyerEmails, setBuyerEmails] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [popupVisible, setPopupVisibility] = useState(false);
    const fieldInFocus = useRef(null)
    const [action, setAction] = useState('');
    const [test, setTest] = useState(1);
    const newSongs = useRef([]);
    const { history } = state
    // const data = useMemo(() => {

    // },[])

    // useEffect(() => {
    //     if (validateEmail(value)) {
    //         const res = await API.checkIfUserExists(value)
    //         console.log("email res:", res)
    //     }

    //     return () => {

    //     }
    // }, [selectedSong.contributors])
    useEffect(() => {

        if (fieldInFocus.current !== null) {
            window.addEventListener('keyup', debounce(async (e) => {
                if (fieldInFocus.current.value && e.target === fieldInFocus.current) {
                    const _email = fieldInFocus.current.value
                    if (validateEmail(_email)) {
                        try {
                            const res = await API.checkIfUserExists(_email)
                            setEmailCheck(_email, res.user)
                        } catch (error) {

                        }

                    }
                }
            }, 2000));
        }
        return () => {
            window.removeEventListener('keyup', debounce);
        };
    }, [fieldInFocus.current])

    const submitSplits = async () => {
        setLoading(true)
        setSubmitted(false)
        try {
            const res = await API.setSplits(state.selectedSong, currentUser)
            setSubmitted(true)
            setLoading(false)
            setState(prev => ({ ...initialState, step: 2 }))
        }
        catch (err) {
            setError(true)
            setLoading(false)
        }
    }

    const hasContributorsAndPrice = (song) => {
        if (parseInt(song.status) === 1)
            return false
        if (song.price === null || song.price === undefined || song.price === '')
            return false
        if (song.contributors === undefined || song.contributors.length === 0)
            return false
        return true
    }


    const fetchMore = async (_page = page, _filter = filter, _sort = sort) => {
        console.log("awaiting")
        await pullSongs(_page, _filter, _sort, true)
    }

    const getSuggestions = async (_input, items, setItems, setInput) => {

        const filter_ = { filter }
        // const res = await API.searchAcceptedSongs(currentUser, filter_, _input)
        setSearchValue(_input)
        const [_songs, nbHits, resultsRevised] = await API.fetchSongs2(currentUser.accessToken, currentUser.email, page, resultsPerPage, filter_, _input)
        if (resultsRevised) {
            let res = resultsRevised.map(it => {
                return ({
                    innerText: it['song_title'],
                    value: it['song_title'],
                    onClick: () => {
                        if (!items.find(el => el.id !== it['song_title'])) {
                            setItems([{ value: it['song_title'], id: it['song_title'], match: it['song_id'] }])
                            // setItems([...items, it.name])
                        }
                        setInput('')
                    },
                    key: it['song_id']
                })
            })
            console.log("res", res)
            return res
        } else
            return []
    }
    const onSearchEnter = async (_val) => {
        console.log("entered search", _val, typeof (_val), Array.isArray(_val))
        if (Array.isArray(_val)) {
            setPage(1)
            if (pageRef)
                pageRef.current = 1
            await pullSongs(1, filter, sort, true, false,
                _val.length > 0 ? _val[0]['value'] : null,
                true)
        }

    }
    const pullSongs = async (_page = page, _filter = filter, _sort = sort, skipLoading = false, retainValues = false, searchValue = null, skipHistory = false) => {
        if (!skipLoading)
            setLoading(true)
        setError(false)
        try {
            if (history[`page-${_page}`] === undefined || skipHistory) {

                const [_songs, nbHits, resultsRevised] = await API.fetchSongs2(currentUser.accessToken, currentUser.email, _page, resultsPerPage, _filter, searchValue)
                totalResults.current = nbHits
                let _tableData = resultsRevised.map(item => {
                    const song = item
                    const _status_text = parseInt(song.status) !== 3 || song.contributors === undefined ? song.status_text
                        : `Needs approval from ${song.contributors.length - song.contributors.filter(it => parseInt(it.agreement) === 1).length} of ${song.contributors.length} contributors`
                    const required_action = _requiredAction(currentUser, song);//_requiredAction(parseInt(song.status), song.contributors !== undefined ? song.contributors.filter((c) => c.email === currentUser.email)[0].agreement : null);
                    return {
                        songTitle: song.song_title,
                        isPrimary: song.isPrimary,
                        song_id: song.id,
                        created_at: parseInt(song.status) !== 1 ? song.updated_at : song.created_at,
                        price: song.price !== null && song.price !== undefined && !(song.contributors !== undefined && parseInt(song.status) === 2) ? song.price : "-",
                        my_split: song.contributors !== undefined && parseInt(song.status) !== 2 ? song.contributors.filter((c) => c.email === currentUser.email)[0].split : "-",
                        status: song.status,
                        status_text: _status_text,
                        action: song.action,
                        is_new: parseInt(song.is_new),
                        actionRequired: song.actionRequired,
                        hasContributorsAndPrice: hasContributorsAndPrice(song)
                    }
                })
                // _tableData = _tableData.filter(it => parseInt(it.status) !== 4 && parseInt(it.status) !== 1)
                const _recentlyApproved = resultsRevised.map(it => {
                    const song = it
                    const required_action = _requiredAction(currentUser, song);//_requiredAction(parseInt(song.status), song.contributors !== undefined ? song.contributors.filter((c) => c.email === currentUser.email)[0].agreement : null);
                    return {
                        ...song
                        // , actionRequired: required_action.value
                    }
                })
                setState(prev => ({
                    ...prev,
                    recentlyApproved: _recentlyApproved,
                    tableData: _tableData,
                    history: { ...prev.history, [`page-${_page}`]: { tableData: _tableData, recentlyApproved: _recentlyApproved } }
                }))
                if (!skipLoading)
                    setLoading(false)

                newSongs.current = resultsRevised.filter(it => it.is_new === "1")
            } else {

                setState(prev => ({
                    ...prev,
                    tableData: history[`page-${_page}`].tableData,
                    recentlyApproved: history[`page-${_page}`].recentlyApproved,
                }))
                if (!skipLoading)
                    setLoading(false)

            }

            setError(false)

        } catch (error) {
            console.error(error)
            setError(true)
        }

    }

    useEffect(() => {


        const updateNewSongTags = async () => {
            try {

                if (newSongs.current.length > 0) {
                    const res = await API.updateNewSongTags(newSongs.current)
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        console.log("currentUser", currentUser)
        if (currentUser.userDetails !== undefined)
            pullSongs()
        return () => { updateNewSongTags(); }
    }, [currentUser])

    const { step, stepTitle, songTitle, recentlyApproved, tableData, selectedSong, actionRequired } = state;

    useEffect(() => {
        if (step === 1) {
            setState(prev => ({ ...prev, stepTitle: { title: prev.selectedSong.song_title, subText: 'Splits & Pricing' } }))
        }
        else {
            setState(prev => ({ ...prev, stepTitle: { title: 'Recently Approved', subText: 'Please submit responses, song splits and pricing within 5 days.' } }))
        }
    }, [step])


    const handleSelect = (input, isSecondary = null, _modalType = 'action', event) => {
        modalType.current = _modalType
        if (!event.target.classList.contains('row') && (isSecondary === null))
            return
        const condition = (a, b) => {

            return a.actionRequired === 1 ? b + 1 : b
        }
        const selectedSong = setSelectedSong(input, condition)
        setPopupVisibility(true);

    }

    const setEmailCheck = (email, res) => {
        setState(prev => (
            {
                ...prev,
                selectedSong: {
                    ...prev.selectedSong,
                    contributors: [
                        ...prev.selectedSong.contributors.map(it => {
                            if (it.email === email)
                                return { ...it, emailCheck: { ...res } }
                            else
                                return it
                        })
                    ]
                }
            }))
    }

    // const setField = (email, stateField, emailCheckField) => {
    //     setState(prev => (
    //         {
    //             ...prev,
    //             selectedSong: {
    //                 ...prev.selectedSong,
    //                 contributors: [
    //                     ...prev.selectedSong.contributors.map(it => {
    //                         if (it.email === email)
    //                             return { ...it, emailCheck: {...res} }
    //                         else
    //                             return it
    //                     })
    //                 ]
    //             }
    //         }))
    // }

    const setSelectedSong = (input, condition = null) => {

        const selectedSong = state.recentlyApproved.filter(song => (song.id === input))[0]
        const _initialPrice = selectedSong.price !== undefined && selectedSong.price !== null && selectedSong.price !== '' && typeof parseFloat(selectedSong.price.replace(/,/g, '')) === 'number' ? selectedSong.price : ''
        setState(prev => ({
            ...prev,
            selectedSong: {
                ...selectedSong,
                stems: [],
                isPrimary: selectedSong.isPrimary,
                contributors: selectedSong.contributors !== undefined ? selectedSong.contributors : [{
                    first_name: currentUser ? currentUser.userDetails !== undefined ? currentUser.userDetails.first_name : "" : "",
                    last_name: currentUser !== undefined ? currentUser.userDetails !== undefined ? currentUser.userDetails.last_name : "" : "",
                    email: currentUser ? currentUser.email : "",
                    split: '',
                    emailCheck: false
                }],
                contributors_initial: selectedSong.contributors !== undefined ? selectedSong.contributors : null,
                price: _initialPrice,
                price_initial: _initialPrice,
                showTrailing: selectedSong.contributors === undefined ? false
                    : selectedSong.price === null || selectedSong.price === undefined || selectedSong.contributors.length === 0 ? false
                        : parseFloat(selectedSong.price.replace(/,/g, '')) % 1 ? true
                            : (parseFloat(selectedSong.price.replace(/,/g, '')) * (AZAA_PLATFORM_FEE_PERCENT) / 100.00) % 1 ? true
                                : selectedSong.contributors.map(c => parseFloat(selectedSong.price.replace(/,/g, '')) * parseFloat(c.split) / 100.00).reduce((a, b) => { return b % 1 ? true : a }, false)
            },
            step: typeof (condition) !== 'function' ? prev.step : condition(selectedSong, prev.step),
            actionRequired: selectedSong.actionRequired
        }))
        return selectedSong

    }

    const resetSplits = (input) => {
        const condition = (selectedSong, currentStep) => {
            return selectedSong.actionRequired === 1 || (selectedSong.isPrimary && parseInt(selectedSong.status) === 3) ? currentStep + 1 : currentStep
        }
        const selectedSong = setSelectedSong(input, condition)
        if (selectedSong.actionRequired === 2) {
            setPopupVisibility(true);
        }
    }

    const addContributor = () => {
        const newContributor = {
            first_name: '',
            last_name: '',
            email: '',
            split: ''
        }
        setState(prev => ({
            ...prev,
            selectedSong:
            {
                ...prev.selectedSong,
                contributors: [...prev.selectedSong.contributors, newContributor]
            }
        }))
    }
    const removeContributor = (input) => {
        const newContributorList = selectedSong.contributors.filter((item, index) => index !== input)
        console.log("new list: " + JSON.stringify(newContributorList))
        setState(prev => ({
            ...prev,
            selectedSong: {
                ...prev.selectedSong,
                contributors: prev.selectedSong.contributors.filter((item, index) => (index !== input))
            }
        }))
    }

    const handleFieldChange = (input) => (event) => {
        let value;
        if (event.target.value !== undefined) {
            value = event.target.value;
        } else {
            value = event.target.attributes.value.value;
        }
        if (input === 'price') {
            console.log(event.target.selectionStart, event.target.attributes, event.target)
            let price = value.split('.')[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            let decimal;
            if (value.split('.').length > 1) {
                //decimal = value.split('.')[1] + '0';
                decimal = value.split('.')[1].substring(0, 2)
                price = price + '.' + decimal;
            }
            setState(prev => ({
                ...prev,
                selectedSong: { ...prev.selectedSong, [input]: price }
            }))
        }
    }
    const handleCollabFieldChange = (id, input) => async (event) => {
        console.log("event.target", event.target)
        let value;
        if (event.target.value !== undefined) {
            value = event.target.value;
        } else {
            value = event.target.attributes.value.value;
        }
        if (input === 'split') {
            value = value.replace(/\D/g, '')
        }
        else if (input === 'email') {
            value = value.replace(/\s/g, '')
            if (fieldInFocus.current !== event.target && validateEmail(value))
                fieldInFocus.current = event.target
        }
        setState(prev => ({
            ...prev,
            selectedSong: {
                ...prev.selectedSong,
                contributors: prev.selectedSong.contributors.map((el, idx) => {
                    if (idx === id) {
                        return { ...el, [input]: value }
                    }
                    else {
                        return el
                    }
                })
            }
        }))
    }

    const priceOnBlur = (event) => {
        console.log("true", event.target)
        let value;
        if (parseFloat(event.target.value) !== 0 && event.target.value !== '') {
            if (event.target.value.split('.').length < 2) {
                value = event.target.value + '.00';

            }
            else {
                value = event.target.value.split('.')[0] + '.' + (event.target.value.split('.')[1] + '0').substring(0, 2)
            }
            setState(prev => ({
                ...prev,
                selectedSong: { ...prev.selectedSong, price: value }
            }))

        }
        else {
            setState(prev => ({
                ...prev,
                selectedSong: { ...prev.selectedSong, price: '' }
            }))
        }
    }

    const handleClose = () => {
        setState(prev => (
            {
                ...prev,
                step: 0,
                selectedSong: {}
            }))
        if (currentUser.userDetails !== undefined) {
            pullSongs()
            setSubmitted(false)
        }
    }

    const nextStep = async () => {
        if (validateStep(step)) {
            setBuyerEmails([])
            if (step === 1) {
                try {

                    const res = await API.validateContributorEmailsAreNotBuyers(currentUser, selectedSong.contributors.map(c => (c.email)))
                    setState(prev => ({ ...prev, step: prev.step + 1 }))
                } catch (error) {
                    if (error.response.status === 406) {
                        const { response: { data: { message } } } = error
                        const _toChange = message.join(', ')
                        setBuyerEmails([...message])
                        setErrorMessage("Email(s) provided include Azaa Buyer account members. Buyer accounts do not include access to songwriter features. Please provide a different email address for this contributor.")
                        setShowErrorMessage(true)
                    }
                }
            }
        }
    }
    const prevStep = () => {
        if (step !== 0) {
            setState(prev => ({ ...prev, step: prev.step - 1 }))
        }
    }

    const validateStep = (step) => {

        if (step === 1) {
            if (selectedSong.price === '' || selectedSong.price === null || selectedSong.price === undefined || Number(selectedSong.price) <= 0) {
                setErrorMessage("You must set a price for this song.")
                setShowErrorMessage(true)
                return false
            }
            let splitTotal = 0;
            console.log(selectedSong.contributors.map(el => el.split))
            selectedSong.contributors.map(el => el.split).forEach(item => {
                if (item !== "") splitTotal += parseInt(item)
            })
            console.log(splitTotal, "split total is")
            const contributorValues = !selectedSong.contributors.map(collab => {
                console.log("contributor is: ", collab)
                if (["", 20].some(function (val) {
                    return Object.values(collab).indexOf(val) >= 0
                })) {
                    setErrorMessage("Please complete all fields.")
                    setShowErrorMessage(true)
                    return false
                }
                if (!validateEmail(collab.email)) {
                    setErrorMessage("Invalid contributor email.")
                    setShowErrorMessage(true)
                    return false
                }

            }).includes(false);
            const contributors = selectedSong.contributors.map(c => ({ email: c.email, split: c.split }))
            const contributors_initial = selectedSong.contributors_initial === null ? null : selectedSong.contributors_initial.map(c => ({ email: c.email, split: c.split }))

            if (!contributorValues) {
                setShowErrorMessage(true)
                return false
            } else if (splitTotal !== 100) {
                setErrorMessage("Splits should add up to 100.")
                setShowErrorMessage(true)
                return false
            } else if (buyerEmails.length > 0 && contributors.reduce((a, b) => buyerEmails.includes(b.email) ? true : a, false)) {
                setErrorMessage("Email(s) provided include Azaa Buyer account members. Buyer accounts do not include access to songwriter features. Please provide a different email address for this contributor.")
                setShowErrorMessage(true)
                return false
            } else if (contributors_initial !== null) {
                const keys_diff = contributors_initial.reduce((p, c, idx) => {
                    return Object.keys(c).filter(key => {
                        if (contributors[idx] === undefined)
                            return true
                        return c[key] !== contributors[idx][key]
                    }).length > 0 ? true : p
                }, false)
                if (!keys_diff && selectedSong.price === selectedSong.price_initial) {
                    setErrorMessage("Please update your splits. These match the terms previously submitted.")
                    setShowErrorMessage(true)
                    return false
                } else {
                    setShowErrorMessage(false)
                    return true;
                }
            } else {
                setShowErrorMessage(false)
                return true
            }
        }
        return false
    }
    const closePopup = () => {
        setPopupVisibility(false);
        setAction('')
    }
    const submitContributorResponse = async () => {
        // terms axios call
        try {
            setLoading(true)
            const res = await API.submitContributorResponse(currentUser.accessToken, currentUser.email, selectedSong.id, action).then(() => { pullSongs() })
            console.log(res)
        }
        catch (err) {
            console.log(error);
            setError(true)

        }
        setLoading(false)
        closePopup()
    }
    const handleAssignment = (input) => (event) => {
        //setPopupVisibility(true)
        event.preventDefault()
        console.log("selectedSong", selectedSong)
        setAction(input)

    }
    console.log("selectedSong",selectedSong)

    const pageNavProps = { onSearchEnter, getSuggestions, pageRef, resultsPerPage, totalResults, page, setPage, totalResults: totalResults.current, fetchMore }
    if (loading && step === 0) {
        return (
            <Wrapper step={step}>
                <Header
                    step={step}
                    stepTitle={stepTitle}
                    pageNavProps={pageNavProps} />
                <InnerWrapper>
                    <LoadingWheel />
                </InnerWrapper>

            </Wrapper>)
    } else {
        switch (step) {
            case 0:
                return (
                    <>
                        <Wrapper step={step}>
                            <Header
                                step={step}
                                stepTitle={stepTitle}
                                pageNavProps={pageNavProps} />
                            <RecentlyApproved content={tableData} handleSelect={handleSelect} setSelectedSong={setSelectedSong} resetSplits={resetSplits} setShowModal={setShowModal} showModal={showModal} />
                            {(actionRequired === 2 && modalType.current !== 'details') && <AcceptAndReject
                                submitContributorResponse={submitContributorResponse}
                                popupVisible={popupVisible}
                                selectedSong={selectedSong}
                                action={action}
                                setAction={setAction}
                                handleAssignment={handleAssignment}
                                closePopup={closePopup}
                                songID={selectedSong ? selectedSong.id : null} />}
                            {
                                ((actionRequired === 3 && modalType.current !== 'details') && popupVisible) &&
                                <StemsTransferModal selectedSong={selectedSong}
                                    showModal={popupVisible} closePopup={closePopup} currentUser={currentUser} />
                            }

                        </Wrapper>
                        {
                            ((actionRequired !== 3 || modalType.current === 'details') && selectedSong != null && popupVisible && hasContributorsAndPrice(selectedSong)) &&
                            <Modal
                                showModal={showModal}
                                title="Song Details"
                                subtext={<SongDetailsHeader >
                                    <div style={{ textTransform: 'none' }}>{selectedSong.song_title}<SongStatusNote>{selectedSong.status_text}</SongStatusNote></div>
                                    {(hasContributorsAndPrice(selectedSong)) &&

                                        <div style={{ textAlign: 'right' }}>
                                            {`${adjustPrice(selectedSong.price)}`}
                                            <SongStatusNote textalign="right">Song Price</SongStatusNote>
                                        </div>
                                    }
                                </SongDetailsHeader>}
                                fixed
                                top="0"
                                overflow="auto"
                                modalPosition="relative"
                                closeModal={closePopup}>
                                <SongDetailsTable padding="0" margin="0" border="undefined" borderBottom="none" selectedSong={selectedSong} />
                                <PricingTable>
                                    <div>Song Price</div><div>{`${adjustPrice(selectedSong.price, selectedSong.showTrailing)}`}</div>
                                    <div>+ 15% Azaa Platform Fee</div><div> + {calculatePriceWithFee(selectedSong.price, selectedSong.showTrailing).fee}</div>
                                    <div>Listing Price</div><div>{calculatePriceWithFee(selectedSong.price, selectedSong.showTrailing).total}</div>
                                </PricingTable>
                                <div style={{ fontWeight: '500', color: 'var(--purpleGrey)', marginTop: '5px', fontStyle: 'italic', fontSize: '10px', display: 'flex' }}>
                                    <span>Note: Azaa will <span style={{ textDecoration: 'underline' }}>add</span> a 15% commision to your listing price before it goes live so you can keep your full asking price.</span>
                                </div>
                            </Modal>
                        }
                    </>
                )
            case 1:
                return (
                    <WrapperOverlay>

                        <Wrapper>
                            <Header
                                step={step}
                                stepTitle={stepTitle}
                                handleClose={handleClose}
                                nextStep={nextStep}
                                nextStepText="Submit"
                                prevStep={prevStep}
                                //prevStepText="Upload Stems"
                                prevStepText="Recently Approved"
                            />
                            <SplitsAndPricing
                                fieldInFocus={fieldInFocus}
                                buyerEmails={buyerEmails}
                                values={selectedSong}
                                removeContributor={removeContributor}
                                addContributor={addContributor}
                                priceOnBlur={priceOnBlur}
                                handleCollabFieldChange={handleCollabFieldChange}
                                validateStep={validateStep}
                                handleChange={handleFieldChange} />
                        </Wrapper>
                        <FeedbackModal setErrorMessage={setErrorMessage} errorMessage={errorMessage} setShowErrorMessage={setShowErrorMessage} showErrorMessage={showErrorMessage} />
                    </WrapperOverlay>
                )
            case 2:
                return <Success handleClose={handleClose} onSubmit={submitSplits} values={{ state, loading, error, submitted }} />
            default:
                return (<Wrapper></Wrapper>)
        }
    }


}

export default SetSplits
