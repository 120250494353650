import React from 'react'
import instagramIcon from '../../images/footerInstagramIcon.svg';
import emailIcon from '../../images/footerEmailUsIcon.svg';

import {
    Wrapper,
    LinksContainer,
    NavLink,
    PhoneNumber,
    Copyright,
    Content
} from './Footer.styles'


const Footer = () => {
    return (
        <Wrapper>
            <Content>
                <LinksContainer width="100px">
                    <a href="https://www.instagram.com/azaamusic/" target="_blank"><img src={instagramIcon} /></a>
                    <a href="mailto:info@azaamusic.com"><img src={emailIcon} /></a>
                </LinksContainer>

                <LinksContainer
                    // width="300px"
                    marginTop="20px"
                    textLinks
                >
                    <NavLink to="/about">About</NavLink>
                    <NavLink to="/contact-us">Contact Us</NavLink>
                    <NavLink to="/faq">FAQ</NavLink>
                    <NavLink to="/terms">Terms</NavLink>
                    <NavLink to="/privacy">Privacy</NavLink>
                </LinksContainer>
                <PhoneNumber></PhoneNumber>
                <Copyright>© 2021 Azaa Music Group LLC</Copyright>
            </Content>
        </Wrapper>
    )
}

export default Footer
