import styled from "styled-components";

export const Wrapper = styled.div`
width: 100vw;
height: 100vh;
display: flex;
flex-direction: column;
position: relative;
align-items: center;
justify-content: center;
overflow: hidden;

`;

export const ControlsWrapper = styled.div`
display: grid;
width: 100%;
opacity: ${props => props.setOpacity ? props.setOpacity : '1'};
transition: ${props => props.transition ? props.transition : ''};
position: absolute;
justify-items: center;
align-items: center;
align-content: center;
grid-template-columns: 3.22vw min-content 1fr 50px 1fr min-content 3.22vw;
grid-template-areas: '. last . playPause . next .';
`;

export const SkipWrapper = styled.div`
    height: 35px;
    grid-area: ${props => props.gridArea ? props.gridArea : ''};
    transform: ${props => props.direction && props.direction === 'last' ? 'rotate(180deg)' : ''};
    opacity: ${props => props.opacity ? props.opacity : '1'};
    width: 35px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        height: 100%;
        width: 100%;
    }
`;

export const GridItem = styled.div`
grid-area: ${props => props.gridArea ? props.gridArea : ''};
`;

export const MusicToggleButton = styled.img`
grid-area: playPause;
max-height: 50px;
height: 3vw;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
-webkit-filter: drop-shadow( 0 0 5px rgba(0, 0, 0, .7));
  filter: drop-shadow( 0 0 5px rgba(0, 0, 0, .7));
`;

export const LogoWrapper = styled.div`
position: absolute;
top: 60px;
height: 100px;
width: 100px;
img{
    height: 100%;
    width: 100%;
}
`;
export const SongDetailsHeader = styled.div`
text-transform: uppercase;
user-select: none;
display: flex;
padding: 0;
justify-content: space-between;
letter-spacing: 0.10rem;
font-weight: 600;
padding-bottom: 10px;
/* border-bottom: 1px solid #ffffff; */

`;

export const SongStatusNote = styled.div`
text-transform: uppercase;
margin-top: 0.7rem;
height: 1rem;
color: var(--purpleGrey);
width: 100%;
position: relative;
font-size: 10px;
text-align: ${props=> props.textalign ? props.textalign : 'left'};
`;

export const PricingTable = styled.div`
font-size: 12px;
width: 100%;
display: grid;

grid-column-gap: .75rem;
grid-template-columns: 1fr 3.5rem 63px;
grid-auto-rows: min-content;
border: 1px solid #2f2f2f;

>div{
    white-space: nowrap;
    text-align: right;
    height: 30px;
    display: flex;
    align-items: center;
    color: var(--purpleGrey);
    /* padding: 5px; */
    /* padding: 1px 0; */
}

>div:nth-of-type(2n + 1){
    /* padding: 5px;// 5px 0 5px; */

    /* letter-spacing: 0.03rem; */
    font-size: 12px;
    padding-left: 20px;
    grid-column: 1 / span 1;
    
    font-weight: 600;
    justify-content: flex-end;
}
>div:nth-of-type(2n){
    font-weight: 500;
    /* padding-right: 20px; */
    grid-column: 2 / span 1;
    /* letter-spacing: .1rem; */
    /* padding: 5px 25px 5px; */
    justify-content: center;
    
    
}
/* &:last-child{
    border-bottom: 0;
} */
/* >div:nth-of-type(4), >div:nth-of-type(3){
    border-bottom: 2px solid var(--greyBackground);
} */
`;

export const Bars = styled.div`
    display: none;
    color: #fff;

    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, -75%);
        
        cursor: pointer;
    }
`