import React, { useState, useEffect, useRef } from 'react'

import Accept from '../../../images/acceptIcon.svg'
import ContributorResponse from '../../../images/agreedIcon.svg'
import Reject from '../../../images/rejectIcon.svg'
import Close from '../../../images/close.svg'

import {
    Wrapper,
    AcceptButtonContainer,
    RejectButtonContainer,
    PopupWrapperOverlay,
    PopupWrapper,
    Subtext,
    ButtonContainer,
    ValueHighlighted,
    CloseButtonContainer,
    Strong,
    Blurb,
    PricingTable,
    Title,
    TableContainer,
    SubTitle,
    ConfirmResponse,
    Row

} from './AcceptAndReject.styles'

import Button from '../../Button'
import Table from '../Table'

import { adjustPrice, calculateCut, calculatePriceWithFee } from '../../../helpers'

import { useAuth } from '../../../contexts/AuthContext'

import { ACCEPT, REJECT } from '../../../config'

const AcceptButton = ({ onClick }) => (
    <AcceptButtonContainer onClick={onClick}>
        <img src={Accept} alt="accept-button" />
    </AcceptButtonContainer>
)

const RejectButton = ({ onClick }) => (
    <RejectButtonContainer onClick={onClick}>
        <img src={Reject} alt="reject-button" />
    </RejectButtonContainer>
)

const ClosePopup = ({ closePopup }) => (
    <CloseButtonContainer >
        <img src={Close} alt="close-popup" onClick={closePopup} />
    </CloseButtonContainer>
)
const Confirmation = ({ currentUser, action, visible, type, value, closePopup, submitContributorResponse, selectedSong, curr_contributor_cut, current_contributor }) => {
    const subtext = ` the proposed sale terms for ${selectedSong.song_title}.`;
    return (
        <PopupWrapper visible={visible}>
            <Row>
                <Title>{action === '' ? 'Review & Respond' : 'Confirm Response'}</Title>
                <ClosePopup closePopup={closePopup} />
            </Row>
            <Blurb>
                Please confirm that you would like to <Strong action={action === ACCEPT ? 'accept' : 'reject'}>{action === ACCEPT ? 'accept' : 'reject'}</Strong>{subtext}
            </Blurb>
            {action === ACCEPT && <ConfirmResponse>
                I, {(currentUser.userDetails.first_name + ' ' + currentUser.userDetails.last_name).replace(/\b\w/g, l => l.toUpperCase())}, accept that upon the sale of {selectedSong.song_title}, I will be paid {current_contributor.split}% of the total sale price of {`$${selectedSong.price}`}, amounting to {curr_contributor_cut}.<br />
            </ConfirmResponse>}
            {action === REJECT && <div>
                I, {(currentUser.userDetails.first_name + ' ' + currentUser.userDetails.last_name).replace(/\b\w/g, l => l.toUpperCase())}, reject the proposed terms.
                <br />I understand that rejecting these terms will require that the submitting artist change them, after which I will be prompted to review & respond to the newly set terms.<br />
            </div>}
            <Subtext>
                {/* <ValueHighlighted>{value}</ValueHighlighted> */}
            </Subtext>
            <ButtonContainer items={1}>
                <Button text={action === ACCEPT ? 'accept' : 'reject'} width="100%"
                    onClick={submitContributorResponse}
                    background="transparent" border="1px solid white" />
            </ButtonContainer>
        </PopupWrapper>

    )
}

const Content = ({ action, visible, closePopup, selectedSong, handleAssignment, curr_contributor_cut, current_contributor, }) => {
    const tableContent = selectedSong.contributors.map((content) => (
        {
            contentID: selectedSong.id,
            rowHighlight: current_contributor.email === content.email ? { background: 'var(--yellow)', textColor: '#000000', fontWeight: '600' } : undefined,
            contentArr: [
                content.email,
                `${content.split}%`,
                `${calculateCut(content.split, selectedSong.price, selectedSong.showTrailing)}`,
                content.agreement !== null ? <><img src={ContributorResponse} alt="agreed" style={{ width: "15px" }} /></> : '-'
            ]
        })
    )
    console.log("table content", tableContent)
    const rowAlignmentArray = ["flex-start", "center", "flex-end", "center"];
    return (
        <PopupWrapper visible={visible}>
            <Row>
                <Title>{action === '' ? 'Review & Respond' : 'Confirm Response'}</Title>
                <ClosePopup closePopup={closePopup} />
            </Row>
            <SubTitle>{selectedSong.primary_email} listed you as a contributor!</SubTitle>
            <Blurb>
                <Strong style={{textTransform: 'none'}}>'{selectedSong.song_title}'</Strong> will be listed with the following splits.
            </Blurb>
            <div>
                <TableContainer>
                    <Table
                        content={tableContent}
                        noHover={true}
                        tableHeaderTextStyling={{ textTransform: 'uppercase' }}
                        tableHeader={["Contributor", "Split [%]", "Split [$]", "Agreement"]}
                        gridTemplateColumns='1fr 5rem 5rem 5rem'
                        rowAlignmentArray={rowAlignmentArray}
                        gridColGap="20px"
                        padding="10px 20px"
                    />
                </TableContainer>
                <PricingTable>
                    <div>Song Price</div><div>{`${adjustPrice(selectedSong.price, selectedSong.showTrailing)}`}</div>
                    <div>+ 15% Azaa Platform Fee</div><div> + {calculatePriceWithFee(selectedSong.price, selectedSong.showTrailing).fee}</div>
                    <div>Listing Price</div><div>{calculatePriceWithFee(selectedSong.price, selectedSong.showTrailing).total}</div>
                </PricingTable>
                <div style={{ fontWeight: '500', color: 'var(--purpleGrey)', marginTop: '5px', fontStyle: 'italic', fontSize: '10px', display: 'flex' }}>
                    <span>Note: Azaa will <span style={{ textDecoration: 'underline' }}>add</span> a 15% commision to your listing price before it goes live so you can keep your full asking price.</span>
                </div>
            </div>
            <Blurb>
                If you choose to accept these terms, you will be paid {curr_contributor_cut} upon sale of this song.<br /><br />
                Rejecting these terms will prompt the submitting artist to update them, after which you will be prompted to review & respond to the newly set terms.<br /><br />
                If the artist that submitted this song has left out a contributor or entered the wrong email, please reject the terms and confer off-platform.
            </Blurb>
            <ButtonContainer items={2}>
                <Button
                    text="Accept"
                    width="100%"
                    onClick={handleAssignment(ACCEPT)}
                    background="transparent"
                    border="2px solid #03cc00" />
                <Button
                    text="Reject"
                    width="100%"
                    onClick={handleAssignment(REJECT)}
                    background="transparent"
                    border="2px solid #9e0000" />
            </ButtonContainer>
        </PopupWrapper>
    )
}

const Popup = ({ action, visible, type, value, closePopup, submitContributorResponse, selectedSong, handleAssignment }) => {
    const { currentUser } = useAuth();
    const overlayRef = useRef(null)
    const current_contributor = selectedSong.contributors.filter(contributor => contributor.email === currentUser.email)[0]
    const curr_contributor_cut = calculateCut(current_contributor.split, selectedSong.price)
    if (action === undefined) {
        return (
            <PopupWrapperOverlay visible={visible}>

            </PopupWrapperOverlay >
        )
    }
    if (action === '') {
        return (
            <PopupWrapperOverlay visible={visible}
                ref={overlayRef}
                onClick={(e) => { console.log(e.target); if (e.target === overlayRef.current) closePopup(); }}
            >
                <Content
                    selectedSong={selectedSong}
                    visible={visible}
                    closePopup={closePopup}
                    action={action}
                    handleAssignment={handleAssignment}
                    curr_contributor_cut={curr_contributor_cut}
                    current_contributor={current_contributor}
                />
            </PopupWrapperOverlay >

        )
    } else if (action !== '') {
        return (
            <PopupWrapperOverlay
                visible={visible}
                ref={overlayRef}
                onClick={(e) => { console.log(e.target); if (e.target === overlayRef.current) closePopup(); }}
            >
                <Confirmation
                    visible={visible}
                    closePopup={closePopup}
                    selectedSong={selectedSong}
                    action={action}
                    submitContributorResponse={submitContributorResponse}
                    currentUser={currentUser}
                    curr_contributor_cut={curr_contributor_cut}
                    current_contributor={current_contributor}
                />
            </PopupWrapperOverlay >

        )
    }

}
const AcceptAndReject = ({ isApproved, handleAssignment, submitContributorResponse, type, value, popupVisible, action, setAction, closePopup, selectedSong }) => {
    console.log('action', action)

    return (
        <>
            <Wrapper isApproved={isApproved} selectedSong={selectedSong}>
            </Wrapper>
            <Popup
                selectedSong={selectedSong}
                setAction={setAction}
                handleAssignment={handleAssignment}
                submitContributorResponse={submitContributorResponse} action={action} closePopup={closePopup} visible={popupVisible !== undefined ? popupVisible : false} type={type} value={value} />
        </>
    )
}

export default AcceptAndReject
