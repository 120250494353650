import React, { useState, useEffect, useRef } from 'react'

import {
    Wrapper,
    SkipWrapper,
    MusicToggleButton,
    ControlsWrapper,
    GridItem,
    LogoWrapper,
    SongDetailsHeader,
    SongStatusNote,
    PricingTable
} from './Dashboard.styles'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { adjustPrice, calculateCut, calculatePriceWithFee } from '../../helpers'
import LoadingWheel from '../LoadingWheel'
import { useDash } from '../../hooks/useDash'
import { useVisualizerHook } from '../../hooks/useVisualizerHook'
import API from '../../API'

import Flexbox from '../Flexbox'
import DashBar from './DashBar'
import Arrow from '../../images/formNavArrow.svg'
import Play from '../../images/play.svg'
import Pause from '../../images/pause.svg'
import AzaaLogo from '../../images/azaaLogo.svg'
import Close from '../../images/close.svg'
import Visualizer from '../Visualizer'
import VisualizerControls from '../Visualizer/VisualizerControls'
import SongDetailsTable from '../SongDetailsTable'
import Button from '../Button'
import Modal from '../Modal'

import ControlsIcon from '../../images/controls.svg'

const PlayButton = ({ onClick, playing, setPlaying }) => {

    return (
        <MusicToggleButton onClick={onClick} src={playing ? Pause : Play} alt={playing ? "pause-button" : "play-button"} />
    )

}

const SkipSong = ({ direction, gridArea, onSkip }) => {
    return (
        <SkipWrapper direction={direction} gridArea={gridArea} onClick={() => onSkip(direction === 'next' ? 1 : -1)}>
            <img src={Arrow} alt={direction} />
        </SkipWrapper >
    )
}

const Controls = ({ nowPlaying, handleSongChange, setPlaying, playing, onSkip }) => {

    return (
        <ControlsWrapper>
            <SkipSong direction="last" gridArea="last" onSkip={onSkip} />
            <PlayButton playing={playing} onClick={() => { setPlaying(!playing) }} />
            <SkipSong direction="next" gridArea="next" onSkip={onSkip} />
        </ControlsWrapper>
    )
}

const Dashboard = () => {

    const {
        onSkip,
        MusicToggleButton,
        ControlsWrapper,
        error,
        setError,
        loading,
        setLoading,
        handleSongChange,
        resultsPerPage,
        songs,
        playing,
        setPlaying,
        fetchMore,
        nowPlaying,
        audioRef,
        file,
        currentUser,
        showModal,
        setShowModal,
        setSelectedSong,
        selectedSong,
        // handleSelect,
        newApproval,
        listener,
        loader,
        ...props } = useDash();
    const { tableData, nbHits } = songs;

    const {
        showControls,
        setShowControls,
        controls,
        setControls,
    } = useVisualizerHook()

    if (loading) return <LoadingWheel height="100vh" top="0px" position="fixed" zIndex="20" />
    else return (
        <Wrapper>
            <Visualizer
                onSkip={onSkip}
                nowPlayingSongId={nowPlaying}
                sound={audioRef}
                listener={listener}
                loader={loader}
                audioFile={file}
                showControls={showControls}
                setShowControls={setShowControls}
                controls={controls}
                setControls={setControls}
            ></Visualizer>
            <LogoWrapper><img src={AzaaLogo} alt="logo" /></LogoWrapper>
            <Controls
                playing={playing}
                onSkip={onSkip}
                setPlaying={setPlaying}
                handleSongChange={handleSongChange}
            />
            <DashBar
                nbHits={nbHits}
                showControls={showControls}
                resultsPerPage={resultsPerPage}
                fetchMore={fetchMore}
                newApproval={newApproval}
                content={tableData}
                setShowModal={setShowModal}
                handleSongChange={handleSongChange}
                setSelectedSong={setSelectedSong}
                nowPlaying={nowPlaying}
                playing={playing}
                {...props}
            // handleSelect={handleSelect} 
            />
            {/* <audio preload="none" ref={audioRef} src={file} onEnded={() => onSkip(1)}>
            </audio> */}
            {
                (selectedSong != null && showModal) &&
                <Modal
                    showModal={showModal}
                    title="Song Details"
                    subtext={<SongDetailsHeader >
                        <div style={{ textTransform: 'none' }}>{selectedSong.song_title}<SongStatusNote>{selectedSong.status_text}</SongStatusNote></div>
                        {selectedSong.status >= 3 &&

                            <div style={{ textAlign: 'right' }}>
                                {`${adjustPrice(selectedSong.price)}`}
                                <SongStatusNote textalign="right">Song Price</SongStatusNote>
                            </div>
                        }
                    </SongDetailsHeader>}
                    closeModal={() => setShowModal(false)}>
                    <SongDetailsTable padding="0" margin="0" border="undefined" borderBottom="none" selectedSong={selectedSong} />
                    <PricingTable>
                        <div>Song Price</div><div>{`${adjustPrice(selectedSong.price, selectedSong.showTrailing)}`}</div>
                        <div>+ 15% Azaa Platform Fee</div><div> + {calculatePriceWithFee(selectedSong.price, selectedSong.showTrailing).fee}</div>
                        <div>Listing Price</div><div>{calculatePriceWithFee(selectedSong.price, selectedSong.showTrailing).total}</div>
                    </PricingTable>
                    <div style={{ fontWeight: '500', color: 'var(--purpleGrey)', marginTop: '5px', fontStyle: 'italic', fontSize: '10px', display: 'flex' }}>
                        <span>Note: Azaa will <span style={{ textDecoration: 'underline' }}>add</span> a 15% commision to your listing price before it goes live so you can keep your full asking price.</span>
                        {/* <p style={{ whiteSpace: 'pre' }}>
                            Note: Azaa will </p><p style={{ textDecoration: 'underline', display: 'flex' }}>add</p> <p style={{ whiteSpace: 'pre' }}> a 15% commision to your listing price before it goes live so you can keep your full asking price.</p>
                     */}
                    </div>
                </Modal>
            }
            <Flexbox cursor="pointer" zIndex="3" height="var(--navBarHeight)" width="20px" fixed top="0" right="calc(3.22vw + 60px)"
                onClick={() => {
                    setShowControls(!showControls)
                }}><img src={ControlsIcon} style={{ position: 'relative', height: '100%', width: '100%' }} /></Flexbox>
            <VisualizerControls showControls={showControls} controls={controls} setControls={setControls} setShowControls={setShowControls} />
        </Wrapper >
    )
}

export default Dashboard
