import React from 'react';

import {
  Wrapper,
  Header,
  Row,
  Col,
  List,
  OuterWrapper
} from './About.styles'

import Footer from '../Footer'

const About = () => {
  return (
    <OuterWrapper>
    <Wrapper>
      <Col>
        <Header>About Azaa</Header>
        <p>Azaa was founded to:</p>
        <List>
          <li>Standardize songwriter fees and restore economic dignity to creators.</li>
          <li>Provide significant A&R value to those sourcing outside songs and ideas for their projects.</li>
        </List>
        <p>
        At Azaa, we believe more songwriters should be able to earn a living off their work, and that industry A&R's and managers should have access to a superb catalog of unplaced songs - all gathered in one easy to navigate marketplace.
        </p><p>
        We believe that songwriters, like producers, should get paid at point of placement.
        </p><p>
        We believe that songwriters signed to major publishers shouldn't have to go get a job.
        </p><p>
        We believe that song sourcing and selection can (and should) exist outside of email.
        </p><p>
        We believe that passed-over songs deserve a second chance. And a third, and a fourth, and a fifth, and a ...
        </p><p>
        We believe in providing opportunities to monetize that folder of unreleased songs sitting on your desktop that are all actually, well ... really great.
        </p><p>
        We believe that music is more financially valuable than our society currently says it is.
        </p>
        <p>
        <strong>Built for A&R’s - By A&R’s.</strong> Azaa hand selects only the highest quality unplaced songs for our marketplace. Our global reach has allowed us to collect an incredible and diverse library of songs from songwriters all over the world in varying stages of their career – from current hitmakers to future star writers. After sourcing more than 15,000 (!) songs pre-launch, we have averaged a stringent 5% acceptance rate, ensuring our industry buyer community will only be exposed to one great song after another, regardless of genre. We proudly offer a concierge service for those with specific tastes as well as a weekly pitch playlist of our new listings. At Azaa we aim to serve and provide only the choicest songs, optimizing success for all parties involved.  
        </p>
        <p>
        <strong>Built for Songwriters - By Songwriters.</strong> Azaa is a highly-vetted private song marketplace where songwriters can list their songs for placement with A&R’s, artists, artist managers, and DJ’s – all those sourcing outside material for their projects. Songwriters set their own price for their listings and retain all their rightful publishing in the Azaa ecosystem. Azaa does not facilitate the sale or transfer of any publishing or master ownership. So … what are songwriters “selling”? Azaa is offering our buyers “Exclusive Release Rights” - which is our fancy way of saying writer fee, or hold fee - the nomenclature hasn’t been ironed out in the music industry (because the issue hasn’t been addressed properly yet), so, yes, we’re creating it. “Exclusive Release Rights” = when your song is purchased, whoever buys your song will be the only party that can release that song, and they have two years to release it. Tada, writer fee. Songwriters have been operating under oppressive and archaic business practices for far too long now. A major paradigm shift in songwriter compensation is beyond overdue. It’s high time to create a new normal that songwriters deserve.
        </p>
      </Col>
      
    </Wrapper>
    <Footer />
    </OuterWrapper>
  );
};

export default About;
