import React, { useRef } from 'react'

import {
    ModalOverlay,
    ModalWrapper,
    GridItem,
    ModalTitle,
    SubText,
    CloseButtonContainer
} from './Modal.styles'

import Close from '../../images/close.svg'

// components
import Button from '../Button'

const ClosePopup = ({ closeModal }) => (
    <CloseButtonContainer >
        <img src={Close} alt="close-popup" onClick={closeModal} />
    </CloseButtonContainer>
)


const Modal = ({ modalPosition, fixed, absolute, top, left, minWidth, maxWidth, width, hideButton = false, contentHeight, uppercaseOff, zIndex, showModal, children, closeModal, title, subtext, onClick, buttonText }) => {
    const modalWrapperRef = useRef(null)
    return (
        <ModalOverlay top={top} left={left} 
        position={fixed ? 'fixed' : absolute ? 'absolute' : undefined}
        ref={modalWrapperRef} zIndex={zIndex} showModal={showModal} onClick={(e) => {
            if (modalWrapperRef.current === e.target) {
                e.stopPropagation();
                closeModal();
            }
        }}>
            <ModalWrapper modalPosition={modalPosition} maxWidth={maxWidth} width={width} minWidth={minWidth} subtext={subtext}>
                <GridItem gridArea="title">
                    <ModalTitle uppercaseOff={uppercaseOff}>{title}</ModalTitle>
                </GridItem>
                <GridItem gridArea="close">
                    <ClosePopup closeModal={closeModal} />
                </GridItem>
                {subtext && <GridItem gridArea="subtext">
                    <SubText>
                        {subtext}
                    </SubText>
                </GridItem>}
                <GridItem gridArea="content" contentHeight={contentHeight}>
                    {children}
                </GridItem>
                {!hideButton &&
                    < GridItem gridArea="button">
                        <Button
                            width="100%"
                            background="transparent"
                            border="1px solid white"
                            text={buttonText ? buttonText : 'Close'}
                            onClick={onClick !== undefined ? onClick : closeModal}
                        />
                    </GridItem>}
            </ModalWrapper>
        </ModalOverlay >
    )
}

export default Modal
