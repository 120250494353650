import styled from 'styled-components'

export const Wrapper = styled.div`
display: ${({ display = 'flex' }) => display};
grid-row: ${({ row }) => row};
grid-column: ${({ col }) => col};
grid-area: ${({ gridArea }) => gridArea};
justify-content: ${({ justify = 'center' }) => justify};
font-size: ${({ fontSize = 'inherit' }) => fontSize};
align-items: ${({ align = 'center' }) => align};
align-self: ${({ alignSelf }) => alignSelf};
justify-self: ${({ justifySelf }) => justifySelf};
width: ${({ width = '100%' }) => width};
height: ${({ height = '100%' }) => height};
background: ${({ background }) => background};
user-select: ${({ userSelect }) => userSelect};
left: ${props => props.left ? props.left : ''};
right: ${props => props.right ? props.right : ''};
top: ${props => props.top ? props.top : ''};
bottom: ${props => props.bottom ? props.bottom : ''};
position: ${props => props.position ? props.position : ''};
transition: ${props => props.transition ? props.transition : ''};
border-bottom: ${props => props.borderBottom ? props.borderBottom : ''};
border: ${props => props.border ? props.border : ''};
border-right: ${props => props.borderRight ? props.borderRight : ''};
border-radius: ${props => props.borderRadius ? props.borderRadius : ''};
padding: ${props => props.padding ? props.padding : ''};
gap: ${props => props.gap ? props.gap : ''};
transform: ${props => props.transform ? props.transform : ''};
opacity: ${({ opacity }) => opacity};
flex-direction: ${({ flexDirection }) => flexDirection};
scroll-snap-type: ${({ scrollSnapType }) => scrollSnapType};
scroll-snap-align: ${({ scrollSnapAlign }) => scrollSnapAlign};
text-align: ${({ textAlign }) => textAlign};
color: ${({ color }) => color};
white-space: ${({ whiteSpace }) => whiteSpace};
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
            width: ${({ mobileWidth }) => mobileWidth};
            height: ${({ mobileHeight }) => mobileHeight};
            flex-basis: ${({ mobileFlexBasis }) => mobileFlexBasis};

        }
`;