import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    OuterWrapper,
    FlexColumn,
    Line,
    MessagesSideBar,
    SongStatusNote,
    MessageStream,
    ScrollWrapper,
    InnerScrollWrapper,
    Link,
    Title,
    LinkHeader,
    SubLink,
    DetailsWrapper,
    SongDetailsHeader,
    LoadingWrapper,
    GridItem,
    NewDot,
    SongDetailsHeaderGrid,
    ComponentWrapper,
    NavArrow,
    PricingTable
} from './Messages.styles'

import axios from 'axios';
import API from '../../API';
import { statusText, isBuyer, toTitleCase, _requiredAction, adjustPrice, calculateCut, calculatePriceWithFee } from '../../helpers'

// Hook
import { useAuth } from '../../contexts/AuthContext'
import { useMessengerFetch } from '../../hooks/useMessengerFetch';

// Components
import MessageGrid from '../MessageGrid'
import MessageInputBar from '../MessageInputBar'
import SongDetailsTable from '../SongDetailsTable'
import SongDetails from '../SongDetails'
import LoadingWheel from '../LoadingWheel'
import Button from '../Button'
import StemsTransferModal from '../StemsTransferModal'

// // Contexts
// import { useMessenger } from '../../contexts/MessengerContext'

import FormNavArrow from '../../images/formNavArrow.svg';

const Conversation = ({ isNew, songID, songTitle, isCurrent, onClick }) => {
    const title = songTitle;//songID !== "1" ? songTitle : 'General';
    return (
        <SubLink isNew={isNew} isCurrent={isCurrent} onClick={onClick}>{title}
            {isNew && <NewDot />}
        </SubLink>
    )
}

const User = ({ user, messageHeaders, acceptedSongConversations, selectedMessage, setSelectedMessage }) => {
    const { currentUser } = useAuth()
    return (
        <>
            {
                acceptedSongConversations.map((c, index) => {
                    return (
                        <Conversation
                            isCurrent={selectedMessage.song_id === c.song_id}
                            isNew={c.receiver_id === c.user_id && c.is_new === '1'}
                            onClick={() => setSelectedMessage({ song_id: c.song_id, user_id: c.user_id, song: { ...c } })}
                            songID={c.song_id}
                            key={index}
                            songTitle={c.song_title} />
                    )
                })
            }
        </>
    )
}

const Content = ({ messages, user, loading }) => {
    if (loading) {
        return (
            <LoadingWrapper>
                <LoadingWheel hideWrapper />
            </LoadingWrapper>)
    }
    else return (
        <MessageGrid messages={messages} loading={loading} currUserID={user.user_id} />
    )
}


const Messages = ({ userID }) => {
    const navigate = useNavigate()
    const { currentUser } = useAuth()
    const {
        showModal,
        setShowModal,
        resultsPerPage,
        totalResults,
        page,
        setPage,
        songDetails,
        messageHeaders,
        sendMessage,
        user,
        continueKey,
        handleChange,
        messageInput,
        setMessageInput,
        loadingSideBar,
        setUserIDs,
        loading,
        error,
        linkheaders,
        messages,
        selectedMessage,
        setSelectedMessage } = useMessengerFetch();
    const [loadMessages, setMessagesToLoad] = useState(null)
    console.log("songDetailssongDetailssongDetailssongDetailssongDetailssongDetailssongDetailssongDetails", songDetails, isBuyer(currentUser))
    if (!messageHeaders.length || loadingSideBar) {
        return <ComponentWrapper><OuterWrapper></OuterWrapper></ComponentWrapper>
    }
    else {
        return (
            <ComponentWrapper>
                <OuterWrapper>
                    <GridItem gridArea="sideBar" borderRight="1px solid var(--purpleGrey)">
                        <MessagesSideBar>
                            <Title>Messages</Title>
                            <div style={{ fontSize: '10px', display: 'flex', columnGap: '20px', padding: '0 20px 20px 50px' }}><div style={{ display: 'flex' }}>Page {page} of <div style={{ display: 'flex', width: 'min-content', marginLeft: '3px' }} onClick={() => { if (page !== Math.ceil(totalResults / resultsPerPage)) setPage(Math.ceil(totalResults / resultsPerPage)) }}>{Math.ceil(totalResults / resultsPerPage)}</div></div>
                                <NavArrow back isValid={page > 1} onClick={() => { if (page > 1) { setPage(page - 1) } }}><img src={FormNavArrow} alt="back" /></NavArrow>
                                <NavArrow isValid={page <= Math.ceil(totalResults / resultsPerPage) - 1} onClick={() => { if (page + 1 <= Math.ceil(totalResults / resultsPerPage)) { setPage(page + 1) } }}><img src={FormNavArrow} alt="next" /></NavArrow>
                            </div>
                            <ScrollWrapper>
                                <InnerScrollWrapper>
                                    <User
                                        user={user}
                                        messageHeaders={messageHeaders}
                                        loading={loading}
                                        setSelectedMessage={setSelectedMessage}
                                        selectedMessage={selectedMessage}
                                        acceptedSongConversations={messageHeaders}
                                    />
                                </InnerScrollWrapper>
                            </ScrollWrapper>
                        </MessagesSideBar>
                    </GridItem>
                    <GridItem gridArea="details" height="100vh" position="relative" overflow="hidden">
                        {songDetails === null &&
                            <DetailsWrapper >
                                <SongDetailsHeaderGrid >
                                    <div>General<SongStatusNote> </SongStatusNote></div>
                                </SongDetailsHeaderGrid>
                            </DetailsWrapper>}
                        {(songDetails !== null && songDetails !== undefined) &&
                            <DetailsWrapper overflow="auto">
                                <SongDetailsHeaderGrid gridAreas="'title price''labelTitle labelPrice''button button'">
                                    <GridItem gridArea="title">{songDetails.song_title}</GridItem>
                                    <GridItem gridArea="labelTitle"><SongStatusNote>{statusText(currentUser, songDetails)}</SongStatusNote></GridItem>
                                    {parseInt(songDetails.song_status) >= 3 &&
                                        <GridItem gridArea="price">{`${songDetails.price.includes('$') ? '' : '$'}${songDetails.price}`}</GridItem>}
                                    {parseInt(songDetails.song_status) >= 3 && <GridItem gridArea="labelPrice"><SongStatusNote textalign="right">Song price</SongStatusNote></GridItem>}
                                    {(!isBuyer(currentUser) && _requiredAction(currentUser, songDetails).text !== '-') &&
                                        <GridItem gridArea="button">
                                            <Button
                                                onClick={() => navigate('/recently-approved')}
                                                text={_requiredAction(currentUser, songDetails).text}
                                                border="var(--buttonBorder)"
                                                width="100%" />
                                        </GridItem>
                                    }
                                    {(isBuyer(currentUser) && _requiredAction(currentUser, songDetails).text !== '-') &&
                                        <GridItem gridArea="button">
                                            <Button
                                                onClick={() => setShowModal(true)}
                                                text={_requiredAction(currentUser, songDetails).text}
                                                border="var(--buttonBorder)"
                                                width="100%" />
                                        </GridItem>
                                    }
                                </SongDetailsHeaderGrid>
                                {((parseInt(songDetails.song_status) >= 3 || (parseInt(songDetails.song_status) === 2 && songDetails.contributors !== undefined) && songDetails.contributors.length > 0) && !isBuyer(currentUser)) &&
                                    <FlexColumn>
                                        <Line />
                                        {songDetails.contributors.length > 1 && <span style={{ paddingTop: '20px', fontSize: '10px', textAlign: 'left' }}>Note: All correspondence is solely between you and Azaa. Your contributors' emails are listed only for reference.</span>}
                                        <SongDetailsTable padding="0 0" margin="0" border="undefined" borderBottom="none" selectedSong={songDetails} />
                                        <PricingTable>
                                            <div>Song Price</div><div>{isBuyer(currentUser) ? songDetails.price : `${adjustPrice(songDetails.price, songDetails.showTrailing)}`}</div>
                                            <div>+ 15% Azaa Platform Fee</div><div> + {calculatePriceWithFee(songDetails.price, songDetails.showTrailing).fee}</div>
                                            <div>Listing Price</div><div>{calculatePriceWithFee(songDetails.price, songDetails.showTrailing).total}</div>
                                        </PricingTable>
                                        <div style={{ fontWeight: '500', color: 'var(--purpleGrey)', marginTop: '5px', fontStyle: 'italic', fontSize: '10px', display: 'flex' }}>
                                            <span>Note: Azaa will <span style={{ textDecoration: 'underline' }}>add</span> a 15% commision to your listing price before it goes live so you can keep your full asking price.</span>
                                        </div>
                                    </FlexColumn>
                                }
                                {
                                    ((songDetails && parseInt(songDetails.song_status) > 4) && <SongDetails selectedSong={songDetails} isBuyer={isBuyer(currentUser)} />
                                    )
                                }
                            </DetailsWrapper>}
                    </GridItem>
                    <GridItem gridArea="messages" overflow="hidden" borderRight="1px solid var(--purpleGrey)">
                        <DetailsWrapper className="hiddenOnSmall">
                            <SongDetailsHeader >
                                <div>
                                    {songDetails !== null && songDetails !== undefined ?
                                        <>{songDetails.song_title}</> :
                                        <>General</>
                                    }
                                    {
                                        <SongStatusNote>{user !== '' ? user.first_name + ' ' + user.last_name : "Messages"}</SongStatusNote>
                                    }
                                </div>
                            </SongDetailsHeader>
                            <Line />
                        </DetailsWrapper>
                        <Content loading={loading} messages={messages} user={user} />
                    </GridItem>
                    <GridItem gridArea="inputbar" background="transparent" padding="20px" borderRight="1px solid var(--purpleGrey)">
                        <MessageInputBar setMessageInput={setMessageInput} onKeyPress={continueKey} sendMessage={sendMessage} onChange={handleChange} messageInput={messageInput.message} />
                    </GridItem>
                </OuterWrapper >
                {(songDetails && parseInt(songDetails['status']) > 4) &&
                    <StemsTransferModal showModal={showModal} 
                    currentUser={currentUser} closePopup={() => { setShowModal(false) }} selectedSong={songDetails} />}
            </ComponentWrapper>
        )
    }
}

export default Messages
