import styled from 'styled-components';

export const Wrapper = styled.div`
    height: 100px;
    scroll-behavior: smooth;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    mask-image: linear-gradient(0deg, transparent 0, red 25%, red 75%, transparent 100%);
    
    user-select: none;



`;

export const OuterWrapper = styled.div`
justify-content: center;
display: flex;
flex-direction: column;
align-items: center;

`;

export const ScrollWrapper = styled.div`
  margin: auto;
  max-height: 100%;
  
  div:not(:last-child){
        margin-bottom: 5px;
    }
`;


export const EmptySpacer = styled.span`
height: 25px;
width: 100%;
position: relative;
display: inline-block;
flex-shrink: 0;
`;

export const Button = styled.button`
    all: unset;
    font-family: var(--defaultFont);
    color: #ffffff;
    height: 25px;
    width: 25px;
    cursor: pointer;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    img{
        width: 12px;
        transform: ${ props => props.name === 'scrollUp' ? 'rotate(-90deg)' : 'rotate(90deg)'};
    }

`;