import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Wrapper, NavLink } from './SuccessPage.styles'

import Button from '../../Button'

import axios from 'axios'

import LoadingWheel from '../../LoadingWheel'

const SuccessPage = ({ onSubmit, values, numSongsSubmitted, resetForm }) => {
    const navigate = useNavigate()
    const submitting = useRef(false)
    const pageRefreshed = useRef(true)

    const { state, userDetails, loading, error, songsSubmitted } = values;
    useEffect(() => {
        const handleSubmit = async () => {
            if (!songsSubmitted && !submitting.current && numSongsSubmitted > 0) {
                submitting.current = true
                await onSubmit()
                submitting.current = false
            }
        }
        handleSubmit()

    }, [])

    if (loading) {
        return (
            <Wrapper>
                <h1 style={{ textTransform: 'none' }}>Submitting</h1>
                <h2 style={{ textTransform: 'none' }}>This may take a few minutes.</h2>
                <h2 style={{ textTransform: 'none' }}>If you have any trouble submitting your application, please <NavLink href="mailto:info@azaamusic.com">contact us</NavLink>.</h2>
                <LoadingWheel hideWrapper></LoadingWheel>
            </Wrapper>
        )
    }
    else if (error) {
        return (
            <Wrapper>
                <h1 style={{ textTransform: 'none' }}>There has been an error with your application.</h1>
                <h2 style={{ textTransform: 'none' }}>Please try again later.</h2>
                <h2 style={{ textTransform: 'none' }}>If you have further trouble submitting your application, please <NavLink href="mailto:info@azaamusic.com">contact us</NavLink>.</h2>
            </Wrapper>

        )
    }
    else if (songsSubmitted) {

        return (
            <Wrapper>
                <h1 style={{ textTransform: 'none' }}>Your {numSongsSubmitted > 1 ? 'songs have' : 'song has'} been submitted for approval!</h1>
                {numSongsSubmitted === 1 ? <h2 style={{ textTransform: 'none' }}>You will hear from us if your song is accepted.</h2> : <h2 style={{ textTransform: 'none' }}>You will hear from us if your songs are accepted.</h2>}

                <Button
                    onClick={() => navigate('/faq')}
                    text="Learn More"
                    width="198px"
                    background="transparent"
                    border="2px solid rgba(255,255,255,0.25)"
                ></Button>
            </Wrapper >
        )
    }
    else {
        return <Wrapper></Wrapper>
    }

}

export default SuccessPage
