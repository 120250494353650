import styled from 'styled-components'

export const QuestionWrapper = styled.div`
position: relative;
display: grid;
border: ${props => props.border ? props.border : ""};
grid-column-gap: ${props => props.gridColGap ? props.gridColGap : ''};
grid-row-gap: ${props => props.gridRowGap ? props.gridRowGap : ''};
grid-template-columns: ${props => props.gridTemplateColumns ? props.gridTemplateColumns : ''};
grid-template-rows: ${props => props.gridTemplateRows ? props.gridTemplateRows : ''};
grid-template-areas: ${props => props.gridTemplateAreas ? props.gridTemplateAreas : ''};
margin-top: ${props => props.marginTop ? props.marginTop : '20px'};
width: 100%;
height: ${props => props.height ? props.height : ''};
`;

export const GridItem = styled.div`
flex-direction: column;
justify-content: center;
display: flex;
grid-area: ${props => props.gridArea ? props.gridArea : ''};
`;

export const ModalWrapper = styled.div`

visibility: ${props => props.showModal ? props.showModal : 'hidden'};
background: var(--greyBackground);
display: grid;
justify-content: center;
align-items: center;
box-shadow: 0 0 20px rgba(0,0,0,0.5);
width: 90%;
max-width: 500px;
grid-template-columns: 1fr min-content;
grid-auto-rows: min-content;
grid-column-gap: 10px;
grid-template-areas: ${props => props.gridAreas ? props.gridAreas :"'title close''loading loading'"};
padding: 20px;
grid-row-gap: 20px;
`;

export const LoadingWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 20px;
`;

export const ErrorWrapper = styled.div`
font-size: 10px;
user-select: none;
`;

export const SubText = styled.div`
font-size: ${props => props.fontSize ? props.fontSize : '12px'};
font-weight: ${props => props.fontWeight ? props.fontWeight : 'normal'};
letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : ''};
margin: ${props=> props.margin? props.margin : ''};
margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};
user-select: none;
color: ${props => props.color ? props.color : 'var(--purpleGrey)'};
`;

export const ModalTitle = styled.div`
user-select: none;
text-transform: uppercase;
`;

export const CloseButtonContainer = styled.div`
display: flex;
width: 100 %;
justify-content: flex-end;
position: relative;
img{
    height: 20px;
    width: 20px;
    cursor: pointer;
}
`;

export const PasswordReset = styled.text`
text-decoration: none;
user-select: none;
cursor: pointer;
margin-left: 0.3rem;
letter-spacing: 0.07rem;
font-weight: 600;
margin-top: 0.5rem;
color: #fff;

align-self: flex-start;

:hover{
    color: #fff;
}
`;