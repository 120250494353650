import React, { useEffect, useRef } from 'react';
import anime from 'animejs/lib/anime.es';

import { Wrapper } from './Cassette.styles'
const Cassette = () => {
    const containerRef = useRef(null);
    useEffect(() => {
        const fixSvgElementCoordinates = (className, xFactor = 2, yFactor = 2) => {
            let elements;
            elements = document.querySelectorAll(className);
            Array.from(elements).forEach((element) => {
                let BBox = element.getBBox();
                let BBoxX2 = xFactor == 0 ? 0 : BBox["width"] / xFactor;
                let BBoxY2 = yFactor == 0 ? 0 : BBox["height"] / yFactor;
                let BBoxX = BBox["x"] + BBoxX2;
                let BBoxY = BBox["y"] + BBoxY2;
                BBox = BBoxX + "px" + " " + BBoxY + "px";
                element.style.transformOrigin = BBox;
            });
        }
        fixSvgElementCoordinates('#tape1')
        fixSvgElementCoordinates('#tape2')

        const animation = anime({
            targets: ['#tape2', '#tape1'],
            rotate: 180,
            easing: 'linear',
            duration: 3000,
            loop: true
        });
        const handleIntersect = (entries, observer) => {
            const entry = entries[0]
            if (entry.isIntersecting)
                animation.play()
            else
                animation.pause()
        }
        const observer = new IntersectionObserver(handleIntersect, { rootMargin: "0px", threshold: 0.6 })
        if (containerRef.current)
            observer.observe(containerRef.current)
        return () => { if (containerRef.current) observer.unobserve(containerRef.current); }
    }, [])

    return <Wrapper ref={containerRef}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119.47 75.88">
            <defs>
                <style>
                    {`.cassette-1,.cassette-2,.cassette-6,.cassette-7{fill:none;}.cassette-1{stroke:#f3f3f3;}.cassette-1,.cassette-6,.cassette-7{stroke - miterlimit:10;}.cassette-1,.cassette-3,.cassette-8{opacity:0.2;}.cassette-3{fill:#8b8786;}.cassette-4{fill:#616161;}.cassette-5{fill:#535353;}.cassette-6{stroke:#222;}.cassette-7{stroke:#3d3d3d;}.cassette-8{fill:#f3f3f3;}.cassette-9{fill:#1a1a1a;}.cassette-10{fill:#181818;}.cassette-11{fill:#222;}`}
                </style>
            </defs>
            <g id="Layer_2" >
                <g id="Layer_4">
                    <path className="cassette-1" d="M119,46.94V67.23l-.94,1.1v2.45a3,3,0,0,1-3,3H4.43a3,3,0,0,1-3-3V68.33L.5,67.23V46.94l.94-1.1V3.5a3,3,0,0,1,3-3H115a3,3,0,0,1,3,3V45.84Z" />
                    <rect className="cassette-2" x="8.14" y="6.3" width="103.19" height="44" rx="5.99" />
                    <path className="cassette-3" d="M108.49,50.74H11A3.28,3.28,0,0,1,7.7,47.46V9.14A3.28,3.28,0,0,1,11,5.86h97.51a3.28,3.28,0,0,1,3.28,3.28V47.46A3.28,3.28,0,0,1,108.49,50.74ZM11,6.74a2.41,2.41,0,0,0-2.4,2.4V47.46a2.41,2.41,0,0,0,2.4,2.4h97.51a2.41,2.41,0,0,0,2.4-2.4V9.14a2.41,2.41,0,0,0-2.4-2.4Z" />
                    <circle className="cassette-4" cx="5.93" cy="70.11" r="2.42" />
                    <polygon className="cassette-5" points="7.41 70.81 6.71 70.11 7.41 69.41 6.63 68.64 5.93 69.34 5.23 68.64 4.45 69.41 5.15 70.11 4.45 70.81 5.23 71.59 5.93 70.89 6.63 71.59 7.41 70.81" />
                    <circle className="cassette-4" cx="5.93" cy="4.16" r="2.42" />
                    <polygon className="cassette-5" points="7.41 3.46 6.71 4.16 7.41 4.86 6.63 5.63 5.93 4.93 5.23 5.63 4.45 4.86 5.15 4.16 4.45 3.46 5.23 2.68 5.93 3.38 6.63 2.68 7.41 3.46" />
                    <circle className="cassette-4" cx="113.63" cy="4.16" r="2.42" />
                    <polygon className="cassette-5" points="112.15 3.46 112.85 4.16 112.15 4.86 112.93 5.63 113.63 4.93 114.33 5.63 115.11 4.86 114.41 4.16 115.11 3.46 114.33 2.68 113.63 3.38 112.93 2.68 112.15 3.46" />
                    <path className="cassette-2" d="M72,27.32V37.71a1.57,1.57,0,0,1-1.58,1.57H48.93a1.57,1.57,0,0,1-1.57-1.57V27.32a1.57,1.57,0,0,1,1.57-1.57H70.38A1.57,1.57,0,0,1,72,27.32Z" />
                    <line className="cassette-6" x1="37.9" y1="21.24" x2="81.42" y2="21.16" />
                    <circle className="cassette-4" cx="113.63" cy="70.11" r="2.42" />
                    <polygon className="cassette-5" points="112.15 70.81 112.85 70.11 112.15 69.41 112.93 68.64 113.63 69.34 114.33 68.64 115.11 69.41 114.41 70.11 115.11 70.81 114.33 71.59 113.63 70.89 112.93 71.59 112.15 70.81" />
                    <line className="cassette-6" x1="91.27" y1="26.9" x2="109.25" y2="65.72" />
                    <circle className="cassette-7" cx="107.12" cy="66.76" r="2.37" />
                    <line className="cassette-7" x1="107.12" y1="69.13" x2="59.73" y2="69.13" />
                    <circle className="cassette-6" cx="107.12" cy="66.76" r="2.37" />
                    <line className="cassette-6" x1="107.12" y1="69.13" x2="59.73" y2="69.13" />
                    <line className="cassette-6" x1="28.39" y1="26.79" x2="10.22" y2="65.72" />
                    <circle className="cassette-6" cx="12.35" cy="66.76" r="2.37" />
                    <line className="cassette-6" x1="12.35" y1="69.13" x2="59.73" y2="69.13" />
                    <polygon className="cassette-8" points="97.79 74.94 21.77 74.94 25.92 56.2 93.64 56.2 97.79 74.94" />
                    <polygon className="cassette-9" points="98.34 75.38 21.45 75.5 25.8 55.88 94.22 55.88 98.34 75.38" />
                    <polygon className="cassette-10" points="97.79 74.94 21.77 74.94 25.92 56.2 93.64 56.2 97.79 74.94" />
                    <circle className="cassette-4" cx="59.78" cy="63.29" r="2.42" />
                    <polygon className="cassette-5" points="61.26 63.99 60.56 63.3 61.26 62.59 60.48 61.82 59.78 62.52 59.08 61.82 58.3 62.59 59 63.3 58.3 63.99 59.08 64.77 59.78 64.07 60.48 64.77 61.26 63.99" />
                    <path className="cassette-4" d="M45.28,68.87a.64.64,0,0,1-.61.44H42.26a.65.65,0,0,1-.65-.65V66.89a.65.65,0,0,1,.32-.56.49.49,0,0,0,0,.21v1.78a.65.65,0,0,0,.65.65H45A.64.64,0,0,0,45.28,68.87Z" />
                    <path d="M45.32,66.89v1.77a.49.49,0,0,1,0,.21A.64.64,0,0,1,45,69H42.54a.65.65,0,0,1-.65-.65V66.54a.49.49,0,0,1,0-.21.61.61,0,0,1,.33-.1h2.41A.66.66,0,0,1,45.32,66.89Z" />
                    <path className="cassette-4" d="M34.44,71.28a1.86,1.86,0,0,1-1.42.66,1.83,1.83,0,0,1-1.16-3.24,1.83,1.83,0,0,0,1.41,3A1.91,1.91,0,0,0,34.44,71.28Z" />
                    <path d="M34.85,70.12a1.78,1.78,0,0,1-.41,1.15h0a1.91,1.91,0,0,1-1.17.41,1.83,1.83,0,0,1-1.41-3h0a1.83,1.83,0,0,1,3,1.42Z" />
                    <path className="cassette-4" d="M74.19,68.87a.64.64,0,0,0,.61.44h2.41a.65.65,0,0,0,.65-.65V66.89a.65.65,0,0,0-.32-.56.49.49,0,0,1,0,.21v1.78a.65.65,0,0,1-.65.65H74.52A.64.64,0,0,1,74.19,68.87Z" />
                    <path d="M74.15,66.89v1.77a.49.49,0,0,0,0,.21.64.64,0,0,0,.33.1h2.41a.65.65,0,0,0,.65-.65V66.54a.49.49,0,0,0,0-.21.61.61,0,0,0-.33-.1H74.8A.66.66,0,0,0,74.15,66.89Z" />
                    <path className="cassette-4" d="M85,71.28a1.86,1.86,0,0,0,1.42.66,1.83,1.83,0,0,0,1.16-3.24,1.83,1.83,0,0,1-1.41,3A1.91,1.91,0,0,1,85,71.28Z" />
                    <path d="M84.62,70.12A1.78,1.78,0,0,0,85,71.27h0a1.91,1.91,0,0,0,1.17.41,1.83,1.83,0,0,0,1.41-3h0a1.83,1.83,0,0,0-3,1.42Z" />
                    <path className="cassette-7" d="M98.34,75.38H21.22l4.35-19.61H94Z" />
                    <path className="cassette-11" d="M37.9,20.67A11.85,11.85,0,1,0,49.75,32.52,11.84,11.84,0,0,0,37.9,20.67Zm7.81,13.88a8.08,8.08,0,1,1-.85-6.12A8,8,0,0,1,45.71,34.55Z" />
                    <path className="cassette-11" d="M81.42,20.67A11.85,11.85,0,1,0,93.26,32.52,11.84,11.84,0,0,0,81.42,20.67Zm7.81,13.88a8.08,8.08,0,1,1-.85-6.12A8,8,0,0,1,89.23,34.55Z" />
                    <path id="tape1" className="cassette-5" d="M88.38,28.43a8.07,8.07,0,1,0,.85,6.12A8,8,0,0,0,88.38,28.43ZM88,34.22A6.71,6.71,0,0,1,85.39,38l-.77-1.31a5,5,0,0,1-1.14.66l.77,1.32a6.7,6.7,0,0,1-4.54.4,6.55,6.55,0,0,1-1-.37l.75-1.32a5.11,5.11,0,0,1-1.14-.65L77.52,38a6.73,6.73,0,0,1-1.93-2.11,6.57,6.57,0,0,1-.89-2.71h1.51a5.3,5.3,0,0,1,0-1.32H74.69a5.79,5.79,0,0,1,.19-1.09,6.67,6.67,0,0,1,2.57-3.76l.77,1.3a6.07,6.07,0,0,1,1.13-.66l-.76-1.31a6.74,6.74,0,0,1,4.53-.4,5.45,5.45,0,0,1,1,.37l-.75,1.31a5.56,5.56,0,0,1,1.14.66L85.32,27a6.59,6.59,0,0,1,1.92,2.11,6.52,6.52,0,0,1,.89,2.71H86.62a5.3,5.3,0,0,1,0,1.32h1.52A5.79,5.79,0,0,1,88,34.22Z" />
                    <path id="tape2" className="cassette-5" d="M39.94,24.7a8.07,8.07,0,0,0-6.13.85,8.08,8.08,0,1,0,6.13-.85Zm4.49,9.52A6.66,6.66,0,0,1,41.87,38l-.77-1.31a5,5,0,0,1-1.14.66l.77,1.32a6.7,6.7,0,0,1-4.54.4,6.55,6.55,0,0,1-1-.37l.76-1.32a5,5,0,0,1-1.15-.65L34,38a6.73,6.73,0,0,1-1.93-2.11,6.57,6.57,0,0,1-.89-2.71H32.7a4.86,4.86,0,0,1,0-1.32H31.17a6.74,6.74,0,0,1,2.76-4.85l.77,1.3a6.07,6.07,0,0,1,1.13-.66l-.76-1.31a6.73,6.73,0,0,1,2.82-.62,6.82,6.82,0,0,1,1.72.22,5.6,5.6,0,0,1,1,.37l-.75,1.31a5.92,5.92,0,0,1,1.15.66L41.8,27a6.74,6.74,0,0,1,1.93,2.11,6.66,6.66,0,0,1,.88,2.71H43.1a5.82,5.82,0,0,1,0,1.32h1.51A5.79,5.79,0,0,1,44.43,34.22Z" />
                </g></g></svg>
    </Wrapper>;
};

export default Cassette;
