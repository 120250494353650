import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { H1, H2, CloseButton, NavLink, FormWrapper } from './BuyerApplicationForm.styles'
import { validateEmail, validateInstagram } from '../../helpers'
import API from '../../API'
import InputField from '../InputField'
import TextArea from '../TextArea'
import Button from '../Button'
import GridItem from '../GridItem'
import LoadingWheel from '../LoadingWheel'
import Toggle from '../Toggle'
import FormNavArrow from '../UploadFormSteps/FormNavArrow';
import FormProgressBar from '../UploadFormSteps/FormProgressBar';
import NextArrow from '../../images/formNavArrow.svg'
import { set } from 'animejs'
import { useAuth } from '../../contexts/AuthContext'
const BuyerApplicationForm = () => {
    const navigate = useNavigate()
    const { sendResetPasswordEmail } = useAuth()
    const initialPayload = {
        name: '',
        email: '',
        label: '',
        artists: '',
        instagram: '',
        website: '',
        useConcierge: true
    }

    const initialState = {
        step: 1,
        payload: initialPayload,
        placeholder: '',
        label: '',
        fieldName: Object.keys(initialPayload)[0]
    }
    const initialRender = useRef(true)
    const [state, setState] = useState(initialState)
    const [vettedEmail, setVettedEmail] = useState(null)
    const [loading, setLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [completed, setCompleted] = useState(false)
    const successPageText = useRef({
        line1: 'Submitting',
        line2: 'This may take a few minutes.',
        line3: <>If you have any trouble submitting your application, please <NavLink href="mailto:info@azaamusic.com">contact us</NavLink>.</>
    })
    const [validContinue, setValidContinue] = useState(false)
    const [error, setError] = useState(false)
    const { payload, fieldName, step, placeholder, label } = state

    const handleChange = (input) => (event) => {
        if (input !== 'useConcierge')
            setState(prev => ({
                ...prev,
                payload: {
                    ...prev.payload,
                    [input]: event.target.value
                }
            }))
        else {
            setState(prev => ({
                ...prev,
                payload: {
                    ...prev.payload,
                    useConcierge: !prev.payload.useConcierge
                }
            }))
        }
    }

    const [badEmails, setBadEmails] = useState([])
    const checkAgainstBadEmailsList = () => {
        const _checkIfTried = badEmails.find(it => it.email === payload.email)
        if (_checkIfTried) {
            setButton({
                showButton: true,
                ...buttonOptions[_checkIfTried.type]
            })
            setError(_checkIfTried.message)
            setValidContinue(false)
        } else {
            setError(false)
            setValidContinue(true)
        }
    }

    const buttonOptions = {
        'user-accepted': {
            text: 'Resend Registration Email',
            onClick: async () => {
                await API.resendUserAcceptanceEmail(payload.email)
            }
        },
        'account-exists': {
            text: 'Send Password Reset Email',
            onClick: async () => {
                await sendResetPasswordEmail(payload.email)
            }
        }
    }
    const [button, setButton] = useState({
        showButton: false,
        ...buttonOptions['user-accepted']
    })
    const [sending, setSending] = useState(false)


    const buttonOnClick = async (e) => {
        e.preventDefault()
        try {
            setSending(true)
            await button.onClick()
            setError('Email sent')
            setSending(false)
        } catch (error) {
            setSending(false)
            setError('An error occurred. Please try again later.')
        }
    }

    const checkEmail = async () => {
        try {
            setError(false)
            setLoading(true)
            setButton(prev => ({
                ...prev,
                showButton: false,
            }))
            const _checkIfTried = badEmails.find(it => it.email === payload.email)
            if (_checkIfTried)
                throw _checkIfTried.type
            if (payload.email !== vettedEmail) {
                await API.authNewSignup(payload.email)
            }
            setError(false)
            setState(prev => ({ ...prev, step: step + 1 }))
            setVettedEmail(payload.email)
            setLoading(false)
            setValidContinue(true)

        } catch (err) {
            let errMessage, errText;
            if (err.response !== undefined) {
                errMessage = err.response.data.message
                if (errMessage.includes('buyer')) {
                    errMessage = errMessage + ' If you would like you sign up for a songwriter account, please use a different email.'
                }
                errText = err.response.data.text
                setBadEmails(prev => ([...prev, { email: payload.email, type: errText, message: errMessage }]))
            } else {
                errText = err
                errMessage = badEmails.find(it => it.email === payload.email).message
            }
            setError(errMessage)
            setButton({
                showButton: true,
                ...buttonOptions[errText]
            })
            setValidContinue(false)
            setLoading(false)
        }
    }

    const validateStep = () => {
        switch (Object.keys(initialPayload)[step - 1]) {
            case 'name':
                return payload.name.replace(/\s+/g, '') !== ""
            case 'email':
                return validateEmail(payload.email)
            case 'instagram':
                return payload.instagram !== '' ? validateInstagram(payload.instagram) : true
            default:
                return true

        }
    }

    const onSubmit = async () => {
        try {
            setError(false)
            setSubmitting(true)
            const res = await API.submitBuyerApplication(payload)
            successPageText.current = {
                line1: 'Your application has been submitted for approval!',
                line2: 'You will hear from us if you are accepted.',
                line3: <></>
            }
            setSubmitting(false)
            setCompleted(true)
        } catch (error) {
            setSubmitting(false)
            setError(true)
            successPageText.current = {
                line1: 'There has been an error with your application.',
                line2: 'Please try again later.',
                line3: <>If you have further trouble submitting your application, please <NavLink href="mailto:info@azaamusic.com">contact us</NavLink>.</>
            }
            setCompleted(true)
        }
    }

    useEffect(() => {
        const getField = () => {
            let _placeholder, _label
            switch (Object.keys(initialPayload)[step - 1]) {
                case 'name':
                    _placeholder = 'Enter your name'.toUpperCase();
                    _label = "What's your name?";
                    break;
                case 'email':
                    _placeholder = 'Email address'.toUpperCase();
                    _label = "What's your email address?";
                    break;
                case 'instagram':
                    _placeholder = '@';
                    _label = "What's your Instagram Handle?"
                    break;
                case 'label':
                    _placeholder = 'Affiliation';
                    _label = "Publisher/Label/Mgmt Affiliation"
                    break;
                case 'artists':
                    _placeholder = 'Artists';
                    _label = "What artist(s) do you represent?"
                    break;
                case 'website':
                    _placeholder = 'Website';
                    _label = "Link to Website"
                    break;
                case 'useConcierge':
                    _placeholder = true;
                    _label = <><p style={{ margin: '0' }}>Would you like to use our Concierge services?</p><p style={{ fontSize: '12px', margin: '10px 0 0' }}><span >Azaa Concierge curates a custom playlist of songs based on <strong style={{ fontWeight: '700' }}>your</strong> specific needs.</span></p></>
                    break;

            }
            return { placeholder: _placeholder, label: _label }
        }
        setState(prev => ({ ...prev, ...getField(), fieldName: Object.keys(initialPayload)[step - 1] }))

    }, [step])
    useEffect(() => {
        if (validateStep()) {
            if (Object.keys(initialPayload)[step - 1] !== 'email')
                setValidContinue(true)
            else {
                checkAgainstBadEmailsList()
            }
        }
        else {
            setValidContinue(false)
        }

    }, [state, step])

    const handleNav = async (dir) => {
        if (dir > 0 && step <= Object.keys(initialPayload).length && validateStep()) {
            if (Object.keys(initialPayload)[step - 1] !== 'email')
                setState(prev => ({ ...prev, step: step + 1 }))
            else {
                try {
                    setLoading(true)
                    await checkEmail()
                    setLoading(false)
                } catch (error) {
                    setLoading(false)

                }

            }
        }
        else if (step > 1 && dir < 0) {
            setState(prev => ({ ...prev, step: step - 1 }))
        }
    }

    const continueKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (validateStep()) {
                handleNav(1)

            }
        }
    }
    console.log(payload)

    return (
        <GridItem
            fontSize="1rem"
            background="#000000"
            display="grid"
            gridTempRows="min-content 1fr"
            gridTempCols="1fr"
            zIndex="4"
            width="100%"
            height="100vh">
            {(submitting || completed) &&
                <GridItem gridCol="1 / span 2" gridRow="1 / span 2" position="absolute" left="0" top="0" height="100%" style={{ flexGrow: '1' }} justifyContent="center" alignItems="center">
                    <H1 style={{ textTransform: 'none', textAlign: 'center' }}>{successPageText.current.line1}</H1>
                    <H2 style={{ textTransform: 'none', textAlign: 'center' }}>{successPageText.current.line2}</H2>
                    <H2 style={{ textTransform: 'none', textAlign: 'center' }}>{successPageText.current.line3}</H2>
                    {!completed && <LoadingWheel hideWrapper position="relative" width="100%"></LoadingWheel>}
                    {
                        (completed && !error) &&
                        <GridItem gridTempCols="1fr minmax(30%,198px) minmax(30%,198px) 1fr" gridColGap="20px" display="grid">
                            <GridItem gridCol=" 2 / span 1">
                                <Button
                                    onClick={() => navigate('/faq')}
                                    text="Learn More"
                                    width="100%"
                                    background="transparent"
                                    border="2px solid rgba(255,255,255,0.25)"
                                ></Button>
                            </GridItem>
                            <GridItem gridCol=" 3 / span 1">
                                <Button
                                    onClick={() => navigate('/')}
                                    text="Home"
                                    width="100%"
                                    background="transparent"
                                    border="2px solid rgba(255,255,255,0.25)"
                                ></Button>
                            </GridItem>
                        </GridItem>
                    }
                </GridItem>
            }
            {!(submitting || completed) && <>
                <GridItem
                    width="100%"
                    alignItems="start"
                    background="var(--greyBackground)"
                    height="5px">
                    <GridItem
                        transition="width 0.5s"
                        width={parseInt((step) / Object.keys(initialPayload).length * 100) + '%'}
                        background="#ffffff"
                        height="5px"></GridItem>
                </GridItem>
                <FormWrapper
                    display="grid"
                    gridTempCols="min-content 442px min-content"
                    width="100%"
                    gridColGap="20px"
                    height="100%"
                    justifyContent="center"
                    position="relative"
                    alignContent="center"
                    alignItems="center"
                >
                    <GridItem gridArea="back">
                        <FormNavArrow
                            direction='back'
                            text={'back'}
                            onClick={() => { handleNav(-1) }}
                            continueValid={step > 1}
                        />
                    </GridItem>
                    <GridItem gridArea="next">
                        <FormNavArrow
                            direction='next'
                            text={step === Object.keys(initialPayload).length ? 'submit' : 'next'}
                            onClick={step === Object.keys(initialPayload).length ? onSubmit : () => { handleNav(1) }}
                            continueValid={validContinue}
                        />
                    </GridItem>
                    <GridItem width="100%" margin="0" position="relative" gridArea="content">
                        <GridItem flexDirection="column" alignItems="start" justifyContent="flex-end" fontSize="1rem" position="absolute" bottom={fieldName === 'useConcierge' ? "45px" : fieldName === 'artists' ? "60px" : "60px"}>{label}</GridItem>
                        {(fieldName !== 'artists' && fieldName !== 'useConcierge') && <InputField
                            onKeyPress={continueKey}
                            type={fieldName === 'email' ? "email" : 'text'}
                            onChange={handleChange(`${fieldName}`)}
                            name={fieldName}
                            width="100%"
                            // label={label}
                            placeholder={placeholder}
                            focusOnMount={true}
                            marginBottom={'20px'}
                            value={payload[fieldName]}
                            uppercaseOff={true} />}
                        {
                            fieldName === 'artists' && <TextArea margin="0 0 -51px" padding="20px" height="100px" onChange={handleChange(`${fieldName}`)} value={payload.artists} />
                        }
                        {
                            fieldName === 'useConcierge' && <Toggle onChange={handleChange(`${fieldName}`)} />
                        }
                    </GridItem>
                    {(button.showButton && fieldName === 'email' && badEmails.findIndex(it => it.email === payload.email) >= 0) &&
                        <GridItem gridArea="content" position="absolute" margin="200px 0 0" width="100%" display="grid" gridTempCols="1fr">
                            <Button
                                hoverColor="#ffffff"
                                color="var(--background)"
                                background="#ffffff"
                                disabled={loading || sending}
                                border={!loading && !sending ? "1px solid #ffffff" : '1px solid rgba(255,255,255,0.1)'}
                                validContinue={!loading && !sending}
                                width="100%"
                                text={button.text}
                                onClick={buttonOnClick}
                            />
                            <div style={{ height: '45px', marginTop: '20px' }}>{error}</div>
                        </GridItem>

                    }

                </FormWrapper>
            </>}
            <CloseButton to="/">&#10006;</CloseButton>
        </GridItem>
    )
}

export default BuyerApplicationForm