import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginOverlay from '../LoginOverlay'

import { useParams } from 'react-router-dom'

import { Wrapper } from './Register.styles'

import API from '../../API'

import {
    SET_PASSWORD_POPUP
} from '../../config.js'
import GridItem from '../GridItem'

const Register = () => {
    const navigate = useNavigate()
    const { regKey } = useParams();
    const [popupType, setPopupType] = useState(SET_PASSWORD_POPUP)
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState({ id: '', email: '', password: '', user_agreement: '' })
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showLogin, setShowLogin] = useState(false)

    useEffect(() => {
        const authUser = async (registration_key = null) => {
            try {
                setError(false);
                setLoading(true);
                const res = await API.authenticateAcceptance(registration_key);
                setUser(prev => ({ ...prev, id: res[0].id, email: res[0].email, user_agreement: res[0].user_agreement }))

            } catch (error) {
                setError(true);
                console.log(error.response.data.message)
                if (error.response.data.message.toLowerCase().includes('user has not been accepted'))
                    setErrorMessage(<><h2 style={{ textTransform: 'none' }}>The registration key provided is invalid or has already been used.</h2><br />
                        <p style={{ fontSize: '1rem' }}>Please sign in or reset your password via the "Log In" button.
                        <br />If you believe this is an error and you have not already registered your account, please <span style={{cursor: 'pointer', fontWeight: 'bold', color: 'var(--yellow)'}} onClick={() => navigate('/contact-us')}>contact us</span>.</p>
                        <br />If you are trying to add a new contributor to an accepted song that you submitted, please do so via the "Recently Accepted" page after logging in.<br />
                        If you are trying to add a new contributor to an accepted song that another songwriter submitted, please contact them off-platform so that they may add them via the "Recently Accepted" page.</>)
                else
                    setErrorMessage(<p>An error occurred while attempting to retrieve the user information associated with this registration key. Please contact the site administrator.</p>)
            }
            setLoading(false);
        }
        authUser(regKey)

    }, [])

    if (loading) return <Wrapper></Wrapper>
    else if (error) return <Wrapper><GridItem alignText="center" height="100vh" width="100%" alignItems="center" justifyContent="center">{errorMessage}</GridItem></Wrapper>
    else {
        return (
            <Wrapper>
                <LoginOverlay
                    user={user}
                    regKey={regKey}
                    setShowLogin={setShowLogin}
                    setUser={setUser}
                    popupType={popupType}
                    setPopupType={setPopupType}
                />
            </Wrapper>
        )
    }

}

export default Register
