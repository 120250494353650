import React from 'react'
import { Wrapper } from './TermsAndConditions.styles'
import TermsAndConditionsPdf from '../../images/AzaaTermsandConditions2022.pdf'
const TermsAndConditions = () => {
    return (
        <Wrapper>
            <object data={TermsAndConditionsPdf} type="application/pdf" >
                alt : <a href={TermsAndConditionsPdf}>Azaa Terms And Conditions.pdf</a>
            </object>
        </Wrapper>
    )
}

export default TermsAndConditions