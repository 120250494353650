import React from 'react'

import { Wrapper, Progression } from './FormProgressBar.styles'

const FormProgressBar = ({step, totalLength, userDetailsStep}) => {
    console.log("total lengt " + totalLength, "step " + step)
    const progress = (step + userDetailsStep) / totalLength * 100 + '%';

    return (
        <Wrapper>
            <Progression 
                step={progress}
            />
        </Wrapper>
    )
}

export default FormProgressBar
