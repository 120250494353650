import styled from 'styled-components'

export const DropDownContainerBox = styled.div`
position: absolute;
right: ${props => props.right ? props.right : '3.22vw'};
z-index: 10;
bottom: ${props => props.bottom ? props.bottom : '3.22vw'};
`;

export const Action = styled.div`

    width: 50px;
height: 50px;


position: relative;
display: flex;
align-items: center;
justify-content: center;
`;

export const DotMenuWrapper = styled(Action)`

svg{
    height: 15px;
}

`;