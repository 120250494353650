import React, { memo, useMemo, useState, useEffect } from 'react'

import { Wrapper, StyledSlider } from './Slider.styles'
import GridItem from '../GridItem'
import Flexbox from '../Flexbox'

const RangeSlider = memo(
    ({ classes, label, onChange, value, rotate, ...sliderProps }) => {
        const [sliderVal, setSliderVal] = useState(value);
        const [mouseState, setMouseState] = useState(null);
        const { min, max } = sliderProps;
        useEffect(() => {
            setSliderVal(value);
        }, [value]);

        const changeCallback = e => {
            setSliderVal(e.target.value);
        };

        useEffect(() => {
            if (mouseState === "up") {
                onChange(sliderVal);
            }
        }, [mouseState]);
        return (
            <>
                <Flexbox column className="range-slider" relative alignItems="start">
                    <p style={{ marginTop: '0' }}>{label}</p>
                    {/* <h3>value: {sliderVal}</h3> */}
                    {/* <StyledSlider
                    type="range"
                    value={sliderVal}
                    {...sliderProps}
                    className={`slider ${classes}`}
                    id="myRange"
                    onChange={changeCallback}
                    onMouseDown={() => setMouseState("down")}
                    onMouseUp={() => setMouseState("up")}
                /> */}
                    <GridItem alignItems="center" className="range-slider" relative display="grid"
                        gridTempRows={rotate ? "1rem 1fr 1rem" : "1rem"}
                        gridTempCols={rotate ? "1fr" : "1rem 1fr 1rem"}
                        gridColGap={"1rem"}
                        gridRowGap={"1rem"}>
                        <Flexbox color="#6e6e6e" justifyContent="end">{min}</Flexbox>
                        <Flexbox relative transform={rotate ? 'rotate(90deg)' : undefined}>
                            <StyledSlider
                                type="range"
                                value={sliderVal}
                                rotate={rotate}
                                {...sliderProps}
                                onChange={changeCallback}
                                onMouseDown={() => setMouseState("down")}
                                onMouseUp={() => setMouseState("up")}
                            />
                        </Flexbox>
                        <Flexbox color="#6e6e6e" justifyContent="start">{max}</Flexbox>
                    </GridItem>
                </Flexbox>
            </>
        );
    }
);

const Slider = ({
    name,
    value,
    onChange,
    range,
    min = 0,
    max = 100,
    step = 2,
    height = "36px",
    lowerBackground = 'var(--greyBackground)',
    upperBackground = 'var(--greyBackground)',
    background = 'var(--greyBackground)',
    thumbDiameter = '20px',
    thumbWidth,
    thumbHeight,
    borderRadius,
    upperColor = 'red',
    lowerColor = 'green',
    thumbColor = "pink",
    rotate }) => {

    return (
        <RangeSlider {...{
            onChange,
            value,
            min: range.length ? range[0] : min,
            max: range.length ? range[1] : max,
            step,
            thumbColor,
            height,
            background,
            lowerBackground,
            upperBackground,
            thumbDiameter,
            thumbWidth,
            borderRadius,
            thumbHeight,
            upperColor,
            lowerColor,
            label: name,
            rotate
        }} />

    )
}

export default Slider