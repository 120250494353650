import React from 'react'
import { useNavigate } from 'react-router-dom'
import AzaaLogo from '../../images/headerLogo.svg'
import { Wrapper, BigText } from './NotFound.styles'

import Button from '../Button'

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <Wrapper>
            <img src={AzaaLogo} alt="Azaa Logo" />
            <BigText>
                <p className="glitch">
                    <span className="glitch">404</span>
                    404
                    <span className="glitch">404</span>
                </p>

            </BigText>
            <h2>Page Not Found</h2>
            <p>This is not the page you're looking for.</p>
            <Button width="260px" border="1px solid #ffffff" onClick={() => { navigate('/') }}>Take Me Home</Button>
        </Wrapper >
    )
}

export default NotFound