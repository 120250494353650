import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { throttle } from '../../utils'
// Components
import Button from '../Button';

// images
import ComputerImage from "../../images/section_2_computers@4x.png"
import VizVid from '../../images/Azaa-Vis2.mp4';
import AzaaVizStill from '../../images/azaaVizStill.png'
import MonitorLarge from '../../images/monitorLarge@4x.png'
import MonitorSmall from '../../images/monitorSmall@4x.png'

import { StillFrame, ButtonContainer, MonitorLargeFrame, MonitorSmallFrame, VidContainer, Computercontainer, Wrapper, Content, InnerContent, Text, H1, Video } from './HomeIntro.styles';

const HomeIntro = ({ background }) => {
    const navigate = useNavigate();
    const videoRef1 = useRef(null)
    const videoRef2 = useRef(null)
    const imageRef1 = useRef(null)
    const imageRef2 = useRef(null)
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    useEffect(() => {
        console.log(windowSize)
        if (windowSize > 600
            && videoRef1.current !== null
            && imageRef1.current !== null
            && videoRef2.current !== null
            && imageRef2.current !== null
        ) {
            videoRef1.current.src = VizVid
            videoRef2.current.src = VizVid

        }

    }, [windowSize])
    useEffect(() => {
        let isMounted = true;
        const handleResize = throttle(() => {
            if (isMounted) {
                setWindowSize(window.innerWidth)
            }
        }, 1000)
        window.addEventListener("resize", handleResize);
        return () => {
            isMounted = false
            window.removeEventListener("resize", handleResize)
        };
    }, [])
    return (
        <Wrapper background={background}>
            <H1>Azaa is the first highly-vetted song marketplace built by songwriters.</H1>
            <Content>
                <InnerContent>
                    <Text>
                        Songwriters - <strong>get paid</strong>.<br />
                        A&R's - <strong>find your next hit</strong>.<br />
                        We make all that easy. <br />
                        Welcome to <strong>Azaa.</strong> <br />
                    </Text>
                    {/* <button>Learn More</button> */}
                    <ButtonContainer>
                        <Button
                            width="100%"
                            background="transparent"
                            border="2px solid rgba(255,255,255,0.25)"
                            onClick={() => { navigate('/faq') }}
                            text="Learn More"
                        />
                    </ButtonContainer>
                </InnerContent>
                <Computercontainer>
                    <MonitorLargeFrame>
                        <img src={MonitorLarge} />
                        <VidContainer largeMonitor>
                            <Video
                                ref={videoRef1}
                                width="100%"
                                height="auto"
                                autoPlay={true}
                                loop={true}
                                controls={false}
                                playsInline
                                muted
                                onLoadedData={() => {
                                    if (windowSize > 600
                                        && videoRef1.current !== null
                                        && imageRef1.current !== null
                                    ) {
                                        imageRef1.current.style.zIndex = -1;
                                    }
                                }}
                            />
                            <StillFrame ref={imageRef1} src={AzaaVizStill}/>
                        </VidContainer>
                    </MonitorLargeFrame>
                    <MonitorSmallFrame>
                        <img src={MonitorSmall} />
                        <VidContainer>
                            <Video
                                ref={videoRef2}
                                width="100%"
                                height="auto"
                                autoPlay={true}
                                loop={true}
                                controls={false}
                                playsInline
                                muted
                                onLoadedData={() => {
                                    if (windowSize > 600
                                        && videoRef2.current !== null
                                        && imageRef2.current !== null
                                    ) {
                                        imageRef2.current.style.zIndex = -1;
                                    }
                                }}
                            />
                            <StillFrame src={AzaaVizStill} ref={imageRef2} />
                        </VidContainer>
                    </MonitorSmallFrame>

                </Computercontainer>
            </Content>
        </Wrapper>
    )
}

export default HomeIntro;
